import { useEffect, useState } from 'react';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';

import { Global, css } from '@emotion/react';

import { COLORS } from '@clutter/clean';

import { BallLoader } from '@shared/ball_loader';

import { GuideFlow } from './guide/guide_flow';
import { isGuideIncentive } from './guide/types/guide_incentive';
import qs from 'qs';

const loadingStyles = css`
  body,
  html {
    background: ${COLORS.tealJungle};
  }
`;

const styles = css`
  body,
  html {
    background: white;
  }
`;

type QueryParams = { a: string | undefined; i: string | undefined };

export const Guide: React.FC<RouteProps> = ({ location }) => {
  const [search, setSearch] = useState<string | undefined>(undefined);

  // While the query params are loading, show a ball loader
  useEffect(() => setSearch(location?.search ?? ''));
  if (search === undefined) {
    return (
      <>
        <Global styles={loadingStyles} />
        <BallLoader />
      </>
    );
  }

  const { a: accountId = '', i: guideIncentive }: QueryParams = qs.parse(
    search,
    {
      ignoreQueryPrefix: true,
    },
  );

  return (
    <>
      <Global styles={styles} />
      <GuideFlow
        accountId={accountId}
        guideIncentive={
          isGuideIncentive(guideIncentive) ? guideIncentive : 'stress'
        }
      />
    </>
  );
};
