import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export { StyledLink as Link };

export const Paragraph = styled.div`
  margin-bottom: 16px;
`;
