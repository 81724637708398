import * as React from 'react';

const { useState, useEffect, useRef } = React;

type UseSlideTuple<T extends HTMLElement> = [
  React.RefObject<T>, // Ref for the inner element with content
  string, // Max height of the outer eleement
  boolean, // is open
  (isOpen?: boolean) => void, // toggle is open (or set to provided boolean)
];

export function useSlide<T extends HTMLElement>(
  initalIsOpen: boolean,
  maxHeightOverride?: number,
): UseSlideTuple<T> {
  const [isOpen, setIsOpen] = useState(initalIsOpen);
  const [maxHeight, setMaxHeight] = useState('0px');
  const ref = useRef<T>(null);
  useEffect(() => {
    setMaxHeight(
      `${isOpen ? maxHeightOverride || ref.current?.clientHeight : 0}px`,
    );
  }, [isOpen]);

  return [
    ref,
    maxHeight,
    isOpen,
    (nextIsOpen?: boolean) =>
      setIsOpen((currentIsOpen) =>
        nextIsOpen === undefined ? !currentIsOpen : nextIsOpen,
      ),
  ];
}
