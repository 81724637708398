import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Box, Button, COLORS, Modal, Text, mq } from '@clutter/clean';

import { CounterCard } from '@root/components/checkout/product_pages/subcomponents/plan_estimator_modal/item_card';
import { SearchBar } from '@root/components/checkout/product_pages/subcomponents/plan_estimator_modal/search_bar';
import {
  SelectedItem,
  SelectedItems,
} from '@root/components/checkout/product_pages/subcomponents/plan_estimator_modal/types';
import { PlanKey } from '@root/resources/types/plan_key';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { planForCuft } from '@utils/plan';

const MAX_CONTENT_WIDTH = '800px';

const Container = styled.div`
  height: calc(100vh - 12px); /* Fallback */
  height: calc(100dvh - 12px);
  max-width: 1400px;

  ${mq({
    width: ['calc(100vw - 12px)', '90vw'],
    height: [null, '90vh'],
  })}
`;

export const PlanEstimatorModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose(plan?: PlanKey): void;
}) => {
  const [selectedItems, setSelectedItems] = useState<SelectedItems>(new Map());

  const onSubmit = () => {
    const cuft = Array.from(selectedItems.values()).reduce(
      (acc, cur) => acc + cur.category.cuft * cur.quantity,
      0,
    );
    onClose(planForCuft(cuft));
  };

  const displayItems = Array.from(selectedItems.values());

  const { isMobile } = useBreakpoints();

  if (isMobile) displayItems.reverse();

  const onChangeCount = (item: SelectedItem, newQuantity: number | null) => {
    const newItems = new Map(selectedItems);
    if (newQuantity === null) newItems.delete(item.category.id);
    else
      newItems.set(item.category.id, {
        quantity: newQuantity,
        category: item.category,
      });

    setSelectedItems(newItems);
  };

  return (
    <Modal includeCloseButton isOpen={isOpen} handleModalClose={onClose}>
      <Container>
        <Box.Flex flexDirection="column" height="100%">
          <Box.FlexItem flexGrow={0}>
            <Box margin="24px" textAlign="center">
              <Text.SmallCaps>Storage Builder</Text.SmallCaps>
            </Box>
          </Box.FlexItem>
          <Box.FlexItem flexGrow={1} background={COLORS.grayBackground}>
            <Box
              padding="0 24px"
              margin="32px auto"
              maxWidth={MAX_CONTENT_WIDTH}
            >
              <Text.Title size="small">Tell us what you’re storing</Text.Title>
              <Text.Callout>
                The more items you add, the better we can estimate your storage
                plan.
              </Text.Callout>
              <Box margin="24px 0 0">
                <SearchBar
                  selectedItems={selectedItems}
                  onSelectedItemsChange={setSelectedItems}
                />
              </Box>
              <Box margin="24px 0 0">
                {displayItems.map((item) => (
                  <Box key={item.category.id} margin="12px 0">
                    <CounterCard
                      item={item.category}
                      count={item.quantity}
                      onChange={(count) => onChangeCount(item, count)}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box.FlexItem>
          <Box.FlexItem flexGrow={0}>
            <Box
              margin="0 auto"
              padding="16px"
              textAlign="right"
              maxWidth="1190px"
            >
              <Button disabled={selectedItems.size === 0} onClick={onSubmit}>
                Estimate my plan
              </Button>
            </Box>
          </Box.FlexItem>
        </Box.Flex>
      </Container>
    </Modal>
  );
};
