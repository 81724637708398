import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { NetworkStatus } from '@apollo/client';

import {
  Breadcrumbs,
  Button,
  COLORS,
  FontWeight,
  ResponsiveContainer,
  SkeletonLoader,
  Text,
  mq,
} from '@clutter/clean';

import {
  Help__Article,
  Help__Category,
  Help__Section,
  useHelpSearchQuery,
} from '@graphql/landing';

import { List } from './article/list';
import { Search } from './helpers/search';
import { Footer } from './layout/footer';
import { FooterCategories } from './layout/footer_categories';
import { FooterSupport } from './layout/footer_support';
import { articleURL, mainURL } from './routes';
import { searchBreadcrumbs, sectionBreadcrumbs } from './utils/breadcrumbs';
import { Repeat } from './utils/repeat';

const ArticleEntry = styled.div`
  display: block;
  padding: 32px 0;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

const ArticleLink = styled(Link)`
  display: block;
`;

const ArticleTitle = styled.div`
  margin: 0 0 8px;
`;

const ArticleSummary = styled.div`
  margin: 8px 0 0;
`;

const ArticleBreadcrumbs = styled.div`
  display: block;
  margin: 8px 0 0;
`;

const Article: React.FC<{
  article: Pick<Help__Article, 'id' | 'title' | 'summary'> & {
    section: Pick<Help__Section, 'id' | 'name'> & {
      category: Pick<Help__Category, 'id' | 'name'>;
    };
  };
}> = ({ article }) => (
  <ArticleEntry>
    <ArticleLink to={articleURL(article.id)}>
      <ArticleTitle>
        <Text.Headline weight={FontWeight.Medium}>
          {article.title}
        </Text.Headline>
      </ArticleTitle>
      <ArticleSummary>
        <Text.Body>{article.summary}</Text.Body>
      </ArticleSummary>
    </ArticleLink>
    <ArticleBreadcrumbs>
      <Breadcrumbs links={sectionBreadcrumbs(article.section)} />
    </ArticleBreadcrumbs>
  </ArticleEntry>
);

const FakeResult: React.FC = () => (
  <ArticleEntry>
    <ArticleTitle>
      <SkeletonLoader width="100%" height="27px" />
    </ArticleTitle>
    <ArticleSummary>
      <SkeletonLoader width="100%" height="48px" />
    </ArticleSummary>
    <ArticleBreadcrumbs>
      <SkeletonLoader width="100%" height="20px" />
    </ArticleBreadcrumbs>
  </ArticleEntry>
);

const Container = styled.div`
  ${mq({
    display: ['block', null, 'grid'],
    gap: [null, null, '88px 32px'],
    grid: [null, null, 'auto / 3fr 2fr'],
    marginTop: ['44px', null, '64px'],
  })}
`;

const MoreButton = styled(Button)`
  margin: 32px 0 0;
`;

const MainLink = styled(Link)`
  color: ${COLORS.tealPrimary};
  font-weight: ${FontWeight.Medium};
`;

const Content = styled.div`
  ${mq({
    margin: ['0 0 32px', null, '0'],
  })}
`;

const Sidebar = styled.div`
  ${mq({
    margin: ['32px 0 0', null, '0'],
  })}
`;

export const SearchResults: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get('q');

  const { data, fetchMore, networkStatus } = useHelpSearchQuery({
    variables: { query: query! },
    skip: !query,
    notifyOnNetworkStatusChange: true,
  });
  if (!query) return <Redirect to={mainURL()} />;

  const paginated = data?.search;
  const pagination = paginated?.pagination;
  const paging = networkStatus === NetworkStatus.fetchMore;

  const onFetchMore = () => {
    if (paging) return;
    if (!pagination) return;
    fetchMore({ variables: { page: pagination.currentPage + 1 } });
  };

  return (
    <div>
      <Helmet>
        <title>Clutter | Help - Search</title>
      </Helmet>
      <ResponsiveContainer>
        <Text.Title size="medium">We’re Here to Help</Text.Title>
        <Search />
        <Breadcrumbs links={searchBreadcrumbs(query, pagination?.totalCount)} />

        <Container>
          <Content>
            <Text.Title size="medium">
              Showing search results for “{query}”
            </Text.Title>
            {paginated?.results.length === 0 && (
              <Text.Headline>
                No results for “{query}“.{' '}
                <MainLink to={mainURL()}>Browse Help Center</MainLink>
              </Text.Headline>
            )}
            {paginated?.results ? (
              paginated?.results.map((article) => (
                <Article key={article.id} article={article} />
              ))
            ) : (
              <Repeat times={2} component={FakeResult} />
            )}
            {pagination && pagination.currentPage < pagination.totalPages && (
              <MoreButton
                fullWidth
                loading={paging}
                disabled={paging}
                kind="secondary"
                onClick={onFetchMore}
              >
                See More Results
              </MoreButton>
            )}
          </Content>
          <Sidebar>
            <List articles={data?.articles} title="Featured Articles" />
          </Sidebar>
        </Container>
      </ResponsiveContainer>
      <Footer>
        <FooterCategories display={['none', null, 'block']} />
        <FooterSupport />
      </Footer>
    </div>
  );
};
