import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text, TextButton, mq } from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { ResponsiveImage } from '@shared/responsive_image';

import { useFreePickupReturnEligible } from '@utils/hooks/use_free_pickup_return_eligible';

import truck from '@images/illustrations/mover_with_sixteen_foot_truck.svg';
import photoCatalog from '@images/illustrations/online_photo_catalog_small.svg';
import boxes from '@images/illustrations/standard_materials_boxes.svg';
import facility from '@images/illustrations/storage_facility.svg';
import storageHeroImage from '@images_responsive/www/checkout/smart_storage_product_detail_hero.jpg';

const ValuePropContainer = styled.div`
  display: flex;
  gap: 16px;
  ${mq({
    padding: ['16px 24px 8px', null, null, '32px'],
  })}
`;

const Illustration = styled.img`
  height: 42px;
  margin-bottom: 8px;
`;

const ImageContainer = styled.div`
  max-width: 748px;

  ${mq({
    display: ['none', null, null, 'block'],
  })}
`;

const Image = styled(ResponsiveImage)`
  border-radius: 4px;
  max-height: 344px;
  width: 100%;
`;

const ScrollContainer = styled.div`
  background: linear-gradient(
    ${COLORS.cloud} 0 50%,
    ${COLORS.grayBackground} 50% 100%
  );
  bottom: 0;
  z-index: 1;
  left: 50%;
  overflow-y: hidden;
  ${mq({
    overflowX: ['auto', null, null, 'visible'],
    width: ['initial', null, null, '92vw'],
    maxWidth: ['initial', null, null, '1230px'],
    position: ['initial', null, null, 'absolute'],
    transform: ['initial', null, null, 'translateX(-50%)'],
    background: [null, null, null, 'none'],
  })}
`;

export const SmartStorageHero: React.FC<{ onShowFeaturesOverlay(): void }> = ({
  onShowFeaturesOverlay,
}) => {
  const track = useTrack({ action: 'click' });
  const freePickupReturnEligible = useFreePickupReturnEligible();

  const handleClick = () => {
    track({
      objectName: 'features',
      objectType: 'button',
      container: 'hero',
      label: 'Learn more',
    });
    onShowFeaturesOverlay();
  };
  return (
    <Box
      height={['initial', null, null, '424px']}
      background={COLORS.cloud}
      position="relative"
    >
      <Box.Flex
        padding="32px 32px 0"
        justifyContent={['left', null, null, 'center']}
      >
        <ImageContainer>
          <Image image={storageHeroImage} sizes="50vw" alt="" loading="eager" />
        </ImageContainer>
        <Box margin={['0 0 24px', null, null, '64px']}>
          <Text.Display>Smart Storage</Text.Display>
          <Box margin={['4px 0 12px', null, null, '4px 0 0']}>
            <Text.Headline>
              We pick it up, store it and bring it back.
              <br />
              All for the same price as traditional self-storage.
            </Text.Headline>
          </Box>
          <Box display={['initial', null, null, 'none']}>
            <TextButton size="medium" onClick={handleClick}>
              Learn more
            </TextButton>
          </Box>
        </Box>
      </Box.Flex>
      <ScrollContainer>
        <Box
          width={['250vw', '150vw', '120vw', 'initial']}
          margin={['0 40px 0', null, null, 0]}
          background={COLORS.cloud}
          border={`1px solid ${COLORS.grayBorder}`}
          borderRadius="4px"
          minHeight="140px"
        >
          <ValuePropContainer>
            <Box.FlexItem textAlign="center" flexBasis="25%">
              <Illustration src={truck} alt="" />
              <Text.Callout>
                {freePickupReturnEligible ? (
                  <>We’ll pick up and inventory your items - furniture too.</>
                ) : (
                  <>
                    We’ll photograph and pick up anything you’d like to store.
                  </>
                )}
              </Text.Callout>
            </Box.FlexItem>
            <Box.FlexItem textAlign="center" flexBasis="25%">
              <Illustration src={boxes} alt="" />
              <Text.Callout>
                We’ll wrap your items using free packing materials to protect
                them.
              </Text.Callout>
            </Box.FlexItem>
            <Box.FlexItem textAlign="center" flexBasis="25%">
              <Illustration src={facility} alt="" />
              <Text.Callout>
                We’ll safely and securely store your items in a
                temperature-controlled facility.
              </Text.Callout>
            </Box.FlexItem>
            <Box.FlexItem textAlign="center" flexBasis="25%">
              <Illustration src={photoCatalog} alt="" />
              <Text.Callout>
                We’ll deliver and ship items from your online inventory
                anywhere, anytime.
              </Text.Callout>
            </Box.FlexItem>
          </ValuePropContainer>
        </Box>
      </ScrollContainer>
      <Box
        width="100%"
        height="80px"
        bottom="0"
        position="absolute"
        display={['none', null, null, 'block']}
        background={COLORS.grayBackground}
      />
    </Box>
  );
};
