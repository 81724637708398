import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import {
  Box,
  Breadcrumbs,
  ExternalContent,
  ResponsiveContainer,
  SkeletonLoader,
  Text,
  mq,
} from '@clutter/clean';

import { Help__Article, useHelpArticleQuery } from '@root/graphql/landing';

import { List } from './article/list';
import { RelatedSections } from './article/related_sections';
import { Review } from './article/review';
import { Search } from './helpers/search';
import { Footer } from './layout/footer';
import { FooterCategories } from './layout/footer_categories';
import { FooterSupport } from './layout/footer_support';
import { mainURL } from './routes';
import { articleBreadcrumbs } from './utils/breadcrumbs';

const Container = styled.div`
  display: grid;
  gap: 88px 132px;

  ${mq({
    grid: ['auto auto / auto', null, 'auto / 3fr 2fr'],
    marginTop: ['44px', null, '120px'],
  })}
`;

const ArticleContent: React.FC<{
  article?: Pick<Help__Article, 'id' | 'title' | 'body'>;
}> = ({ article }) => (
  <div>
    {article ? (
      <Text.Title size="large">{article.title}</Text.Title>
    ) : (
      <SkeletonLoader width="100%" height="54px" />
    )}

    <Box margin="16px 0 32px">
      {article ? (
        <ExternalContent dangerouslySetInnerHTML={{ __html: article.body }} />
      ) : (
        <SkeletonLoader width="100%" height="44px" />
      )}
    </Box>

    <Review />
  </div>
);

export const Article: React.FC = () => {
  const { articleID } = useParams<{ articleID: string }>();
  const { data } = useHelpArticleQuery({
    variables: { id: articleID },
  });

  const article = data?.article;
  const section = article?.section;
  const category = section?.category;

  if (article === null) return <Redirect to={mainURL()} />;

  const title = ['Clutter | Help', article?.title]
    .filter((component) => component !== undefined)
    .join(' - ');

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ResponsiveContainer>
        <Text.Title size="medium">We’re Here to Help</Text.Title>
        <Search />
        {article ? (
          <Breadcrumbs links={articleBreadcrumbs(article)} />
        ) : (
          <SkeletonLoader width="100%" height="20px" />
        )}

        <Container>
          <ArticleContent article={article} />
          <div>
            <List
              title="Related Articles"
              articles={article?.section.articles}
            />
            <RelatedSections category={category} />
          </div>
        </Container>
      </ResponsiveContainer>
      <Footer>
        <FooterSupport />
        <FooterCategories display={['none', null, 'block']} />
      </Footer>
    </div>
  );
};
