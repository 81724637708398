import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, Text, mq } from '@clutter/clean';

import movingTeam from '@images_responsive/www/services/moving_team.jpg';

type Content = {
  headline: React.ReactNode;
  description: React.ReactNode;
};

interface IProductTeam {
  title: React.ReactNode;
  content: Content[];
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  ${mq({
    padding: ['64px 24px 48px', null, '120px 24px'],
  })};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;

  ${mq({ textAlign: ['center', null, 'left'] })}
`;

const Section = styled.div`
  margin-bottom: 32px;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
  white-space: nowrap;

  ${mq({ marginBottom: ['24px', null, '40px'] })}
`;

const Headline = styled(Text.Headline)`
  margin-bottom: 8px;
`;

const DesktopImageContainer = styled.img`
  border-radius: 8px;
  margin: auto 0 auto 80px;
  width: 517px;

  ${mq({ display: ['none', null, 'block'] })}
`;

const MobileImageContainer = styled.img`
  border-radius: 8px;
  margin: 0 0 40px;

  ${mq({ display: ['block', null, 'none'] })}
`;

const Content: React.FC<{ content: Content[] }> = ({ content }) => (
  <div>
    {content.map((section, i) => (
      <Section key={i}>
        <Headline weight={FontWeight.Medium}>{section.headline}</Headline>
        <Text.Body>{section.description}</Text.Body>
      </Section>
    ))}
  </div>
);

const ProductTeam: React.FC<IProductTeam> = ({ title, content }) => {
  return (
    <Container>
      <ContentContainer>
        <Title size="large">{title}</Title>
        <MobileImageContainer src={movingTeam.src} alt="" />
        <Content content={content} />
      </ContentContainer>
      <DesktopImageContainer src={movingTeam.src} alt="" />
    </Container>
  );
};

export { ProductTeam };
