/* @jsx jsx */
import * as React from 'react';
import { Link } from 'react-router-dom';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { COLORS, FontWeight, SkeletonLoader, mq } from '@clutter/clean';

import { Help__Category, Help__Section } from '@graphql/landing';

import { Chevron } from '../helpers/chevron';
import { sectionURL } from '../routes';
import { Repeat } from '../utils/repeat';

const Container = styled.div`
  ${mq({
    margin: [null, null, '8px 0'],
  })}
`;

const SectionLink = styled(Link)`
  display: block;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${COLORS.grayBorder};
  }
`;

const SectionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: ${FontWeight.Medium};

  ${mq({
    padding: ['30px 0', null, '20px 0'],
    fontSize: ['16px', null, '18px'],
  })}
`;

const SectionName = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

const SectionChevron = styled(Chevron)`
  flex-grow: 0;
  flex-shrink: 0;
  ${mq({
    display: ['none', null, 'block'],
  })}
`;

const Section: React.FC<{
  category: Pick<Help__Category, 'id'>;
  section: Pick<Help__Section, 'id' | 'name'>;
}> = ({ category, section }) => (
  <SectionLink to={sectionURL(category.id, section.id)} key={section.id}>
    <SectionContainer>
      <SectionName>{section.name}</SectionName>
      <SectionChevron direction="right" />
    </SectionContainer>
  </SectionLink>
);

const FakeSection: React.FC = () => (
  <SectionContainer>
    <SkeletonLoader width="100%" height="23px" />
  </SectionContainer>
);

export const Sections: React.FC<{
  category?: Pick<Help__Category, 'id'> & {
    sections: ReadonlyArray<Pick<Help__Section, 'id' | 'name'>>;
  };
}> = ({ category }) => (
  <Container>
    {category ? (
      category.sections.map((section) => (
        <Section key={section.id} category={category} section={section} />
      ))
    ) : (
      <Repeat times={2} component={FakeSection} />
    )}
  </Container>
);
