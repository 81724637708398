import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text, TextButton, mq } from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { ResponsiveImage } from '@shared/responsive_image';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import calendar from '@images/illustrations/calendar.svg';
import moverWithBoxes from '@images/illustrations/mover_with_boxes_small.svg';
import moversWithTV from '@images/illustrations/movers_with_tv_small.svg';
import onlineQuote from '@images/illustrations/online_quote.svg';
import movingHeroImage from '@images_responsive/www/checkout/moving_product_detail_hero.jpg';

const ValuePropContainer = styled.div`
  display: flex;
  gap: 16px;
  ${mq({
    padding: ['16px 24px 8px', null, null, '32px'],
  })}
`;

const Illustration = styled.img`
  height: 42px;
  margin-bottom: 8px;
`;

const ImageContainer = styled.div`
  max-width: 748px;
  z-index: 1;

  ${mq({
    display: ['none', null, null, 'block'],
  })}
`;

const Image = styled(ResponsiveImage)`
  border-radius: 4px;
  max-height: 344px;
  width: 100%;
`;

const ScrollContainer = styled.div`
  background: linear-gradient(
    ${COLORS.cloud} 0 50%,
    ${COLORS.grayBackground} 50% 100%
  );
  bottom: 0;
  z-index: 2;
  left: 50%;
  overflow-y: hidden;
  ${mq({
    overflowX: ['auto', null, null, 'visible'],
    width: ['initial', null, null, '92vw'],
    maxWidth: ['initial', null, null, '1230px'],
    position: ['initial', null, null, 'absolute'],
    transform: ['initial', null, null, 'translateX(-50%)'],
    background: [null, null, null, 'none'],
  })}
`;

const ValuePropCard = styled.div<{ primary?: boolean; standalone?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ primary }) => (primary ? '#00404D' : COLORS.cloud)};
  color: ${({ primary }) => (primary ? COLORS.cloud : COLORS.panther)};
  padding: 8px;
  border: 1px ${({ primary }) => (primary ? '#00404D' : COLORS.grayBorder)}
    solid;
  border-radius: ${({ standalone }) => (standalone ? '0px' : '8px')};
  height: 100%;
  align-items: center;
`;

const StartingAtValueProp: React.FC<{ standalone: boolean }> = ({
  standalone,
}) => (
  <ValuePropCard primary standalone={standalone}>
    <Text.Title size="extraSmall">Starting at</Text.Title>
    <Text.Display>$96</Text.Display>
    <Text.Caption>Our average apartment move is $212</Text.Caption>
  </ValuePropCard>
);

const DefaultValueProps: React.FC = () => (
  <Box
    width={['250vw', '150vw', '120vw', 'initial']}
    margin={['0 40px 0', null, null, 0]}
    minHeight="140px"
  >
    <ValuePropContainer>
      <Box.FlexItem textAlign="center" flexBasis="20%">
        <StartingAtValueProp standalone={false} />
      </Box.FlexItem>
      <Box.FlexItem textAlign="center" flexBasis="20%">
        <ValuePropCard>
          <Illustration src={calendar} alt="" />
          <Text.Body>Fair billing by the minute</Text.Body>
        </ValuePropCard>
      </Box.FlexItem>
      <Box.FlexItem textAlign="center" flexBasis="20%">
        <ValuePropCard>
          <Illustration src={onlineQuote} alt="" />
          <Text.Body>Real-time day-of move tracking</Text.Body>
        </ValuePropCard>
      </Box.FlexItem>
      <Box.FlexItem textAlign="center" flexBasis="20%">
        <ValuePropCard>
          <Illustration src={moverWithBoxes} alt="" />
          <Text.Body>Industry-leading quality standards</Text.Body>
        </ValuePropCard>
      </Box.FlexItem>
      <Box.FlexItem textAlign="center" flexBasis="20%">
        <ValuePropCard>
          <Illustration src={moversWithTV} alt="" />
          <Text.Body>Fully employed pro movers</Text.Body>
        </ValuePropCard>
      </Box.FlexItem>
    </ValuePropContainer>
  </Box>
);

const DefaultHero: React.FC<{
  handleClick(): void;
  cityName?: string;
}> = ({ handleClick, cityName }) => {
  const { isMobile } = useBreakpoints();

  return (
    <Box
      height={['initial', null, null, '484px']}
      background={COLORS.cloud}
      position="relative"
    >
      <Box.Flex
        padding="32px 32px 0"
        justifyContent={['left', null, null, 'center']}
      >
        <Box margin={['0 0 24px', null, null, '0 64px 64px 64px']}>
          <Text.Display>
            Modern&nbsp;moving
            <br />
            for any budget
          </Text.Display>
          <Box
            margin={['4px 0 12px', null, null, '4px 0 0']}
            maxWidth={['initial', null, null, '552px']}
          >
            <Text.Headline>
              Stress-free moving{cityName && ` in ${cityName}`} with transparent
              prices and industry-low minimums
            </Text.Headline>
          </Box>
          <Box display={['initial', null, null, 'none']}>
            <TextButton size="medium" onClick={handleClick}>
              Learn more
            </TextButton>
          </Box>
        </Box>
        <ImageContainer>
          <Image image={movingHeroImage} sizes="50vw" alt="" loading="eager" />
        </ImageContainer>
      </Box.Flex>
      {isMobile ? (
        <StartingAtValueProp standalone={true} />
      ) : (
        <ScrollContainer>
          <DefaultValueProps />
        </ScrollContainer>
      )}
      <Box
        width="100%"
        height="120px"
        bottom="0"
        position="absolute"
        display={['none', null, null, 'block']}
        background={COLORS.grayBackground}
      />
    </Box>
  );
};

export const MovingHero: React.FC<{
  showOverlay(): void;
  cityName?: string;
}> = ({ showOverlay, cityName }) => {
  const track = useTrack({ action: 'click' });
  const handleClick = () => {
    track({
      objectName: 'features',
      objectType: 'button',
      container: 'hero',
      label: 'Learn more',
    });
    showOverlay();
  };

  return <DefaultHero handleClick={handleClick} cityName={cityName} />;
};
