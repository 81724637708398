import React, { useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';

import { Box, COLORS, Text } from '@clutter/clean';

import {
  AvailabilityFragment,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
} from '@graphql/platform';

import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { EventSchema } from '@root/resources/wt/types';

import { Calendar } from '@shared/calendar';
import {
  FROM_ISO_OPTIONS,
  hashDateTime,
} from '@shared/calendar/calendar_utils';

import { useFilteredAvailabilities } from '@utils/availability';
import { useStabilizedFunction } from '@utils/hooks';
import { useStorageAvailabilities } from '@utils/hooks/availabilities';
import { useAppointmentFunnelEvents } from '@utils/hooks/funnel_events/use_appointment_time_funnel_events';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { usePricingSetForZIP } from '@utils/hooks/pricing';
import { extractPlan } from '@utils/pricing/pricing_set';
import {
  EventName,
  createThirdPartyConversionEvent,
} from '@utils/third_party_conversion_events';

import { ArrivalWindowSelector } from '../shared/appointment/arrival_window_selector';

export const Appointment: React.FC<StorageCheckoutStepProps> = ({
  onChange,
  scrollToStep,
  enabled,
  values: { commitment, datePreferred, dateScheduled, planSize, zip },
}) => {
  const arrivalWindowSelectorRef = useRef<HTMLDivElement>(null);
  const [availableDays, setAvailableDays] = useState<Set<string> | undefined>();
  const pricingSet = usePricingSetForZIP(zip);
  const dateInteractionRef = useRef(datePreferred !== undefined);
  const trackFunnelEvent = useTrackFunnelEvents();

  useEffect(() => {
    if (enabled) {
      trackFunnelEvent({
        schema: EventSchema.WWW__PickupPriceViewed,
        action: 'display',
        metadata: {},
      });
    }
  }, [enabled, trackFunnelEvent]);

  useEffect(() => {
    if (!enabled || !datePreferred) {
      dateInteractionRef.current = false;
    }
  }, [enabled, datePreferred]);

  const rateGroupName = commitment;

  const expectedPlanID = extractPlan(
    rateGroupName,
    planSize,
    pricingSet ?? undefined,
  )?.id;

  const { availabilities, fetchMore, from, till, loading, fetched } =
    useStorageAvailabilities({
      expectedPlanID,
      orderSubtype: OrderSubtypeEnum.Onboarding,
      orderType: OrderTypeEnum.Pickup,
      serviceType: OrderServiceTypeEnum.FullService,
      zip: zip!,
      skip: !zip,
    });

  const filteredAvailabilities = useFilteredAvailabilities(
    availabilities,
    datePreferred,
  );

  useEffect(() => {
    if (!availabilities) {
      return;
    }
    const selectables = new Set<string>();
    for (const availability of availabilities) {
      const selectable = DateTime.fromISO(
        availability.fromTime,
        FROM_ISO_OPTIONS,
      );
      selectables.add(hashDateTime(selectable));
    }
    setAvailableDays(selectables);
  }, [availabilities]);

  // Auto-select the first time slot, which will be the all-day SLA time slot
  useEffect(() => {
    if (filteredAvailabilities && filteredAvailabilities.length > 0) {
      onTimeChange(filteredAvailabilities[0] as AvailabilityFragment, false);
    }
  }, [filteredAvailabilities]);

  const currentDay = DateTime.local().startOf('day');

  const isUnavailable = useStabilizedFunction(
    (value: DateTime, availabilitySet: Set<string> | undefined) =>
      !availabilitySet?.has(hashDateTime(value)),
  );

  const { trackAppointmentDateCompleted, trackArrivalWindowCompleted } =
    useAppointmentFunnelEvents({
      enabled,
      from,
      till,
      originZip: zip ?? '',
      appointmentDatesShown: fetched,
      arrivalWindowCount: filteredAvailabilities?.length,
      currentDay,
      availableDays,
      isUnavailable,
    });

  const isPastDay = (value: DateTime) => value <= currentDay;

  const onDateChange = (date: DateTime) => {
    if (availableDays?.has(hashDateTime(date))) {
      trackFunnelEvent({
        schema: EventSchema.WWW__PickupPriceCompleted,
        action: 'click',
        metadata: {},
      });
      trackAppointmentDateCompleted(date.toISO());
      onChange('datePreferred', date);
      arrivalWindowSelectorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      createThirdPartyConversionEvent(EventName.DATE_PREFERRED, {
        date_preferred: date?.toISO(),
      });
      onChange('dateScheduled', undefined);
    }
  };

  const onTimeChange = (
    availability: AvailabilityFragment,
    autoScroll: boolean,
  ) => {
    trackArrivalWindowCompleted(availability.formatted);
    onChange('dateScheduled', availability);

    if (autoScroll) {
      scrollToStep('next');
    }
    createThirdPartyConversionEvent(EventName.AVAILABILITY, {
      date_scheduled: availability?.fromTime,
    });
  };

  return (
    <>
      <Box margin={['64px 0 0', '72px 0 0']}>
        <Text.Title size="small" color={COLORS.tealDark}>
          When do you want us to pick up your items?
        </Text.Title>
        <Box margin="4px 0 32px">
          <Text.Body color={COLORS.storm}>
            Availability fills up fast; secure your spot now. You can reschedule
            or cancel for free up to 48 hours before your appointment.
          </Text.Body>
        </Box>
        <Calendar
          from={from}
          till={till}
          fetchMore={loading ? undefined : fetchMore}
          selected={(value) =>
            !!datePreferred && datePreferred.hasSame(value, 'day')
          }
          pastDay={isPastDay}
          unavailable={(value) => isUnavailable(value, availableDays)}
          onSelect={onDateChange}
        />
        {datePreferred && !filteredAvailabilities?.length && (
          <Box textAlign="center">
            <Text.Body>
              We’re fully booked for that date! Please try another date.
            </Text.Body>
          </Box>
        )}
        <Box
          ref={arrivalWindowSelectorRef}
          style={
            datePreferred && !!filteredAvailabilities?.length
              ? undefined
              : { visibility: 'hidden' }
          }
        >
          <ArrivalWindowSelector
            selectedAvailability={dateScheduled}
            availabilities={filteredAvailabilities ?? []}
            onChange={onTimeChange}
          />
        </Box>
      </Box>
    </>
  );
};
