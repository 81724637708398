import * as Sentry from '@sentry/browser';

import { track } from '@root/initializers/wt';

import { gtm, gtmEnabled } from '@utils/gtm';

declare const uetq: Array<Record<string, unknown>>;
declare const dotq: Array<Record<string, unknown>>;
declare const fbq: any;
declare const AddShoppersWidget: { track_conv(): void } | undefined;

export enum ProductType {
  SELF_STORAGE = 'self_storage',
  SMART_STORAGE = 'smart_storage',
  MOVING = 'moving',
}

export type IThirdPartyEventParams = {
  visitor_token?: string;
  product_type?: ProductType | null;
  zip_code?: string;
  promo_name?: string;
  checkout_version?: string | null;
  value?: string | number;
  currency?: string;
  orderID?: string | null;
  name?: string;
  phone?: string;
  email?: string;
  date_preferred?: string;
  date_scheduled?: string;
};

export enum EventName {
  INITIATE_FLOW = 'InitiateFlow',
  CHECKOUT = 'Checkout',
  PHONE_SUBMIT = 'PhoneSubmit',
  LEAD = 'Lead',
  PHONE_NUMBER_CLICKED = 'PhoneNumberClicked',
  LEAD_FORM_FILL = 'LeadFormFill',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  PLAN_PRICES_VIEWED = 'PlanPricesViewed',
  DATE_PREFERRED = 'DatePreferred',
  AVAILABILITY = 'Availability',
  ADDRESS = 'Address',
  INTRO = 'Intro',
  MOVING_OPT_IN = 'MovingOptIn',
  MOVING_ADDRESS_VALID = 'MovingAddressValid',
}

// EventCategory is only used for bing (uetq).
enum EventCategory {
  FLOW = 'Flow',
  LEAD = 'Lead',
}

/*
 *  TODO: Instead of logging event payloads in dev envs we should have a development account for each pixel service.
 */

const googleEvent = async ({
  name,
  metadata,
}: {
  name: EventName;
  metadata?: IThirdPartyEventParams;
}) => {
  if (!(await gtmEnabled())) {
    // eslint-disable-next-line no-console
    return console.info('DevLog: Google event', { name, metadata });
  }
  gtm({
    event: name,
    ...metadata,
  });
};

const bingEvent = ({
  category,
  action,
}: {
  category: EventCategory;
  action: EventName;
}) => {
  if (typeof uetq === 'undefined') {
    // eslint-disable-next-line no-console
    return console.info('DevLog: Bing event', { category, action });
  }

  uetq.push({ ec: category, ea: action });
};

const fbEvent = ({
  name,
  custom,
  metadata,
}: {
  name: EventName;
  custom: boolean;
  metadata?: IThirdPartyEventParams;
}) => {
  if (typeof fbq === 'undefined') {
    // eslint-disable-next-line no-console
    return console.info('DevLog: FB event', { name, custom, metadata });
  }

  fbq(custom ? 'trackCustom' : 'track', name, metadata);
};

const oathEvent = ({
  name,
  metadata,
}: {
  name: EventName;
  metadata?: IThirdPartyEventParams;
}) => {
  const data = {
    projectId: '10000',
    properties: {
      pixelId: '10096630',
      qstrings: {
        et: 'custom',
        el: name,
        gv: metadata?.value,
      },
    },
  };

  if (typeof dotq === 'undefined') {
    // eslint-disable-next-line no-console
    console.info('DevLog: OATH event', { name, data });
  } else {
    try {
      dotq.push(data);
    } catch (e: any) {
      Sentry.captureMessage(
        `OATH error: ${e.message}`,
        Sentry.Severity.Warning,
      );
    }
  }
};

const addShoppersEvent = ({
  metadata,
}: {
  metadata?: IThirdPartyEventParams;
}) => {
  const data = {
    order_id: metadata?.orderID,
    value: metadata?.value,
  };

  if (typeof AddShoppersWidget === 'undefined') {
    // eslint-disable-next-line no-console
    console.info('DevLog: AddShoppers event', { data });
  } else {
    try {
      (window as any).AddShoppersConversion = data;
      AddShoppersWidget.track_conv();
    } catch (e: any) {
      Sentry.captureMessage(
        `AddShoppers error: ${e.message}`,
        Sentry.Severity.Warning,
      );
    }
  }
};

const companyToTracker: { [K: string]: any } = {
  fb: fbEvent,
  google: googleEvent,
  bing: bingEvent,
  oath: oathEvent,
  addShoppers: addShoppersEvent,
};

interface IConversionArg {
  custom?: boolean;
  name?: EventName;
  category?: EventCategory;
  action?: EventName;
}
const EVENT_NAME_TO_CONVERSION_ARGS: {
  [K in EventName]: { [K2: string]: IConversionArg | IConversionArg[] };
} = {
  [EventName.INITIATE_FLOW]: {
    fb: { custom: true, name: EventName.INITIATE_FLOW },
    bing: { category: EventCategory.FLOW, action: EventName.INITIATE_FLOW },
    google: { name: EventName.INITIATE_FLOW },
    oath: { name: EventName.INITIATE_FLOW },
  },
  [EventName.INTRO]: {
    google: { name: EventName.INTRO },
  },
  [EventName.MOVING_OPT_IN]: {
    google: { name: EventName.MOVING_OPT_IN },
  },
  [EventName.MOVING_ADDRESS_VALID]: {
    google: { name: EventName.MOVING_ADDRESS_VALID },
  },
  [EventName.LEAD]: {
    fb: { custom: false, name: EventName.LEAD },
    bing: { category: EventCategory.LEAD, action: EventName.LEAD_FORM_FILL },
    google: { name: EventName.LEAD_FORM_FILL },
    oath: { name: EventName.LEAD_FORM_FILL },
  },
  [EventName.PHONE_SUBMIT]: {
    fb: { custom: true, name: EventName.PHONE_SUBMIT },
    bing: { category: EventCategory.FLOW, action: EventName.PHONE_SUBMIT },
    google: { name: EventName.PHONE_SUBMIT },
    oath: { name: EventName.PHONE_SUBMIT },
  },
  [EventName.CHECKOUT]: {
    fb: { custom: false, name: EventName.CHECKOUT },
    bing: { category: EventCategory.FLOW, action: EventName.CHECKOUT },
    google: { name: EventName.CHECKOUT },
    oath: { name: EventName.CHECKOUT },
    addShoppers: {},
  },
  [EventName.PHONE_NUMBER_CLICKED]: {
    fb: [
      { custom: true, name: EventName.PHONE_NUMBER_CLICKED },
      { custom: false, name: EventName.LEAD },
    ],
  },
  [EventName.ADD_PAYMENT_INFO]: {
    fb: { custom: false, name: EventName.ADD_PAYMENT_INFO },
    bing: { category: EventCategory.FLOW, action: EventName.ADD_PAYMENT_INFO },
    google: { name: EventName.ADD_PAYMENT_INFO },
    oath: { name: EventName.ADD_PAYMENT_INFO },
  },
  [EventName.PLAN_PRICES_VIEWED]: {
    fb: { custom: false, name: EventName.PLAN_PRICES_VIEWED },
    google: { name: EventName.PLAN_PRICES_VIEWED },
    bing: {
      category: EventCategory.FLOW,
      action: EventName.PLAN_PRICES_VIEWED,
    },
    oath: { name: EventName.PLAN_PRICES_VIEWED },
  },
  [EventName.LEAD_FORM_FILL]: {},
  [EventName.DATE_PREFERRED]: {
    google: { name: EventName.DATE_PREFERRED },
  },
  [EventName.AVAILABILITY]: {
    google: { name: EventName.AVAILABILITY },
  },
  [EventName.ADDRESS]: {
    google: { name: EventName.ADDRESS },
  },
};

export const createThirdPartyConversionEvent = (
  name: EventName,
  metadata?: IThirdPartyEventParams,
) => {
  const eventData = EVENT_NAME_TO_CONVERSION_ARGS[name];
  for (const company of Object.keys(eventData)) {
    const events = (
      Array.isArray(eventData[company])
        ? eventData[company]
        : [eventData[company]]
    ) as [];

    events.forEach((event: IConversionArg) => {
      companyToTracker[company]({ ...event, metadata });
    });
  }
  track({
    metadata,
    action: 'external_pixel_trigger',
    label: name,
  });
};
