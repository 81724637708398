import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, Box, FontWeight, Text } from '@clutter/clean';

import { ZipCTA } from '@root/components/www/home/zip_cta';

import { ButtonCTA } from '@shared/button_cta';
import { Block, BlockProps } from '@shared/page_builder/block';
import { ImageDescriptor } from '@shared/page_builder/types/image_descriptor';
import { ResponsiveImage } from '@shared/responsive_image';
import { ServiceSelection } from '@shared/service_selector/constants';

import checkMark from '@images/icons/check-circle.svg';

type Content = {
  headline?: string;
  description?: string;
  bulletPoints?: string[];
};

type ImageAlignment = 'left' | 'right';

const Image = styled(ResponsiveImage)`
  border-radius: 8px;
  overflow: hidden;
`;

const SINGLE_IMAGE_SIZES = `(min-width: ${BREAKPOINTS.MD}) 50vw, 100vw`;
const DUAL_IMAGE_SIZES = `(min-width: ${BREAKPOINTS.MD}) 25vw, 100vw`;

const Images = ({
  imageA,
  imageB,
}: {
  imageAlignment: ImageAlignment;
  imageA: ImageDescriptor;
  imageB?: ImageDescriptor;
}) => (
  <Box position="relative">
    {imageB ? (
      <>
        <Image
          image={imageA}
          sizes={DUAL_IMAGE_SIZES}
          loading="lazy"
          alt=""
          style={{
            width: imageA.widthPercent ? imageA.widthPercent + '%' : '57%',
            position: 'absolute',
          }}
        />
        <Image
          image={imageB}
          sizes={DUAL_IMAGE_SIZES}
          loading="lazy"
          alt=""
          style={{
            width: imageB.widthPercent ? imageB.widthPercent + '%' : '57%',
            left: '100%',
            transform: 'translateX(-100%)',
            top: '74px',
            position: 'relative',
          }}
        />
      </>
    ) : (
      <Image
        image={imageA}
        sizes={SINGLE_IMAGE_SIZES}
        loading="lazy"
        alt=""
        style={{ width: '100%' }}
      />
    )}
  </Box>
);

export const ContentWithSideImage = ({
  content,
  contentColumns = 4,
  cta,
  zipCTA,
  imageA,
  imageB,
  imageAlignment,
  subTitle,
  title,
  ...blockProps
}: {
  content: Content[];
  contentColumns?: number;
  cta?: {
    service?: ServiceSelection;
    label: string;
    container: string;
  };
  zipCTA?: Partial<React.ComponentProps<typeof ZipCTA>>;
  imageA: ImageDescriptor;
  imageB?: ImageDescriptor;
  imageAlignment: 'left' | 'right';
  subTitle?: string;
  title: React.ReactNode;
} & BlockProps) => {
  const contentPercentage = ((contentColumns / 12) * 100).toFixed(6) + '%';
  const imagePercentage = (((11 - contentColumns) / 12) * 100).toFixed(6) + '%';
  return (
    <Block {...blockProps}>
      <Box.Flex
        margin="0 auto"
        gap={['24px', '8%']}
        flexDirection={[
          'column',
          null,
          imageAlignment === 'left' ? 'row-reverse' : 'row',
        ]}
      >
        <Box.Flex
          flexDirection="column"
          flexBasis={['100%', contentPercentage]}
        >
          <Text.Title size="large">{title}</Text.Title>
          {subTitle && <Text.Headline>{subTitle}</Text.Headline>}
          <Box.Flex flexDirection="column" gap="20px" margin="32px 0 0">
            {content.map((c) => (
              <Box key={c.description}>
                {c.headline && (
                  <Box margin="0 0 8px">
                    <Text.Headline weight={FontWeight.Medium}>
                      {c.headline}
                    </Text.Headline>
                  </Box>
                )}
                {c.description && <Text.Body>{c.description}</Text.Body>}
                {c.bulletPoints && (
                  <Box.Grid
                    gridTemplateColumns={
                      c.bulletPoints.length > 3 ? ['1fr', '1fr 1fr'] : '1fr'
                    }
                    gridGap="16px"
                  >
                    {c.bulletPoints?.map((item) => (
                      <Box.Flex key={item} gap="8px" alignItems="center">
                        <img src={checkMark} alt="" loading="eager" />
                        <span>{item}</span>
                      </Box.Flex>
                    ))}
                  </Box.Grid>
                )}
              </Box>
            ))}
            {cta && (
              <Box margin="20px 0 0">
                <ButtonCTA {...cta} entryPoint="landing_page_cta" />
              </Box>
            )}
            {zipCTA && (
              <Box margin="8px 0 16px">
                <ZipCTA
                  wtConfig={{ container: 'landing_page_zip_cta' }}
                  {...zipCTA}
                />
              </Box>
            )}
          </Box.Flex>
        </Box.Flex>
        <Box.FlexItem flexBasis={['100%', imagePercentage]}>
          <Images
            imageA={imageA}
            imageB={imageB}
            imageAlignment={imageAlignment}
          />
        </Box.FlexItem>
      </Box.Flex>
    </Block>
  );
};
