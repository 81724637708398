import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text, mq } from '@clutter/clean';

import bbb from '@images/reviews/bbb.svg';
import reviewAvatar1 from '@images/reviews/circle_review_avatar_1.png';
import reviewAvatar2 from '@images/reviews/circle_review_avatar_2.png';
import trustPilot from '@images/reviews/trust_pilot_with_count.svg';
import yelp from '@images/reviews/yelp.svg';

const ReviewContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px;
  background: ${COLORS.cloud};
  border-radius: 8px;

  ${mq({
    maxWidth: [null, null, null, '60%'],
  })}
`;

const ReviewImage = styled.img`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  background: chartreuse;
`;

type Review = {
  content: string;
  author: string;
};

export const ReviewOverlay: React.FC<{ reviewA: Review; reviewB: Review }> = ({
  reviewA,
  reviewB,
}) => {
  return (
    <Box.Flex
      width="100%"
      padding={['12px', '24px', '64px 24px']}
      gap="24px"
      flexDirection="column"
    >
      <ReviewContainer>
        <ReviewImage src={reviewAvatar1} />
        <Box.Flex gap="8px" flexDirection="column">
          <Text.Body>“{reviewA.content}”</Text.Body>
          <Text.SmallCaps>{reviewA.author}</Text.SmallCaps>
        </Box.Flex>
      </ReviewContainer>
      <ReviewContainer style={{ alignSelf: 'flex-end' }}>
        <ReviewImage src={reviewAvatar2} />
        <Box.Flex gap="8px" flexDirection="column">
          <Text.Body>“{reviewB.content}”</Text.Body>
          <Text.SmallCaps>{reviewB.author}</Text.SmallCaps>
        </Box.Flex>
      </ReviewContainer>
    </Box.Flex>
  );
};

const FooterContainer = styled.div`
  display: flex;
  height: 80px;
  justify-content: space-between;
  background: ${COLORS.cloud};
  position: relative;
  border-radius: 0 0 4px 4px;
  border-style: none solid solid solid;
  border-color: ${COLORS.grayBorder};

  & > div {
    align-items: center;
    border-right: 1px solid ${COLORS.grayBorder};
    display: flex;
    flex-basis: 25%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    padding: 4px;
    text-align: center;

    &:last-of-type {
      border-right: none;
    }
  }
`;

export const ReviewFooter: React.FC<{ description: string; count: number }> = ({
  description,
  count,
}) => (
  <FooterContainer>
    <div>
      <Text.Caption>{description}</Text.Caption>
      <Text.Callout>
        <b>{count.toLocaleString()}+</b>
      </Text.Callout>
    </div>
    <div>
      <img src={trustPilot} alt="5 stars on TrustPilot" />
    </div>
    <div>
      <img src={yelp} alt="4 stars on Yelp" />
    </div>
    <div>
      <img src={bbb} alt="A+ on BBB" />
    </div>
  </FooterContainer>
);
