import { Token } from '@stripe/stripe-js';

import {
  DoorToDoor__OnboardingInputType,
  ErrorCodeEnum,
  LaborPolicyFragment,
  Maybe,
  PricingSetFragment,
  useDoorToDoorOnboardingReserveMutation,
} from '@graphql/platform';

import { TEAL_USER_ID } from '@root/components/checkout/helpers/agent_booking';
import { IMPACT_CLICK_ID } from '@root/initializers/impact';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';

import { LeadField } from '@shared/lead_form';

import { GQLError } from '@utils/gql_errors';
import { useReservationCompletedFunnelEvent } from '@utils/hooks/funnel_events/use_reservation_completed_funnel_event';
import { useAppointmentQuoteCreate } from '@utils/hooks/pricing/use_create_appointment_quote';
import {
  extractLaborPolicy,
  extractPlan,
  extractRateGroup,
  extractStoragePrice,
} from '@utils/pricing/pricing_set';
import {
  EventName,
  ProductType,
  createThirdPartyConversionEvent,
} from '@utils/third_party_conversion_events';

import { StorageCheckoutData } from '../data';

type ReservationData = Pick<
  StorageCheckoutData,
  | 'address'
  | 'bundleKind'
  | 'disposalSelected'
  | 'checkoutType'
  | 'commitment'
  | 'dateScheduled'
  | 'planSize'
  | 'protectionPlan'
  | 'zip'
  | 'coupon'
> & {
  laborPolicy?: LaborPolicyFragment;
  lead?: { [key in LeadField]: string };
  monthlyStorageAmount?: number;
  pricingSet?: Maybe<PricingSetFragment>;
};

export const useReserveSmartStorage = ({
  address,
  bundleKind,
  checkoutType,
  commitment,
  dateScheduled,
  disposalSelected,
  coupon,
  lead,
  planSize,
  pricingSet,
  protectionPlan,
  zip,
}: ReservationData) => {
  const trackReservationCompleted = useReservationCompletedFunnelEvent();

  const [reserveOnboarding] = useDoorToDoorOnboardingReserveMutation();

  const rateGroupName = commitment;

  const rateGroup = extractRateGroup(rateGroupName, pricingSet!);

  const laborPolicy = extractLaborPolicy(rateGroupName!, pricingSet!);

  const monthlyStorageAmount = extractStoragePrice(
    rateGroupName,
    planSize,
    pricingSet ?? undefined,
  );

  const {
    quote: { laborRate, appointmentQuote, packageSetEntries },
    loading: quoteLoading,
  } = useAppointmentQuoteCreate({
    pricingSet: pricingSet ?? undefined,
    zip,
    rateGroupName,
    planSize,
    customerToken: undefined,
  });

  const quoteID = pricingSet?.quoteId;

  const storagePlan = extractPlan(rateGroupName!, planSize!, pricingSet!);

  const checkoutPackageSetEntries = packageSetEntries.map((entry) => {
    return {
      id: entry.id,
      amount: entry.amount!,
    };
  });

  const book = async (
    customerToken?: string,
    leadToken?: string,
    stripeToken?: Token,
  ) => {
    if (!leadToken && !customerToken) {
      throw new GQLError({
        errorCode: ErrorCodeEnum.MismatchedCustomer,
        errorMessage:
          'No leadToken or customerToken present when booking onboarding',
      });
    }

    if (
      !address ||
      !dateScheduled ||
      !storagePlan ||
      !laborPolicy ||
      !laborRate ||
      !pricingSet ||
      !quoteID ||
      !rateGroup
    ) {
      const data: Record<string, any> = {
        address,
        dateScheduled,
        storagePlan,
        laborPolicy,
        laborRate,
        pricingSet,
        quoteID,
        rateGroup,
      };
      const fields = Object.keys(data).filter((key) => !data[key]);

      throw new GQLError({
        errorCode: ErrorCodeEnum.Unknown,
        errorMessage: `Missing fields: ${fields.join(', ')}`,
      });
    }

    const marketingData = IMPACT_CLICK_ID
      ? { impactClickID: IMPACT_CLICK_ID }
      : undefined;

    const params: DeepMutable<DoorToDoor__OnboardingInputType> = {
      bundleKind,
      customerToken,
      date: dateScheduled.fromTime,
      disposalRequested: !!disposalSelected,
      email: lead?.email,
      userID: TEAL_USER_ID,
      incentive: { eligible: false },
      marketingData,
      name: lead?.name,
      pickupAddress: {
        city: address.city!,
        street: address.street!,
        state: address.state!,
        zip: address.zip!,
        aptsuite: address.aptsuite,
      },
      phone: lead?.phone,
      planID: storagePlan.id,
      pricing: {
        appointmentQuoteID: appointmentQuote?.id,
        laborBillingFormat: laborPolicy.laborBillingFormat,
        laborPolicyID: laborPolicy.id,
        laborRateID: laborRate.id,
        packageSetEntries: checkoutPackageSetEntries,
        quoteID,
      },
      promoCode: coupon?.promoCode,
      protectionPlanSlug: protectionPlan,
      rateGroupID: rateGroup.id,
      slaWindowDuration: dateScheduled.duration,
      stripeToken: stripeToken?.id,
      subsource: `twoPage:${checkoutType}:smartStorage`,
      visitorToken: WT_VISITOR_TOKEN,
    };

    const { data } = await reserveOnboarding({ variables: { input: params } });

    if (!data || data.result?.error) {
      throw new GQLError(
        data?.result?.error ?? {
          errorCode: ErrorCodeEnum.Unknown,
          errorMessage: 'Unknown server error',
        },
      );
    }

    createThirdPartyConversionEvent(EventName.CHECKOUT, {
      value: monthlyStorageAmount,
      zip_code: address!.zip,
      product_type: ProductType.SMART_STORAGE,
      currency: 'USD',
      orderID: data.result?.orderID,
    });

    const orderToken = data.result?.orderToken;
    const accountPortalUrl = data.result?.accountPortalUrl;
    if (orderToken) {
      trackReservationCompleted({ orderId: data.result.orderID! });
      return {
        orderToken: orderToken,
        accountUrl: accountPortalUrl!,
      };
    }
    return null;
  };

  return [book, quoteLoading] as const;
};
