import React, { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';

import { Searchbar, SkeletonLoader, Text, mq } from '@clutter/clean';

import {
  Help__Article,
  Help__Section,
  useHelpAutocompleteQuery,
} from '@graphql/landing';

import { useDebouncedValue } from '@utils/hooks/debounce';

import { articleURL, searchURL } from '../routes';

const LIMIT = 5;

const ResultNavLink = Searchbar.Result.withComponent(NavLink);

const Container = styled.div`
  margin: 32px 0;

  ${mq({
    width: [null, null, '50%'],
  })}
`;

const FakeResultContainer = styled(Searchbar.Result)`
  &:hover {
    color: initial;
    background: initial;
  }
`;

const EmptyResultsContainer = styled(Text.Body)`
  text-align: center;
  margin: 12px 0;
  padding: 12px 32px;
`;

const FakeResult: React.FC = () => (
  <FakeResultContainer>
    <SkeletonLoader width="100%" height="20px" />
  </FakeResultContainer>
);

const Results: React.FC<{
  articles?: ReadonlyArray<
    Pick<Help__Article, 'id' | 'title'> & {
      section: Pick<Help__Section, 'id' | 'name'>;
    }
  >;
  loading: boolean;
  query: string;
}> = ({ articles, loading, query }) => {
  if (loading || !articles) {
    return (
      <>
        <FakeResult />
        <FakeResult />
      </>
    );
  }

  if (!articles.length) {
    return (
      <EmptyResultsContainer>No search results found</EmptyResultsContainer>
    );
  }

  return (
    <>
      {articles?.map((article) => (
        <ResultNavLink key={article.id} to={articleURL(article.id)}>
          <Searchbar.Result.Title>
            <Searchbar.Highlight text={article.title} query={query} />
          </Searchbar.Result.Title>
          <Searchbar.Result.Label>
            {article.section.name}
          </Searchbar.Result.Label>
        </ResultNavLink>
      ))}
    </>
  );
};

export const Search: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState<string | undefined>(() => {
    const params = new URLSearchParams(location.search);
    return params.get('q') ?? undefined;
  });
  const debounced = useDebouncedValue(query);
  const onSubmit = () => {
    if (!query?.length) return;
    history.push({
      pathname: searchURL(),
      search: `q=${encodeURIComponent(query)}`,
    });
  };
  const { data, loading } = useHelpAutocompleteQuery({
    variables: { query: debounced! },
    skip: !debounced,
  });
  const articles = data?.autocomplete.results?.slice(0, LIMIT);

  return (
    <Container>
      <Searchbar.Form onSubmit={onSubmit}>
        <Searchbar.Input
          placeholder="Search"
          query={query}
          onChange={setQuery}
        />
        {query && (
          <Searchbar.Dropdown>
            <Searchbar.Section>Top Article Suggestions</Searchbar.Section>
            <Results articles={articles} loading={loading} query={query} />
          </Searchbar.Dropdown>
        )}
      </Searchbar.Form>
    </Container>
  );
};
