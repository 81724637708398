import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Box, FontWeight, Text, mq } from '@clutter/clean';

import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import {
  ADDRESS_VALIDATION,
  ClientAddress,
} from '@root/resources/types/address';

import { FieldAddress } from '@shared/field_address';
import { FieldText } from '@shared/field_text';

const Label = styled(Text.Body)`
  font-weight: ${FontWeight.Medium};
  margin-bottom: 6px;
`;

const Fields = styled.div`
  display: grid;

  ${mq({
    gridTemplateColumns: ['1fr', '2fr 1fr'],
    gridGap: [0, '12px'],
  })}
`;

export const AddressFields: React.FC<
  StorageCheckoutStepProps & { onZipChanged(matchesOriginal: boolean): void }
> = ({ onChange, onZipChanged, values: { address, zip } }) => {
  const track = useTrack({ action: 'input' });
  const [originalZIP] = useState(zip);
  const [error, setError] = useState<string>();

  const handleAddressChange = (_: 'address', value: Partial<ClientAddress>) => {
    setError(undefined);

    if (
      value.googleAddressType === 'route' ||
      value.googleAddressType === '' ||
      !value.zip
    ) {
      setError(ADDRESS_VALIDATION.INCOMPLETE);
      return;
    }

    if (value.zip !== zip) {
      onChange('zip', value.zip);
      onZipChanged(value.zip !== originalZIP);
    }

    const formattedAddress = `${value.street}, ${value.city}, ${value.state} ${value.zip}`;

    track({
      objectName: 'address',
      value: formattedAddress,
      objectType: 'input:text',
      label: 'address',
    });
    onChange('address', value);
  };

  return (
    <Box>
      <Fields>
        <Box>
          <Label>Address</Label>
          <FieldAddress
            testId="address-search"
            value={address}
            label="Street"
            onChange={handleAddressChange}
            onInputChange={(street) => street === '' && onChange('address', {})}
            hideClearButton={true}
            includeCityInInput={true}
            field="address"
            error={error}
            selectFirstOnBlur={true}
            inputProps={{ autoComplete: 'off' }}
          />
        </Box>
        <FieldText
          label="Apt / Suite"
          name="aptsuite"
          onChange={(_, value) =>
            onChange('address', { ...address, aptsuite: value })
          }
          value={address?.aptsuite ?? ''}
          autoComplete="user_typed_aptsuite"
        />
      </Fields>
    </Box>
  );
};
