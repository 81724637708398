import * as React from 'react';

import { IMapping } from '../../resources/types/mapping';
import { FieldText } from './field_text';

interface IFieldsListProps {
  fields: string[];
  mapping: IMapping;
  errors?: { [key: string]: any };
  resource: any;
  disabled?: { [fieldName: string]: boolean };
  onChange(field: string, value: string): void;
  onBlur?(field: string, value: any): void;
}

class FieldsList extends React.Component<IFieldsListProps> {
  public render() {
    const {
      fields,
      mapping,
      resource,
      onChange,
      onBlur,
      errors = {},
      disabled = {},
    } = this.props;
    return fields.map((field) => {
      const value = resource[field];
      const { label, placeholder, inputProps, mask, transformation, prompt } =
        mapping[field];

      return (
        <FieldText
          key={field}
          name={field}
          value={value}
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          mask={mask}
          error={errors[field]}
          placeholder={placeholder}
          transformation={transformation}
          disabled={disabled[field]}
          className="field"
          prompt={prompt}
          {...inputProps}
        />
      );
    });
  }
}

export { FieldsList };
