import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  InfoModal,
  SpinLoader,
  Text,
} from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { Action, useClientDataContext } from '@shared/client_data_context';
import { ServiceSelection } from '@shared/service_selector/constants';

import { useClientDataContextCoupon } from '@utils/hooks/use_client_data_context_coupon';
import { titleize } from '@utils/text';

import giftCardWithSparkle from '@images/illustrations/gift_card_with_sparkle.svg';

export const Modal: React.FC<{ serviceSelection: ServiceSelection }> = ({
  serviceSelection,
}) => {
  const track = useTrack({ objectName: 'coupon_auto_applied_modal' });
  const { dispatch } = useClientDataContext();
  const { coupon, expirationDate, loading } = useClientDataContextCoupon();

  const [showModal, setShowModal] = useState<boolean>(false);

  const validForService =
    serviceSelection !== ServiceSelection.Moving || coupon?.movingEligible;

  useEffect(() => {
    if (showModal) {
      track({
        action: 'display',
        value: coupon?.promoCode,
        metadata: { service: serviceSelection, valid_coupon: validForService },
      });
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal || !coupon) {
      return;
    }
    if (!validForService) {
      dispatch({ type: Action.ClearURLPromoCode });
    } else {
      setShowModal(true);
    }
  }, [coupon, showModal, validForService]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <InfoModal isOpen={showModal} handleModalClose={handleModalClose}>
      <Box textAlign="center" margin="auto" width="75%">
        <Text.Title size="medium">Discount has been applied!</Text.Title>
      </Box>
      <Box margin="32px 0">
        {loading ? (
          <Box>
            <SpinLoader />
          </Box>
        ) : (
          <Box
            position="relative"
            padding="16px 16px 16px 84px"
            background={COLORS.tealBackground}
          >
            <Box position="absolute" left="16px" top={[0, '-8px']}>
              <img src={giftCardWithSparkle} alt="" />
            </Box>
            {!!coupon && (
              <Text.Callout weight={FontWeight.Medium}>
                {coupon.percent
                  ? `${coupon.percent * 100}%`
                  : `$${coupon.amount}`}{' '}
                off {titleize(serviceSelection)}
                {expirationDate && ` until ${expirationDate}`}! Coupon "
                {coupon.promoCode}" will be applied at checkout.
              </Text.Callout>
            )}
          </Box>
        )}
      </Box>
      <Box textAlign="right">
        <Button onClick={handleModalClose}>Got it</Button>
      </Box>
    </InfoModal>
  );
};
