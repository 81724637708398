import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Box, COLORS, Icon } from '@clutter/clean';

import { FunnelEntryPoint } from '@shared/funnel_entry_point';
import { FunnelEntryPointDropdown } from '@shared/funnel_entry_point_dropdown';
import { FunnelEntryPointForm } from '@shared/funnel_entry_point_form';
import { FunnelEntryPointErrorMessage } from '@shared/funnel_entry_point_message';
import { FunnelEntryPointSubmitButton } from '@shared/funnel_entry_point_submit_button';
import { FunnelEntryPointZipInput } from '@shared/funnel_entry_point_zip_input';
import { useFunnelEntryPointContext } from '@shared/use_funnel_entry_point_context';

import { ServiceSelection } from './constants';

const leftLocationStyles = css`
  bottom: 0;
  left: -16px;
  transform: translate(-100%);
`;

const aboveLocationStyles = css`
  bottom: 120%;
  left: 50%;
  width: 100%;
  transform: translate(-50%);
`;

const ErrorMessage = styled.div<{ location: 'above' | 'left' }>`
  position: absolute;
  z-index: 10;
  background: ${COLORS.flower};
  padding: 10px;
  font-size: 16px;
  margin: 0 auto;
  left: 50%;
  color: ${COLORS.toucan};
  border-radius: 2px;
  font-weight: bold;
  height: 56px;
  display: flex;
  align-items: center;

  ${({ location }) =>
    location === 'above' ? aboveLocationStyles : leftLocationStyles};

  &::after {
    bottom: -3px;
    content: ${({ location }) => (location === 'left' ? 'none' : "' '")};
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${COLORS.flower};
    left: 50%;
    transform: rotate(45deg);
    border-radius: 3px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 0 16px 0 0;
  color: ${COLORS.tealDark};

  input {
    height: 48px;
    width: 100%;
    margin-left: 16px;
  }
`;

const ButtonContainer = styled.div`
  flex-grow: 1;
  margin-left: 16px;

  button {
    padding-right: 0;
    padding-left: 0;
    text-transform: none;
    white-space: nowrap;
    width: 100%;
    min-width: 130px;
  }
`;

const FooterButtonContainer = styled.div`
  margin-left: 16px;

  button {
    min-width: 0;
    text-transform: none;
    white-space: nowrap;
    padding: 0;
    text-align: center;
    width: 56px;
  }
`;

const Form: React.FC<{
  id: string;
  position: 'header' | 'footer';
}> = ({ id, position }) => {
  const { serviceSelection } = useFunnelEntryPointContext();
  const submitButtonText =
    serviceSelection === ServiceSelection.Moving
      ? 'Get a Quote'
      : 'Get Pricing';

  return (
    <FunnelEntryPointForm id={id}>
      <Box.Flex position="relative">
        <InputContainer>
          <Box.FlexItem height="48px" width="176px" minWidth="152px">
            <FunnelEntryPointDropdown
              menuPlacement={position === 'footer' ? 'above' : 'below'}
            />
          </Box.FlexItem>
          <Box.FlexItem>
            <FunnelEntryPointZipInput />
          </Box.FlexItem>
        </InputContainer>
        {position === 'footer' ? (
          <FooterButtonContainer>
            <FunnelEntryPointSubmitButton loading={false}>
              <Icon.Arrow color={COLORS.cloud} />
            </FunnelEntryPointSubmitButton>
          </FooterButtonContainer>
        ) : (
          <ButtonContainer>
            <FunnelEntryPointSubmitButton loading={false}>
              {submitButtonText}
            </FunnelEntryPointSubmitButton>
          </ButtonContainer>
        )}
        <FunnelEntryPointErrorMessage>
          <ErrorMessage location={position === 'footer' ? 'above' : 'left'} />
        </FunnelEntryPointErrorMessage>
      </Box.Flex>
    </FunnelEntryPointForm>
  );
};

const PinnedServiceSelector: React.FC<{
  container: string;
  id: string;
  initialService?: ServiceSelection;
  position: 'header' | 'footer';
}> = ({ container, id, initialService, position }) => {
  return (
    <FunnelEntryPoint initialService={initialService} wtConfig={{ container }}>
      <Form id={id} position={position} />
    </FunnelEntryPoint>
  );
};

export { PinnedServiceSelector };
