import * as React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text, mq } from '@clutter/clean';

import { Block, BlockProps } from '@shared/page_builder/block';

const StatNumber = styled(Text.Title)`
  line-height: 49px;
  ${mq({
    fontSize: ['40px', '36px'],
  })};
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mq({
    marginBottom: ['32px', null, 0],
  })};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StatDescription = styled(Text.Callout)`
  ${mq({
    whiteSpace: ['initial', 'nowrap'],
  })}
`;

type Stat = {
  value: React.ReactNode;
  description: React.ReactNode;
};

const Stats: React.FC<
  {
    textColor?: string;
    stats: [Stat, Stat, Stat];
  } & BlockProps
> = ({
  stats,
  background = COLORS.tealDark,
  textColor = COLORS.cloud,
  ...blockProps
}) => (
  <Block
    background={background}
    padding={['48px 0', '52px 0']}
    textAlign="center"
    {...blockProps}
  >
    <Box.Grid
      gridTemplateColumns={['1fr', null, 'repeat(3, 1fr)']}
      color={textColor}
    >
      {stats.map(({ description, value }, i) => (
        <SectionContainer key={i}>
          <StatNumber size="medium">{value}</StatNumber>
          <StatDescription>{description}</StatDescription>
        </SectionContainer>
      ))}
    </Box.Grid>
  </Block>
);

export { Stats };
