import React, { useEffect, useState } from 'react';

import { Box, Label } from '@clutter/clean';

import { useMovingRouteSummaryQuery } from '@graphql/platform';

import { OSACard } from '@root/components/checkout/product_pages/subcomponents/osa_card';
import { MovingCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import {
  ADDRESS_VALIDATION,
  ClientAddress,
  MovingAddress,
  StrictMovingAddressDetails,
} from '@root/resources/types/address';

import { ErrorCard } from '@shared/card';
import { FieldAddress } from '@shared/field_address';

import {
  EventName,
  createThirdPartyConversionEvent,
} from '@utils/third_party_conversion_events';

import {
  clearStoragePlan,
  determineMoveSize,
  sanitizeUnitTypesInput,
} from '../../helpers/moving';

export type AddressField = 'startAddress' | 'endAddress';

export const sanitizeAddressInput = ({
  street,
  city,
  state,
  zip,
}: Partial<MovingAddress> = {}) => {
  if (!street || !city || !state || !zip) {
    return undefined;
  }

  return { street, city, state, zip };
};

const START_LABEL = 'Starting address';
const END_LABEL = 'Destination address';

export const MovingAddressFields: React.FC<{
  startAddress?: Partial<MovingAddress>;
  endAddress?: Partial<MovingAddress>;
  startAddressDetails?: StrictMovingAddressDetails;
  rooms?: Record<string, number | boolean>;
  flowOnChange: MovingCheckoutStepProps['onChange'];
  onChange(field: string, address: Partial<ClientAddress>): void;
  onValidateRoute?(valid: boolean): void;
  onValidatingRoute?(validating: boolean): void;
}> = ({
  startAddress,
  endAddress,
  startAddressDetails,
  rooms,
  flowOnChange,
  onChange,
  onValidateRoute,
  onValidatingRoute,
}) => {
  const [errors, setErrors] = useState<{
    [key in AddressField]: string | undefined;
  }>({ startAddress: undefined, endAddress: undefined });

  const originAddress = sanitizeAddressInput(startAddress);
  const destinationAddress = sanitizeAddressInput(endAddress);

  const unitTypes =
    startAddress &&
    sanitizeUnitTypesInput({ ...startAddress, ...startAddressDetails }, rooms);
  const moveSize = determineMoveSize(startAddressDetails);

  const track = useTrack();

  const { data, loading, error } = useMovingRouteSummaryQuery({
    variables: {
      destinationAddress: destinationAddress!,
      originAddress: {
        ...originAddress!,
        details: {
          buildingType: startAddressDetails?.buildingType,
        },
      },
      unitTypes: unitTypes,
      moveSize: moveSize,
    },
    skip: !originAddress || !destinationAddress,
    onCompleted: (data) => {
      if (data.movingRouteSummary.validMoveRoute) {
        createThirdPartyConversionEvent(EventName.MOVING_ADDRESS_VALID);
        const longDistance = data.movingRouteSummary.longDistance;
        const extendedDestinationServiceArea =
          data.movingRouteSummary.extendedDestinationServiceArea;
        flowOnChange('longDistance', longDistance);
        flowOnChange(
          'extendedDestinationServiceArea',
          extendedDestinationServiceArea,
        );
        if (longDistance) {
          // Long distance customers are not eligible for help & save
          flowOnChange('adjustedMoverCount', undefined);
        }
        if (extendedDestinationServiceArea) {
          clearStoragePlan(flowOnChange);
        }
      }
    },
  });
  const isOSA = data && !data.movingRouteSummary.validMoveRoute;

  useEffect(() => onValidatingRoute?.(loading), [loading]);

  useEffect(() => {
    if (!originAddress || !destinationAddress) {
      onValidateRoute?.(false);
    } else {
      onValidateRoute?.(!isOSA);
    }
  }, [originAddress, destinationAddress, isOSA]);

  const handleAddressChange = (field: AddressField, value: ClientAddress) => {
    if (
      value?.googleAddressType === 'route' ||
      value?.googleAddressType === ''
    ) {
      setErrors({ ...errors, [field]: ADDRESS_VALIDATION.INCOMPLETE });
    } else {
      setErrors({ ...errors, [field]: undefined });

      onChange(field, value);

      if (value.city && value.state && value.zip) {
        const formattedAddress = `${value.street}, ${value.city}, ${value.state} ${value.zip}`;

        track({
          objectName:
            field === 'startAddress' ? 'start_address' : 'end_address',
          value: formattedAddress,
          objectType: 'input:text',
          label: field === 'startAddress' ? START_LABEL : END_LABEL,
        });
      }
    }
  };

  return (
    <>
      <Box margin="0 0 12px">
        <Label>{START_LABEL}</Label>
        <FieldAddress
          value={startAddress}
          onChange={handleAddressChange}
          onInputChange={(street) =>
            street === '' && onChange('startAddress', {})
          }
          placeholder="Starting address"
          hideClearButton={true}
          includeCityInInput={true}
          field="startAddress"
          error={errors.startAddress}
          inputProps={{ autoComplete: 'off' }}
          selectFirstOnBlur={true}
          testId="startAddress"
        />
      </Box>
      <Box>
        <Label>{END_LABEL}</Label>
        <FieldAddress
          value={endAddress}
          onChange={handleAddressChange}
          onInputChange={(street) =>
            street === '' && onChange('endAddress', {})
          }
          placeholder="Destination address"
          hideClearButton={true}
          includeCityInInput={true}
          field="endAddress"
          error={errors.endAddress}
          inputProps={{ autoComplete: 'off' }}
          initialPrompt="Enter the same address if you’re moving or rearranging items in the same building"
          selectFirstOnBlur={true}
          testId="endAddress"
        />
      </Box>
      {isOSA && <OSACard />}
      {error && <ErrorCard>Error occurred while validating route.</ErrorCard>}
    </>
  );
};
