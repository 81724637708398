import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';

import { Box, Button, COLORS, FontWeight, Text, mq } from '@clutter/clean';

import { ErrorCodeEnum } from '@graphql/platform';

import {
  CheckoutType,
  SharedCheckoutStepProps,
} from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import { Errors } from '@root/resources/errors';
import { PHONE_MASK } from '@root/resources/masks';
import { IMapping } from '@root/resources/types/mapping';
import { ServiceEnum } from '@root/resources/types/service';
import { EventSchema } from '@root/resources/wt/types';

import { Action, useClientDataContext } from '@shared/client_data_context';
import { LeadForm, LeadFormRef } from '@shared/lead_form';

import {
  ErrorWithoutTypename,
  FormattedGQLError,
  GQLError,
} from '@utils/gql_errors';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { titleize } from '@utils/text';

const Fields = styled.div`
  display: grid;

  ${mq({
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: ['0', '20px'],
  })}
`;

const MAPPING: IMapping = {
  email: {
    type: 'text',
    label: 'Email',
    transformation: (value) => value.trim(),
    inputProps: {
      autoComplete: 'email',
    },
  },
  phone: {
    label: 'Phone',
    type: 'text',
    mask: PHONE_MASK,
    inputProps: {
      type: 'tel',
      autoComplete: 'phone',
    },
  },
  name: {
    type: 'text',
    label: 'First & Last Name',
    transformation: titleize,
    inputProps: {
      autoComplete: 'name',
    },
  },
};

export const LeadFields: React.FC<
  SharedCheckoutStepProps & {
    onLeadCreate(success: boolean, newStorageCustomer?: boolean): void;
    serviceContext: ServiceEnum;
    loading?: boolean;
    disabled?: boolean;
    buttonLabel?: string;
  }
> = ({
  onChange,
  values: { checkoutType, name, email, phone, currentStorageCustomer },
  onLeadCreate,
  serviceContext,
  loading: parentLoading,
  disabled: parentDisabled,
  buttonLabel = 'Check Availability',
}) => {
  const track = useTrack();
  const ref = useRef<LeadFormRef>(null);
  const { dispatch } = useClientDataContext();
  const [{ errors }, setErrors] = useState(() => ({ errors: new Errors() }));
  const [gqlError, setGqlError] = useState<ErrorWithoutTypename>();
  const [loading, setLoading] = useState(false);
  const disabled =
    loading || parentLoading || parentDisabled || !name || !email || !phone;
  const isReonboarding = checkoutType === CheckoutType.Reonboarding;
  const trackFunnelEvent = useTrackFunnelEvents();

  const handleFormSubmit = async () => {
    if (disabled) return;
    track({
      action: 'click',
      objectName: 'lead_submit',
      objectType: 'button',
      label: buttonLabel,
    });
    let hasSyncError = false;

    if (!name || name.trim().split(' ').length < 2) {
      hasSyncError = true;
      errors.add('name', 'Please provide your full name.');
    }

    if (!email) {
      hasSyncError = true;
      errors.add('email', 'Email is required.');
    }

    if (!phone) {
      hasSyncError = true;
      errors.add('phone', 'Phone is required.');
    }

    if (hasSyncError) {
      setErrors({ errors });
    }

    try {
      setLoading(true);
      const {
        token,
        id,
        currentStorageCustomer: leadIsStorageCustomer,
      } = await ref.current!.createLead();
      const newStorageCustomer =
        leadIsStorageCustomer && !currentStorageCustomer;
      if (token && id) {
        onChange('leadToken', token);
        onChange('currentStorageCustomer', leadIsStorageCustomer);
        trackFunnelEvent({
          schema: EventSchema.WWW__LeadCaptureCompleted,
          action: 'submit',
          metadata: {
            name,
            email,
            lead_id: id,
          },
        });
      }
      onLeadCreate(token !== undefined, newStorageCustomer);
    } catch (error) {
      if (error instanceof GQLError) {
        if (error.fullError.errorCode === ErrorCodeEnum.InvalidEmail) {
          errors.add('email', 'Please enter a valid email address.');
        } else if (!error.metadata.canResolveViaMFA) {
          setGqlError(error.fullError);
        }
      }
      setErrors({ errors });
      onLeadCreate(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field: 'name' | 'email', value: string) => {
    errors.reset(field);
    dispatch({ type: Action.ClearLead });
    setGqlError(undefined);
    track({
      action: 'input',
      objectName: field,
      value: value,
      objectType: 'input:text',
      label: field === 'name' ? 'First & Last' : 'Email',
    });
    onChange(field, value);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <Fields>
        <LeadForm
          ref={ref}
          errors={errors}
          fields={['name', 'phone', 'email']}
          disabled={isReonboarding}
          leadAttributes={{ name, email, phone }}
          validateCustomerStatus={false}
          serviceContext={serviceContext}
          onChange={handleChange}
          mapping={MAPPING}
        />
      </Fields>
      {isReonboarding && (
        <Box margin="-16px 0 0">
          <Text.Caption color={COLORS.hippo} weight={FontWeight.Medium}>
            You can update your contact information through Customer Care once
            your appointment is scheduled.
          </Text.Caption>
        </Box>
      )}
      <Box.Flex
        margin="12px 0 0"
        textAlign={['center']}
        flexDirection="column"
        maxWidth={[null, '265px']}
      >
        <Button
          size="large"
          type="submit"
          disabled={disabled}
          loading={loading || parentLoading}
        >
          {buttonLabel}
        </Button>
      </Box.Flex>
      {gqlError && (
        <Box
          background={COLORS.dust}
          padding="24px"
          borderRadius="4px"
          margin="24px 0 0"
        >
          <FormattedGQLError error={gqlError} />
        </Box>
      )}
    </form>
  );
};
