import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  ResponsiveValue,
  SkeletonLoader,
  Text,
  UnstyledButton,
  mq,
  toggleStyleValue,
} from '@clutter/clean';

import { Fallback } from '@shared/fallback';

import { DropdownArrow } from '@images/svg/dropdown_arrow';

const TabContainer = styled(UnstyledButton)<{
  active: boolean;
  gridArea: ResponsiveValue<string>;
}>`
  background: ${toggleStyleValue(
    'active',
    COLORS.tealBackground,
    COLORS.cloud,
  )};
  height: 100%;
  min-height: 80px;
  border-right: 1px solid ${COLORS.grayBorder};
  border-bottom: 1px solid
    ${toggleStyleValue('active', 'transparent', COLORS.grayBorder)};

  &:last-of-type {
    border-right: none;
  }

  svg {
    display: block;
    transform: ${toggleStyleValue('active', 'rotate(180deg)', 'none')};
  }

  ${({ gridArea }) =>
    mq({
      gridArea,
    })}
`;

const Content: React.FC<{
  label: string;
  loading?: boolean;
  loaderWidth?: string;
  value: React.ReactNode;
}> = ({ label, loading, loaderWidth, value }) => {
  return (
    <Box.Flex flexDirection="column" alignItems="center">
      <Text.Caption color={COLORS.hippo}>
        <b>{label}</b>
      </Text.Caption>
      <Text.Body color={COLORS.tealPrimary}>
        <Fallback
          delayMs={0}
          loader={
            <SkeletonLoader
              width={loaderWidth ?? '60px'}
              height="20px"
              margin="2px 0"
            />
          }
          value={loading ? undefined : true}
        >
          <b>{value}</b>
        </Fallback>
      </Text.Body>
    </Box.Flex>
  );
};

const Tab: React.FC<{
  children: React.ReactNode;
  active: boolean;
  gridArea: ResponsiveValue<string>;
  onClick(): void;
}> = ({ children, active, gridArea, onClick }) => {
  return (
    <TabContainer active={active} onClick={onClick} gridArea={gridArea}>
      <Box.Flex flexDirection="column" alignItems="center" gap="4px">
        {children}
        <Box>
          <DropdownArrow fillColor={COLORS.tealPrimary} />
        </Box>
      </Box.Flex>
    </TabContainer>
  );
};

const Combined = Object.assign(Tab, { Content });

export { Combined as Tab };
