import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DateTime } from 'luxon';

import { Elements } from '@stripe/react-stripe-js';
import { Token } from '@stripe/stripe-js';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';

import { Lead__ServiceNeeds } from '@graphql/platform';

import { MovingBillingModal } from '@root/components/checkout/cart/moving_billing_modal';
import { PhoneInput } from '@root/components/checkout/cart/phone_input';
import {
  PaymentContextProvider,
  usePaymentContext,
} from '@root/components/checkout/payment_context';
import { setBookedFlag } from '@root/components/checkout/utilities/persistence';
import { useTrackBookingErrors } from '@root/components/checkout/utilities/use_track_booking_errors';
import { DEFAULT_ELEMENTS_OPTIONS, getStripe } from '@root/initializers/stripe';
import { WTProvider, flushEvents, useTrack } from '@root/initializers/wt';
import { ServiceEnum } from '@root/resources/types/service';
import { phone as phoneValidator } from '@root/resources/validator';
import { EventSchema } from '@root/resources/wt/types';
import { WWW_ROUTES } from '@root/root/routes';

import { useClientDataContext } from '@shared/client_data_context';
import { CheckoutFooter } from '@shared/content/checkout_footer';
import { Header } from '@shared/header/header';
import { PaymentMethodError } from '@shared/payment_method_error';
import { QualifiedContactWidget } from '@shared/qualified_contact_widget';

import {
  ErrorWithoutTypename,
  FormattedGQLError,
  GQLError,
} from '@utils/gql_errors';
import { useCartViewedFunnelEvent } from '@utils/hooks/funnel_events/use_cart_viewed_funnel_event';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useOnMount } from '@utils/hooks/mount';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { getReferralCouponByService } from '@utils/referral';

import { useCreateLead } from '../shared/lead_form';
import { MFAModal, MFAResult } from '../shared/mfa_modal';
import { Content, Grid } from './cart/layout';
import { MissingInformationModal } from './cart/missing_information_modal';
import { MovingBookingDetails } from './cart/moving_booking_details';
import { MovingCartValueProps } from './cart/moving_cart_value_props';
import { MovingDueToday } from './cart/moving_due_today';
import { MovingFreeStorageCard } from './cart/moving_free_storage_card';
import { MovingServiceSummary } from './cart/moving_service_summary';
import { PaymentMethod } from './cart/payment_method';
import { PromoCodeInput } from './cart/promo_code_input';
import { ReserveCTA } from './cart/reserve_cta';
import { useMovingCheckoutContext } from './context';
import { useMovingStoragePlanInput } from './helpers/use_moving_storage_plan_input';
import { useReserveMove } from './helpers/use_reserve_move';
import { Moving } from './product_pages/steps/arrays/moving';
import { MovingCheckoutStep } from './types';
import { scrollToStep } from './utilities/scroll_animation';

const RESERVE_BUTTON_LABEL = 'Confirm reservation';
const PAYMENT_METHOD_ID = 'payment-method';
const PHONE_ID = 'card-input-container';

const SERVICE_NEEDS = [Lead__ServiceNeeds.Moving];

const CheckoutCartContent = () => {
  const {
    flowState: { onChange, goToStep, prev, values, completeFlow },
    navigationState: { dispatch: navigationDispatch },
    pricingSet,
  } = useMovingCheckoutContext();
  const {
    coupon,
    name,
    phone,
    email,
    zip,
    defaultSource,
    freeStorageSelected,
    planSize,
    commitment,
    protectionPlan,
    checkoutType,
    extendedDestinationServiceArea,
  } = values;

  const history = useHistory();
  const track = useTrack();
  const createLead = useCreateLead();
  const reserveMove = useReserveMove();
  const { isDesktop } = useBreakpoints();
  const leadData = {
    name: name ?? '',
    phone: phone ?? '',
    email: email ?? '',
  };

  useCartViewedFunnelEvent();
  const { tokenize, paymentMethodError, stripeToken, billingNameError } =
    usePaymentContext();

  const [showMFAModal, setShowMFAModal] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [gqlError, setGqlError] = useState<ErrorWithoutTypename | null>(null);
  const [showCancellationModal, setShowCancellationModal] = useState(false);

  const trackFunnelEvent = useTrackFunnelEvents();

  const editNavigationRef = useRef(false);

  const goToDetailPage = (step: MovingCheckoutStep) => {
    track({
      action: 'click',
      objectName: 'edit',
      value: step,
    });
    editNavigationRef.current = true;
    goToStep(step);
    navigationDispatch({ type: 'setTargetStep', payload: { step } });
    history.length > 1
      ? history.goBack()
      : history.replace(WWW_ROUTES.PRODUCT_PAGE_MOVING);
  };

  useOnMount(() => {
    navigationDispatch({ type: 'viewCart' });
    const unsubscribe = history.listen((newLocation) => {
      // If a customer navigates back to the previous page via the back button, we want to ensure
      // that we track that action as a step transition (but not if they go to a
      // different, non-booking page which is treated as abandonment).
      if (
        WWW_ROUTES.PRODUCT_PAGE_MOVING === newLocation.pathname &&
        !editNavigationRef.current
      ) {
        prev();
      }
      // This listener will only be called on navigation _after_ the page
      // unmounts, so we call this manually instead of returning as part of
      // effect cleanup
      unsubscribe();
    });
  });

  const {
    data: { referral },
  } = useClientDataContext();

  useOnMount(() => {
    if (!coupon) {
      onChange(
        'coupon',
        getReferralCouponByService(ServiceEnum.Moving, referral),
      );
    }
  });

  useTrackBookingErrors({
    phoneError,
    billingNameError,
    paymentMethodError,
    gqlError,
  });

  const boundCreateLead = async () => {
    try {
      const { token } = await createLead({
        leadAttributes: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          serviceNeeds: SERVICE_NEEDS,
        },
        serviceContext: ServiceEnum.Moving,
        validateCustomerStatus: true,
      });

      onChange('leadToken', token);

      return token;
    } catch (error) {
      if (error instanceof GQLError) {
        if (error.metadata.canResolveViaMFA) {
          setShowMFAModal(true);
          track({ action: 'display', objectName: 'mfa_modal' });
        } else {
          setGqlError(error.fullError);
        }
      } else {
        throw error;
      }
    }
  };

  const storagePlanInput = useMovingStoragePlanInput({
    zip: values.endAddress?.zip,
    planSize: planSize,
    commitment: commitment,
    protectionPlan: protectionPlan,
    checkoutType: checkoutType,
    pricingSet: pricingSet,
  });

  const bookMove = async ({
    customerToken,
    leadToken,
    stripeToken,
  }: {
    customerToken?: string;
    leadToken: string;
    stripeToken?: Token;
  }): Promise<boolean> => {
    if (!stripeToken && !defaultSource) {
      throw new Error('Cannot book without card');
    }
    try {
      const { orderToken, accountPortalUrl } = await reserveMove({
        stripeToken,
        customerToken,
        leadToken,
        values,
        storagePlanInput,
      });
      await completeFlow('book', {
        order_token: orderToken,
      });
      setBookedFlag();
      flushEvents();
      window.location.href = accountPortalUrl;
      return true;
    } catch (error) {
      if (error instanceof GQLError) {
        setGqlError(error.fullError);
      } else {
        throw error;
      }
      return false;
    }
  };

  const onMFAModalClose = ({ customerToken, leadToken }: MFAResult) => {
    setShowMFAModal(false);
    if (customerToken && leadToken) {
      track({ objectType: 'mfa_modal', action: 'submit' });
      try {
        setLoading(true);
        bookMove({ customerToken, leadToken, stripeToken });
      } catch {
        setLoading(false);
      }
    } else {
      track({
        objectType: 'mfa_modal',
        action: 'click',
        label: 'close',
      });
    }
  };

  const onReserve = async () => {
    setGqlError(null);
    setLoading(true);

    let hasBooked = false;
    try {
      let hasPhoneError = false;
      let hasCardError = false;

      if (phone && phoneValidator(phone)) {
        onChange('phone', phone);
        trackFunnelEvent({
          schema: EventSchema.WWW__PhoneCaptureCompleted,
          action: 'submit',
          metadata: { phone_number: phone },
        });
      } else {
        setPhoneError('Please enter a valid phone number');
        hasPhoneError = true;
      }

      let currentStripeToken: Token | undefined = stripeToken;
      if (!defaultSource) {
        currentStripeToken = await tokenize();
        if (!currentStripeToken) {
          hasCardError = true;
        }
      }

      if (hasPhoneError || hasCardError) {
        setTimeout(
          () =>
            scrollToStep(hasPhoneError ? PHONE_ID : PAYMENT_METHOD_ID, 'top'),
          10,
        );
        return;
      }

      const leadToken = await boundCreateLead();
      if (!leadToken) return;

      hasBooked = await bookMove({
        leadToken,
        stripeToken: currentStripeToken,
      });
    } finally {
      if (!hasBooked) setLoading(false);
    }
  };

  const ErrorText = isDesktop ? Text.Callout : Text.Caption;

  const bookingError = (gqlError || paymentMethodError) && (
    <Box textAlign="center" margin="8px 0 0">
      <ErrorText color={COLORS.toucan}>
        {gqlError && <FormattedGQLError error={gqlError} />}
        {paymentMethodError && (
          <PaymentMethodError error={paymentMethodError} />
        )}
      </ErrorText>
    </Box>
  );

  const cancelBy = (values.datePreferred || DateTime.local()).minus({
    days: 2,
  });

  const promoCodeElement = (
    <PromoCodeInput
      coupon={coupon}
      onChange={(value) => {
        onChange('coupon', value);
      }}
      service="moving"
      disabled={!!coupon || !!freeStorageSelected}
    />
  );

  return (
    <Box minHeight="100vh">
      {!isDesktop && (
        <>
          <MovingServiceSummary onEdit={goToDetailPage} collapsible />
          <Box margin="8px 8px 32px">
            <MovingDueToday />
          </Box>
          {!extendedDestinationServiceArea && <MovingFreeStorageCard />}
        </>
      )}
      <Content>
        <Grid>
          <Box padding={[null, null, '120px 0 0']}>
            <Box margin="0 0 32px">
              <MovingBookingDetails
                values={values}
                onChange={onChange}
                onEdit={goToDetailPage}
                onCancellationLinkClick={() => setShowCancellationModal(true)}
              />
              <Box id={PHONE_ID} margin="24px 0 0">
                <PhoneInput
                  onChange={(value) => {
                    setPhoneError(undefined);
                    onChange('phone', value);
                  }}
                  phoneError={phoneError}
                  value={phone ?? ''}
                />
              </Box>
            </Box>
            <Box id={PAYMENT_METHOD_ID} margin="0 0 16px">
              <PaymentMethod
                defaultSource={defaultSource}
                onClearDefaultSource={() =>
                  onChange('defaultSource', undefined)
                }
              />
            </Box>
            <Box margin="0 0 32px">
              {!isDesktop && promoCodeElement}
              {freeStorageSelected && (
                <Box margin="-8px 0 0">
                  <Text.Caption color={COLORS.storm} weight={FontWeight.Medium}>
                    Promo already applied. One promo per reservation.
                  </Text.Caption>
                </Box>
              )}
            </Box>
            <ReserveCTA
              bookingError={bookingError}
              buttonDisabled={loading}
              buttonLabel={RESERVE_BUTTON_LABEL}
              onReserve={onReserve}
              buttonLoading={loading}
              cancelByDateTime={cancelBy}
              onCancellationLinkClick={() => setShowCancellationModal(true)}
              service={Lead__ServiceNeeds.Moving}
            />
            <Box position="sticky" top="100vh" margin="auto" padding="32px 0">
              <MovingCartValueProps />
            </Box>
          </Box>
          {isDesktop && (
            <Box
              background={COLORS.grayBackground}
              height="100%"
              padding="120px 40px 0"
              position="relative"
              style={{ zIndex: 1000 }}
            >
              <MovingServiceSummary onEdit={goToDetailPage} />
              {promoCodeElement}
              {!extendedDestinationServiceArea && <MovingFreeStorageCard />}
            </Box>
          )}
        </Grid>
      </Content>
      <MFAModal
        {...leadData}
        zip={zip ?? ''}
        serviceNeeds={SERVICE_NEEDS}
        isOpen={showMFAModal}
        onClose={onMFAModalClose}
      />
      <MovingBillingModal
        isOpen={showCancellationModal}
        handleModalClose={() => setShowCancellationModal(false)}
      />
      <MissingInformationModal service="moving" values={values} />
    </Box>
  );
};

export const CheckoutMovingCart = () => {
  const { isDesktop } = useBreakpoints();
  return (
    <WTProvider
      params={{
        container: MovingCheckoutStep.Cart,
        position: Moving.length - 1,
      }}
    >
      <Elements options={DEFAULT_ELEMENTS_OPTIONS} stripe={getStripe()}>
        <PaymentContextProvider>
          <Header
            hideZipInput={true}
            sticky={false}
            opaque={!isDesktop}
            menuItems={[]}
          />
          <CheckoutCartContent />
          <QualifiedContactWidget bottomOffset={isDesktop ? 0 : 120} />
          <CheckoutFooter />
          {/* Added padding so the sticky button doesn't overlap footer */}
          <Box height={['140px', '120px', '0']} />
        </PaymentContextProvider>
      </Elements>
    </WTProvider>
  );
};
