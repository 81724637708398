import { BREAKPOINTS } from '@clutter/clean';

import { STICKY_CONTENT_CONTAINER_HEIGHT } from '@root/components/checkout/product_pages/storage_product_page';
import { COMBINED_DESKTOP_MAP_HEIGHT } from '@root/components/checkout/product_pages/subcomponents/moving_map';

import { skipAnimation } from '@utils/browser';

import jump from 'jump.js';

export const SCROLL_DURATION =
  process.env.RAILS_ENV === 'test' || skipAnimation() ? 0 : 900;

const centerAlignStep = (id: string, alignedElementHeight: number) => {
  const el = document.querySelector<HTMLDivElement>('#' + id);

  if (!el) return;

  const center = window.innerHeight / 2;
  const height = el.offsetHeight;
  const offset =
    window.innerWidth < BREAKPOINTS.SM_INT
      ? Math.min(-32, -(center - height / 2))
      : -(center - alignedElementHeight / 2);
  jump(el, {
    duration: SCROLL_DURATION,
    offset,
  });
};

export const scrollToStep = (
  name: string,
  mode: 'centered' | 'top' = 'centered',
) => {
  if (mode === 'top') {
    jump('#' + name, {
      duration: SCROLL_DURATION,
      offset: window.innerWidth < BREAKPOINTS.SM_INT ? -32 : -64,
    });
    return;
  }

  centerAlignStep(name, STICKY_CONTENT_CONTAINER_HEIGHT);
};

export const createBoundScrollToStep =
  (currentIdx: number, steps: Array<{ name: string }>) =>
  (step: 'next' | 'current' = 'current', mode?: 'centered' | 'top') => {
    const offset = step === 'next' ? 1 : 0;
    const name = steps[currentIdx + offset]?.name;
    if (name) {
      scrollToStep(name, mode);
    }
  };
// Because moving only has a single sticky element, it's more natural to align
// the focused step with the map. We can probably come up with a heuristic
// that works for both versions in the future.

export const movingScrollToStep = (name: string) => {
  centerAlignStep(name, COMBINED_DESKTOP_MAP_HEIGHT);
};

export const createMovingBoundScrollToStep =
  (currentIdx: number, steps: Array<{ name: string }>) =>
  (step: 'next' | 'current' = 'next') => {
    const offset = step === 'next' ? 1 : 0;
    const name = steps[currentIdx + offset]?.name;
    if (name) {
      movingScrollToStep(name);
    }
  };
