import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS } from '@clutter/clean';

import { Superscript } from '@root/components/checkout/product_pages/steps/smart_storage/bundles/superscript';
import { PLANS } from '@root/constants/pricing';

import { Currency } from '@shared/currency';

const DOUBLE_DRIVE_TIME_REGIONS = [
  'Inland Empire',
  'Los Angeles',
  'New Jersey',
  'Orange County',
  'Philadelphia',
  'Sacramento',
  'San Diego',
  'San Francisco',
  'South Jersey',
  'Stockton',
  'Ventura County',
];

const Table = styled.table`
  border: 1px solid ${COLORS.grayBorder};
  border-collapse: collapse;
  border-spacing: 24px;
  margin: 0 auto;

  th,
  td {
    border: 1px solid ${COLORS.grayBorder};
    border-collapse: collapse;
    padding: 16px;
  }
`;

export type FAQContent = {
  question: string;
  answer: React.ReactNode;
};

export const buildSmartStorageFAQContentList = (
  subjobPackageEligible?: boolean,
): FAQContent[] => {
  let faq: FAQContent[] = [
    {
      question: 'Can I visit a Smart Storage facility to pickup my items?',
      answer: (
        <>
          Clutter’s Smart Storage facilities are closed to the public. This
          ensures that your items are stored safely and only handled by trusted
          professionals.
          <br />
          <br />
          You can use our app to order any item back from storage when you need
          it. Items don’t have to be delivered to the same location they were
          picked up from.
          <br />
          <br />
          We can even have items shipped to anywhere in the U.S. via FedEx and
          UPS.
        </>
      ),
    },
  ];

  if (subjobPackageEligible) {
    faq = faq.concat([
      {
        question:
          'What if I need additional items picked up or delivered while items are in storage?',
        answer: (
          <>
            If you commit to 4 months of storage, then you get one pickup and/or
            delivery of items for free<Superscript>1</Superscript>.
            <br />
            <br />
            If you commit to 8 months of storage, then you get four pickup
            and/or delivery of items for free<Superscript>1</Superscript>.
            <br />
            <br />
            <Superscript>1</Superscript>The free hours have a maximum allotment
            based on your selected storage plan size. If a pickup or delivery
            lasts longer than that time, you will be billed hourly.
            <br />
            <br />
            This is the free allotment of hours by storage plan size:
            <br />
            <Table>
              <thead>
                <tr>
                  <th>Plan size</th>
                  <th>Free hours</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{PLANS['5x5'].label}</td>
                  <td>
                    <Box textAlign="center">2</Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['5x10'].label}</td>
                  <td>
                    <Box textAlign="center">3</Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x10'].label}</td>
                  <td>
                    <Box textAlign="center">5</Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x15'].label}</td>
                  <td>
                    <Box textAlign="center">6</Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x20'].label}</td>
                  <td>
                    <Box textAlign="center">6</Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x25'].label}</td>
                  <td>
                    <Box textAlign="center">6</Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x30'].label}</td>
                  <td>
                    <Box textAlign="center">6</Box>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ),
      },
      {
        question:
          'What charges apply if my free pickup or delivery lasts longer than the free allotment of hours?',
        answer: (
          <>
            If your free pickup or delivery lasts longer than the free allotment
            of hours, then you will be billed at the following hourly rate:
            <br />
            <Table>
              <thead>
                <tr>
                  <th>Plan size</th>
                  <th>Hourly rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{PLANS['5x5'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={122} />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['5x10'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={122} />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x10'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={155} />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x15'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={155} />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x20'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={187} />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x25'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={187} />
                    </Box>
                  </td>
                </tr>
                <tr>
                  <td>{PLANS['10x30'].label}</td>
                  <td>
                    <Box textAlign="center">
                      <Currency amount={220} />
                    </Box>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ),
      },
    ]);
  } else {
    faq = faq.concat([
      {
        question: 'How much does it cost to have items returned?',
        answer: (
          <>
            Your final delivery is based on how much you store - select a plan
            above to see the amount. This helps cover the cost of the truck,
            labor, and transport of the items.
            <br />
            <br />
            Deliveries of just some of your items are charged per hour. On
            select eligible items, you can also have FedEx ship your items in
            storage anywhere nationwide for a flat rate fee.
          </>
        ),
      },
      {
        question: 'Does the pickup have an hourly minimum?',
        answer: (
          <>
            Yes. The initial Smart Storage pickup comes with a one hour minimum.
          </>
        ),
      },
    ]);
  }
  return faq.concat([
    {
      question:
        'Can I have a different moving company pick up my items from your Smart Storage facility?',
      answer: (
        <>
          Clutter can deliver your items to any location within your service
          area.
          <br />
          <br />
          If you need items delivered somewhere outside of that service area, we
          will arrange to have those items shipped or work with a moving company
          of your choice to arrange for a pickup of the items you have in
          storage. Because Clutter’s service area is so broad, customers almost
          always pick Clutter’s return services.
        </>
      ),
    },
    {
      question: 'What storage size do I need? ',
      answer: (
        <>
          Since we will automatically downgrade your storage size if you end up
          needing less space, we recommend that you overestimate the storage
          space that you reserve.
          <br />
          <br />
          Generally speaking we’ve found the following storage sizes to be the
          right match:
          <br />
          <br />
          <Table>
            <thead>
              <tr>
                <th>Size description</th>
                <th>Size</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Small closet</td>
                <td>{PLANS['5x5'].label}</td>
              </tr>
              <tr>
                <td>Walk-in closet</td>
                <td>{PLANS['5x10'].label}</td>
              </tr>
              <tr>
                <td>Garage (most popular size)</td>
                <td>{PLANS['10x10'].label}</td>
              </tr>
              <tr>
                <td>Studio apartment</td>
                <td>{PLANS['10x15'].label}</td>
              </tr>
              <tr>
                <td>1 bedroom home</td>
                <td>{PLANS['10x20'].label}</td>
              </tr>
              <tr>
                <td>2 bedroom home</td>
                <td>{PLANS['10x25'].label}</td>
              </tr>
              <tr>
                <td>3 bedroom home</td>
                <td>{PLANS['10x30'].label}</td>
              </tr>
            </tbody>
          </Table>
        </>
      ),
    },
    {
      question: 'Can I tell you what I need picked up before my appointment?',
      answer: (
        <>
          Once you make your storage reservation, you gain access to your own
          online account portal. From there, you can manage your appointments
          and add or update the inventory we are picking up any time before we
          arrive.
        </>
      ),
    },
    {
      question: 'How many Move & Pack Specialists will you send?',
      answer: (
        <>
          Our system uses your appointment details including the inventory you
          entered to determine the right number of Move & Pack Specialists to
          send so we can quickly and safely move your items.
        </>
      ),
    },
    {
      question: 'Can I have more items picked up in the future?',
      answer: (
        <>
          Absolutely! You are free to add or remove items from your Smart
          Storage as often as you need. All pickups and deliveries are charged
          per hour.
        </>
      ),
    },
    {
      question:
        'How are Smart Storage facilities secure and temperature-controlled?',
      answer: (
        <>
          Unlike local storage units that sit empty and unmonitored, we maintain
          safe and healthy environments for our employees and your items.
          <br />
          <br />
          Our Smart Storage facilities are closed to the public and we only
          allow trained Clutter professionals access to any items in storage.
          Our policies ensure that all items are treated with care and are
          monitored 24/7. All facilities feature 24/7 CCTV security in addition
          to staff presence. Our facilities get regular cleaning and
          preventative pest control, which means your items will be free of
          dust, dirt, and pests. We keep temperatures regulated with
          state-of-the-art fan systems that ensure the safety of our team and
          your items.
        </>
      ),
    },
  ]);
};

export const buildMovingFAQContentList = (region?: string): FAQContent[] => [
  {
    question: 'Do you charge extra for drive time?',
    answer:
      region && DOUBLE_DRIVE_TIME_REGIONS.includes(region) ? (
        <>
          Drive time rates are regulated by the state and require knowing both
          the starting address and ending address.
          <br />
          <br />
          View your specific drive time requirements when you continue to
          reservation.
        </>
      ) : (
        <>No. Drive time is included in your standard rate.</>
      ),
  },
  {
    question: 'Do you provide protection and insurance for my move?',
    answer: (
      <>
        Clutter provides full coverage for moves as required by local
        regulations.
        <br />
        <br />
        Before your move you will be asked to declare a value for the move and
        your items will be protected up to the value declared.
      </>
    ),
  },
  {
    question: 'Can you get my building a COI?',
    answer: (
      <>
        Yes! Once you make a free reservation you will be able to request a COI
        through your account portal.
      </>
    ),
  },
  {
    question: 'How many movers do I need?',
    answer: (
      <>
        Clutter recommends the number of movers you’ll need based on experience
        from over 500,000 moves. However, since we don’t know your specific
        circumstances, you can build a move that fits your needs. Reducing the
        recommended number of movers may result in a longer appointment time and
        you may be required to help pack, lift, carry, load, and unload your
        items. We have an 8-hour max appointment time, so if you reduce the
        number of movers, we cannot guarantee that your appointment will be
        completed same-day. Only reduce the number of movers if you are
        physically able to help during your appointment and you agree to take
        instructions from our movers. You will also be required to complete a
        virtual walkthrough and sign a waiver before your appointment.
      </>
    ),
  },
  {
    question: 'Do you contract out your movers?',
    answer: (
      <>
        No. Clutter fully employs all their movers. Each mover is provided a
        fair wage, benefits, and goes through an extensive training program to
        ensure a quality moving experience.
      </>
    ),
  },
  {
    question: 'Is there a minimum number of hours for my move?',
    answer: (
      <>
        Yes, there is an hourly minimum for every move. This minimum can vary
        depending on your region, the move date, and local regulations.
        <br />
        <br />
        View your hourly minimum when you continue to reservation.
      </>
    ),
  },
  {
    question: 'Can I provide item-specific details for my move?',
    answer: (
      <>
        Yes! Once you make a free reservation for your move you will be able to
        complete a virtual walkthrough so we can properly prepare for the
        specific needs of your move.
      </>
    ),
  },
];

export const buildStoreAndMoveFAQContentList = (): FAQContent[] => [
  {
    question: 'How does this work?',
    answer: (
      <>
        Clutter provides 1-month of free storage when you use our A-to-B moving
        service. There are two ways you can use our storage facilities. Some
        customers need us to pack up their items, store them for a period of
        time, and then deliver them to their new home. Other customers need us
        to move most of their items from point A to point B and the rest,
        directly into storage.
      </>
    ),
  },
  {
    question: 'How much does this cost?',
    answer: (
      <>
        Store & Move is billed using an hourly labor rate, a flat warehouse fee,
        and any fees for materials or protection plans.
      </>
    ),
  },
  {
    question: 'How long do I get free storage for?',
    answer: (
      <>
        Simply enter your zip code and then follow the prompts on your screen to
        build your quote online.
      </>
    ),
  },
  {
    question: 'How do I book my delivery appointment?',
    answer: (
      <>
        Once your initial pickup is completed, you’ll be able to book your final
        return appointment from your online account portal.
      </>
    ),
  },
  {
    question: 'How does packing work?',
    answer: (
      <>
        Clutter can do as much or as little packing as you need! We are required
        to disassemble certain large items and will always wrap your items with
        protective blankets so that everything is secure.
      </>
    ),
  },
  {
    question: 'Can I add a protection plan?',
    answer: (
      <>
        Protection plans can be easily added at checkout with options ranging
        from standard to premium depending on the value of your items.
      </>
    ),
  },
];
