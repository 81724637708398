import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text, mq } from '@clutter/clean';

import amex from '@images/icons/americanexpress_small.svg';
import maestro from '@images/icons/maestro_small.svg';
import mastercard from '@images/icons/mastercard_small.svg';
import poweredByStripe from '@images/icons/powered_by_stripe.svg';
import secure from '@images/icons/secure.svg';
import visa from '@images/icons/visa_small.svg';

const Secure = styled.img`
  margin-right: 12px;
`;

const PaymentCardContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 0 16px;
`;

const CardIcons = styled.img`
  ${mq({ height: ['20px', null, '24px'] })}
`;

export const CredibilityBanner: React.FC<{ showCardIcons?: boolean }> = ({
  showCardIcons,
}) => (
  <Box.Flex
    background={COLORS.grayBackground}
    borderRadius="4px"
    padding="16px"
    alignItems="center"
    margin="24px 0"
    style={{ gap: '16px' }}
    flexWrap="wrap"
    flexDirection={showCardIcons ? ['column', null, 'row'] : 'row'}
  >
    <Box.Flex
      flexGrow={1}
      alignItems="center"
      justifyContent={['space-between', null, 'left']}
    >
      <Box.Flex>
        <Secure src={secure} alt="" />
        <Text.Caption>Secure & encrypted</Text.Caption>
      </Box.Flex>
      {showCardIcons && (
        <PaymentCardContainer>
          <CardIcons src={visa} alt="" />
          <CardIcons src={mastercard} alt="" />
          <CardIcons src={amex} alt="" />
          <CardIcons src={maestro} alt="" />
        </PaymentCardContainer>
      )}
    </Box.Flex>
    <img height="24px" src={poweredByStripe} alt="Powered By Stripe" />
  </Box.Flex>
);
