import { ready } from '../utilities/ready';
import { IThirdPartyEventParams } from './third_party_conversion_events';

interface IGtmParams extends IThirdPartyEventParams {
  event: string;
}
interface IDataLayer {
  push(e: IGtmParams): () => void;
}

declare let dataLayer: IDataLayer;

const gtmDataLayerPromise = new Promise<any>((resolve) => {
  ready(() => {
    if ((window as any).dataLayer) {
      resolve(dataLayer);
    } else {
      resolve(null);
    }
  });
});

export const gtmEnabled = async () => (await gtmDataLayerPromise) !== null;

export const gtm = async (params: IGtmParams) => {
  const gtmDataLayer = await gtmDataLayerPromise;
  if (gtmDataLayer) {
    gtmDataLayer.push(params);
  }
};
