import React, { useContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router';

import {
  DraftFragment,
  ReferralPromoFragment,
  useCouponQuery,
} from '@graphql/platform';

import { clearSessionCheckoutData } from '@root/components/checkout/utilities/persistence';
import { LeadSource } from '@root/resources/lead';

import { parseGQLErrorUnion } from '@utils/gql_errors';
import { useCouponDefaultTest } from '@utils/hooks/ab_testing';
import { getUTMParams } from '@utils/utm_params';

import { ServiceSelection } from './service_selector/constants';
import qs from 'qs';

export type ClientData = {
  lead: {
    email?: string;
    source?: LeadSource;
    token?: string;
  };
  draft?: DraftFragment;
  previousCheckoutZip?: string;
  previousCheckoutService?: ServiceSelection;
  monthlyStorageAmount?: number;
  referral?: ReferralPromoFragment;
  zip?: string;
  zipHasSelfStorage: boolean;
  zipPreselected: boolean;
  initialUTMParams: Record<string, string>;
  urlPromoCode?: string;
  urlPromoMovingEligible?: boolean;
  bookingPartner: {
    name?: string;
    userToken?: string;
  };
  serviceSelection?: ServiceSelection;
};

export enum Action {
  /** Sets a partial or zip for display purposes only. It may or not have been geocoded. */
  SetZip = 'set_zip',
  /** Sets a service selection for use when entering the funnel.*/
  SetService = 'set_service',
  /** Sets all data required to enter the main checkout. If a zip is included it must have been geocoded. */
  SetupCheckout = 'setup_checkout',
  /** Sets all data required for a pre-hydrated checkout, e.g. shareable or reonboarding. */
  SetupHydratedCheckout = 'setup_hydrated_checkout',
  /** Sets the token and email for an existing or newly created lead */
  SetLead = 'set_lead',
  /** Clears the lead */
  ClearLead = 'clear_lead',
  /** Sets information needed to apply a referral promotion in the funnel */
  SetReferralPromo = 'set_referral_promo',
  /** Sets a promo code that was supplied as a URL query parameter */
  SetURLPromoCode = 'set_url_promo_code',
  /** Clears the promo code */
  ClearURLPromoCode = 'clear_url_promo_code',
}

type SetZip = {
  type: Action.SetZip;
  payload: { zip: string };
};

type SetService = {
  type: Action.SetService;
  payload: { service: ServiceSelection };
};

type SetupCheckout = {
  type: Action.SetupCheckout;
  payload: {
    leadSource: LeadSource;
    zip?: string;
    zipHasSelfStorage?: boolean;
    serviceSelection?: ServiceSelection;
    draft?: DraftFragment;
  };
};

type SetupHydratedCheckout = {
  type: Action.SetupHydratedCheckout;
  payload: {
    leadToken?: string;
    zip?: string;
    zipPreselected?: boolean;
  };
};

type SetLead = {
  type: Action.SetLead;
  payload: {
    email: string;
    token: string;
  };
};

type ClearLead = {
  type: Action.ClearLead;
};

type SetReferralPromo = {
  type: Action.SetReferralPromo;
  payload: {
    referral: ReferralPromoFragment;
  };
};

type SetURLPromoCode = {
  type: Action.SetURLPromoCode;
  payload: {
    urlPromoCode: string;
    urlPromoMovingEligible: boolean;
  };
};

type ClearURLPromoCode = {
  type: Action.ClearURLPromoCode;
};

type ActionType =
  | SetZip
  | SetService
  | SetupCheckout
  | SetupHydratedCheckout
  | SetLead
  | ClearLead
  | SetReferralPromo
  | SetURLPromoCode
  | ClearURLPromoCode;

export type Dispatch = (action: ActionType) => void;

function reducer(state: ClientData, action: ActionType): ClientData {
  switch (action.type) {
    case Action.SetZip: {
      return { ...state, zip: action.payload.zip };
    }
    case Action.SetService: {
      return { ...state, serviceSelection: action.payload.service };
    }
    case Action.SetupCheckout: {
      if (
        !action.payload.zip ||
        action.payload.zip !== state.previousCheckoutZip ||
        action.payload.serviceSelection !== state.previousCheckoutService ||
        action.payload.draft
      ) {
        clearSessionCheckoutData();
      }
      return {
        ...state,
        lead: {
          ...state.lead,
          source: action.payload.leadSource,
        },
        draft: action.payload.draft,
        previousCheckoutZip: action.payload.zip,
        previousCheckoutService: action.payload.serviceSelection,
        zip: action.payload.zip,
        zipHasSelfStorage: action.payload.zipHasSelfStorage || false,
        zipPreselected: !!action.payload.zip,
        serviceSelection: action.payload.serviceSelection,
      };
    }
    case Action.SetupHydratedCheckout: {
      return Object.assign(
        {},
        {
          ...state,
          zipPreselected: !!action.payload.zipPreselected,
        },
        action.payload.leadToken && { leadToken: action.payload.leadToken },
        action.payload.zip && { zip: action.payload.zip },
      );
    }
    case Action.SetLead: {
      return {
        ...state,
        lead: {
          ...state.lead,
          email: action.payload.email,
          token: action.payload.token,
        },
      };
    }
    case Action.ClearLead: {
      return {
        ...state,
        lead: {
          ...state.lead,
          email: undefined,
          token: undefined,
        },
      };
    }
    case Action.SetReferralPromo: {
      return {
        ...state,
        referral: action.payload.referral,
      };
    }
    case Action.SetURLPromoCode: {
      return {
        ...state,
        urlPromoCode: action.payload.urlPromoCode,
        urlPromoMovingEligible: action.payload.urlPromoMovingEligible,
      };
    }
    case Action.ClearURLPromoCode: {
      return {
        ...state,
        urlPromoCode: undefined,
        urlPromoMovingEligible: undefined,
      };
    }
  }
}

const DispatchContext = React.createContext<Dispatch | undefined>(undefined);
const DataContext = React.createContext<ClientData | undefined>(undefined);

export const ClientDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { search } = useLocation();
  const { promoCode: defaultPromoCode } = useCouponDefaultTest();
  const qsParams = qs.parse(search, { ignoreQueryPrefix: true });
  const [state, dispatch] = useReducer(
    reducer,
    {
      zipPreselected: false,
      zipHasSelfStorage: false,
      initialUTMParams: {},
    },
    (state) => {
      return {
        ...state,
        zip: qsParams.zip ?? undefined,
        bookingPartner: {
          name: qsParams.ref ?? undefined,
          userToken: qsParams.partner_token ?? undefined,
        },
        lead: {},
        initialUTMParams: getUTMParams(),
        serviceSelection: qsParams.service,
      };
    },
  );

  const promoCode = qsParams.promo_code ?? defaultPromoCode;
  const { data } = useCouponQuery({
    variables: {
      code: promoCode ?? '',
    },
    skip: !promoCode,
  });

  useEffect(() => {
    const [coupon] = parseGQLErrorUnion(data?.coupon);
    if (coupon && coupon.promoCode !== state.urlPromoCode) {
      dispatch({
        type: Action.SetURLPromoCode,
        payload: {
          urlPromoCode: promoCode,
          urlPromoMovingEligible: coupon.movingEligible,
        },
      });
    }
  }, [data, dispatch, state.serviceSelection]);

  return (
    <DataContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </DataContext.Provider>
  );
};

export const useClientDataContext = () => {
  const data = useContext(DataContext);
  const dispatch = useContext(DispatchContext);

  if (!data || !dispatch) {
    throw new Error('ClientDataProvider not present');
  }
  return { data, dispatch };
};
