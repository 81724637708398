import React, { useEffect, useState } from 'react';

import { capitalize } from 'lodash-es';

import styled from '@emotion/styled';

import { Token } from '@stripe/stripe-js';

import { Box, COLORS, FontWeight, Text, TextButton } from '@clutter/clean';

import { Source } from '@graphql/platform';

import { usePaymentContext } from '@root/components/checkout/payment_context';
import { EventSchema } from '@root/resources/wt/types';

import { PaymentMethodErrorKind } from '@shared/payment_method_error';

import { useStabilizedFunction } from '@utils/hooks';
import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

import checkCircle from '@images/icons/check-circle.svg';

import { WalletElement, WalletProviderName } from '../wallet_element';
import { CreditCardInput } from './credit_card_input';
import { CredibilityBanner } from './subcomponents/credibility_banner';

const StyledWalletElement = styled(WalletElement)`
  margin: 24px 0 32px;
`;

const Separator = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${COLORS.grayBorder};
    margin: 0 4px;
  }
`;

const SavedPaymentMethod: React.FC<{
  onRemove(): void;
  paymentMethod: WalletProviderName | Source;
}> = ({ onRemove, paymentMethod }) => (
  <Box.Flex
    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.1)"
    borderRadius="4px"
    padding="24px"
  >
    <Box.FlexItem alignSelf="center" padding="0 16px 0 0">
      <img src={checkCircle} alt="" />
    </Box.FlexItem>
    <Box.FlexItem>
      <Text.Body weight={FontWeight.Medium}>Saved payment method</Text.Body>
      {typeof paymentMethod === 'string' ? (
        <Text.Body>
          {paymentMethod === WalletProviderName.ApplePay
            ? 'Apple Pay'
            : 'Wallet'}
        </Text.Body>
      ) : (
        <Text.Body>
          {paymentMethod.fundingType && (
            <>{capitalize(paymentMethod.fundingType)} Card </>
          )}
          {`**** **** **** ${paymentMethod.number ?? '****'}`}
        </Text.Body>
      )}
    </Box.FlexItem>
    <Box.FlexItem alignSelf="center" margin="0 0 0 auto">
      <TextButton kind="destructive" onClick={onRemove}>
        Remove
      </TextButton>
    </Box.FlexItem>
  </Box.Flex>
);

type PaymentMethodProps = {
  required?: boolean;
  disabled?: boolean;
  defaultSource?: Source;
  onClearDefaultSource(): void;
};

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  required = true,
  disabled,
  defaultSource,
  onClearDefaultSource,
}) => {
  const [applePayAvailable, setApplePayAvailable] = useState(false);
  const trackFunnelEvent = useTrackFunnelEvents();

  const {
    billingName,
    billingNameError,
    onBillingNameChange,
    onCardInputChange,
    onPaymentMethodError,
    onWalletToken,
    paymentMethodError,
    stripeToken,
  } = usePaymentContext();

  useEffect(() => {
    trackFunnelEvent({
      schema: EventSchema.WWW__PaymentMethodViewed,
      action: 'display',
      metadata: {},
    });
  }, [trackFunnelEvent]);

  const onRemove = () => {
    if (!disabled) {
      onWalletToken(undefined);
      onClearDefaultSource();
    }
  };

  const onApplePayToken = useStabilizedFunction(
    async (newWalletToken: Token) => {
      onWalletToken(newWalletToken);
      trackFunnelEvent({
        schema: EventSchema.WWW__PaymentMethodCompleted,
        action: 'submit',
        metadata: { method: 'apple_pay' },
      });
    },
  );

  const subText = required
    ? 'You won’t be charged now, but we require a payment method to hold your appointment.'
    : 'A payment method must be added at least 2 days before your appointment to prevent cancellation.';

  const paymentMethod =
    stripeToken?.card?.tokenization_method === 'apple_pay'
      ? WalletProviderName.ApplePay
      : defaultSource;

  return (
    <Box>
      <Box margin="0 0 16px">
        <Box margin="0 0 4px">
          <Text.Title size="extraSmall">
            Add payment method{!required && ' (Optional)'}
          </Text.Title>
        </Box>
        <Text.Callout color={COLORS.storm}>{subText}</Text.Callout>
      </Box>
      {paymentMethod ? (
        <>
          <SavedPaymentMethod
            onRemove={onRemove}
            paymentMethod={paymentMethod}
          />
        </>
      ) : (
        <>
          <StyledWalletElement
            disabled={disabled}
            onApplePayToken={onApplePayToken}
            onApplePayAvailable={setApplePayAvailable}
            onApplePaySelectPrepaidFunding={() => {
              onPaymentMethodError(PaymentMethodErrorKind.PrepaidCard);
            }}
          />
          {applePayAvailable && !disabled && (
            <Separator>
              <Text.SmallCaps color={COLORS.hippo}>
                OR PAY BY CREDIT CARD
              </Text.SmallCaps>
            </Separator>
          )}
          <Box margin="24px 0 0">
            <CreditCardInput
              disabled={disabled}
              billingName={billingName}
              onChangeBillingName={onBillingNameChange}
              onChangeCard={onCardInputChange}
              paymentError={paymentMethodError}
              billingNameError={billingNameError}
            />
          </Box>
          <CredibilityBanner showCardIcons />
        </>
      )}
    </Box>
  );
};
