/* @jsx jsx */
import * as React from 'react';
import { Link } from 'react-router-dom';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import {
  COLORS,
  FontWeight,
  ResponsiveContainer,
  SkeletonLoader,
  Text,
  mq,
} from '@clutter/clean';

import { Help__Article, Help__Section } from '@graphql/landing';

import { Pluralize } from '@shared/formatters/pluralize';

import { Chevron } from '../helpers/chevron';
import { articleURL, assetURL, sectionURL } from '../routes';
import { Repeat } from '../utils/repeat';

const Container = styled.div`
  ${mq({ margin: [`0 -24px`, 'initial'] })}
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid ${COLORS.grayBorder};

  ${mq({
    padding: ['30px 24px', '30px 0'],
  })}

  color: ${COLORS.tealDark};

  &:hover {
    color: ${COLORS.tealPrimary};
  }
`;

const ArticleTitle = styled(Text.Body)`
  flex-grow: 1;
`;

const ArticleChevron = styled(Chevron)`
  ${mq({
    display: ['none', null, 'inline-block'],
  })}
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionLogo = styled.img`
  ${mq({
    width: ['64px', null, 'auto'],
    height: ['auto', null, '128px'],
    margin: ['0 16px 0 0', null, '0 0 16px 0'],
  })}
`;

const SectionHeader = styled.div<{ expanded?: boolean }>`
  display: flex;
  align-items: center;

  ${({ expanded }) =>
    mq({
      padding: ['20px 24px', '20px 0', '0 0 14px'],
      borderTop: [`1px solid ${COLORS.grayBorder}`, null, 'none'],
      borderBottom: [
        expanded ? `1px solid ${COLORS.grayBorder}` : 'none',
        null,
        'none',
      ],
      flexDirection: ['row', null, 'column'],
      textAlign: ['initial', null, 'center'],
    })}
`;

const SectionInfo = styled.div`
  flex-grow: 1;
`;

const ArticleCount = styled(Text.Body)`
  color: ${COLORS.hippo};
`;

const SectionChevron = styled(Chevron)`
  ${mq({
    display: ['inline-block', null, 'none'],
  })}
`;

const ArticlesList = styled.section<{ expanded?: boolean }>`
  ${({ expanded }) =>
    mq({
      display: [expanded ? 'block' : 'none', null, 'block'],
    })}
`;

const SectionSeeAll = styled.div`
  ${mq({
    margin: ['30px 24px', '30px 0'],
  })}
`;

const SeeAllButton = styled(Text.Button)`
  color: ${COLORS.tealPrimary};
  text-transform: uppercase;

  &:hover {
    color: ${COLORS.tealBrand};
  }
`;

const SectionsList = styled.section`
  display: grid;

  ${mq({
    gridTemplateColumns: ['100%', null, 'repeat(3, 1fr)'],
    gridGap: ['0', null, '96px'],
    margin: ['60px 0 0', null, '96px 0 60px'],
  })}
`;

const Article: React.FC<{
  article: Pick<Help__Article, 'id' | 'title'>;
}> = ({ article }) => (
  <Link to={articleURL(article.id)}>
    <ArticleContainer>
      <ArticleTitle weight={FontWeight.Medium}>{article.title}</ArticleTitle>
      <ArticleChevron direction="right" />
    </ArticleContainer>
  </Link>
);

const Section: React.FC<{
  section: Pick<Help__Section, 'id' | 'categoryID' | 'name'> & {
    articles: ReadonlyArray<Pick<Help__Article, 'id' | 'title'>>;
  };
}> = ({ section }) => {
  const [expanded, setExpanded] = React.useState(false);
  const articles = section.articles;

  return (
    <SectionContainer>
      <SectionHeader expanded={expanded} onClick={() => setExpanded(!expanded)}>
        <SectionLogo
          alt={section.name}
          src={assetURL(`sections/${section.id}.svg`)}
        />
        <SectionInfo>
          <Text.Title size="small">{section.name}</Text.Title>
          <ArticleCount>
            <Pluralize
              count={articles.length}
              singular="Article"
              plural="Articles"
            />
          </ArticleCount>
        </SectionInfo>
        <SectionChevron direction={expanded ? 'up' : 'down'} />
      </SectionHeader>
      <ArticlesList expanded={expanded}>
        {articles.slice(0, 3).map((article) => (
          <Article key={article.id} article={article} />
        ))}
        <SectionSeeAll>
          <Link to={sectionURL(section.categoryID, section.id)}>
            <SeeAllButton>See all</SeeAllButton>
          </Link>
        </SectionSeeAll>
      </ArticlesList>
    </SectionContainer>
  );
};

const FakeArticle: React.FC = () => (
  <ArticleContainer>
    <SkeletonLoader width="100%" height="24px" />
  </ArticleContainer>
);

const FakeSection: React.FC = () => (
  <SectionContainer>
    <SectionHeader>
      <SkeletonLoader width="100%" height="65px" />
    </SectionHeader>
    <ArticlesList>
      <Repeat times={3} component={FakeArticle} />
      <SectionSeeAll>
        <SkeletonLoader width="100%" height="20px" />
      </SectionSeeAll>
    </ArticlesList>
  </SectionContainer>
);

export const FeaturedSections: React.FC<{
  sections?: ReadonlyArray<
    Pick<Help__Section, 'id' | 'categoryID' | 'name'> & {
      articles: ReadonlyArray<Pick<Help__Article, 'id' | 'title'>>;
    }
  >;
}> = ({ sections }) => (
  <ResponsiveContainer>
    <Container>
      <SectionsList>
        {sections ? (
          sections.map((section) => (
            <Section key={section.id} section={section} />
          ))
        ) : (
          <Repeat times={3} component={FakeSection} />
        )}
      </SectionsList>
    </Container>
  </ResponsiveContainer>
);
