import * as React from 'react';

import { DateTime } from 'luxon';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  COLORS,
  FontWeight,
  Text,
  mq,
  toggleStyleValue,
  transparentize,
} from '@clutter/clean';

interface ICalendarProps {
  from: DateTime;
  till: DateTime;
  showLegend?: boolean;
  featuredPromoDescription?: React.ReactNode;
  selected(value: DateTime): boolean;
  pastDay(value: DateTime): boolean;
  unavailable(value: DateTime): boolean;
  featured?(value: DateTime): boolean;
  onSelect(value: DateTime): void;
  fetchMore?(): void;
}

const LABELS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const NUMERIC_DAY_VALUES: { [key: string]: number } = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
};

const STEP = { days: 1 };
const SEGMENT_OPTIONS = { year: 'numeric', month: 'long' } as const;
const CALENDAR_DAY_RATIO = 100 / 7;

const CalendarLabels = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${COLORS.grayBorder};
  margin-bottom: 32px;
`;

const CalendarLabel = styled.div`
  color: ${COLORS.panther};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  flex-basis: ${CALENDAR_DAY_RATIO}%;
  padding-bottom: 8px;
`;

const Month = styled.div`
  margin: 16px 8px;
  color: ${COLORS.panther};
  font-size: 16px;
  font-weight: 700;
`;

const MonthContainer = styled.div`
  margin: 0 -8px;
`;

const CalendarEntries = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
`;

const Entry = styled.div<{ day: string }>`
  flex-basis: ${CALENDAR_DAY_RATIO}%;

  &:first-of-type {
    margin-left: ${({ day }) => CALENDAR_DAY_RATIO * NUMERIC_DAY_VALUES[day]}%;
  }

  ${mq({ padding: ['8px 3px', '8px'] })};
`;

const SeeMore = styled.button`
  font-weight: bold;
  font-size: 16px;
  margin: 32px auto;
  outline: none;
  border: none;
  background: none;
  color: ${COLORS.tealPrimary};
  display: block;
  cursor: pointer;
`;

const defaultStyles = css`
  background: ${COLORS.cloud};
  border: 1.5px solid ${COLORS.panther};
  color: ${COLORS.panther};
`;

const hoveredStyles = css`
  background: ${COLORS.tealBackground};
  border: 1.5px solid ${COLORS.panther};
  color: ${COLORS.panther};
`;

const unavailableStyles = css`
  background: ${COLORS.grayBorder};
  border: 1.5px solid ${COLORS.grayBorder};
  color: ${COLORS.hippo};
  cursor: default;
`;

const selectedStyles = css`
  background: ${COLORS.tealPrimary};
  border: 1.5px solid ${COLORS.tealPrimary};
  color: ${COLORS.cloud};
`;

const pastDayStyles = css`
  background: ${COLORS.grayBackground};
  border: 1.5px solid ${COLORS.grayBackground};
  color: ${COLORS.grayBorder};
  cursor: default;
`;

const featuredDayStyles = css`
  background: ${transparentize(COLORS.dew, 0.6)};
  border: 1.5px solid ${COLORS.tealBrand};
`;

const Legend = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 42px;

  ${mq({ overflowX: ['scroll', null, 'initial'] })};
`;

const LegendKey = styled.div<{ unavailable?: boolean; featured?: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  margin-right: 16px;
  ${({ unavailable, featured }) =>
    unavailable
      ? unavailableStyles
      : featured
        ? featuredDayStyles
        : defaultStyles};
`;

const LegendCaption = styled(Text.Body)`
  font-weight: ${FontWeight.Bold};
  white-space: nowrap;
`;

const Row = styled.div<{ featured: boolean }>`
  display: flex;
  align-items: center;
  margin: ${toggleStyleValue('featured', '0 16px 0 0', '0 40px 0 0')};
`;

const Day = styled.button<{
  selected: boolean;
  unavailable: boolean;
  pastDay: boolean;
  featured: boolean;
}>`
  ${({ selected, unavailable, pastDay, featured }) =>
    pastDay
      ? pastDayStyles
      : unavailable
        ? unavailableStyles
        : selected
          ? selectedStyles
          : featured
            ? featuredDayStyles
            : defaultStyles}
  border-radius: 4px;
  padding: 15px 0px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  &:hover {
    transition: 0.1s all;
    ${({ selected, unavailable, pastDay }) =>
      pastDay
        ? pastDayStyles
        : unavailable
          ? unavailableStyles
          : selected
            ? selectedStyles
            : hoveredStyles}
  }
`;

export const Calendar: React.FC<ICalendarProps> = (props) => {
  const {
    from,
    till,
    showLegend = true,
    fetchMore,
    selected,
    pastDay,
    unavailable,
    featured = () => false,
    onSelect,
    featuredPromoDescription,
  } = props;

  const byMonth: Map<string, DateTime[]> = new Map();

  for (let date: DateTime = from; date < till; date = date.plus(STEP)) {
    const key = `${date.year}-${date.month}`;
    if (!byMonth.has(key)) {
      byMonth.set(key, []);
    }
    byMonth.get(key)!.push(date);
  }

  return (
    <div>
      {showLegend && (
        <Legend>
          {featuredPromoDescription && (
            <Row featured={!!featuredPromoDescription}>
              <LegendKey featured={true} />
              <LegendCaption>{featuredPromoDescription}</LegendCaption>
            </Row>
          )}

          <Row featured={!!featuredPromoDescription}>
            <LegendKey />
            <LegendCaption>Available</LegendCaption>
          </Row>
          <Row featured={!!featuredPromoDescription}>
            <LegendKey unavailable={true} />
            <LegendCaption>Unavailable</LegendCaption>
          </Row>
        </Legend>
      )}
      <CalendarLabels>
        {LABELS.map((label, i) => (
          <CalendarLabel key={i}>
            <Text.SmallCaps>{label}</Text.SmallCaps>
          </CalendarLabel>
        ))}
      </CalendarLabels>
      {Array.from(byMonth.keys()).map((key) => {
        const dates = byMonth.get(key)!;
        const [year, month] = key.split('-').map((value) => Number(value));
        const section = DateTime.local(year, month);

        return (
          <MonthContainer key={String(section)}>
            <Month>{section.toLocaleString(SEGMENT_OPTIONS)}</Month>
            <CalendarEntries>
              {dates.map((date) => (
                <React.Fragment key={String(date)}>
                  <Entry day={date.weekdayShort}>
                    <Day
                      selected={selected(date)}
                      pastDay={pastDay(date)}
                      unavailable={unavailable(date)}
                      featured={featured && featured(date)}
                      onClick={() => {
                        if (!unavailable(date) && !pastDay(date)) {
                          onSelect(date);
                        }
                      }}
                      data-test-id={`${date.toISO()}-date`}
                    >
                      {date.day}
                    </Day>
                  </Entry>
                </React.Fragment>
              ))}
            </CalendarEntries>
          </MonthContainer>
        );
      })}
      {fetchMore && <SeeMore onClick={() => fetchMore()}>See More</SeeMore>}
    </div>
  );
};
