import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

import { DeferredImage } from '@shared/deferred_image';

import { useDeferredImage } from '@utils/hooks';

import phoneOutline from '@images/home/phone.svg';

import phoneVideo from '@videos/home/booking_animation.mp4';

const PhoneContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PhoneVideo = styled.video`
  position: absolute;
  border: 1.5px solid ${COLORS.grayBorder};
  border-radius: 4.75px;
  top: 11.45%;
  left: 5.95%;
  width: 88.64%;
`;

export const AccessItems: React.FC = () => {
  const { resolvedImage, targetRef } = useDeferredImage(phoneVideo);
  return (
    <Box.Flex
      ref={targetRef}
      height={[null, '480px']}
      borderRadius="10px"
      flexDirection={['column', 'row']}
    >
      <Box.FlexItem
        background={COLORS.tealPrimary}
        flexShrink={0}
        width={['100%', '324px']}
        height={['60vh', 'initial']}
        position="relative"
      >
        <PhoneContainer>
          <Box width="200px" height="408px" position="relative">
            <DeferredImage src={phoneOutline} width={374} height={762} />
            <PhoneVideo {...resolvedImage} muted autoPlay loop playsInline />
          </Box>
        </PhoneContainer>
      </Box.FlexItem>
      <Box.Flex flexDirection="column" padding="20px" overflow="auto">
        <Box margin="0 0 12px">
          <Text.Title size="small">Accessing your items</Text.Title>
        </Box>
        <Text.Body>
          Clutter stores your items in secure Smart Storage facilities in your
          area.
          <br />
          <br />
          Our facilities are temperature-controlled and safeguarded by 24/7
          security. They are also configured to safely store a wide variety of
          items, from boxes to fragile furniture.
          <br />
          <br />
          You can view and search for items in your Clutter online account. From
          there, you can schedule appointments to add or remove items from
          storage, serviced by our friendly Move & Pack Specialists.
          <br />
          <br />
          Items don’t have to be delivered back to their same pickup address. We
          can even ship items anywhere in the U.S. via FedEx.
        </Text.Body>
      </Box.Flex>
    </Box.Flex>
  );
};
