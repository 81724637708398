import React, { useEffect, useMemo, useState } from 'react';

import { Box, COLORS } from '@clutter/clean';

import { AddressFragment, useGeocodeQuery } from '@graphql/platform';

import { MapProvider, useMapContext } from '@shared/map/context';
import { DirectionsRenderer } from '@shared/map/directions_renderer';
import { Marker } from '@shared/map/marker';

import { useGoogleMapJS } from '@utils/hooks/use_google_map_js';

import pin from '@images/map/pin.svg';

import GoogleMap from 'google-map-react';

type MapProps = {
  origin?: Pick<AddressFragment, 'latitude' | 'longitude'>;
  destination?: Pick<AddressFragment, 'latitude' | 'longitude'>;
  zip?: string;
  height?: string | string[];
  width?: string | string[];
};

export const COMBINED_DESKTOP_MAP_HEIGHT = 480; // Map + Social / Estimation footer

const STYLES: GoogleMap.MapTypeStyle[] = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'all',
    stylers: [{ lightness: 40 }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#EBEBEB' }],
  },
];

const OPTIONS = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  styles: STYLES,
};

const LA_LAT_LNG = { lat: 34, lng: -118 };

const DIRECTIONS_OPTIONS: google.maps.DirectionsRendererOptions = {
  polylineOptions: {
    strokeColor: COLORS.tealBrand,
    strokeWeight: 4,
  },
  markerOptions: {
    icon: pin,
    position: LA_LAT_LNG,
  },
};

const Map: React.FC<MapProps & GoogleMap.Props> = ({
  origin: { latitude: originLat, longitude: originLng } = {},
  destination: { latitude: destLat, longitude: destLng } = {},
  zip,
  ...props
}) => {
  const { maps, map } = useMapContext();
  const geocode = useGeocodeQuery({ variables: { zip: zip! }, skip: !zip });

  const [directions, setDirections] = useState<
    google.maps.DirectionsResult | undefined
  >();

  const [origin, destination] = useMemo(
    () => [
      originLat && originLng ? { lat: originLat, lng: originLng } : undefined,
      destLat && destLng ? { lat: destLat, lng: destLng } : undefined,
    ],
    [originLat, originLng, destLat, destLng],
  );

  useEffect(() => {
    if (origin && destination && maps) {
      const directionsService = new maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK && result) {
            setDirections(result);
          }
        },
      );
    }
  }, [origin, destination, maps]);

  const { bounds, center } = useMemo(() => {
    if (!map || !maps) {
      return { bounds: undefined, center: undefined };
    }
    let center: GoogleMap.Coords | undefined = undefined;
    let bounds: google.maps.LatLngBounds | undefined = undefined;
    if (!origin || !destination) {
      center = geocode.data?.geocode?.center || LA_LAT_LNG;
    } else if (origin && !destination) {
      center = origin;
    } else {
      const mapBounds = new maps.LatLngBounds();
      mapBounds.extend(origin);
      mapBounds.extend(destination);
      bounds = mapBounds;
    }
    return { bounds, center };
  }, [origin, destination, map, maps, geocode]);

  useEffect(() => {
    if (!map || !bounds) return;
    map.fitBounds(bounds);
  }, [map, bounds]);

  if (!useGoogleMapJS()) {
    return null;
  }

  return (
    <MapProvider.Map
      {...props}
      zoom={11}
      center={center}
      defaultCenter={LA_LAT_LNG}
      options={OPTIONS}
    >
      {directions && map && (
        <DirectionsRenderer
          directions={directions}
          options={DIRECTIONS_OPTIONS}
        />
      )}
      {!directions && origin && (
        <Marker lat={origin.lat} lng={origin.lng} icon={pin} />
      )}
      {!directions && destination && (
        <Marker lat={destination.lat} lng={destination.lng} icon={pin} />
      )}
    </MapProvider.Map>
  );
};

export const MapWithDirections: React.FC<MapProps> = ({
  height,
  width,
  ...props
}) => (
  <Box height={height} width={width}>
    <MapProvider includeMapComponent={false}>
      <Map {...props} />
    </MapProvider>
  </Box>
);
