import { EventSchema } from '@root/resources/wt/types';

import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useOnMount } from '@utils/hooks/mount';

export const useCartViewedFunnelEvent = () => {
  const trackFunnelEvent = useTrackFunnelEvents();
  useOnMount(() => {
    trackFunnelEvent({
      schema: EventSchema.WWW__CartViewed,
      action: 'display',
      metadata: {},
    });
  });
};
