import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  RadioCard,
  SERIF_FONT_FAMILY,
  Text,
} from '@clutter/clean';

import { useMovingCheckoutContext } from '@root/components/checkout/context';

import facility from '@images/illustrations/storage_facility.svg';

import { MovingFreeStorageModal } from './moving_free_storage_modal';

const Title = styled.span`
  font-family: ${SERIF_FONT_FAMILY};
  font-weight: ${FontWeight.Semibold};
  font-size: 16px;
`;

const Image = styled.img`
  width: 58px;
  height: 39px;
`;

const LinkText = styled(Text.Caption)`
  color: ${COLORS.tealPrimary};
  font-weight: ${FontWeight.Medium};
  text-decoration: underline;

  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

export const MovingFreeStorageCard: React.FC = () => {
  const {
    flowState: {
      values: { coupon, freeStorageSelected, planSize },
      onChange,
    },
  } = useMovingCheckoutContext();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClick = () => {
    if (!!coupon) {
      setError(
        'Unable to add promo as there is one already applied. One per reservation.',
      );
      onChange('freeStorageSelected', false);
      return;
    }
    setError(null);
    onChange('freeStorageSelected', freeStorageSelected ? false : true);
  };

  useEffect(() => {
    onChange('freeStorageSelected', !!planSize);
  }, [planSize]);

  return (
    <Box margin={['0 24px 32px', 0]}>
      <Box margin="0 0 16px">
        <Text.Title size="extraSmall">
          You’re eligible for this promo
        </Text.Title>
      </Box>
      <RadioCard.Standalone
        selected={!!freeStorageSelected}
        onClick={handleClick}
      >
        <Box.Flex textAlign="left">
          <Image src={facility} />
          <Box width="100%" margin="0 0 0 16px">
            <Title>1 month of FREE storage</Title>
            <Box margin="4px 0 12px">
              <Text.Caption color={COLORS.hippo}>
                Add this offer to get one month of free storage with sizes
                ranging from 5x5 to 10x50 and no minimum commitments.
              </Text.Caption>
            </Box>
            <Box width="fit-content">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowModal(true);
                }}
              >
                <LinkText>View offer details</LinkText>
              </a>
            </Box>
          </Box>
        </Box.Flex>
      </RadioCard.Standalone>
      {error && !!coupon && (
        <Box margin="8px 0 0">
          <Text.Caption color={COLORS.toucan} weight={FontWeight.Medium}>
            {error}
          </Text.Caption>
        </Box>
      )}
      <MovingFreeStorageModal
        isOpen={showModal}
        planAdded={!!planSize}
        onClose={() => setShowModal(false)}
      />
    </Box>
  );
};
