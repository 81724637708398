import React, { useEffect } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  InfoModal,
  Label,
  MEDIUM_TITLE_STYLES,
  Text,
} from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { FunnelEntryPoint } from '@shared/funnel_entry_point';
import { FunnelEntryPointDropdown } from '@shared/funnel_entry_point_dropdown';
import { FunnelEntryPointForm } from '@shared/funnel_entry_point_form';
import { FunnelEntryPointErrorMessage } from '@shared/funnel_entry_point_message';
import { FunnelEntryPointSubmitButton } from '@shared/funnel_entry_point_submit_button';
import { FunnelEntryPointZipInput } from '@shared/funnel_entry_point_zip_input';

const Error = styled(Text.Caption)`
  color: ${COLORS.toucan};
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 0 16px 16px 0;

  input {
    height: 48px;
    width: 100%;
    margin-left: 16px;
  }
`;

const ButtonContainer = styled.div`
  button {
    width: 100%;
    min-width: 130px;
  }
`;

type FormProps = {
  id: string;
  wtConfig: {
    container: string;
    label: string;
    [metadataKey: string]: string;
  };
};

const Form: React.FC<FormProps> = ({ id, wtConfig }) => {
  return (
    <FunnelEntryPoint wtConfig={wtConfig}>
      <Box textAlign="center" margin="0 0 24px">
        <Text color={COLORS.tealDark} style={MEDIUM_TITLE_STYLES.LG}>
          Get pricing
        </Text>
      </Box>
      <FunnelEntryPointForm id={id}>
        <Box>
          <Label>I need help with</Label>
          <Box>
            <InputContainer>
              <Box.FlexItem height="48px" minWidth="152px" flexBasis="50%">
                <FunnelEntryPointDropdown />
              </Box.FlexItem>
              <Box.FlexItem flexGrow={1}>
                <FunnelEntryPointZipInput />
              </Box.FlexItem>
            </InputContainer>
            <FunnelEntryPointErrorMessage
              render={(errorMessage) => (
                <Box margin="-8px 4px 12px">
                  <Error>{errorMessage}</Error>
                </Box>
              )}
            />
            <ButtonContainer>
              <FunnelEntryPointSubmitButton />
              <Box textAlign="center" margin="16px 0 64px">
                <Text.Callout color={COLORS.storm}>
                  In under 2 minutes!
                </Text.Callout>
              </Box>
            </ButtonContainer>
          </Box>
        </Box>
      </FunnelEntryPointForm>
    </FunnelEntryPoint>
  );
};

const ServiceSelectorModal: React.FC<
  {
    isOpen: boolean;
    onClose: () => void;
  } & FormProps
> = ({ isOpen, onClose, ...formProps }) => {
  const track = useTrack();

  useEffect(() => {
    if (isOpen) {
      track({
        action: 'click',
        objectName: 'cta',
        objectType: 'button',
        ...formProps,
      });
    }
  }, [isOpen]);

  const handleModalClose = () => {
    onClose();
  };

  return (
    <InfoModal isOpen={isOpen} handleModalClose={handleModalClose}>
      <Form {...formProps} />
    </InfoModal>
  );
};

export { ServiceSelectorModal };
