import React, { type FC } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FluidContainer, Text, mq } from '@clutter/clean';

import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  ZIP_INPUT_TRIGGER_ID,
} from '@shared/header/header';
import { PinnedFooterContainer } from '@shared/pinned_footer_container';
import { ServiceSelection } from '@shared/service_selector/constants';
import { HeroServiceSelector } from '@shared/service_selector/hero_service_selector';
import { PinnedServiceSelector } from '@shared/service_selector/pinned_service_selector';

import { useIntersectionObserver } from '@utils/hooks';

import { BadgeOne, BadgeTwo } from './hero/badge';
import { Player } from './hero/player';

const Container = styled.div`
  position: relative;
  color: ${COLORS.tealDark};

  ${mq({
    padding: ['24px 0 0', null, '0'],
    textAlign: ['center', null, 'left'],
    paddingTop: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT],
  })}
`;

const FooterContainer = styled(PinnedFooterContainer)`
  padding: 16px 24px;
  ${mq({
    display: ['block', 'none'],
  })}
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  form {
    width: 100%;
  }
`;

const Title = styled(Text.Display)`
  ${mq({ fontSize: ['32px', null, '48px'] })}
`;

const ResponsiveFluidContainer = styled(FluidContainer)`
  ${mq({
    padding: ['24px 24px 0', null, '0 24px'],
  })}
`;

const BadgesContainer = styled.div`
  position: relative;
  ${mq({
    margin: ['0 0 40px', null, '0'],
  })}
`;

const Badges = styled.div`
  position: absolute;
  width: 210px;
  height: 140px;
  ${mq({
    top: ['initial', null, '-60px'],
    right: ['-10px', '-20px', '-30px'],
    bottom: ['-40px', null, 'initial'],
  })}
`;

const BadgeOneContainer = styled.div`
  ${mq({
    top: ['initial', null, 0],
    left: 0,
    bottom: [0, null, 'initial'],
  })}
  position: absolute;
  width: 120px;
  height: 120px;
`;

const BadgeTwoContainer = styled.div`
  ${mq({
    top: [0, null, 'initial'],
    bottom: ['initial', null, 0],
    right: 0,
  })}
  position: absolute;
  width: 102px;
  height: 120px;
`;

export const HeroPlayer: FC = () => {
  const [setNode, entry] = useIntersectionObserver();
  const isIntersecting = entry ? entry.isIntersecting : true;

  return (
    <>
      <Container ref={setNode}>
        <ResponsiveFluidContainer>
          <Box.Flex
            gap={['12px', '24px', '40px', '40px']}
            flexDirection={['column', null, 'row']}
            alignItems={[null, null, 'center']}
          >
            <Box.FlexItem
              flexGrow={1}
              flexShrink={1}
              flexBasis={[null, null, '50%']}
            >
              <BadgesContainer>
                <Player />
                <Badges>
                  <BadgeOneContainer>
                    <BadgeOne />
                  </BadgeOneContainer>
                  <BadgeTwoContainer>
                    <BadgeTwo />
                  </BadgeTwoContainer>
                </Badges>
              </BadgesContainer>
            </Box.FlexItem>
            <Box.FlexItem
              flexGrow={1}
              flexShrink={1}
              flexBasis={[null, null, '50%']}
            >
              <Box.Flex gap={['12px', '24px']} flexDirection="column">
                <Box.Flex
                  gap="12px"
                  alignItems="center"
                  justifyContent={['center', null, 'initial']}
                >
                  <Box.Flex
                    gap="4px"
                    justifyContent={['center', null, 'left']}
                    flexDirection="column"
                  >
                    <Title>Storage & Moving</Title>
                    <Text.Headline>
                      Now smarter and cheaper than ever
                    </Text.Headline>
                  </Box.Flex>
                </Box.Flex>
                <Box id={ZIP_INPUT_TRIGGER_ID}>
                  <HeroServiceSelector
                    initialService={ServiceSelection.Storage}
                    id="hero_service_selector"
                  />
                </Box>
                <FooterContainer show={!isIntersecting} hideMobile={true}>
                  <Footer>
                    <PinnedServiceSelector
                      position="footer"
                      id="footer_service_selector"
                      container="footer_service_selector"
                    />
                  </Footer>
                </FooterContainer>
              </Box.Flex>
            </Box.FlexItem>
          </Box.Flex>
        </ResponsiveFluidContainer>
      </Container>
    </>
  );
};
