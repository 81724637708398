import { Help__Article, Help__Category, Help__Section } from '@graphql/landing';

import { pluralize } from '@utils/pluralize';

import {
  articleURL,
  categoryURL,
  mainURL,
  searchURL,
  sectionURL,
} from '../routes';

type Category = Pick<Help__Category, 'id' | 'name'>;
type Section = Pick<Help__Section, 'id' | 'name'> & { category: Category };
type Article = Pick<Help__Article, 'id' | 'title'> & { section: Section };

const ROOT_BREADCRUMB = { text: 'Help Center', to: mainURL() };

export const categoryBreadcrumbs = (category: Category) => [
  ROOT_BREADCRUMB,
  { text: category.name, to: categoryURL(category.id) },
];

export const sectionBreadcrumbs = (section: Section) => [
  ...categoryBreadcrumbs(section.category),
  { text: section.name, to: sectionURL(section.category.id, section.id) },
];

export const articleBreadcrumbs = (article: Article) => [
  ...sectionBreadcrumbs(article.section),
  { text: article.title, to: articleURL(article.id) },
];

export const searchBreadcrumbs = (query: string, count?: number) => [
  ROOT_BREADCRUMB,
  {
    text: `${
      count !== undefined ? pluralize(count, 'result', 'results') : 'searching'
    } for “${query}”`,
    to: `${searchURL()}?q=${encodeURIComponent(query)}`,
  },
];
``;
