/* @jsx jsx */
import * as React from 'react';
import { useHistory } from 'react-router';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { Box, COLORS, SkeletonLoader, TagSelector, Text } from '@clutter/clean';

import { Help__Category, Help__Section } from '@root/graphql/landing';

import { sectionURL } from '../routes';

const MARGIN = 8;

const Heading = styled(Text.Title)`
  margin: 80px 0 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

export const RelatedSections: React.FC<{
  category?: Pick<Help__Category, 'id'> & {
    sections: ReadonlyArray<Pick<Help__Section, 'id' | 'name'>>;
  };
}> = ({ category }) => {
  const history = useHistory();

  return (
    <nav>
      <Heading size="medium">Related Topics</Heading>
      <Box.Flex flexWrap="wrap" margin={`-${MARGIN}px`}>
        {category ? (
          category.sections.map((section) => (
            <Box key={section.id} margin={`${MARGIN}px`}>
              <TagSelector.Tag
                selected={false}
                size="medium"
                onClick={() =>
                  history.push(sectionURL(category.id, section.id))
                }
              >
                {section.name}
              </TagSelector.Tag>
            </Box>
          ))
        ) : (
          <SkeletonLoader width="100%" height="40px" />
        )}
      </Box.Flex>
    </nav>
  );
};
