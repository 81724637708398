import React from 'react';

import styled from '@emotion/styled';

import { Box, FontWeight, LARGE_TITLE_STYLES, Text } from '@clutter/clean';

import { useMovingCheckoutContext } from '@root/components/checkout/context';

import { Currency } from '@shared/currency';
import { Spacer } from '@shared/spacer';

import { PolicyModal } from './subcomponents/policy_modal';

const Body = styled(Text.Body)`
  padding-top: 24px;
`;

export const MovingBillingModal: React.FC<{
  isOpen: boolean;
  handleModalClose(): void;
}> = ({ isOpen, handleModalClose }) => {
  const {
    flowState: {
      values: { movingQuote },
    },
  } = useMovingCheckoutContext();

  const laborRateAmount = movingQuote?.laborRate?.amount;
  const minimumHours = movingQuote?.movingConfiguration
    ? movingQuote?.movingConfiguration?.requiredLaborDuration / 3600
    : 0;

  const minimumCost = (() => {
    if (!!movingQuote?.reservationDepositAmount) {
      return movingQuote?.reservationDepositAmount * 10;
    } else {
      return laborRateAmount ? laborRateAmount * minimumHours : 0;
    }
  })();

  const travelRateMultiplier =
    movingQuote?.movingConfiguration?.travelRateMultiplier;

  return (
    <PolicyModal isOpen={isOpen} handleModalClose={() => handleModalClose()}>
      <Text style={LARGE_TITLE_STYLES.SM}>When will I be charged?</Text>
      <Body>Don’t worry, your reservation is completely free!</Body>
      <Box margin="32px 0 0">
        <Text.Headline weight={FontWeight.Medium}>Deposit</Text.Headline>
      </Box>
      <Body>
        Your <b>non-refundable</b> deposit is 10% of the minimum cost of your
        move. We’ll charge that amount to the credit card we have on file{' '}
        {movingQuote?.formattedDepositInterval} before the day of your move. If
        that attempt fails, we will try again 24 hours later. If the second
        attempt fails, your move will be auto-canceled.
        <Spacer height="12px" />
        We will perform a pre-authorization check with the card on file 7 days
        before your appointment to verify sufficient funds are available to
        cover the appointment. If you are booking within 7 days of your
        appointment then we will perform the check immediately after booking.
        The check will be immediately released once the pre-authorization check
        is completed.
      </Body>
      <Box margin="32px 0 0">
        <Text.Headline weight={FontWeight.Medium}>Final bill</Text.Headline>
      </Box>
      <Body>
        Your final bill will be charged when your move has been completed. Your
        deposit will be applied to your final bill.
      </Body>
      <Body>
        We estimate the minimum cost of your move will be{' '}
        <Currency amount={minimumCost} />. Your final charges will be based on
        your quoted hourly rate and the amount of time you actually use.
      </Body>
      {!!travelRateMultiplier && travelRateMultiplier == 2 && (
        <Body>
          State law requires us to charge for double the drive time to transport
          your items between locations.
        </Body>
      )}
      <Box margin="32px 0 0">
        <Text.Headline weight={FontWeight.Medium}>
          Cancellation and Rescheduling
        </Text.Headline>
      </Box>
      <Body>
        We ask that you reschedule or cancel at least 48hrs before your
        appointment. Otherwise,{' '}
        <b>
          a $100 fee will apply and your deposit amount will not be refunded.
        </b>{' '}
        You can reschedule or cancel online in just a few clicks.
      </Body>
    </PolicyModal>
  );
};
