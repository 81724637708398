import { IValidation } from '../types/validation';
import {
  email,
  phone,
  required,
  requiredValidator,
  validate,
  wordCount,
} from '../validator';
import { addressValidations } from './address';

const checkoutValidations: { [key: string]: IValidation[] } = {
  name: [
    requiredValidator('Name'),
    {
      validator: wordCount(2),
      message: 'Please enter your full name.',
      conditions: required,
    },
  ],
  email: [
    requiredValidator('Email'),
    {
      validator: email,
      message: 'Please enter a valid email address.',
      conditions: required,
    },
  ],
  phone: [
    requiredValidator('Phone'),
    {
      validator: phone,
      message: 'Please enter a valid phone number.',
      conditions: required,
    },
  ],
  planSize: [requiredValidator('Plan')],
  datePreferred: [requiredValidator('Pickup date')],
  address: [
    {
      validator: (address) =>
        !!address && validate(address, addressValidations).none,
      message: 'Please provide a valid address',
    },
  ],
  stripeToken: [
    {
      validator: required,
      message: 'Card info is required',
      conditions: (stripeToken, params) => !!params.cardless,
    },
  ],
};

export { checkoutValidations };
