import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { COLORS, FontWeight, SkeletonLoader, Text, mq } from '@clutter/clean';

import { Help__Article } from '@graphql/landing';

import { Chevron } from '../helpers/chevron';
import { articleURL } from '../routes';
import { Repeat } from '../utils/repeat';

const ArticleLink = styled(Link)`
  color: ${COLORS.tealDark};
  padding: 30px 0;
  border-bottom: 1px solid ${COLORS.grayBorder};

  &:hover {
    color: ${COLORS.tealPrimary};
  }
`;

const ArticleContainer = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

const ArticleContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArticleTitle = styled(Text.Body)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ArticleChevron = styled(Chevron)`
  flex-grow: 0;
  ${mq({
    display: ['none', null, 'initial'],
  })}
`;

const ArticleEntry: React.FC<{
  article: Pick<Help__Article, 'id' | 'title'>;
}> = ({ article }) => (
  <ArticleContainer>
    <ArticleLink to={articleURL(article.id)}>
      <ArticleContent>
        <ArticleTitle weight={FontWeight.Medium}>{article.title}</ArticleTitle>
        <ArticleChevron direction="right" />
      </ArticleContent>
    </ArticleLink>
  </ArticleContainer>
);

const ArticleLoader: React.FC = () => (
  <ArticleContainer>
    <SkeletonLoader width="100%" height="24px" />
  </ArticleContainer>
);

const Heading = styled(Text.Title)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

export const List: React.FC<{
  articles?: ReadonlyArray<Pick<Help__Article, 'id' | 'title'>>;
  title: string;
}> = ({ articles, title }) => (
  <div>
    <Heading size="medium">{title}</Heading>
    {articles ? (
      articles?.map((article) => (
        <ArticleEntry key={article.id} article={article} />
      ))
    ) : (
      <Repeat times={2} component={ArticleLoader} />
    )}
  </div>
);
