import React, { type ReactNode, useState } from 'react';

import { BREAKPOINTS, Box, Text } from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { ResponsiveImage } from '@shared/responsive_image';
import { TabContainer } from '@shared/tab_container';
import { TabItem } from '@shared/tab_item';
import { YouTubePlayer } from '@shared/you_tube_player';

import hoardersImage from '@images_responsive/home/partnerships/hoarders.jpg';
import honestRenovationsImage from '@images_responsive/home/partnerships/honest_renovations.jpg';
import rachelRayShowImage from '@images_responsive/home/partnerships/rachel_ray_show.jpg';
import swedishDeathCleaningImage from '@images_responsive/home/partnerships/swedish_death_cleaning.jpg';

import { BaseTemplate } from './brand/base_template';

type PartnershipProps = {
  heading: string;
  subheading: string;
  name: string;
  container: string;
  media: ReactNode;
  body: ReactNode;
};

const IMAGE_SIZES = `(min-width: ${BREAKPOINTS.XL}) 550px, (min-width: ${BREAKPOINTS.MD}) 40vw, 100vw`;

const PARTNERSHIPS: PartnershipProps[] = [
  {
    heading: 'Instant Dream Home',
    subheading: 'The Exclusive moving & storage partner of Netflix’s hit show',
    name: 'Netflix',
    container: 'instant_dream_home',
    media: (
      <YouTubePlayer
        title="Instant Dream Home | Official Trailer | Netflix"
        videoID="XyJRl54zJdU"
        wtConfig={{
          objectName: 'instant_dream_home_trailer',
        }}
      />
    ),
    body: (
      <>
        The hit new Netflix show, Instant Dream Home, relies on Clutter and our
        industry-leading Move & Pack team to make the impossible possible as
        they tackled ambitious, full-home renovations for deserving families
        &ndash; in under 12 hours! Book with our team.
      </>
    ),
  },
  {
    heading: 'Honest Renovations',
    subheading:
      'Clutter helps bring Jessica Alba’s The Honest Company to the small screen',
    name: 'Jessica Alba',
    container: 'honest_renovations',
    media: (
      <ResponsiveImage
        image={honestRenovationsImage}
        sizes={IMAGE_SIZES}
        alt="Clutter helps bring Jessica Alba’s The Honest Company to the small screen"
      />
    ),
    body: (
      <>
        Clutter is the official partner of the new home renovation show Honest
        Renovations, featuring Jessica Alba and Lizzy Mathis. The show follows
        the hosts as they help families complete home renovations and discuss
        parenting, with eight hour-long episodes set to{' '}
        <a
          href="https://www.tvinsider.com/1041409/honest-renovations-jessica-alba-lizzy-mathis-roku/"
          target="_blank"
        >
          air soon on The Roku Channel
        </a>
        .
      </>
    ),
  },
  {
    heading: 'Swedish Death Cleaning',
    subheading:
      'Clutter is teaming with Amy Poehler in The Gentle Art of Swedish Death Cleaning',
    name: 'Amy Poehler',
    container: 'swedish_death_cleaning',
    media: (
      <ResponsiveImage
        image={swedishDeathCleaningImage}
        sizes={IMAGE_SIZES}
        alt="Clutter is teaming with Amy Poehler in The Gentle Art of Swedish Death Cleaning"
      />
    ),
    body: (
      <>
        Swedish Death Cleaning is the ultimate home makeover show, hosted by Amy
        Poehler. Each episode Clutter helps a Swedish Death Cleaner as they help
        people at a crossroads in their lives declutter their homes and say
        goodbye to unnecessary belongings through the practice of döstädning.{' '}
        <a
          href="https://variety.com/2022/tv/news/peacock-amy-poehler-gentle-art-of-swedish-death-cleaning-1235227555/"
          target="_blank"
        >
          Coming soon on Peacock
        </a>
        .
      </>
    ),
  },
  {
    heading: 'Rachael Ray Show',
    subheading:
      'Daytime TVs sweetheart, Rachael Ray, chooses Clutter to help her and her guests',
    name: 'Rachel Ray',
    container: 'rachel_ray_show',
    media: (
      <ResponsiveImage
        image={rachelRayShowImage}
        sizes={IMAGE_SIZES}
        alt="Daytime TVs sweetheart, Rachael Ray, chooses Clutter to help her and her guests"
      />
    ),
    body: (
      <>
        Rachael Ray chose Clutter to be featured on upcoming episodes of the
        Rachael Ray Show. Keep an eye on daytime TVs favorite home-everything
        host to see why Clutter is the obvious choice to help you with your
        storage and moving needs.
      </>
    ),
  },
  {
    heading: 'Hoarders',
    subheading:
      'Clutter works with Hoarders to turn peoples’ lives from chaotic to peaceful',
    name: 'Hoarders',
    container: 'hoarders',
    media: (
      <ResponsiveImage
        image={hoardersImage}
        sizes={IMAGE_SIZES}
        alt="Clutter works with Hoarders to turn people’s lives from chaotic to peaceful"
      />
    ),
    body: (
      <>
        We’ve all been watching Hoarders for years. Its a classic for home
        renovation TV. This classic just got a brand new makeover by partnering
        with Clutter to help change the lives of Hoarders through out the
        nation.
      </>
    ),
  },
];

export const Brand = () => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const track = useTrack({
    container: 'brand_credibility',
  });

  const partnership = PARTNERSHIPS[activeTabIndex];
  return (
    <>
      <Text.Title size="large">
        Trusted by people and brands you know and love...
      </Text.Title>
      <Box margin={['32px 0 24px', '48px 0 40px']}>
        <TabContainer>
          {PARTNERSHIPS.map((partnership, index) => (
            <TabItem
              key={index}
              name={partnership.name}
              active={activeTabIndex === index}
              onClick={() => {
                track({
                  action: 'click',
                  objectType: 'tab',
                  label: partnership.name,
                });
                setActiveTabIndex(index);
              }}
            />
          ))}
        </TabContainer>
      </Box>
      <BaseTemplate
        heading={partnership.heading}
        container={partnership.container}
        subheading={partnership.subheading}
        body={partnership.body}
        media={partnership.media}
      />
    </>
  );
};
