import styled from '@emotion/styled';

import { COLORS, mq, toggleStyleValue } from '@clutter/clean';

import { CustomLink } from '@shared/custom_link';

export const MenuLink = styled(CustomLink)<{ isFeatured?: boolean }>`
  border-radius: 4px;
  display: inline-block;
  text-align: center;

  background: ${toggleStyleValue(
    'isFeatured',
    COLORS.tealBackground,
    'transparent',
  )};
  min-width: ${toggleStyleValue('isFeatured', '90px', '0')};
  padding: ${toggleStyleValue('isFeatured', '7px 12px', 'initial')};

  ${mq({
    marginRight: [null, null, '24px'],
  })}

  &:hover {
    color: ${COLORS.tealBrand};

    & span {
      border-color: ${COLORS.tealBrand};
    }
  }
`;
