import React from 'react';

import { DateTime, DateTimeFormatOptions, Duration } from 'luxon';

import { Box, Button, COLORS, RadioCard, Text } from '@clutter/clean';

import {
  AvailabilityFragment,
  MultidayAvailabilityFragment,
} from '@graphql/platform';

import { FROM_ISO_OPTIONS } from '@shared/calendar/calendar_utils';

import { CardContents } from '../../../subcomponents/card_contents';

const formatAvailability = (
  availability: AvailabilityFragment | MultidayAvailabilityFragment,
  fromTimeOnly: boolean,
) =>
  fromTimeOnly
    ? availability.formattedFromTime
    : availability.formatted.replace(/:00 /g, '');

const ALL_DAY_DURATION = Duration.fromObject({ hours: 8 });

function formatTime(time: DateTime) {
  const format: DateTimeFormatOptions = { hour: 'numeric' };
  if (time.minute !== 0) {
    format.minute = 'numeric';
  }
  return time.toLocaleString(format);
}

export const ArrivalWindowSelector: React.FC<{
  selectedAvailability?: AvailabilityFragment;
  availabilities: ReadonlyArray<
    AvailabilityFragment | MultidayAvailabilityFragment
  >;
  onChange(
    availability: AvailabilityFragment | MultidayAvailabilityFragment,
    autoScroll: boolean,
  ): void;
  fromTimeOnly?: boolean;
  isLongDistance?: boolean;
}> = ({
  selectedAvailability,
  availabilities,
  onChange,
  fromTimeOnly,
  isLongDistance,
}) => {
  if (availabilities.length === 0) {
    return null;
  }

  const flexibleWindow = availabilities.find(
    (availability) =>
      availability.duration &&
      Duration.fromISO(availability.duration) >= ALL_DAY_DURATION,
  );
  const nonFlexibleWindows = availabilities.filter(
    (availability) =>
      availability.duration &&
      Duration.fromISO(availability.duration) < ALL_DAY_DURATION,
  );

  const nonFlexibleWindowFeeAmount =
    nonFlexibleWindows.length > 0 ? nonFlexibleWindows[0].feeAmount : undefined;
  const showTypeSelector =
    flexibleWindow &&
    nonFlexibleWindowFeeAmount &&
    nonFlexibleWindowFeeAmount.value > 0;

  const selectedAvailabilityDuration =
    selectedAvailability?.duration &&
    Duration.fromISO(selectedAvailability.duration);
  const selectedFlexibleArrival =
    !!selectedAvailabilityDuration &&
    selectedAvailabilityDuration >= ALL_DAY_DURATION;
  const selectedScheduledArrival =
    !!selectedAvailabilityDuration &&
    selectedAvailabilityDuration < ALL_DAY_DURATION;

  return (
    <Box>
      <Text.Title size="extraSmall">Select an arrival window</Text.Title>
      {isLongDistance && (
        <Text.Body color={COLORS.storm}>(Applies to each day)</Text.Body>
      )}
      {showTypeSelector && (
        <>
          <Box.Grid
            margin={['24px 0 0', '32px 0 0']}
            gridTemplateColumns={['1fr', '1fr 1fr']}
            gridGap={['12px', '16px']}
          >
            <RadioCard
              name="flexibleArrival"
              value="Flexible Arrival"
              selected={selectedFlexibleArrival}
              onChange={() => {
                onChange(flexibleWindow, true);
              }}
            >
              <CardContents
                label="Flexible Arrival"
                description={`Receive a 3 hour arrival window the day before your appointment. The earliest possible arrival is at ${formatTime(
                  DateTime.fromISO(flexibleWindow.fromTime, FROM_ISO_OPTIONS),
                )} and the latest possible arrival is at ${formatTime(
                  DateTime.fromISO(flexibleWindow.tillTime, FROM_ISO_OPTIONS),
                )}.`}
                showLoader={false}
                price={0}
              />
            </RadioCard>
            <RadioCard
              name="scheduledArrival"
              value="Scheduled Arrival"
              selected={selectedScheduledArrival}
              onChange={() => {
                onChange(nonFlexibleWindows[0], false);
              }}
            >
              <CardContents
                label="Scheduled Arrival"
                description="Select a set arrival window. Limited availability."
                showLoader={false}
                price={nonFlexibleWindowFeeAmount?.value}
              />
            </RadioCard>
          </Box.Grid>
        </>
      )}
      {(!showTypeSelector || selectedScheduledArrival) && (
        <Box.Flex
          flexDirection="row"
          justifyContent={['space-between', 'center']}
          margin="8px 0 0 0"
          flexWrap={['wrap', 'nowrap']}
        >
          {(showTypeSelector ? nonFlexibleWindows : availabilities).map(
            (availability, i) => {
              const selected = selectedAvailability
                ? availability.fromTime === selectedAvailability.fromTime &&
                  availability.tillTime === selectedAvailability.tillTime
                : false;
              const formattedAvailability = formatAvailability(
                availability,
                !!fromTimeOnly,
              );
              return (
                <Box
                  key={`arrival-window-${i}`}
                  width="50%"
                  maxWidth="224px"
                  padding="6px 8px"
                >
                  <Button
                    kind={selected ? 'primary' : 'secondary'}
                    size={['small', 'medium']}
                    fullWidth
                    onClick={() => onChange(availability, true)}
                  >
                    {formattedAvailability}
                  </Button>
                </Box>
              );
            },
          )}
        </Box.Flex>
      )}
    </Box>
  );
};
