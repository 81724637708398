import { useCouponQuery } from '@graphql/platform';

import { useClientDataContext } from '@shared/client_data_context';

import { parseGQLErrorUnion } from '@utils/gql_errors';

import { useCouponDefaultTest } from './ab_testing';

export const useClientDataContextCoupon = () => {
  const {
    data: { urlPromoCode },
  } = useClientDataContext();
  const { data, loading } = useCouponQuery({
    variables: { code: urlPromoCode ?? '' },
    skip: !urlPromoCode,
  });
  const { expirationDate } = useCouponDefaultTest();

  const [coupon, couponError] = parseGQLErrorUnion(data?.coupon);
  return { coupon, couponError, loading, expirationDate };
};
