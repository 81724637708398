import React, { forwardRef } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, mq } from '@clutter/clean';

import { CheckoutFooter } from '@shared/content/checkout_footer';
import { Header } from '@shared/header/header';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

export const DISABLED_STYLE = {
  opacity: 0.2,
  pointerEvents: 'none',
} as const;

const StepsLeft = forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children }, ref) => {
    const { isDesktop } = useBreakpoints();
    if (!isDesktop) return null;
    return (
      <Box ref={ref} width="50%" maxWidth="730px" position="relative">
        {children}
      </Box>
    );
  },
);

const StepsRight = styled.div`
  ${mq({
    maxWidth: ['100%', null, '730px'],
    margin: [0, null, '0 0 0 60px'],
    width: ['100%', null, '50%'],
  })}
`;

const StepsOuter = styled.div`
  display: flex;
  justify-content: center;
  background: ${COLORS.grayBackground};
  ${mq({
    padding: [' 24px 24px 20px', null, '72px 60px'],
  })}
`;

const Container: React.FC<{
  children: React.ReactNode;
  isLoggedIn: boolean;
}> = ({ children, isLoggedIn }) => {
  return (
    <>
      <Header
        hideZipInput={true}
        sticky={false}
        opaque
        isLoggedIn={isLoggedIn}
      />
      {children}
      <CheckoutFooter />
    </>
  );
};

const StepContainerInner = styled.div`
  transition: opacity 0.2s linear;
  ${mq({
    scrollMarginTop: ['32px', '30vh'],
  })}
`;

const StepContainer: React.FC<{
  enabled: boolean;
  id: string;

  children: React.ReactNode;
}> = ({ id, enabled, children }) => (
  <StepContainerInner
    id={id}
    style={enabled ? undefined : DISABLED_STYLE}
    onFocusCapture={(e) => {
      if (!enabled && e.relatedTarget && 'focus' in e.relatedTarget) {
        e.preventDefault();
        (e.relatedTarget as any).focus();
      }
    }}
  >
    {children}
  </StepContainerInner>
);

const StickyContainer: React.FC<
  {
    containerHeight: number;
    children: React.ReactNode;
  } & React.BaseHTMLAttributes<HTMLDivElement>
> = ({ containerHeight, children, ...props }) => {
  return (
    <Box
      {...props}
      width="100%"
      position="sticky"
      style={{ top: `calc(50vh - ${containerHeight / 2}px)` }}
    >
      {children}
    </Box>
  );
};

export const Layout = Object.assign(Container, {
  StepsLeft,
  StepsRight,
  StepsOuter,
  StickyContainer,
  StepContainer,
});
