import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Box } from '@clutter/clean';

import { Footer } from '@shared/content/footer';
import {
  DESKTOP_HEADER_HEIGHT,
  Header,
  MOBILE_HEADER_HEIGHT,
} from '@shared/header/header';

import { Content } from './callback_request/content';

export const CallbackRequest: React.FC = () => {
  return (
    <>
      <HelmetProvider context={global.helmetContext}>
        <Helmet>
          <title>Clutter | Manage Call Back Request</title>
        </Helmet>
      </HelmetProvider>
      <Box>
        <Header opaque />
        <Box
          minHeight={[
            `calc(100vh - ${MOBILE_HEADER_HEIGHT} - 64px)`,
            `calc(100vh - ${DESKTOP_HEADER_HEIGHT} - 80px)`,
          ]}
          margin={['40px 0 64px', null, '40px 0 124px']}
        >
          <Box maxWidth={[null, '720px']} margin={['0 16px', null, '0 auto']}>
            <Content />
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};
