import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text, mq } from '@clutter/clean';

import { DeferredImage } from './deferred_image';

interface IValuePropCard {
  title: React.ReactNode;
  description: React.ReactNode;
  illustration: string;
  className?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 0 16px;
`;

const Title = styled(Text.Title)`
  color: ${COLORS.tealDark};
  margin-bottom: 8px;
  justify-content: flex-start;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.tealDark};
  justify-content: flex-end;
`;

const Illustration = styled(DeferredImage)`
  margin: 0 auto 24px;

  ${mq({
    height: ['120px', '88px', '120px'],
  })}
`;

const ValuePropCard: React.FC<IValuePropCard> = ({
  title,
  description,
  illustration,
  className,
}) => {
  return (
    <Container className={className}>
      <Illustration src={illustration} alt="" />
      <Title size="extraSmall">{title}</Title>
      <Body>{description}</Body>
    </Container>
  );
};

export { IValuePropCard, ValuePropCard };
