/* @jsx jsx */
import * as React from 'react';
import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import { jsx } from '@emotion/react';

import { ApolloProvider } from '@apollo/client';

import { buildLandingApolloClient } from '@root/initializers/apollo/build_landing_apollo_client';

import { Article } from './help/article';
import { Category } from './help/category';
import { Layout } from './help/layout';
import { Root } from './help/root';
import {
  articleURL,
  categoryURL,
  mainURL,
  searchURL,
  sectionURL,
} from './help/routes';
import { SearchResults } from './help/search_results';

export const Help: React.FC = () => {
  const [client] = useState(buildLandingApolloClient);

  return (
    <HelmetProvider>
      <Layout>
        <ApolloProvider client={client}>
          <Switch>
            <Route exact path={mainURL()} component={Root} />
            <Route exact path={searchURL()} component={SearchResults} />
            <Route exact path={articleURL()} component={Article} />
            <Route exact path={categoryURL()} component={Category} />
            <Route exact path={sectionURL()} component={Category} />
          </Switch>
        </ApolloProvider>
      </Layout>
    </HelmetProvider>
  );
};
