import React, { useState } from 'react';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';

import { DeferredImage } from '@shared/deferred_image';

import arrowUp from '@images/icons/arrow_up.svg';

import { TogglerIcon } from './toggler_icon';

export const CollapsibleSummaryContainer: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Box.Flex
        padding="12px 16px"
        background={COLORS.grayBackground}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text.Callout color={COLORS.tealPrimary} weight={FontWeight.Medium}>
          {isOpen ? 'Hide' : 'Show'} reservation summary
        </Text.Callout>
        &nbsp;
        <Box.FlexItem alignSelf="center" padding="">
          <TogglerIcon isOpen={isOpen}>
            <DeferredImage src={arrowUp} />
          </TogglerIcon>
        </Box.FlexItem>
      </Box.Flex>
      <Box
        transition="max-height 0.2s ease-in-out"
        overflow="hidden"
        maxHeight={isOpen ? '2000px' : '0'}
        background={COLORS.grayBackground}
      >
        <Box margin="24px 24px 0px">{children}</Box>
      </Box>
    </>
  );
};
