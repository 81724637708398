import React from 'react';

import { DateTime } from 'luxon';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  COLORS,
  Text,
  UnstyledButton,
  transparentize,
} from '@clutter/clean';

import { Lead__ServiceNeeds } from '@graphql/platform';

import { TermsOfUseAgreement } from '@root/components/checkout/terms_of_use_agreement';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

const JumboButton = styled(Button)`
  width: 100%;
  height: 64px;
`;

const MobileFooter = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  background: white;
  z-index: 1;
  transition: transform 0.4s ease;
  position: fixed;
  box-shadow: 0 0 16px ${transparentize(COLORS.panther, 0.1)};
  transform: translateY(0);
`;

export const ReserveCTA: React.FC<{
  bookingError: React.ReactNode;
  onReserve: ({
    paymentRequired,
  }: {
    paymentRequired?: boolean;
  }) => Promise<void>;
  buttonLoading: boolean;
  buttonDisabled: boolean;
  cancelByDateTime: DateTime;
  buttonLabel: string;
  onCancellationLinkClick(): void;
  service: Lead__ServiceNeeds;
}> = ({
  bookingError,
  cancelByDateTime,
  onReserve,
  buttonLoading,
  buttonDisabled,
  buttonLabel,
  onCancellationLinkClick,
  service,
}) => {
  const cancelByDate = cancelByDateTime.toFormat('MMM d');
  const { isDesktop } = useBreakpoints();

  return isDesktop ? (
    <>
      {service === Lead__ServiceNeeds.Moving && (
        <Box>
          <Box margin="0 0 4px">
            <Text.Title size="extraSmall">Confirm your reservation</Text.Title>
          </Box>
          <Text.Callout color={COLORS.storm}>
            Worry-free reservation. You can cancel free of charge before{' '}
            {cancelByDate}.{' '}
            <UnstyledButton onClick={() => onCancellationLinkClick()}>
              <Text.Callout style={{ textDecoration: 'underline' }}>
                Cancellation policy
              </Text.Callout>
            </UnstyledButton>
          </Text.Callout>
        </Box>
      )}
      <Box margin="16px 0 0">
        <JumboButton
          kind="primary"
          fullWidth
          disabled={buttonDisabled}
          loading={buttonLoading}
          onClick={() => {
            onReserve({ paymentRequired: true });
          }}
        >
          {buttonLabel}
        </JumboButton>
        <TermsOfUseAgreement actionLabel={buttonLabel} />
        {bookingError && <Box>{bookingError}</Box>}
      </Box>
    </>
  ) : (
    <MobileFooter>
      <Button
        kind="primary"
        fullWidth
        disabled={buttonDisabled}
        loading={buttonLoading}
        onClick={() => onReserve({ paymentRequired: true })}
      >
        {buttonLabel}
      </Button>
      <TermsOfUseAgreement actionLabel={buttonLabel} />
      {bookingError && <Box>{bookingError}</Box>}
    </MobileFooter>
  );
};
