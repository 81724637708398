import React from 'react';

import { Box } from '@clutter/clean';

import {
  DraftFragment,
  Lead__ServiceNeeds,
  Maybe,
  useGeocodeQuery,
} from '@graphql/platform';

import { MapWithDirections } from '@root/components/checkout/product_pages/subcomponents/map_with_directions';

import { MapProvider } from '@shared/map/context';
import { Marker } from '@shared/map/marker';
import { useServerDataContext } from '@shared/server_data_context';

const StorageMap: React.FC<{ address?: Maybe<DraftFragment['address']> }> = ({
  address,
}) => {
  const { inferredZipCode } = useServerDataContext();
  const geocode = useGeocodeQuery({
    variables: { zip: address?.zip ?? inferredZipCode ?? '90210' },
  });
  const center =
    (address?.latitude && address?.longitude
      ? { lat: address.latitude, lng: address.longitude }
      : undefined) || geocode.data?.geocode?.center;

  return (
    <Box height="144px" width="144px">
      {center && (
        <MapProvider
          center={center}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            draggable: false,
          }}
        >
          {center && <Marker {...center} />}
        </MapProvider>
      )}
    </Box>
  );
};

export const Map: React.FC<{ draft: DraftFragment }> = ({ draft }) => {
  return (
    <>
      {draft.latestService === Lead__ServiceNeeds.Moving ? (
        <MapWithDirections
          origin={draft.movingOriginAttributes ?? undefined}
          destination={draft.movingDestinationAttributes ?? undefined}
          zip={draft.movingOriginAttributes?.zip ?? ''}
          height="144px"
          width="144px"
        />
      ) : (
        <StorageMap address={draft.address} />
      )}
    </>
  );
};
