import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  EXTRA_SMALL_TITLE_STYLES,
  SMALL_TITLE_STYLES,
  Text,
  TextButton,
  mq,
} from '@clutter/clean';

import { Moving__BuildingTypeEnum } from '@graphql/platform';

import { MovingCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import { StrictMovingBuildingType } from '@root/resources/types/address';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { FeaturesOverlay } from '../../subcomponents/features_overlay';
import { MOVING_LEARN_MORE } from '../../subcomponents/features_overlay/data';
import { RadioCard } from '../../subcomponents/radio_card';

const BuildingCards = styled.div`
  display: grid;
  grid-gap: 16px;

  ${mq({
    marginTop: ['24px', '32px'],
    gridTemplateColumns: ['1fr', '1fr 1fr'],
  })}
`;

type BuildingType = { label: string; description: string; cost?: string };

const BUILDING_TYPES: Record<string, BuildingType> = {
  [Moving__BuildingTypeEnum.Other]: {
    label: 'Up to 10 items',
    description: 'Moving a single item or just the big furniture',
  },
  [Moving__BuildingTypeEnum.DormOrCampusApartment]: {
    label: 'Dorm room',
    description: 'Moving items out of a college residence',
  },
  [Moving__BuildingTypeEnum.Apartment]: {
    label: 'Apartment',
    description: 'Moving items out of an apartment, studio, or condo',
  },
  [Moving__BuildingTypeEnum.House]: {
    label: 'House',
    description: 'Moving items out of a house or townhouse',
  },
  [Moving__BuildingTypeEnum.StorageFacilityOrWarehouse]: {
    label: 'Storage unit',
    description: 'Moving items out of a self-storage unit of any size',
  },
  [Moving__BuildingTypeEnum.Commercial]: {
    label: 'Business',
    description: 'Moving items out of a commercial unit or business',
  },
};

export const Building: React.FC<MovingCheckoutStepProps> = ({
  onChange,
  values: { startAddressDetails, rooms },
  scrollToStep,
}) => {
  const { isMobile } = useBreakpoints();
  const track = useTrack({ action: 'click' });
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const handleChange = (selectedBuildingType: StrictMovingBuildingType) => {
    track({
      objectName: 'building_type_selector',
      value:
        selectedBuildingType === Moving__BuildingTypeEnum.Other
          ? 'Up to 10 items'
          : selectedBuildingType,
      objectType: 'button',
    });

    onChange('startAddressDetails', {
      buildingType:
        selectedBuildingType === Moving__BuildingTypeEnum.Other ||
        selectedBuildingType === Moving__BuildingTypeEnum.DormOrCampusApartment
          ? Moving__BuildingTypeEnum.Apartment
          : selectedBuildingType,
      squareFootage: undefined,
      unitSize: undefined,
    });

    if (selectedBuildingType === Moving__BuildingTypeEnum.Other) {
      onChange('rooms', { ['10_items']: 1 });
    } else if (
      selectedBuildingType === Moving__BuildingTypeEnum.DormOrCampusApartment
    ) {
      onChange('rooms', { ['dorm_room']: 1 });
    } else {
      onChange('rooms', undefined);
    }

    onChange('adjustedMoverCount', undefined);
    scrollToStep();
  };

  const handleLearnMoreClick = () => {
    track({
      objectName: 'features',
      objectType: 'button',
      label: 'Learn more about moving',
    });
    setShowOverlay(true);
  };

  const is10ItemMove =
    rooms && Object.keys(rooms).some((key) => key === '10_items');
  const isDormRoomMove =
    rooms && Object.keys(rooms).some((key) => key === 'dorm_room');

  const selectedCardValue = is10ItemMove
    ? Moving__BuildingTypeEnum.Other
    : isDormRoomMove
      ? Moving__BuildingTypeEnum.DormOrCampusApartment
      : startAddressDetails?.buildingType;

  return (
    <>
      <Box.Flex justifyContent="space-between">
        <Text.Title size="small" color={COLORS.tealDark}>
          What are you moving?
        </Text.Title>
        <Box.FlexItem
          flexShrink={0}
          display={['none', null, null, 'initial']}
          alignSelf="center"
        >
          <TextButton onClick={handleLearnMoreClick}>
            Learn more about moving
          </TextButton>
        </Box.FlexItem>
      </Box.Flex>
      <BuildingCards>
        {Object.entries(BUILDING_TYPES).map(([key, { label, description }]) => (
          <RadioCard
            key={key}
            selected={key === selectedCardValue}
            onChange={() => handleChange(key as StrictMovingBuildingType)}
            value={key}
          >
            <Box textAlign="center">
              <Text
                style={
                  isMobile ? EXTRA_SMALL_TITLE_STYLES.SM : SMALL_TITLE_STYLES.SM
                }
              >
                {label}
              </Text>
              <Box margin="0 auto" maxWidth="232px">
                <Text.Callout color={COLORS.hippo}>{description}</Text.Callout>
              </Box>
            </Box>
          </RadioCard>
        ))}
      </BuildingCards>
      <FeaturesOverlay
        isOpen={showOverlay}
        onClose={() => setShowOverlay(false)}
        content={MOVING_LEARN_MORE}
      />
    </>
  );
};
