import { useEffect } from 'react';
import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text, toggleStyleValue } from '@clutter/clean';

import { ProgressStepper } from '@shared/progress_stepper';

const Background = styled.div<{ hasFooter: boolean }>`
  position: absolute;
  min-height: 100%;
  width: 100%;
  background: ${COLORS.cloud};
  padding-bottom: ${toggleStyleValue('hasFooter', '96px', 'initial')};
`;

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const TextWrapper = styled.div`
  padding: 24px;
`;

const StyledTitle = styled(Text.Title)`
  padding-top: 24px;
`;

const StyledBody = styled(Text.Body)`
  padding-top: 8px;
  padding-bottom: 16px;
`;

interface IStepContainerProps {
  currentStep: number;
  totalSteps: number;
  title: string;
  details?: string[];
  hasFooter?: boolean;
  children?: React.ReactNode;
}

export const StepContainer: React.FC<IStepContainerProps> = ({
  currentStep,
  totalSteps,
  title,
  details,
  hasFooter = false,
  children,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Background hasFooter={hasFooter}>
      <Content>
        <TextWrapper>
          <ProgressStepper currentStep={currentStep} totalSteps={totalSteps} />
          <StyledTitle size="large">{title}</StyledTitle>
          {details &&
            details.map((paragraph, index) => (
              <StyledBody key={index}>{paragraph}</StyledBody>
            ))}
        </TextWrapper>
        {children}
      </Content>
    </Background>
  );
};
