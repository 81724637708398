import React, { useEffect, useState } from 'react';

import { PHONE_MASK } from '@root/resources/masks';
import { phone as phoneValidator } from '@root/resources/validator';
import { EventSchema } from '@root/resources/wt/types';

import { FieldText } from '@shared/field_text';

import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';
import { useOnMount } from '@utils/hooks/mount';

export const PhoneInput = ({
  value = '',
  phoneError,
  onChange,
}: {
  value: string | undefined;
  phoneError?: string | undefined;
  onChange(number: string): void;
}) => {
  const [error, setError] = useState<string | undefined>(phoneError);
  const trackFunnelEvent = useTrackFunnelEvents();

  useOnMount(() => {
    trackFunnelEvent({
      schema: EventSchema.WWW__PhoneCaptureViewed,
      action: 'display',
      metadata: {},
    });
  });

  useEffect(() => {
    if (phoneError) {
      setError(phoneError);
    }
  }, [phoneError]);

  return (
    <FieldText
      name="phone"
      type="tel"
      autoComplete="phone"
      label="Phone number"
      error={error}
      value={value}
      mask={PHONE_MASK}
      onChange={(_, value) => {
        if (error) setError(undefined);
        onChange(value);
      }}
      onBlur={() => {
        if (phoneValidator(value)) {
          trackFunnelEvent({
            schema: EventSchema.WWW__PhoneCaptureCompleted,
            action: 'submit',
            metadata: { phone_number: value },
          });
        } else {
          setError('Please enter a valid phone number');
        }
      }}
    />
  );
};
