import React, { type FC } from 'react';

import styled from '@emotion/styled';

import HERO_POSTER from '@videos/home/hero-poster.jpg';

import HERO_H264_MP4 from '@videos/home/hero-h264.mp4';
import HERO_H265_MP4 from '@videos/home/hero-h265.mp4';
import HERO_WEBM from '@videos/home/hero.webm';

const FALLBACK_SRC = 'https://assets.clutter.com/videos/hero.mp4';

const Video = styled.video`
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
`;

const H264_CODECS = ['avc1.42E01E', 'mp4a.40.2'].join(',');
const H265_CODECS = ['hev1'].join(',');

export const Player: FC = () => (
  <Video
    autoPlay
    playsInline
    muted
    loop
    width={1920}
    height={1080}
    preload="none"
    poster={HERO_POSTER}
  >
    <source src={HERO_WEBM} type="video/webm" />
    <source src={HERO_H265_MP4} type={`video/mp4; codecs="${H265_CODECS}"`} />
    <source src={HERO_H264_MP4} type={`video/mp4; codecs="${H264_CODECS}"`} />
    <source src={FALLBACK_SRC} type="video/mp4" />
  </Video>
);
