import { useEffect } from 'react';

import { ErrorCodeEnum } from '@graphql/platform';

import { useTrack } from '@root/initializers/wt';

import {
  PAYMENT_ERROR_TO_MESSAGE,
  PaymentMethodErrorKind,
} from '@shared/payment_method_error';

import { ErrorWithoutTypename } from '@utils/gql_errors';

export function useTrackBookingErrors({
  phoneError,
  billingNameError,
  paymentMethodError,
  gqlError,
}: {
  phoneError: string | undefined;
  billingNameError: string | undefined;
  paymentMethodError: PaymentMethodErrorKind | undefined;
  gqlError: ErrorWithoutTypename | null;
}) {
  const track = useTrack();
  useEffect(() => {
    const errorMessages = [
      // Note: The GQL error message should be first for data continuity as it
      // was once the only error tracked as `value` below
      gqlError &&
        gqlError.errorCode !== ErrorCodeEnum.Unknown &&
        gqlError.errorMessage,
      phoneError,
      billingNameError,
      paymentMethodError && PAYMENT_ERROR_TO_MESSAGE[paymentMethodError],
    ].filter((e): e is string => typeof e === 'string');

    if (errorMessages.length) {
      track({
        action: 'display',
        objectType: 'error',
        value: errorMessages[0],
        metadata: {
          errors: errorMessages,
        },
      });
    }
  }, [track, phoneError, gqlError, paymentMethodError, billingNameError]);
}
