import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  Modal,
  Text,
  UnstyledButton,
  mq,
} from '@clutter/clean';

import {
  Lead__ServiceNeeds,
  useMfaAttemptMakeMutation,
  useMfaRequestCreateMutation,
} from '@graphql/platform';

import { XButton } from '@root/components/icons/x_button';

import { Action, useClientDataContext } from '@shared/client_data_context';
import { VerificationInput } from '@shared/verification_input';

const CODE_LENGTH = 6;

const TopBorder = styled.div`
  border-bottom: 1px solid ${COLORS.grayBorder};
  position: absolute;
  left: 0;
  top: 56px;
  width: 100%;
  height: 0px;
`;

const ModalContainer = styled.div`
  text-align: center;
  max-height: 80vh;
  overflow-y: auto;
  ${mq({
    width: ['90vw', '556px'],
  })}
`;

const ResendLink = styled(Text.Button)`
  color: ${COLORS.tealPrimary};
  text-align: center;
  width: 156px;
  margin: 0 auto;
`;

const EMPTY_RESULT = {
  customerToken: undefined,
  leadToken: undefined,
};

export type MFAResult =
  | { customerToken: string; leadToken: string }
  | { customerToken: undefined; leadToken: undefined };

const MFAModal: React.FC<{
  email: string;
  zip: string;
  name: string;
  phone?: string;
  serviceNeeds: Lead__ServiceNeeds[];
  isOpen: boolean;
  onClose(result: MFAResult): void;
}> = (props) => {
  const { email, zip, name, phone, serviceNeeds, isOpen, onClose } = props;

  const {
    data: {
      lead: { source },
    },
    dispatch,
  } = useClientDataContext();

  const [code, setCode] = useState<string>('');
  const [state, setState] = useState<'error' | 'valid' | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [executeRequest, { data: dataRequest, loading: loadingRequest }] =
    useMfaRequestCreateMutation({
      variables: { input: { email } },
    });

  useEffect(() => {
    if (isOpen) executeRequest();
  }, [executeRequest, isOpen]);

  const requestID = dataRequest?.mfaRequestCreate?.request?.id;

  const [executeAttempt, { loading: attempting }] = useMfaAttemptMakeMutation();

  const handleVerification = async (code: string) => {
    if (!requestID) throw new Error('Unable to verify without an MFA request');

    // Make an attempt to verify the code vs. the ID
    const { data: dataAttempt } = await executeAttempt({
      variables: {
        input: {
          requestID,
          code,
          lead: {
            state: 'open',
            email,
            zip,
            name,
            phone,
            source: source!,
            url: window.location.href,
            serviceNeeds,
          },
        },
      },
    });

    if (!dataAttempt) throw new Error('MFA attempt GQL error');

    const customerToken = dataAttempt.mfaAttemptMake?.customer?.token;
    const leadToken = dataAttempt.mfaAttemptMake?.lead?.token;
    const attemptError = dataAttempt.mfaAttemptMake?.error;

    // The attempt was successful if we received an attempt token
    if (customerToken && leadToken) {
      dispatch({
        type: Action.SetLead,
        payload: { token: leadToken, email },
      });
      onClose({ customerToken, leadToken });
      setState('valid');
      setErrorMessage('');
    } else if (attemptError) {
      setState('error');
      setErrorMessage(attemptError);
    } else {
      throw new Error('MFA attempt GQL error — didn’t receive token or error');
    }
  };

  return (
    <Modal isOpen={isOpen} handleModalClose={() => onClose(EMPTY_RESULT)}>
      <Box position="absolute" left="24px" top="24px">
        <UnstyledButton onClick={() => onClose(EMPTY_RESULT)}>
          <XButton />
        </UnstyledButton>
      </Box>
      <Box textAlign="center" margin="24px auto" width="200px">
        <Text.SmallCaps>Verify By Email</Text.SmallCaps>
      </Box>
      <TopBorder></TopBorder>
      <ModalContainer>
        <Box
          textAlign="center"
          maxWidth={['initial', '324px', null, '396px']}
          margin={['40px 24px', '40px auto 24px']}
        >
          <Text.Title size="medium">
            Enter your code to confirm your account
          </Text.Title>
        </Box>
        <Box textAlign="center" padding={['0px 24px 40px', '0 88px 40px']}>
          <Text.Body>
            Looks like you already have an account! Confirm by entering the 6
            digit code and we’ll send the reservation linked to {email}.
          </Text.Body>
        </Box>
        <Box padding={['0px 24px 12px', '0 116px 12px']}>
          <Box margin="auto auto 12px">
            <Text.Body weight={FontWeight.Medium}>
              Enter your 6 digit code
            </Text.Body>
          </Box>
          <VerificationInput
            length={CODE_LENGTH}
            disabled={!requestID || attempting}
            value={code}
            onChange={async (code: string): Promise<void> => {
              if (code.length < CODE_LENGTH) {
                setCode(code);
                setState(undefined);
              } else if (code.length == CODE_LENGTH) {
                setCode(code);
                handleVerification(code);
              }
            }}
            state={state}
          />
        </Box>
        <Box margin="12px auto 0" minHeight="40px" textAlign="center">
          <Text.Caption color={COLORS.toucan} weight={FontWeight.Medium}>
            {errorMessage}
          </Text.Caption>
        </Box>
        <Box margin="0 auto 40px">
          <UnstyledButton>
            <ResendLink onClick={() => executeRequest()}>
              Resend Code
            </ResendLink>
          </UnstyledButton>
        </Box>
        <Box textAlign="right" margin="16px 24px">
          <Button
            disabled={
              !(code.length == CODE_LENGTH) || loadingRequest || attempting
            }
            loading={loadingRequest || attempting}
            onClick={() => handleVerification(code)}
            size="large"
          >
            Apply
          </Button>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export { MFAModal };
