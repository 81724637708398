import styled from '@emotion/styled';

import { mq } from '@clutter/clean';

interface ICardProps {
  offset?: number;
  background?: string;
  shadowOff?: boolean;
  marginBottom?: number;
  mobileOffset?: number;
}

const Card = styled.section<ICardProps>`
  position: relative;
  box-shadow: 0 16px 32px 0 rgba(10, 61, 58, 0.1);
  background: white;

  ${({ offset, mobileOffset }) => {
    const mb = 128;
    const mo =
      mobileOffset === 0
        ? 0
        : mobileOffset
          ? mobileOffset
          : offset
            ? offset
            : 0;
    return mq({
      margin: ['0', '0 48px'],
      marginBottom: ['0', `${offset ? mb + offset : mb}px`],
      minHeight: ['400px', undefined, '375px'],
      top: [0, `${offset || 0}px`],
      marginTop: [`${mo}px`, 0],
    });
  }}
`;

export { Card };
