import React, { useEffect } from 'react';

import { DateTime } from 'luxon';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import { useLatestRef } from '@clutter/hooks';

import { MultidayAvailabilityFragment } from '@graphql/platform';

import {
  FROM_ISO_OPTIONS,
  hashDateTime,
} from '@shared/calendar/calendar_utils';

const deliveryDatesForDate = (
  date: string,
  availabilities: ReadonlyArray<MultidayAvailabilityFragment>,
) => {
  const result = new Set<string>();
  for (const availability of availabilities) {
    const selectable = DateTime.fromISO(
      availability.fromTime,
      FROM_ISO_OPTIONS,
    );
    const hashedDateTime = hashDateTime(selectable);
    if (hashedDateTime === date) {
      availability.returnAvailabilities?.forEach((entry) => result.add(entry));
    }
  }
  return Array.from(result)
    .sort()
    .map((r) => DateTime.fromISO(r));
};

const DateCardContainer = styled.div<{ selected: boolean }>`
  width: 72px;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ selected }) =>
    selected ? `${COLORS.tealPrimary}` : `${COLORS.cloud}`};
  border: ${({ selected }) =>
    selected
      ? `1.5px solid ${COLORS.tealPrimary}`
      : `1.5px solid ${COLORS.panther}`};

  &:hover {
    background: ${({ selected }) =>
      selected ? `${COLORS.tealPrimary}` : `${COLORS.tealBackground}`};
  }
`;

const StyledSmallCaps = styled(Text.SmallCaps)`
  font-size: 10px;
`;

const DateCard: React.FC<{
  date: DateTime;
  selected: boolean;
  onSelect(): void;
}> = ({ date, selected, onSelect }) => {
  return (
    <DateCardContainer selected={selected} onClick={onSelect}>
      <StyledSmallCaps color={selected ? `${COLORS.cloud}` : `${COLORS.hippo}`}>
        {date.toFormat('ccc')}
      </StyledSmallCaps>
      <Text.Body
        weight={FontWeight.Medium}
        color={selected ? `${COLORS.cloud}` : `${COLORS.panther}`}
      >
        {date.day}
      </Text.Body>
      <StyledSmallCaps color={selected ? `${COLORS.cloud}` : `${COLORS.hippo}`}>
        {date.toLocaleString({ month: 'short' })}
      </StyledSmallCaps>
    </DateCardContainer>
  );
};

export const DeliveryDateSelector: React.FC<{
  selectedAvailability: string;
  availabilities: ReadonlyArray<MultidayAvailabilityFragment>;
  unpackingScheduled?: DateTime;
  onSelect(date: DateTime): void;
  trackUnpackingDateViewed(availableDays: number): void;
}> = ({
  selectedAvailability,
  availabilities,
  unpackingScheduled,
  onSelect,
  trackUnpackingDateViewed,
}) => {
  const deliveryDates = deliveryDatesForDate(
    selectedAvailability,
    availabilities,
  );

  const deliveryDatesRef = useLatestRef(deliveryDates);

  useEffect(() => {
    trackUnpackingDateViewed(deliveryDatesRef.current.length);
  }, [trackUnpackingDateViewed, deliveryDatesRef, selectedAvailability]);

  return (
    <Box>
      <Text.Title size="extraSmall">
        Select a guaranteed delivery date
      </Text.Title>
      <Text.Body color={COLORS.storm}>
        Change your start date to see other possible delivery dates.
      </Text.Body>
      <Box.Flex
        flexDirection="row"
        justifyContent="flex-start"
        margin="8px 0 0 0"
        gap="8px"
        flexWrap="wrap"
      >
        {deliveryDatesForDate(selectedAvailability, availabilities).map(
          (date, i) => {
            return (
              <DateCard
                selected={unpackingScheduled?.toISODate() === date.toISODate()}
                date={date}
                key={i}
                onSelect={() => onSelect(date)}
              />
            );
          },
        )}
      </Box.Flex>
    </Box>
  );
};
