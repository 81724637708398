import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const SKIP_SCROLL_TO_TOP_STATE = { skipScrollToTop: true };

export const ScrollToTop: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const mounted = useRef(false);
  const location = useLocation<{ skipScrollToTop?: boolean }>();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (location.state?.skipScrollToTop) {
      return;
    }
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};
