import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  ButtonComponent,
  COLORS,
  FontWeight,
  Text,
  ZIndex,
  mq,
  toggleStyleValue,
} from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { useOnMount } from '@utils/hooks/mount';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

type ButtonProps = {
  isOpen: boolean;
  bottomOffset?: number;
  onClick(): void;
};

const BUTTON_LABEL = 'Talk with an expert';

const StyledButton = styled(ButtonComponent)<{ isOpen: boolean }>`
  border-radius: 24px;
  border: 2px solid ${COLORS.tealPrimary};
  background: ${toggleStyleValue('isOpen', COLORS.cloud, COLORS.tealPrimary)};
  height: 48px;
  box-shadow: 0 8px 19px rgb(0 0 0 / 32%);
  min-width: unset;

  ${mq({
    padding: ['10px', '10px 20px'],
  })}
`;

const PhoneIcon: React.FC<{ strokeColor?: string }> = ({
  strokeColor = COLORS.cloud,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="transparent"
        fillRule="evenodd"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.04 22.39l.012.007a5.533 5.533 0 0 0 6.884-.755l.774-.774c.72-.72.72-1.888 0-2.609L19.449 15a1.846 1.846 0 0 0-2.609 0 1.843 1.843 0 0 1-2.608 0L9.014 9.781a1.846 1.846 0 0 1 0-2.609 1.843 1.843 0 0 0 0-2.608L5.754 1.3a1.846 1.846 0 0 0-2.609 0l-.774.774a5.535 5.535 0 0 0-.756 6.884l.008.012A49.935 49.935 0 0 0 15.04 22.39z"
      />
    </svg>
  );
};

const ToggleButton: React.FC<ButtonProps> = ({
  isOpen,
  bottomOffset = 0,
  onClick,
}) => {
  const { isMobile } = useBreakpoints();
  return (
    <Box
      position="fixed"
      bottom={`${32 + bottomOffset}px`}
      right="32px"
      style={{ zIndex: ZIndex.MODAL + 1 }}
    >
      <StyledButton
        isOpen={isOpen}
        onClick={onClick}
        kind={isOpen ? 'secondary' : 'primary'}
      >
        <Box.Flex gap="8px">
          <PhoneIcon strokeColor={isOpen ? COLORS.tealPrimary : COLORS.cloud} />
          {!isMobile && (
            <Text.Body weight={FontWeight.Medium}>{BUTTON_LABEL}</Text.Body>
          )}
        </Box.Flex>
      </StyledButton>
    </Box>
  );
};

export const Button: React.FC<ButtonProps> = (props) => {
  const track = useTrack();
  useOnMount(() =>
    track({
      action: 'display',
      label: BUTTON_LABEL,
      objectType: 'button',
      objectName: 'qualified_contact_widget',
    }),
  );
  return <ToggleButton {...props} />;
};
