import React from 'react';

import {
  Box,
  COLORS,
  EXTRA_SMALL_TITLE_STYLES,
  FontWeight,
  Text,
  UnstyledButton,
} from '@clutter/clean';

import { CouponSummary } from '@root/components/checkout/cart/coupon_summary';
import { useMovingCheckoutContext } from '@root/components/checkout/context';

import checkmark from '@images/icons/checkmark-teal.svg';
import sparkle from '@images/illustrations/sparkle.svg';

const SummaryItem = ({ children }: { children: React.ReactNode }) => (
  <Box.Flex gap="4px">
    <img src={checkmark} alt="" />
    <Text.Callout weight={FontWeight.Medium}>{children}</Text.Callout>
  </Box.Flex>
);

export const MovingSavingsSummary = () => {
  const {
    flowState: {
      values: { coupon },
      onChange,
    },
  } = useMovingCheckoutContext();

  const summaryItems: React.ReactElement[] = [];

  if (coupon) {
    summaryItems.push(
      <SummaryItem>
        <CouponSummary coupon={coupon} />{' '}
        <UnstyledButton onClick={() => onChange('coupon', undefined)}>
          <Text.Caption weight={FontWeight.Medium} color={COLORS.tealPrimary}>
            Remove
          </Text.Caption>
        </UnstyledButton>
      </SummaryItem>,
    );
  }

  if (!summaryItems.length) return null;

  return (
    <Box
      background={COLORS.dust}
      border="1px solid"
      borderRadius="4px"
      borderColor={COLORS.sunshine}
      padding="8px 16px 16px"
    >
      <Text style={EXTRA_SMALL_TITLE_STYLES.SM}>
        Your savings <img src={sparkle} alt="" />
      </Text>
      <Box.Flex gap="8px" flexDirection="column">
        {summaryItems}
      </Box.Flex>
    </Box>
  );
};
