import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  Text,
  UnstyledButton,
  mq,
} from '@clutter/clean';

import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { SharedCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import clock from '@images/illustrations/clock_large.svg';
import trashCan from '@images/illustrations/trash_can_large.svg';

import { CardContents } from '../../subcomponents/card_contents';
import { PricingModal } from '../../subcomponents/disposal/pricing_modal';
import { RadioCard } from '../../subcomponents/radio_card';

const Cards = styled.div`
  display: grid;

  ${mq({
    marginTop: ['24px', '32px'],
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: ['12px', '16px'],
  })}
`;

type DisposalStepProps = SharedCheckoutStepProps;

export const Disposal: React.FC<DisposalStepProps> = ({
  enabled,
  onChange,
  scrollToStep,
  values: { disposalSelected },
}) => {
  const [showPricingModal, setShowPricingModal] = useState<boolean>(false);
  const { disposalEligible } = useSharedCheckoutContext();
  const { isMobile } = useBreakpoints();
  const track = useTrack({ action: 'click' });
  const visible = disposalEligible;

  useEffect(() => {
    if (!disposalEligible) {
      onChange('disposalSelected', undefined);
    }
  }, [disposalEligible]);

  useEffect(() => {
    if (enabled && visible)
      track({
        action: 'display',
        metadata: {},
      });
  }, [enabled, visible]);

  const handleChange = (selected: boolean) => {
    onChange('disposalSelected', selected);
    isMobile && scrollToStep('current');
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <Box margin={['48px 0 0', null, '72px 0 0']}>
        <Text.Headline weight={FontWeight.Medium} color={COLORS.tealDark}>
          Do you have any items to dispose of?
        </Text.Headline>
        <Box margin="4px 0 0">
          <Text.Body color={COLORS.storm}>
            Use our disposal service and check one more thing off of your to-do
            list.
          </Text.Body>
          <UnstyledButton
            onClick={() => {
              setShowPricingModal(true);
              track({
                objectName: 'disposal_view_pricing',
                label: 'View Pricing and Restrictions',
                objectType: 'button',
              });
            }}
          >
            <Text.Body color={COLORS.tealPrimary} weight={FontWeight.Medium}>
              View Pricing and Restrictions
            </Text.Body>
          </UnstyledButton>
        </Box>
        <Cards>
          <RadioCard
            name="disposalAddOn"
            value="Not now"
            selected={disposalSelected === false}
            onChange={() => {
              track({
                objectName: 'disposal_add_on_card',
                objectType: 'button',
                label: 'Not now',
                value: false,
              });
              handleChange(false);
            }}
          >
            <CardContents
              label="Not now"
              description="You can change your mind the day of, if anything comes up!"
              price={null}
              imgSrc={clock}
            />
          </RadioCard>
          <RadioCard
            name="disposalAddOn"
            value="Yes, please!"
            selected={disposalSelected === true}
            onChange={() => {
              track({
                objectName: 'disposal_add_on_card',
                objectType: 'button',
                label: 'Yes, please!',
                value: true,
              });
              handleChange(true);
            }}
          >
            <CardContents
              label="Yes please!"
              description="You can tell us about your items after booking to help things go smoothly."
              price={50}
              imgSrc={trashCan}
              suffix="+"
            />
          </RadioCard>
        </Cards>
      </Box>
      <PricingModal
        isOpen={showPricingModal}
        onClose={() => setShowPricingModal(false)}
      />
    </>
  );
};
