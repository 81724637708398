import * as React from 'react';

import { debounce } from 'lodash-es';

import styled from '@emotion/styled';

import * as Sentry from '@sentry/browser';

import { COLORS, Text } from '@clutter/clean';

import { AddressFragment } from '@graphql/platform';

import { ClientAddress } from '@root/resources/types/address';

import { AddressAutocompleteService } from '@utils/address_autocomplete_service';
import { extractAddressFromGeocoderResult } from '@utils/google_maps';

import { GMAP_LOADJS_ID, loadMapsApi } from '../../initializers/google_maps';
import { LocationMarker } from '../icons/location_marker';
import {
  FieldAutocomplete,
  IFieldAutocompleteProps,
} from './field_autocomplete';
import * as loadjs from 'loadjs';

interface IAutocompleteOption {
  key: string;
  value: string;
  prediction: google.maps.places.AutocompletePrediction;
}

interface IPredictionProps {
  highlighted: boolean;
}

const PredictionWrapper = styled.div<IPredictionProps>`
  background: ${({ highlighted }) =>
    highlighted ? COLORS.grayBorder : 'none'};
  position: relative;
  border-top: none;
  margin: 4px;
  padding: 4px 0 4px 12px;
`;

const PromptContainer = styled.div`
  width: 100%;
  height: 36px;
  font-weight: 500;
  color: ${COLORS.storm};

  border-radius: 4px 4px 0 0;
  padding: 10px 16px 0;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

const MenuContainer = styled.ul`
  background: white;
  position: absolute;
  z-index: 1000;
  top: 52px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
`;

const Menu: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <MenuContainer>
    <PromptContainer>
      <Text.Caption>Select an address from the results below</Text.Caption>
    </PromptContainer>
    {children}
  </MenuContainer>
);

const Prediction: React.FC<{
  option: IAutocompleteOption;
  highlighted: boolean;
}> = ({ option, highlighted }) => (
  <PredictionWrapper data-test-id="addressResult" highlighted={highlighted}>
    {option.value}
  </PredictionWrapper>
);

export interface IFieldAddressProps
  extends Omit<IFieldAutocompleteProps, 'onChange' | 'options'> {
  value?: Partial<AddressFragment>;
  label?: string;
  error?: string | string[];
  initialPrompt?: React.ReactNode;
  includeCityInInput?: boolean;
  onChange(field: string, address: Partial<ClientAddress>): void;
  onInputChange?(addressStreet: string): void;
  onClear?(): void;
  selectFirstOnBlur?: boolean;
  testId?: string;
}

export const FieldAddress: React.FC<IFieldAddressProps> = (props) => {
  const [options, setOptions] = React.useState<IAutocompleteOption[]>([]);
  const [inputValue, setInputValue] = React.useState<string>(
    props.value?.street || '',
  );

  const [autocompleteService, setAutocompleteService] = React.useState<
    InstanceType<typeof AddressAutocompleteService> | undefined
  >();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'test') {
      setAutocompleteService(() => new AddressAutocompleteService());
    } else {
      loadMapsApi();
      loadjs.ready(GMAP_LOADJS_ID, () => {
        setAutocompleteService(() => new AddressAutocompleteService());
      });
    }
  }, []);

  const {
    inputProps = {},
    hideClearButton,
    field,
    error,
    initialPrompt = 'Start typing to find an address',
    selectFirstOnBlur,
    onClear,
    testId,
  } = props;
  const valid = props.value?.city !== undefined;

  const onChange = (_: any, option: IAutocompleteOption) => {
    if (!option) {
      return;
    }
    // Set inputValue to the street portion of the address
    let inputValue = option.prediction.structured_formatting.main_text;
    if (props.includeCityInInput) {
      // example secondary text: `Los Angeles, CA, USA`, no need for country for now
      inputValue +=
        ', ' +
        option.prediction.structured_formatting.secondary_text.replace(
          ', USA',
          '',
        );
    }
    setInputValue(inputValue);
    geocodeAndUpdateFields(option.prediction);
  };

  const onInputChange = (input: string) => {
    setInputValue(input);
    // TODO: there's a strange bug where when Downshift has a controlled selectedValue,
    // it will call onInputChange twice, but for component it's called with `undefined`
    // the second time.
    if (input !== inputValue && input !== undefined) {
      // Block editing the street without selecting an address from the dropdown
      props.onChange(props.field, { street: input });
      if (props.onInputChange) {
        props.onInputChange(input);
      }
    }
    if (!input) {
      return;
    }
    getPlacePredictions(input);
  };

  const getPlacePredictions = React.useMemo(
    () =>
      debounce((input: string) => {
        if (!autocompleteService) {
          Sentry.captureMessage(
            'GoogleMaps autocomplete service failed to load',
            Sentry.Severity.Warning,
          );
          return setOptions([]);
        }

        const componentRestrictions = { country: ['ca', 'us'] };
        autocompleteService!.getSuggestions(
          input,
          (predictions) => {
            if (!predictions) {
              // TODO: Logging
              return;
            }
            setOptions(
              predictions.map((prediction) => ({
                value: prediction.description,
                key: prediction.place_id,
                prediction,
                Component: Prediction,
              })),
            );
          },
          { componentRestrictions },
        );
      }, 300),
    [autocompleteService],
  );

  const geocodeAndUpdateFields = (
    prediction: google.maps.places.AutocompletePrediction,
  ) => {
    autocompleteService?.geocodeSuggestion(prediction, (result) => {
      if (!result) return;
      const {
        street,
        state,
        city,
        zipCode: zip,
        latitude,
        longitude,
        googleAddressType,
      } = extractAddressFromGeocoderResult(result);

      const formattedZIP = zip.replace(/\s+/g, '').toUpperCase();

      const address: ClientAddress = {
        street,
        state,
        city,
        zip: formattedZIP,
        latitude,
        longitude,
        googleAddressType,
      };
      props.onChange(props.field, address);
    });
  };

  return (
    <FieldAutocomplete
      field={field}
      options={options}
      placeholder={props.placeholder}
      value={props.value}
      disabled={props.disabled}
      onChange={onChange}
      onInputChange={onInputChange}
      inputValue={inputValue}
      hideClearButton={hideClearButton}
      error={error}
      prompt={valid ? 'We’ve located address' : initialPrompt}
      valid={valid}
      inputProps={{
        ...props.inputProps,
        className: props.className || inputProps.className,
        name: 'addressSearch',
      }}
      Menu={Menu}
      icon={<LocationMarker />}
      selectFirstOnBlur={selectFirstOnBlur}
      onClear={onClear}
      testId={testId}
    />
  );
};
