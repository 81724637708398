import { useEffect, useState } from 'react';

import { DateTime } from 'luxon';

import { useLatestRef } from '@clutter/hooks';

import {
  AvailabilitiesQuery,
  AvailabilitiesQueryVariables,
  MovingQuoteFragment,
  OrderServiceTypeEnum,
  OrderSubtypeEnum,
  OrderTypeEnum,
  useAvailabilitiesQuery,
  useLongDistanceAvailabilitiesQuery,
} from '@graphql/platform';

import { normalizeDate } from '@shared/calendar/calendar_utils';

import { clampAvailabilities } from '@utils/availability';

const OFFSET = { days: 1 };
const DEFAULT_INCREMENT = { weeks: 2 };
const INITIAL_OFFSET = { weeks: 4 };

const NO_AVAILABILITY: AvailabilitiesQuery['availabilities'] = [];
const ULTRA_LONG_DISTANCE_CUTOFF = 600;

type BaseAvailabilityParams = {
  zip: string;
  orderType: OrderTypeEnum;
  orderSubtype: OrderSubtypeEnum;
  startDate?: DateTime;
  tillDate?: DateTime;
  skip?: boolean;
  /** Clamp availabilities to the provided dates and read from the cache only.  */
  clamp?: boolean;
};

type StorageAvailabilityParams = {
  expectedPlanID: string | undefined; // Included for better type safety
  serviceType: OrderServiceTypeEnum | undefined; // Included for better type safety
};

type MovingAvailabilityParams = {
  movers?: number;
  movingQuote?: MovingQuoteFragment;
  expectedPlanID?: string;
};

const useAvailabilities = ({
  zip,
  orderType,
  orderSubtype,
  expectedPlanID,
  startDate,
  tillDate,
  skip,
  movers,
  serviceType,
  clamp,
  movingQuote,
}: BaseAvailabilityParams &
  Partial<StorageAvailabilityParams & MovingAvailabilityParams>) => {
  const from =
    startDate ||
    DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const till = tillDate || from.plus(INITIAL_OFFSET);
  const [cursor, setCursor] = useState<DateTime>(till);
  const [fetched, setFetched] = useState<boolean>(false);
  const tillRef = useLatestRef(till);

  const buildInput = (
    inputFrom: DateTime = from,
    inputTill: DateTime = till,
  ): AvailabilitiesQueryVariables['input'] => {
    return {
      from: inputFrom.toISO(),
      till: inputTill.toISO(),
      order: {
        address: {
          zip,
          street: '',
          city: '',
          state: '',
        },
        type: orderType,
        subtype: orderSubtype,
        expectedPlanID,
        movers,
        serviceType,
      },
      movingQuoteSignedID: movingQuote?.signedID,
    };
  };

  const longDistance = !!movingQuote?.longDistanceTransportationRate;
  const ultraLongDistance =
    (movingQuote?.driveDistanceInMiles ?? 0) > ULTRA_LONG_DISTANCE_CUTOFF;

  useEffect(() => {
    // If any input parameter changes that could impact availability, we reset
    // the paged availability to make sure it can be re-fetched. Alternately, we
    // could imperatively re-fetch from till -> cursor but this isn't a common
    // occurance.
    setCursor(tillRef.current);
  }, [
    orderType,
    orderSubtype,
    expectedPlanID,
    movers,
    serviceType,
    tillRef,
    longDistance,
    ultraLongDistance,
  ]);

  const useStandardOrLongDistanceAvailabilitiesQuery = longDistance
    ? useLongDistanceAvailabilitiesQuery
    : useAvailabilitiesQuery;

  const {
    data,
    fetchMore: internalFetchMore,
    loading,
  } = useStandardOrLongDistanceAvailabilitiesQuery({
    variables: { input: buildInput() },
    fetchPolicy: clamp ? 'cache-only' : undefined,
    skip,
    onCompleted: () => {
      setFetched(true);
    },
  });

  const availabilities = data ? data.availabilities : NO_AVAILABILITY;
  const clampedAvailabilities =
    clamp && startDate && tillDate
      ? clampAvailabilities(availabilities, { from: startDate, till: tillDate })
      : availabilities;

  const normalizedDate = normalizeDate(from);
  const resolvedDate =
    normalizedDate.month === from.month
      ? normalizedDate
      : from.plus({ days: 1 - from.day });

  const fetchMore = () => {
    const current = cursor;
    setCursor(current.plus(DEFAULT_INCREMENT));

    if (skip) {
      return;
    }
    internalFetchMore({
      variables: {
        input: buildInput(
          current.plus(OFFSET),
          current.plus(DEFAULT_INCREMENT),
        ),
      },
    });
  };

  return {
    from: resolvedDate,
    till: cursor,
    availabilities: clampedAvailabilities,
    fetchMore,
    loading,
    fetched,
  };
};

export const useMovingAvailabilities = (
  params: BaseAvailabilityParams & MovingAvailabilityParams,
) => useAvailabilities(params);

export const useStorageAvailabilities = (
  params: BaseAvailabilityParams & StorageAvailabilityParams,
) => useAvailabilities(params);
