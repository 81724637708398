import React, { useEffect } from 'react';

import { MovingCheckoutStepProps } from '@root/components/checkout/types';
import { EventSchema } from '@root/resources/wt/types';

import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

import { Quote as WhiteGloveQuote } from './white_glove_quote/quote';

export const MoverCount: React.FC<MovingCheckoutStepProps> = ({ enabled }) => {
  const trackFunnelEvent = useTrackFunnelEvents();

  useEffect(() => {
    if (enabled)
      trackFunnelEvent({
        schema: EventSchema.WWW__MovePriceViewed,
        action: 'display',
        metadata: {},
      });
  }, [enabled, trackFunnelEvent]);

  return <WhiteGloveQuote />;
};
