import React, { type ComponentProps, type FC } from 'react';

import { Dropdown as DropdownComponent } from '@clutter/clean';

import {
  SERVICE_SELECTION_OPTIONS,
  SERVICE_SELECTION_OPTIONS_CHALLENGER,
} from '@shared/service_selector/constants';

import { useDisposalServiceSmokeTest } from '@utils/hooks/ab_testing';

import { useFunnelEntryPointContext } from './use_funnel_entry_point_context';

export const FunnelEntryPointDropdown: FC<
  Partial<ComponentProps<typeof DropdownComponent>>
> = (props) => {
  const { serviceSelection, onServiceChange } = useFunnelEntryPointContext();
  const disposalSmokeTestEnabled = useDisposalServiceSmokeTest();

  return (
    <DropdownComponent
      options={
        disposalSmokeTestEnabled
          ? SERVICE_SELECTION_OPTIONS_CHALLENGER
          : SERVICE_SELECTION_OPTIONS
      }
      placeholder="Select service"
      value={serviceSelection ?? ''}
      onChange={onServiceChange as any}
      {...props}
    />
  );
};
