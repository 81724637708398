import React from 'react';

import styled from '@emotion/styled';

import { COLORS, Text, mq } from '@clutter/clean';

import { ButtonCTA } from '@shared/button_cta';
import { DeferredImage } from '@shared/deferred_image';
import { ServiceSelection } from '@shared/service_selector/constants';

import { useDeferredImage } from '@utils/hooks';

import phoneOutline from '@images/home/phone.svg';

import phoneVideo from '@videos/home/booking_animation.mp4';

interface IProductAppPreview {
  title: React.ReactNode;
  description: React.ReactNode;
  service: ServiceSelection;
}

const Container = styled.div`
  color: ${COLORS.tealDark};

  ${mq({
    textAlign: ['center', null, 'left'],
  })}
`;

const FluidContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1600px;

  ${mq({
    padding: ['64px 24px 32px', null, '60px'],
    flexWrap: ['initial', null, 'wrap'],
    height: ['100%', null, '900px'],
  })}
`;

const PhoneContainer = styled.div`
  ${mq({
    margin: ['0 auto 32px', null, 'auto'],
    width: ['100%', null, '50%'],
    paddingRight: [0, 0, '24px'],
    order: [2, null, 1],
  })}
`;

const Title = styled(Text.Title)`
  max-width: 480px;

  ${mq({
    width: ['100%', null, '50%'],
    margin: ['0 auto 32px', null, 'auto 0 16px;'],
    order: [1, null, 2],
  })}
`;

const TextBody = styled(Text.Body)`
  ${mq({ marginBottom: ['24px', null, '40px'] })}
`;

const CtaContainer = styled.div`
  max-width: 480px;
  order: 3;

  ${mq({ width: ['100%', null, '50%'], margin: ['0 auto', null, '0 0 auto'] })}
`;

const PhoneContainerInner = styled.div`
  max-width: 374px;
  max-height: 762px;
  margin: 0 auto;
  position: relative;
`;

const PhoneVideo = styled.video`
  position: absolute;
  border: 1.5px solid ${COLORS.grayBorder};
  border-radius: 4.75px;
  top: 11.45%;
  left: 5.95%;
  width: 88.64%;
`;

export const ProductAppPreview: React.FC<IProductAppPreview> = ({
  title,
  description,
  service,
}) => {
  const { resolvedImage, targetRef } = useDeferredImage(phoneVideo);

  return (
    <Container ref={targetRef}>
      <FluidContainer>
        <PhoneContainer>
          <PhoneContainerInner>
            <DeferredImage src={phoneOutline} width={374} height={762} />
            <PhoneVideo {...resolvedImage} muted autoPlay loop playsInline />
          </PhoneContainerInner>
        </PhoneContainer>
        <Title size="large">{title}</Title>
        <CtaContainer>
          <TextBody>{description}</TextBody>
          <ButtonCTA
            container="product_app_preview"
            label="Get a Quote"
            entryPoint="service_page_button"
            service={service}
          />
        </CtaContainer>
      </FluidContainer>
    </Container>
  );
};
