import React, { useCallback, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';

import {
  COLORS,
  Text as CleanText,
  FontWeight,
  Input,
  Label,
} from '@clutter/clean';

import { SharedInputProps } from './cds/types/shared_input_props';
import { useNamedOnChange } from './cds/utils/hooks';
import { createTextMaskInputElement } from 'text-mask-core';

const StyledInput = styled(Input)`
  width: 100%;
`;

const Error = styled(CleanText.Caption)`
  height: 28px;
  padding: 8px 0 0;
`;

type FieldTextProps = SharedInputProps<string> &
  Omit<React.ComponentProps<typeof Input>, 'onChange' | 'onBlur'> & {
    mask?: Array<string | RegExp> | ((val: string) => Array<string | RegExp>);
    maskGuide?: boolean;
    disabled?: boolean;
    prompt?: React.ReactNode;
    transformation?(value: string): string;
    uncontrolled?: boolean;
  };

type CallbackRef<T> = (el: T | null) => void;
type MaskTuple = [
  CallbackRef<HTMLInputElement>,
  React.ChangeEventHandler<HTMLInputElement>,
];

const useMask = (
  mask:
    | Array<string | RegExp>
    | ((val: string) => Array<string | RegExp>)
    | undefined,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  guide?: boolean,
): MaskTuple => {
  const inputRef = useRef<any>(null);
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);
  const wrappedOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.update();
    }
    onChange(e);
  };

  useEffect(() => {
    if (inputEl && mask && mask.length) {
      inputRef.current = createTextMaskInputElement({
        inputElement: inputEl,
        mask,
        guide,
      });
    }
  }, [inputEl, mask]);

  const ref = useCallback(
    (node: HTMLInputElement | null) => {
      if (node && mask && mask.length) {
        setInputEl(node);
      } else {
        setInputEl(null);
      }
    },
    [mask],
  );

  return [ref, wrappedOnChange];
};

const FieldText: React.FC<FieldTextProps> = ({
  name,
  onChange,
  onBlur,
  label,
  error,
  className,
  mask,
  maskGuide = true,
  value,
  disabled,
  transformation,
  uncontrolled = false,
  prompt,
  ...inputProps
}) => {
  const handleChange = useNamedOnChange(onChange, transformation);
  const handleBlur = useNamedOnChange(onBlur, transformation);
  const [ref, wrappedOnChange] = useMask(mask, handleChange, maskGuide);
  const valueProps =
    uncontrolled || mask ? { defaultValue: value } : { value: value ?? '' };
  return (
    <div>
      {label && <Label>{label}</Label>}
      <StyledInput
        ref={ref}
        name={name}
        type="text"
        onChange={wrappedOnChange}
        onBlur={handleBlur}
        state={error ? 'error' : undefined}
        disabled={disabled}
        {...valueProps}
        {...inputProps}
      />
      <Error
        color={error ? COLORS.toucan : COLORS.storm}
        weight={FontWeight.Medium}
      >
        {error || prompt}
      </Error>
    </div>
  );
};

export { FieldText, FieldTextProps };
