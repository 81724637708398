import { isPlainObject } from 'lodash-es';

import { DateTime, Duration } from 'luxon';

type ExtendedSafeValue<T> =
  | JSONSafeValue
  | T
  | { [key: string]: ExtendedSafeValue<T> };

type ExtendedSerializable<T> = {
  [key: string]: ExtendedSafeValue<T>;
};

/** An interface for user defined serializers.  */
type CustomSerializer<T> = {
  prefix: string;
  /** Must return a boolean _and_ have an annotated return type of `val is T`.
   * Use of key is discouraged due to potential for invalid serialization if
   * multiple properties with the same key exist.
   */
  test: (val: any, key: string) => val is T;
  toString: (val: T) => string;
  fromString: (str: string) => T;
};

type ExtractSerialized<S> = S extends CustomSerializer<infer T> ? T : never;
type ExtractSerializedTypes<S> =
  S extends ReadonlyArray<CustomSerializer<any>>
    ? ExtractSerialized<S[number]>
    : never;

const setSerializer: CustomSerializer<Set<JSONSafeValue>> = {
  prefix: 'SET',
  test: (val): val is Set<JSONSafeValue> => val instanceof Set,
  toString: (set) => JSON.stringify(Array.from(set)),
  fromString: (str) => new Set(JSON.parse(str)),
};

const luxonDateTimeSerializer: CustomSerializer<DateTime> = {
  prefix: 'LUXON_DATE_TIME',
  test: (val): val is DateTime => val instanceof DateTime,
  toString: (date) => date.toJSON(),
  fromString: (str) => DateTime.fromISO(str),
};

const luxonDurationSerializer: CustomSerializer<Duration> = {
  prefix: 'LUXON_DURATION',
  test: (val): val is Duration => val instanceof Duration,
  toString: (duration) => duration.toISO(),
  fromString: (str) =>
    str === 'PT0S' ? Duration.fromObject({ hours: 0 }) : Duration.fromISO(str),
};

const dateSerializer: CustomSerializer<Date> = {
  prefix: 'DATE',
  test: (val): val is Date => val instanceof Date,
  toString: (date) => date.toISOString(),
  fromString: (str) => new Date(str),
};

const undefinedSerializer: CustomSerializer<undefined> = {
  prefix: 'UNDEFINED',
  test: (val): val is undefined => val === undefined,
  toString: () => 'undefined',
  fromString: () => undefined,
};

export const defaultSerializers = [
  setSerializer,
  luxonDateTimeSerializer,
  luxonDurationSerializer,
  dateSerializer,
  undefinedSerializer,
] as const;

type InstanceSerializable<T> = ExtendedSerializable<ExtractSerializedTypes<T>>;

export type DefaultSerializable = InstanceSerializable<
  typeof defaultSerializers
>;

/** A class for serializing of data beyond what JSON.parse/stringify can handle,
 * with type provided safeguards. By default it supports Sets, Dates, and
 * `undefined` as single property values. Arrays of these types are not
 * supported by default, but can be via individual serializers. */
export class Serializer<
  Serializers extends ReadonlyArray<
    CustomSerializer<any>
  > = typeof defaultSerializers,
> {
  private serializers: Serializers;

  /**
   * @template Serializers optional generic describing the array of custom
   *  serializers provided
   */
  constructor(serializers: Serializers = defaultSerializers as any) {
    this.serializers = serializers;
  }

  /**
   * Serializes data to a string.
   *
   * Errors provided by typescript if `data` is not the serializable are rather
   * cryptic. The most likely error is `Index signature is missing in type
   * '[invalid type here]' (after falling through to the last type in JSONSafeValue)
   *
   * There's a limitation from typescript around implicit index signatures for
   * interfaces that causes types with an interface typed property (with safe
   * members) to be "unassignable". If the interface is internal, it should be
   * converted to a type. If it is external, there is no easy solution. See
   * https://github.com/microsoft/TypeScript/issues/15300 for more details.
   *
   * @param data A type aligned with serializers passed in when instantiating
   * the class.
   */
  public serialize(data: InstanceSerializable<Serializers>) {
    return JSON.stringify(this.recursiveSerialize(data));
  }

  /**
   * Hydrates a string generated by `serialize` back to its original form. This
   * method is not type-safe as there's no way to guarantee that the provided
   * string matches the generic (beyond being valid input to `serialize`).
   *
   * @template T A convenience for casting the return type from `any` to a
   * specific type
   */
  public deserialize<T extends InstanceSerializable<Serializers>>(raw: string) {
    return this.recursiveDeserialize(raw) as T;
  }

  private recursiveTransform<T>(
    transformer: (key: string, value: T) => [string, any],
    data: ExtendedSerializable<ExtractSerializedTypes<Serializers>>,
  ) {
    return Object.entries(data).reduce<ExtendedSerializable<T>>(
      (acc, [key, value]) => {
        if (isPlainObject(value)) {
          acc[key] = this.recursiveTransform(transformer, value as any);
        } else {
          const [newKey, newValue] = transformer(key, value as unknown as T);
          acc[newKey] = newValue;
        }
        return acc;
      },
      {},
    );
  }

  private recursiveSerialize(
    data: ExtendedSerializable<ExtractSerializedTypes<Serializers>>,
  ): {
    [key: string]: JSONSafeValue;
  } {
    return this.recursiveTransform((key, value) => {
      if (
        typeof value === 'string' ||
        typeof value === 'boolean' ||
        typeof value === 'number' ||
        value === null
      ) {
        return [key, value as JSONSafeValue];
      }

      if (isPlainObject(value)) {
        return [key, this.recursiveSerialize(value as any)];
      }

      for (const serializer of this.serializers) {
        if (serializer.test(value, key)) {
          const newKey = this.getPrefixedKey(serializer, key);
          const newValue = serializer.toString(value);
          return [newKey, newValue];
        }
      }

      if (Array.isArray(value)) {
        return [key, value];
      }

      throw new Error('Could not serialize! ' + key);
    }, data);
  }

  private recursiveDeserialize(
    raw: string,
  ): ExtendedSerializable<ExtractSerializedTypes<Serializers>> {
    const obj = JSON.parse(raw);
    return this.recursiveTransform((key, value) => {
      if (isPlainObject(value)) {
        return [key, this.recursiveDeserialize(value as any)];
      }

      for (const serializer of this.serializers) {
        if (this.checkKey(key, serializer)) {
          const newKey = key.replace(this.getPrefixedKey(serializer), '');
          const newValue = serializer.fromString(value as any);
          return [newKey, newValue];
        }
      }
      return [key, value];
    }, obj);
  }

  private getPrefixedKey(serializer: CustomSerializer<unknown>, key = '') {
    return `$$${serializer.prefix}$$_${key}`;
  }

  private checkKey(key: string, serializer: CustomSerializer<any>) {
    return key.startsWith(`$$${serializer.prefix}$$_`);
  }
}
