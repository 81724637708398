import { type ApolloClient } from '@apollo/client';

import { DraftFragment } from '@graphql/platform';

import { CheckoutType } from '@root/components/checkout/types';
import { parseDraftToValues } from '@root/components/checkout/utilities/drafts';
import { InitialValues } from '@root/components/checkout/utilities/initial_values/types';
import {
  restoreMovingSessionCheckoutData,
  restoreStorageSessionCheckoutData,
} from '@root/components/checkout/utilities/persistence';

import { deferPromise } from '@utils/async';

import {
  ReonboardingOptions,
  getReonboardingInitialValues,
} from './initial_values/reonboarding';
import {
  ShareableOptions,
  getShareableInitialValues,
} from './initial_values/shareable';

// prettier-ignore
type ValueInitializerOptions = (
  | { type: CheckoutType.Standard } & { draft?: DraftFragment}
  | ({ type: CheckoutType.Shareable } & ShareableOptions)
  | ({ type: CheckoutType.Agent } & ShareableOptions)
  | ({ type: CheckoutType.Reonboarding } & ReonboardingOptions)
);

// If the user navigates to another page and then navigates back, we want to use
// the persisted session storage values instead of refetching from the API
let hasFetchedReonboardingValues = false;
let hasFetchedShareableValues = false;

const MINIMUM_DELAY = process.env.NODE_ENV === 'test' ? 0 : 2000;

export async function getInitialValues(
  client: ApolloClient<unknown>,
  options: ValueInitializerOptions,
): Promise<InitialValues> {
  let values: InitialValues | undefined;

  if (options.type === CheckoutType.Shareable && !hasFetchedShareableValues) {
    values = await deferPromise(
      getShareableInitialValues(options),
      MINIMUM_DELAY,
    );
    hasFetchedShareableValues = true;
  }

  if (
    options.type === CheckoutType.Reonboarding &&
    !hasFetchedReonboardingValues
  ) {
    values = await deferPromise(
      getReonboardingInitialValues(options),
      MINIMUM_DELAY,
    );
    hasFetchedReonboardingValues = true;
  }

  if (options.type === CheckoutType.Standard && options.draft) {
    const { movingData, storageData } = await parseDraftToValues(
      client,
      options.draft,
    );
    const sharedProperties = {
      checkoutType: CheckoutType.Standard,
    };
    values = {
      movingData: Object.assign(movingData, sharedProperties),
      storageData: Object.assign(storageData, sharedProperties),
    };
  }

  if (values) return values;

  return {
    movingData: restoreMovingSessionCheckoutData().data,
    storageData: restoreStorageSessionCheckoutData().data,
  };
}

export { InitialValues };
