import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  COLORS,
  Modal,
  Text,
  TextButton,
  UnstyledButton,
} from '@clutter/clean';

import { SharedCheckoutData } from '@root/components/checkout/data';
import { SharedCheckoutStepProps } from '@root/components/checkout/types';
import { XButton } from '@root/components/icons/x_button';
import { Errors } from '@root/resources/errors';
import { PHONE_MASK } from '@root/resources/masks';
import { checkoutValidations } from '@root/resources/validations/checkout';
import { validate } from '@root/resources/validator';

import { FieldText } from '@shared/field_text';

export const EditContactModal: React.FC<{
  isOpen: boolean;
  values: Pick<SharedCheckoutData, 'name' | 'phone' | 'email'>;
  onChange: SharedCheckoutStepProps['onChange'];
  onClose(): void;
}> = ({ isOpen, values, onChange, onClose }) => {
  const [leadValues, setLeadValues] = useState(values);
  const [errors, setErrors] = useState(() => new Errors());
  useEffect(() => {
    if (isOpen) {
      setLeadValues(values);
    }
  }, [isOpen, setLeadValues, values]);

  const updateField = <K extends keyof SharedCheckoutData>(
    key: K,
    value: SharedCheckoutData[K],
  ) => {
    const nextValue = { ...leadValues, [key]: value };
    setErrors(
      validate(nextValue, checkoutValidations, ['name', 'phone', 'email']),
    );
    setLeadValues(nextValue);
  };

  return (
    <Modal isOpen={isOpen} handleModalClose={onClose}>
      <Box
        minWidth={[null, '568px', '652px']}
        width={['calc(100vw - 32px)', 'initial']}
        padding="24px"
        position="relative"
      >
        <Box position="absolute" top="28px" right="24px">
          <UnstyledButton onClick={onClose}>
            <XButton />
          </UnstyledButton>
        </Box>
        <Box
          textAlign="center"
          margin="0 -24px 16px"
          padding="0 0 16px"
          borderColor={COLORS.grayBorder}
          borderStyle={'none none solid none'}
        >
          <Text.Title size="extraSmall">Edit Contact</Text.Title>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onChange('name', leadValues.name);
            onChange('phone', leadValues.phone);
            onChange('email', leadValues.email);
            onChange('leadToken', undefined);
            onClose();
          }}
        >
          <FieldText
            label="Full Name"
            name="name"
            autoComplete="name"
            error={errors.messages.name}
            onChange={updateField}
            value={leadValues.name}
          />
          <FieldText
            label="Email"
            name="email"
            autoComplete="email"
            error={errors.messages.email}
            onChange={updateField}
            value={leadValues.email}
          />
          <FieldText
            label="Phone number"
            name="phone"
            type="tel"
            autoComplete="phone"
            error={errors.messages.phone}
            mask={PHONE_MASK}
            onChange={updateField}
            value={leadValues.phone}
          />
          <Button fullWidth type="submit" disabled={errors.any}>
            Save contact details
          </Button>
          <Box textAlign="center" padding="16px 16px 0">
            <TextButton
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              Cancel
            </TextButton>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
