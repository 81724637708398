import React from 'react';

import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import { COLORS, Text, toggleStyleValue } from '@clutter/clean';

import { SpinLoader } from '@shared/spin_loader';

import { CustomLink } from './custom_link';

type ThemeProperty = 'bg' | 'bgHover' | 'outline' | 'color';

type ThemeName = 'default' | 'action' | 'white' | 'secondary' | 'tertiary';

const BUTTON_THEMES: {
  [name in ThemeName]: { [prop in ThemeProperty]: string };
} = {
  default: {
    bg: 'white',
    bgHover: COLORS.grayBackground,
    outline: COLORS.tealDark,
    color: COLORS.tealDark,
  },
  action: {
    bg: COLORS.tealPrimary,
    bgHover: COLORS.__primaryButtonHover,
    outline: COLORS.tealPrimary,
    color: 'white',
  },
  white: {
    bg: COLORS.cloud,
    bgHover: COLORS.grayBackground,
    outline: COLORS.cloud,
    color: COLORS.panther,
  },
  secondary: {
    bg: COLORS.cloud,
    bgHover: COLORS.tealBackground,
    outline: COLORS.tealPrimary,
    color: COLORS.tealPrimary,
  },
  tertiary: {
    bg: COLORS.tealPrimary,
    bgHover: COLORS.__primaryButtonHover,
    outline: COLORS.cloud,
    color: COLORS.cloud,
  },
};

type SizeProperty = 'padding' | 'loadingPadding' | 'minWidth';

type ButtonSize = 'small' | 'medium' | 'large';

const BUTTON_SIZES: {
  [size in ButtonSize]: { [prop in SizeProperty]: string };
} = {
  small: {
    padding: '12px 8px',
    loadingPadding: '12px 26px 12px 8px',
    minWidth: '124px',
  },
  medium: {
    padding: '12px 26px',
    loadingPadding: '12px 36px 12px 16px',
    minWidth: '130px',
  },
  large: {
    padding: '20px 52px',
    loadingPadding: '20px 62px 20px 42px',
    minWidth: '150px',
  },
};

interface IButtonProps {
  themeName?: ThemeName;
  size?: ButtonSize;
  loading?: boolean;
  fullWidth?: boolean;
}

const getValueFromTheme =
  (property: ThemeProperty) =>
  ({ themeName = 'default' }: IButtonProps) => {
    return BUTTON_THEMES[themeName][property];
  };

const getValueForSize = (size: ButtonSize = 'medium', property: SizeProperty) =>
  BUTTON_SIZES[size][property];

const ALLOWED_PROPS: Record<string, boolean> = {
  useATag: true, // Needed for <ButtonLink />
};

const OMITTED_PROPS: Record<string, boolean> = {
  loading: true, // Valid prop for <img /> tags
};

const ButtonComponent = styled('button', {
  shouldForwardProp(prop: string) {
    // By default, emotion forwards all props to non-DOM components and any
    // valid HTML attribute to DOM elements regardless of tag name.
    return (isPropValid(prop) || ALLOWED_PROPS[prop]) && !OMITTED_PROPS[prop];
  },
})<IButtonProps>`
  background: ${getValueFromTheme('bg')};
  border: 1px solid ${getValueFromTheme('outline')};
  border-radius: 4px;
  color: ${getValueFromTheme('color')};
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  outline: none;
  text-align: center;
  transition: 0.1s linear;
  user-select: none;
  position: relative;
  overflow: hidden;
  letter-spacing: normal;
  line-height: 14px;
  width: ${toggleStyleValue('fullWidth', '100%', 'initial')};
  display: ${toggleStyleValue('fullWidth', 'block', 'inline-block')};

  min-width: ${({ size }) => getValueForSize(size, 'minWidth')};
  padding: ${({ size, loading }) =>
    getValueForSize(size, loading ? 'loadingPadding' : 'padding')};

  /* TODO: Make link styling not global */
  && {
    text-decoration: none;
    color: ${getValueFromTheme('color')};
  }

  /* TODO: Allow passing disabled as a prop to ButtonLink so we don’t rely on this class */
  &:disabled,
  &.disabled {
    pointer-events: none;
    box-shadow: none;
    color: ${COLORS.hippo};
    border: none;
    background: ${COLORS.grayBorder};
  }

  &:focus {
    box-shadow: none;
    transform: scale(0.98);
  }

  &:hover {
    background: ${getValueFromTheme('bgHover')};
  }

  & > ${SpinLoader} {
    margin-left: 2px;
    transition: all 0.3s;
    right: ${toggleStyleValue('loading', '8px', '-20px')};
    top: calc(50% - 7px);
  }
`;

const Button: React.FC<
  IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => {
  return (
    <ButtonComponent {...props} style={props.style}>
      <Text.Button>{children}</Text.Button>
      {props.loading !== undefined && <SpinLoader />}
    </ButtonComponent>
  );
};

const ButtonLink = ButtonComponent.withComponent(CustomLink);
const ButtonLabel = ButtonComponent.withComponent('label');

export { Button, ButtonComponent, ButtonLabel, ButtonLink };
