import React from 'react';

import styled from '@emotion/styled';

import { ErrorCodeEnum, ErrorFragment } from '@graphql/platform';

import { ContactPhoneLink } from '@shared/contact_phone_link';

export type ErrorWithoutTypename = Omit<ErrorFragment, '__typename'>;

type ErrorMetadata = {
  /** Indicates that an error can be resolved by completing an MFA flow (e.g. an existing, canceled account) */
  canResolveViaMFA?: boolean;
};

export class GQLError extends Error {
  public fullError: ErrorWithoutTypename;
  public metadata: ErrorMetadata;
  constructor(error: ErrorWithoutTypename, metadata?: ErrorMetadata) {
    super(error.errorMessage);
    this.fullError = error;
    this.metadata = metadata || {};
  }
}

const LOGIN_LINK = 'https://account.clutter.com';

function isErrorFragment(data: any): data is ErrorFragment {
  return data.__typename === 'Error';
}

const Link = styled.a`
  text-decoration: underline;
`;

/**
 * A utility for splitting a union between a custom GQL error and some other
 * type into separate variables, mostly for type safety.
 *
 * @param data The data attribute returned by a GQL query
 */
export function parseGQLErrorUnion<T extends { __typename?: string }>(
  data: ErrorFragment | T | undefined | null,
) {
  const notError =
    data && !isErrorFragment(data)
      ? // TS doesn't narrow T correctly here for some reason
        (data as Exclude<T, ErrorFragment>)
      : undefined;
  const error = data && isErrorFragment(data) ? data : undefined;
  return [notError, error] as const;
}

const CUSTOM_MESSAGES: PartialRecord<
  ErrorCodeEnum,
  React.FC<{ movingSelected?: boolean; goToStep?: (stepName: string) => void }>
> = {
  [ErrorCodeEnum.PendingStorageOnboarding]: () => (
    <>
      Looks like you already have an appointment with Clutter. Please login to{' '}
      <Link href={LOGIN_LINK}>account.clutter.com</Link> to make changes to your
      appointment
    </>
  ),
  [ErrorCodeEnum.ActiveStorageAccount]: () => (
    <>
      Looks like you already store items with Clutter. Please login to{' '}
      <Link href={LOGIN_LINK}>account.clutter.com</Link> to add or remove items.
    </>
  ),
  [ErrorCodeEnum.ExistingCustomer]: () => (
    <>
      You already have an account with Clutter. Please visit your{' '}
      <Link href={LOGIN_LINK}>Clutter Account Portal</Link> to complete booking
      this appointment.
    </>
  ),
  [ErrorCodeEnum.MismatchedCustomer]: () => (
    <>
      A Clutter account for this email address already exists, and has a
      different phone number on it. Please use the original phone number, or try
      a new email. For more assistance, call us at <ContactPhoneLink />.
    </>
  ),
  [ErrorCodeEnum.ExistingPendingReferral]: () => (
    <>
      A pending referral already exists on your account. Please try again
      without the referral code.
    </>
  ),
  [ErrorCodeEnum.ExistingPaidReferral]: () => (
    <>
      Your account has already received a referral credit. Please try again
      without the referral code.
    </>
  ),
  [ErrorCodeEnum.PastNextDayCutoff]: () => (
    <>Next day appointments cannot be booked past 4:30 PM</>
  ),
  [ErrorCodeEnum.UnsafeAddress]: () => (
    <>
      This address has been marked as currently unserviceable. If you think this
      is a mistake, please call us at <ContactPhoneLink />.
    </>
  ),
  [ErrorCodeEnum.BannedAccount]: () => (
    <>
      Your account is disabled and you cannot book appointments. If you think
      this is a mistake, please call us at <ContactPhoneLink />.
    </>
  ),
  [ErrorCodeEnum.SuspendedAccount]: () => (
    <>
      Your account is suspended. Please pay off your remaining balance before
      booking an appointment.
    </>
  ),
  [ErrorCodeEnum.UnitNotAvailable]: () => (
    <>
      Oh no, that unit is no longer available! Scroll up to check out other
      units.
    </>
  ),
  [ErrorCodeEnum.CouponNotEligible]: ({}) => (
    <>That coupon code is not eligible for the service you have selected.</>
  ),
  [ErrorCodeEnum.ZendeskApiError]: ({}) => (
    <>
      An unexpected error occured and your callback was not scheduled. Please
      call us at <ContactPhoneLink />.
    </>
  ),
};

export const FormattedGQLError: React.FC<{
  error: ErrorWithoutTypename;
  movingSelected?: boolean;
  goToStep?: (stepName: string) => void;
}> = ({ error: { errorCode, errorMessage }, movingSelected, goToStep }) => {
  if (errorCode === ErrorCodeEnum.Unknown)
    throw new Error(`Unhandled unknown GQL error: ${errorMessage}`);
  const MessageComponent = CUSTOM_MESSAGES[errorCode];
  return (
    <>
      {MessageComponent ? (
        <MessageComponent goToStep={goToStep} movingSelected={movingSelected} />
      ) : (
        errorMessage
      )}
    </>
  );
};
