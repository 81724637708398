import React, { useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { Box, COLORS, Text } from '@clutter/clean';

import { useMovingCheckoutContext } from '@root/components/checkout/context';
import {
  MovingCheckoutStepProps,
  MovingStepDescriptor,
} from '@root/components/checkout/types';
import { ClientAddress } from '@root/resources/types/address';

import { geocode } from '@utils/geocode';

import {
  AddressField,
  MovingAddressFields,
  sanitizeAddressInput,
} from '../../subcomponents/moving_address_fields';
import { SkipButton } from '../../subcomponents/skip_button';

export const addressCompleted: MovingStepDescriptor['resolveCompleted'] = ({
  values,
  validMoveRoute,
}) =>
  !!(
    sanitizeAddressInput(values.startAddress) &&
    sanitizeAddressInput(values.endAddress) &&
    !!validMoveRoute
  ) || values.skipAddressSelected === true;

const SKIP_LABEL = 'Skip, I’ll add this later';

export const Address: React.FC<MovingCheckoutStepProps> = ({
  onChange,
  values: {
    startAddress,
    endAddress,
    startAddressDetails,
    rooms,
    zip,
    skipAddressSelected,
    skipAddressEligible,
  },
  scrollToStep,
}) => {
  const client = useApolloClient();
  const { createQuote } = useMovingCheckoutContext();

  // Resetting/clearing the input on a Downshift-powered autocomplete
  // input (like the address input) is difficult to do because
  // of how Downshift controls the input. This is a hack to force
  // the whole MovingAddressFields component re-render when addresses
  // are skipped to "clear" the input
  const [key, setKey] = useState(0);

  const didCompleteRoute = (field: AddressField, value: ClientAddress) =>
    (field === 'endAddress' &&
      value.state &&
      value.street &&
      startAddress?.state) ||
    (field === 'startAddress' &&
      value.state &&
      value.street &&
      endAddress?.state);

  const handleAddressChange = async (
    field: AddressField,
    value: ClientAddress,
  ) => {
    onChange(field, value);
    if (field === 'startAddress' && value.zip) {
      onChange('zip', value.zip);
      onChange(
        'skipAddressEligible',
        !(await geocode(client, value.zip))?.geocode?.extendedMovingServiceArea,
      );
    }
    onChange('skipAddressSelected', false);
    if (didCompleteRoute(field, value)) {
      onChange('adjustedMoverCount', undefined, () => createQuote());
      scrollToStep();
    }
    onChange('unpackingScheduled', undefined);

    onChange('moverCountSelected', undefined);
    onChange('movingBundle', undefined);
  };

  const onSkipAddress = () => {
    onChange('moverCountSelected', undefined);
    onChange('movingBundle', undefined);

    onChange('startAddress', { zip });
    onChange('endAddress', { zip });
    onChange('skipAddressSelected', true);
    onChange('longDistance', undefined);
    onChange('adjustedMoverCount', undefined, () => createQuote());
    setKey((oldKey) => oldKey + 1);
    scrollToStep();
  };

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']}>
      <Text.Title color={COLORS.tealDark} size="small">
        Where are you moving?
      </Text.Title>
      <Box margin="4px 0 24px">
        <Text.Body color={COLORS.storm}>
          We’ll pick up your items, help you pack them if you need to and move
          them securely to your new location.
        </Text.Body>
      </Box>
      <Box.Flex flexDirection="column" gap="20px">
        <MovingAddressFields
          key={key}
          startAddress={startAddress}
          endAddress={endAddress}
          startAddressDetails={startAddressDetails}
          rooms={rooms}
          flowOnChange={onChange}
          onChange={handleAddressChange}
        />
      </Box.Flex>
      {zip && skipAddressEligible && (
        <SkipButton
          buttonText={SKIP_LABEL}
          selected={!!skipAddressSelected}
          trackingObjectName="skip_address"
          name="skipAddress"
          value="skipAddress"
          onSkip={onSkipAddress}
        />
      )}
    </Box>
  );
};
