import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CheckoutContextProvider } from '@root/components/checkout/context';
import { CheckoutMovingCart } from '@root/components/checkout/moving_cart';
import { MovingProductPage } from '@root/components/checkout/product_pages/moving_product_page';
import { StorageProductPage } from '@root/components/checkout/product_pages/storage_product_page';
import { SmartStorageCart } from '@root/components/checkout/smart_storage_cart';
import { WWW_ROUTES } from '@root/root/routes';

export const Checkout = () => (
  <CheckoutContextProvider>
    <Switch>
      <Route
        exact
        path={WWW_ROUTES.PRODUCT_PAGE_STORAGE}
        component={StorageProductPage}
      />
      <Route path={WWW_ROUTES.STORAGE_CART} component={SmartStorageCart} />
      <Route
        exact
        path={WWW_ROUTES.PRODUCT_PAGE_MOVING}
        component={MovingProductPage}
      />
      <Route path={WWW_ROUTES.MOVING_CART} component={CheckoutMovingCart} />
    </Switch>
  </CheckoutContextProvider>
);
