/* @jsx jsx */
import * as React from 'react';
import { useState } from 'react';

import { jsx } from '@emotion/react';

import { ApolloProvider } from '@apollo/client';

import { buildPlatformApolloClient } from '@root/initializers/apollo/build_platform_apollo_client';

import { ReviewSave } from '@shared/page_review';

export const Review: React.FC = () => {
  const [client] = useState(buildPlatformApolloClient);
  return (
    <ApolloProvider client={client}>
      <ReviewSave question="Did this answer your question?" />
    </ApolloProvider>
  );
};
