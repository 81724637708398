import * as React from 'react';

import styled from '@emotion/styled';

import { Text } from '@clutter/clean';

import { ButtonComponent, ButtonLink } from '@shared/button';
import { ContactPhoneLink } from '@shared/contact_phone_link';

import { StepContainer } from '../step_container';
import { IGuideStepProps } from '../types/guide_step';

const TITLE =
  'Thanks for reading the guide. We look forward to serving you soon';
const STRESS_COPY =
  'Remember, customers who prepare ahead typically save 2-3 hours of time. Get ready for an enjoyable move!';
const SAVE_COPY =
  'Customers who prepare ahead typically save 2+ hours during their appointment, saving $100 or more on costs. Get ready and you will save!';

const SUB_TITLE = 'Do you have a question?';

const FollowUpWrapper = styled.div`
  margin: 0 auto;
  padding: 24px;
  padding-top: 48px;
`;

const BlockButton = styled(ButtonComponent)`
  display: block;
  margin: 16px 0;
`;

const LinkButton = BlockButton.withComponent(ButtonLink);
const PhoneButton = BlockButton.withComponent(
  ContactPhoneLink,
) as React.ComponentType<
  Omit<React.ComponentProps<typeof BlockButton>, 'children'> &
    React.ComponentProps<typeof ContactPhoneLink>
>;

export const GuideComplete: React.FC<IGuideStepProps> = ({
  guideIncentive,
  currentStep,
  totalSteps,
}) => {
  return (
    <StepContainer
      currentStep={currentStep}
      totalSteps={totalSteps}
      title={TITLE}
      details={guideIncentive === 'stress' ? [STRESS_COPY] : [SAVE_COPY]}
    >
      <FollowUpWrapper>
        <Text.Title size="small">{SUB_TITLE}</Text.Title>
        <LinkButton themeName="secondary" to="/help">
          Help Center
        </LinkButton>
        <PhoneButton themeName="secondary">
          {() => 'Call Customer Care'}
        </PhoneButton>
      </FollowUpWrapper>
    </StepContainer>
  );
};
