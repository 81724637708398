import React from 'react';

import styled from '@emotion/styled';

import { Input } from '@clutter/clean';

import { useClientDataContext } from '@shared/client_data_context';
import { ServiceSelection } from '@shared/service_selector/constants';

import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import { useFunnelEntryPointContext } from './use_funnel_entry_point_context';

export const StyledZipInput = styled(Input)`
  width: 100%;
`;

export const FunnelEntryPointZipInput: React.FC<{
  shortenPlaceholderText?: boolean;
}> = ({ shortenPlaceholderText }) => {
  const { isMobile } = useIsomorphicBreakpoints();
  const {
    data: { zip },
  } = useClientDataContext();
  const { onZipChange, serviceSelection } = useFunnelEntryPointContext();

  const shortenPlaceholder =
    shortenPlaceholderText === undefined ? isMobile : shortenPlaceholderText;

  return (
    <StyledZipInput
      autoComplete="postal-code"
      name="zip"
      placeholder={
        serviceSelection === ServiceSelection.Moving
          ? shortenPlaceholder
            ? 'Starting ZIP'
            : 'Enter your starting ZIP'
          : shortenPlaceholder
            ? 'Enter ZIP'
            : 'Enter your ZIP'
      }
      value={zip ?? ''}
      onChange={onZipChange}
      type="tel"
      aria-label="ZIP"
    />
  );
};
