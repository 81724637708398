import React from 'react';
import { useParams } from 'react-router-dom';

import styled from '@emotion/styled';

import { Box, COLORS, Text } from '@clutter/clean';

import { CallbackWindow } from '@graphql/platform';

import {
  useSharedCheckoutContext,
  useSharedFlowStateContext,
} from '@root/components/checkout/context';
import { ServiceEnum } from '@root/resources/types/service';

import { CallbackWindowSummary } from './callback_window_summary';
import { Form } from './form';

const TitleContainer = styled.div`
  border-bottom: 1px solid ${COLORS.grayBorder};
  padding-bottom: 12px;
  margin-bottom: 12px;
`;

export const Content: React.FC<{
  scheduledWindow?: CallbackWindow;
  onConfirm: (scheduledWindow: CallbackWindow) => void;
}> = ({ scheduledWindow, onConfirm }) => {
  const {
    flowState: {
      values: { email, zip },
    },
  } = useSharedCheckoutContext();

  const { onChange } = useSharedFlowStateContext();

  const { service: serviceParam } = useParams<{
    service: 'storage' | 'moving';
  }>();
  const service =
    serviceParam === 'moving' ? ServiceEnum.Moving : ServiceEnum.DoorToDoor;

  if (scheduledWindow) {
    return (
      <Box padding="16px" textAlign="center">
        <Box>
          <Text.Title size="small">Call back confirmed!</Text.Title>
        </Box>
        <Text.Callout color={COLORS.tealDark}>
          <CallbackWindowSummary callbackWindow={scheduledWindow} />
        </Text.Callout>
      </Box>
    );
  }

  return (
    <Box padding="16px" textAlign="center">
      <TitleContainer>
        <Text.Title size="extraSmall">Schedule a call back</Text.Title>
      </TitleContainer>
      <Form
        service={service}
        email={email}
        zip={zip}
        onLeadCreated={({ phone }) => onChange('phone', phone)}
        onConfirm={onConfirm}
      />
    </Box>
  );
};
