import React, {
  type ChangeEventHandler,
  type FC,
  type FormEvent,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';

import { useApolloClient } from '@apollo/client';

import { WTEventParams } from '@clutter/wt';

import { GeocodeFragment } from '@graphql/platform';

import { useResolveCheckoutURL } from '@root/components/checkout/utilities/checkout_url';
import { track } from '@root/initializers/wt';
import { generateLeadSource } from '@root/resources/lead';

import { Action, useClientDataContext } from '@shared/client_data_context';
import { GeocodeError } from '@shared/geocode_error';
import { ServiceSelection } from '@shared/service_selector/constants';

import { formatZIP } from '@utils/formatters';
import {
  GeocodeStatus,
  geocode,
  parseGeocodeEligibility,
} from '@utils/geocode';
import { getPageName } from '@utils/get_pagename_utilities';
import { useStabilizedFunction } from '@utils/hooks';
import { useDisposalServiceSmokeTest } from '@utils/hooks/ab_testing';
import { useTrackFunnelEntryPointViewed } from '@utils/hooks/funnel_events/use_track_funnel_entry_point_viewed';
import { useMountedRef } from '@utils/hooks/mount';

import { FunnelEntryPointContext } from './funnel_entry_point_context';
import { type FunnelEntryPointContextValue } from './funnel_entry_point_context_value';
import { FunnelEntryPointSmokeTestModal } from './funnel_entry_point_smoke_test_modal';

const eligibleForService = (
  service?: ServiceSelection,
  geocode?: GeocodeFragment,
): boolean => {
  if (!geocode || !service) return false;

  const { hasMoving, hasStorage } = parseGeocodeEligibility(geocode);

  switch (service) {
    case ServiceSelection.Moving:
      return hasMoving;
    case ServiceSelection.Storage:
      return hasStorage;
    default:
      return hasStorage;
  }
};

export const FunnelEntryPoint: FC<{
  children: ReactNode;
  initialService?: ServiceSelection;
  wtConfig: WTEventParams & { container: string };
  onServiceChange?(service: ServiceSelection | undefined): void;
}> = ({
  children,
  initialService,
  wtConfig,
  onServiceChange: propsOnServiceChange,
}) => {
  const {
    data: { zip, serviceSelection: contextServiceSelection },
    dispatch,
  } = useClientDataContext();
  const history = useHistory();
  const resolveCheckoutURL = useResolveCheckoutURL();
  const mountedRef = useMountedRef();
  const [serviceSelection, setServiceSelection] = useState<
    ServiceSelection | undefined
  >(initialService ?? ServiceSelection.Storage);

  useEffect(() => {
    if (!initialService) onServiceChange(contextServiceSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextServiceSelection, initialService]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ReactNode | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);

  const client = useApolloClient();

  useTrackFunnelEntryPointViewed({ service: initialService });

  const disposalSmokeTestEnabled = useDisposalServiceSmokeTest();

  const onSubmit = useStabilizedFunction(
    async (e: FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        track({
          objectType: 'input:button',
          objectName: 'cta',
          label: 'Get Pricing',
          action: 'click',
          value: zip,
          metadata: {
            formData: zip,
            serviceSelection: serviceSelection ?? 'None',
          },
          ...wtConfig,
        });

        if (!serviceSelection) {
          setError('Please select a service from the dropdown.');
          return;
        }

        if (loading) {
          return;
        }
        setLoading(true);

        const result = await geocode(client, zip);

        setLoading(false);

        const shouldEnterCheckout = eligibleForService(
          serviceSelection,
          result.geocode,
        );

        if (
          shouldEnterCheckout &&
          serviceSelection !== ServiceSelection.Disposal
        ) {
          const checkoutURL = await resolveCheckoutURL(
            serviceSelection,
            result.geocode,
          );
          dispatch({
            type: Action.SetupCheckout,
            payload: {
              leadSource: generateLeadSource({
                domain: 'www',
                pageName: getPageName({ prefix: false }),
                entryPoint: wtConfig.container,
              }),
              zip: zip,
              serviceSelection: serviceSelection,
            },
          });

          history.push(checkoutURL);
        } else if (
          disposalSmokeTestEnabled &&
          shouldEnterCheckout &&
          serviceSelection === ServiceSelection.Disposal
        ) {
          setShowModal(true);
        } else {
          setError(
            <GeocodeError
              type={
                // If this status is valid but we're still in this branch, it
                // means we service the ZIP, but not the for desired service and
                // should show the a service specific OSA message.
                result.status === GeocodeStatus.Valid
                  ? GeocodeStatus.OSA
                  : result.status
              }
              service={serviceSelection}
            />,
          );
        }
      } finally {
        if (mountedRef.current) setLoading(false);
      }
    },
  );

  const onZipChange: ChangeEventHandler<HTMLInputElement> =
    useStabilizedFunction((event) => {
      const value = formatZIP(event.currentTarget.value);

      track({
        action: 'input',
        label: 'zip',
        value,
        objectType: 'input:text',
        objectName: 'zip',
        ...wtConfig,
      });

      dispatch({ type: Action.SetZip, payload: { zip: value } });
    });

  const onServiceChange = useStabilizedFunction(
    (service: ServiceSelection | undefined) => {
      setError(undefined);
      if (service) {
        dispatch({ type: Action.SetService, payload: { service } });
        setServiceSelection(service);
      }
      propsOnServiceChange?.(service);
    },
  );

  const value: FunnelEntryPointContextValue = useMemo(
    () => ({
      serviceSelection,
      onServiceChange,
      onSubmit,
      loading,
      onZipChange,
      errorMessage: error,
    }),
    [serviceSelection, onSubmit, error, loading, onZipChange, onServiceChange],
  );

  return (
    <FunnelEntryPointContext.Provider value={value}>
      {children}
      <FunnelEntryPointSmokeTestModal
        zip={zip}
        isOpen={showModal}
        handleModalClose={() => setShowModal(false)}
      />
    </FunnelEntryPointContext.Provider>
  );
};
