import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';

import { ExternalContent, mq } from '@clutter/clean';

import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from '@shared/header/header';

const Content = styled(ExternalContent)`
  p {
    ${mq({
      scrollMarginTop: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT],
    })}
  }
`;

export const PrivacyPolicy = () => (
  <Content>
    <p>
      <strong>Clutter Privacy Policy</strong>
    </p>
    <p>
      <strong>Last Updated:</strong> August 31, 2023
    </p>
    <p>
      Clutter Inc. (&ldquo;<strong>Clutter</strong>,&rdquo; &ldquo;
      <strong>we</strong>,&rdquo; or &ldquo;<strong>us</strong>&rdquo;) is
      committed to protecting your privacy. Our Privacy Policy explains how we
      collect, use, disclose, and share your information, and provides you with
      choices about your information. This Privacy Policy applies to our
      websites, including <Link to="/">www.clutter.com</Link>, and any other
      website, product, or service that links to this Privacy Policy
      (collectively, our &ldquo;
      <strong>Services</strong>&rdquo;). Note this Privacy Policy does not
      address our privacy practices relating to Clutter&rsquo;s employees and
      other personnel.
    </p>
    <p>
      We encourage you to review our <Link to="/legal/tos">Terms of Use</Link>{' '}
      because they contain important information about your use of our Services.
    </p>
    <ul>
      <li>
        <Link to="#information-collection">
          HOW WE COLLECT AND USE INFORMATION
        </Link>
      </li>
      <li>
        <Link to="#cookies">COOKIES AND OTHER TRACKING TECHNOLOGIES</Link>
      </li>
      <li>
        <Link to="#information-use">HOW WE USE YOUR INFORMATION</Link>
      </li>
      <li>
        <Link to="#information-sharing">HOW WE SHARE YOUR INFORMATION</Link>
      </li>
      <li>
        <Link to="#online-advertising">
          ONLINE ADVERTISING AND THIRD-PARTY TRACKING
        </Link>
      </li>
      <li>
        <Link to="#information-choices">
          YOUR CHOICES ABOUT YOUR INFORMATION
        </Link>
      </li>
      <li>
        <Link to="#information-protection">
          HOW WE STORE AND PROTECT YOUR INFORMATION
        </Link>
      </li>
      <li>
        <Link to="#children-privacy">CHILDREN&rsquo;S PRIVACY</Link>
      </li>
      <li>
        <Link to="#third-party-links">
          LINKS TO OTHER WEB SITES AND SERVICES
        </Link>
      </li>
      <li>
        <Link to="#contact-us">HOW TO CONTACT US</Link>
      </li>
      <li>
        <Link to="#privacy-policy-changes">CHANGES TO OUR PRIVACY POLICY</Link>
      </li>
      <li>
        <Link to="#region-disclosures">REGION-SPECIFIC DISCLOSURES</Link>
        <ul>
          <li>
            <Link to="#region-disclosures-nevada">NEVADA</Link>
          </li>
          <li>
            <Link to="#region-disclosures-california">CALIFORNIA</Link>
          </li>
        </ul>
      </li>
    </ul>
    <p id="information-collection">
      <strong>1. HOW WE COLLECT AND USE INFORMATION</strong>
    </p>
    <p>
      Capitalized terms that are not defined in this Privacy Policy have the
      meaning given them in our <Link to="/legal/tos">Terms of Use</Link>.
    </p>
    <p>
      <strong>Information you provide to us directly</strong>: We may collect
      personal information such as your first and last name, phone number,
      e-mail address, physical address, and payment information when you use or
      register for our Services. For example, we may collect your name, email,
      phone number, address, and payment information when you create an account
      or subscribe to a storage plan. We may collect your name, email, and phone
      number when you correspond with us or request a quote. We may also collect
      your contact information when you contact customer service, or otherwise
      communicate with us for account verification or other service-related
      matters.
    </p>
    <p>
      <strong>
        Information we may receive from third party sites that connect to our
        Services
      </strong>
      : We may receive information about you from third parties and combine that
      with information we collect through our Services. For example, when you
      interact with us through a social media site or third-party service, such
      as when you like, follow, comment, add a review, provide referrals through
      our &ldquo;Invite friends&rdquo; feature, or share Clutter content on
      Facebook, Twitter, Yelp, or other sites, we may receive information from
      the social network, including your profile information, picture, user ID
      associated with your social media account, and any other information you
      permit the social network to share with third parties. Subject to any
      privacy settings, any content that you make public is searchable by other
      individuals. If you remove information that you posted to any public
      portions of the Services, copies may remain viewable in cached and
      archived pages of the Services, or if other individuals have copied or
      saved that information. The data we receive from these third-party sites
      is dependent upon that third party&rsquo;s policies and your privacy
      settings on that third-party site. You should always review and, if
      necessary, adjust your privacy settings on third-party websites and
      services before linking or connecting them to our Services.
    </p>
    <p>
      <strong>Information from Other Sources:</strong> We may receive
      information about you from third parties and combine this information with
      other information we collect from or about you. For example, we may
      receive information about you to verify identity or payment information,
      update our records, or better provide the Services available to you. We
      also collect information about you that is publicly available. We may also
      receive your information from third parties such as distribution partners,
      data services, marketing firms, and analytics partners.
    </p>
    <p>
      <strong>Address Book Information:</strong> Through our &ldquo;Invite
      friends&rdquo; referral feature, you may also directly choose a friend to
      invite to use our Services through your mobile device&rsquo;s native app
      or email. With your permission, we may access your contact list available
      on your mobile device so that you can invite your friends to join our
      Services. When you send an invitation to your friends to join the
      Services, you will send an invitation through your mobile device&rsquo;s
      native text messaging application, so your friends will know you are the
      person extending the invitation. We will not store or collect the
      information contained in your Address Book, and we will not use that
      information for any purpose other than to send invitations to your
      contacts at your request.
    </p>
    <p>
      <strong>Job Application Information: </strong>Through our recruitment
      process, we may collect your full name, email address, phone number,
      resume, cover letter, immigration status, state/province/territory of
      residence, gender, race, and any additional information you choose to
      provide to us. We may also collect background check information including
      professional and educational history, criminal history that may be
      relevant for a position with our team, in connection with your job
      application when permitted by applicable law.&nbsp;
    </p>
    <p id="cookies">
      <strong>2. COOKIES AND OTHER TRACKING TECHNOLOGIES</strong>
    </p>
    <p>
      We, and our third-party partners, automatically collect certain types of
      usage information when you use our Services, read our emails, or otherwise
      engage with us. We typically collect this information through a variety of
      tracking technologies, including cookies, web beacons, embedded scripts,
      location-identifying technologies, file information, and similar
      technology (collectively, &ldquo;<strong>tracking technologies</strong>
      &rdquo;).
    </p>
    <p>
      We, and our third-party partners, use tracking technologies to
      automatically collect usage and device information, such as:
    </p>
    <ul>
      <li>
        Information about your device and its software, such as your IP address,
        browser type, Internet service provider, device type/model/manufacturer,
        operating system, date and time stamp, and a unique ID that allows us to
        uniquely identify your browser, mobile device, or your account
        (including, for example, a persistent device identifier or an Ad ID),
        and other such information. We may also work with third-party partners
        to employ technologies, including the application of statistical
        modeling tools, which permit us to recognize and contact you across
        multiple devices.
      </li>
      <li>
        Information about the way you access and use our Services, for example,
        the site from which you came and the site to which you are going when
        you leave our Services, the pages you visit, the links you click,
        whether you open emails or click the links contained in emails, whether
        you access the Services from multiple devices, whether you accepted a
        contact, and other actions you take on the Services.
      </li>
      <li>
        Information about your location, including GPS coordinates (e.g.,
        latitude and/or longitude) or similar information regarding the location
        of your mobile device, or we may be able to approximate or infer a
        device&rsquo;s location by analyzing other information, like an IP
        address. We also use the Google Maps API to gather information about
        your location. Google uses various technologies to determine your
        location, including IP address, GPS, and other sensors that may, for
        example, provide Google with information on nearby devices, Wi-Fi access
        points, and cell towers (see Google Maps{' '}
        <a
          href="https://policies.google.com/privacy?hl=en"
          target="_blank"
          rel="noopener"
        >
          privacy policy
        </a>{' '}
        to learn more).
      </li>
      <li>
        We may collect analytics data or use third-party analytics tools such as
        Google Analytics to help us measure traffic and usage trends for the
        Services and to understand more about the demographics of our users. You
        can learn more about Google&rsquo;s practices at{' '}
        <a
          href="http://www.google.com/policies/privacy/partners"
          target="_blank"
          rel="noopener"
        >
          http://www.google.com/policies/privacy/partners
        </a>{' '}
        and view its currently available opt-out options at{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        . We may also work with third-party partners to employ technologies,
        including the application of statistical modeling tools, which permit us
        to recognize and contact you across multiple devices.
      </li>
    </ul>
    <p>
      <strong>Cookies Opt-Out</strong>. If you would prefer not to accept
      cookies, most browsers will allow you to: (i)&nbsp;change your browser
      settings to notify you when you receive a cookie, which lets you choose
      whether to accept it; (ii)&nbsp;disable existing cookies; or
      (iii)&nbsp;set your browser to automatically reject cookies. Please note
      that doing so may negatively impact your experience using the Services, as
      some features on our Services may not work properly. Depending on your
      mobile device and operating system, you may not be able to delete or block
      all cookies. You may also set your email options to prevent the automatic
      downloading of images that may contain technologies that would allow us to
      know whether you have accessed our email and performed certain functions
      with it.
    </p>
    <p>
      We and our third-party partners may also use cookies and tracking
      technologies for advertising purposes. For more information about tracking
      technologies, please see{' '}
      <Link to="#online-advertising">
        <strong>Online Advertising and Third-Party Tracking</strong>
      </Link>{' '}
      below.
    </p>
    <p id="information-use">
      <strong>3. HOW WE USE YOUR INFORMATION</strong>
    </p>
    <p>We may use your information for the following purposes:</p>
    <ul>
      <li>
        To operate, maintain, improve, and provide to you the features and
        functionality of the Services. This includes processing payment,
        tracking orders, implement new features and enhancements, or otherwise
        provide the Services.
      </li>
      <li>
        To communicate directly with you, such as to send you Services-related
        emails, SMS text messages, or calls (e.g., account verification, updates
        to features of the Services, technical and security notices, pick up
        details). We may also send you promotional messages from us and from our
        partners, including email messages, or, with your consent, SMS text
        messages or calls. You may change your communications preferences at any
        time, see the{' '}
        <Link to="/legal/privacy_policy#information-choices">
          <strong>Your Choices About Your Information</strong>
        </Link>{' '}
        section below. Please note that you are not able to opt-out of receiving
        Services-related messages from us, including security and legal notices.
      </li>
      <li>
        To remember your information so you will not have to re-enter it during
        a subsequent visit; help you efficiently access your information after
        you sign in; provide, improve, test, update and monitor the Services; or
        diagnose or fix technology problems.
      </li>
      <li>
        To identify potential customers who may be interested in our Services
        for the purposes of targeting relevant marketing to such persons.
      </li>
      <li>
        To enable you to participate in interactive features of the Services
        (such as engaging in surveys, reviews, or promotions).
      </li>
      <li>
        To create aggregated or otherwise anonymized data that does not
        reasonably identify you directly as an individual.
      </li>
      <li>
        To perform statistical, demographic, or marketing analyses, for any
        purpose.
      </li>
      <li>
        To comply and fulfill our legal duties and obligations such as tax and
        accounting.
      </li>
      <li>
        To respond to your emails, comments, questions or requests for customer
        service.
      </li>
      <li>To ensure the security of our Services and preventing abuses.</li>
      <li>
        To ensure the security of our IT environments and protection of data.
      </li>
      <li>
        For any other purpose we may describe when you provide the information.
      </li>
    </ul>
    <p>
      Please note that we may at times receive or process personal data to
      create deidentified data that can no longer reasonably be used to infer
      information about, or otherwise be linked to, a particular individual or
      household. Where we maintain deidentified data, we will maintain and use
      the data in deidentified form and not attempt to reidentify the data
      except as required or permitted by law.&nbsp;
    </p>
    <p id="information-sharing">
      <strong>4. HOW WE SHARE YOUR INFORMATION</strong>
    </p>
    <p>
      We may share your personal information in the instances described below.
      For further information on your choices regarding your information, see
      the{' '}
      <Link to="/legal/privacy_policy#information-choices">
        <strong>Your Choices About Your Information</strong>
      </Link>{' '}
      section below. We may share your personal information with:
    </p>
    <ul>
      <li>
        Other companies and brands owned or controlled by Clutter, and other
        companies owned by or under common ownership as Clutter, which also
        includes our subsidiaries (i.e., any organization we own or control) or
        our ultimate parent entity (i.e., any organization that owns or controls
        us) and any subsidiaries it owns. These companies will use your personal
        information in the same way as we can under this Privacy Policy.
      </li>
      <li>
        Third-party vendors and other service providers that perform the
        Services on our behalf, as needed to carry out their work for us, which
        may include identifying and serving targeted advertisements, providing
        mailing services, providing tax and accounting services, contest
        fulfillment, web hosting, or providing analytic services.
      </li>
      <li>
        Business partners with whom we do business, including to facilitate your
        purchase of a product or service, or for their own marketing purposes in
        compliance with applicable laws and subject to their separate privacy
        notices.
      </li>
      <li>
        The public when you provide feedback on our Services. For example, if
        you post user content or comment on our social media sites, your
        information, such as your first name, last initial, state of residence,
        and your comments, may be displayed on our Services or on our social
        media pages. Please do not provide personal information you would not
        want to be public.&nbsp;
      </li>
      <li>
        Other parties in connection with a company transaction, such as a
        merger, sale of company assets or shares, reorganization, financing,
        change of control or acquisition of all or a portion of our business by
        another company or third party, or in the event of a bankruptcy or
        related or similar proceedings
      </li>
      <li>
        Third parties as required by law or subpoena or if we reasonably believe
        that such action is necessary to (a)&nbsp;comply with the law and the
        reasonable requests of law enforcement; (b)&nbsp;to enforce our{' '}
        <Link to="/legal/tos">Terms of Use</Link> or to protect the security or
        integrity of our Services; and/or (c)&nbsp;to exercise or protect the
        rights, property, or personal safety of Clutter, our visitors, or
        others.
      </li>
      <li>
        Others in an aggregated or otherwise anonymized form that does not
        reasonably identify you directly as an individual.
      </li>
    </ul>
    <p id="online-advertising">
      <strong>5. ONLINE ADVERTISING AND THIRD-PARTY TRACKING</strong>
    </p>
    <p>
      <strong>Interest-Based Advertising</strong>. We participate in
      interest-based advertising and use third-party advertising companies to
      serve you targeted advertisements based on your browsing history. We may
      share, or we may permit third-party online advertising networks, social
      media companies, and other third-party service to collect information
      about your use of our Services over time so that they may play or display
      ads on our Services, on other devices you may use, and on other websites,
      apps, or service, including on Facebook. Typically, though not always, the
      information we share is provided through cookies or similar tracking
      technologies, which recognize the device you are using and collect
      information, including click stream information, browser type, time and
      date you visited the site, and other information. We and our third-party
      partners use this information to make the advertisements you see online
      more relevant to your interests, as well as to provide advertising-related
      service such as reporting, attribution, analytics, and market research.
    </p>
    <p>
      <strong>Social Media Advertising</strong>. We display targeted advertising
      to you through social media platforms, such as Facebook, Instagram,
      Twitter, and others. These companies have interest-based advertising
      programs that allow us to direct advertisements to users who have shown
      interest in our Services while those users are on the social media
      platform or to groups of other users who share similar traits, such as
      likely commercial interests and demographics. These advertisements are
      governed by the privacy policies of those social media companies that
      provide them.
    </p>
    <p>
      <strong>Cross-Device Linking</strong>. We, or our third-party partners,
      may link your various devices so that content you see on one device can
      result in relevant advertising on another device. We do this by collecting
      information about each device you use when you are logged in to our
      Services. We may also work with third-party partners who employ tracking
      technologies or the application of statistical modeling tools to determine
      if two or more devices are linked to a single user or household. We may
      share a common account identifier (such as an email address or user ID)
      with third-party advertising partners to help recognize you across
      devices. We, and our partners, can use this cross-device linkage to serve
      interest-based advertising and other personalized content to you across
      your devices, to perform analytics, and to measure the performance of our
      advertising campaigns.
    </p>
    <p>
      <strong>Your choices:</strong>
    </p>
    <ul>
      <li>
        <strong>Cookies</strong>. As noted above, most browsers allow you to
        adjust your browser settings to: (i)&nbsp;notify you when you receive a
        cookie, which lets you choose whether to accept it; (ii)&nbsp;disable
        existing cookies; or (iii)&nbsp;set your browser to automatically reject
        cookies.
      </li>
    </ul>
    <p>
      Blocking or deleting cookies may negatively impact your experience using
      the Services, as some features and services on our Services may not work
      properly.
    </p>
    <ul>
      <li>
        <strong>Interest-based advertising</strong>. To learn about
        interest-based advertising and how you may be able to opt-out of some of
        this advertising, you may wish to visit the Network Advertising
        Initiative&rsquo;s online resources at{' '}
        <a
          href="http://www.networkadvertising.org/choices"
          target="_blank"
          rel="noopener"
        >
          http://www.networkadvertising.org/choices
        </a>{' '}
        and/or the DAA&rsquo;s resources at{' '}
        <a
          href="http://www.aboutads.info/choices"
          target="_blank"
          rel="noopener"
        >
          www.aboutads.info/choices
        </a>
        .
      </li>
      <li>
        <strong>Cross-device linking</strong>. Please note that opting-out of
        receiving interest-based advertising through the NAI&rsquo;s and
        DAA&rsquo;s online resources will only opt-out a user from receiving
        interest-based ads on that specific browser or device, but the user may
        still receive interest-based ads on his or her other devices. You must
        perform the opt-out on each browser or device you use.
      </li>
      <li>
        <strong>Mobile advertising</strong>. You may also be able to limit
        interest-based advertising through the settings on your mobile device by
        selecting &ldquo;limit ad tracking&rdquo; (iOS) or &ldquo;opt-out of
        interest-based ads&rdquo; (Android). You may also be able to opt-out of
        some&mdash;but not all&mdash;interest-based ads served by mobile ad
        networks by visiting{' '}
        <a
          href="http://youradchoices.com/appchoices"
          target="_blank"
          rel="noopener"
        >
          http://youradchoices.com/appchoices
        </a>{' '}
        and downloading the mobile AppChoices app.
      </li>
    </ul>
    <p>
      Some of these opt-outs may not be effective unless your browser is set to
      accept cookies. If you delete cookies, change your browser settings,
      switch browsers or computers, or use another operating system, you will
      need to opt-out again.
    </p>
    <p>
      <strong>Google Analytics and Advertising</strong>. We use Google Analytics
      to recognize you and link the devices you use when you visit our Services
      on your browser or mobile device, log in to your account on our Services,
      or otherwise engage with us. We share a unique identifier, like a user ID
      or hashed email address, with Google to facilitate the Services. Google
      Analytics allows us to better understand how our users interact with our
      Services and to tailor our advertisements and content to you. For
      information on how Google Analytics collects and processes data, as well
      as how you can control information sent to Google, review Google&rsquo;s
      site &ldquo;How Google uses data when you use our partners&rsquo; sites or
      apps&rdquo; located at{' '}
      <a
        href="http://www.google.com/policies/privacy/partners"
        target="_blank"
        rel="noopener"
      >
        www.google.com/policies/privacy/partners
      </a>
      . You can learn about Google Analytics&rsquo; currently available
      opt-outs, including the Google Analytics Browser Ad-On here{' '}
      <a
        href="https://tools.google.com/dlpage/gaoptout/"
        target="_blank"
        rel="noopener"
      >
        https://tools.google.com/dlpage/gaoptout
      </a>
      .
    </p>
    <p>
      We may also utilize certain forms of display advertising and other
      advanced features through Google Analytics, such as Remarketing with
      Google Analytics, Google Display Network Impression Reporting, the
      DoubleClick Campaign Manager Integration, and Google Analytics
      Demographics and Interest Reporting. These features enable us to use
      first-party cookies (such as the Google Analytics cookie) and third-party
      cookies (such as the DoubleClick advertising cookie) or other third-party
      cookies together to inform, optimize, and display ads based on your past
      visits to the Services. You may control your advertising preferences or
      opt-out of certain Google advertising products by visiting the Google Ads
      Preferences Manager, currently available at{' '}
      <a
        href="https://google.com/ads/preferences"
        target="_blank"
        rel="noopener"
      >
        https://google.com/ads/preferences
      </a>
      , or by visiting NAI&rsquo;s online resources at{' '}
      <a
        href="http://www.networkadvertising.org/choices"
        target="_blank"
        rel="noopener"
      >
        http://www.networkadvertising.org/choices
      </a>
      .
    </p>
    <p id="information-choices">
      <strong>6. YOUR CHOICES ABOUT YOUR INFORMATION</strong>
    </p>
    <p>
      <strong>You Control Your Account Information and Settings</strong>: You
      may update your profile information, such as your name and phone number,
      by editing information on your user profile screen.
    </p>
    <p>
      <strong>How to Control Your Email Communications Preferences</strong>. You
      can stop receiving promotional email communications from us by clicking on
      the &ldquo;unsubscribe link&rdquo; provided in such communications. We
      make every effort to promptly process all unsubscribe requests. As noted
      above, you may not opt out of Services-related communications (e.g.,
      account verification, purchase and billing confirmations and reminders,
      changes/updates to features of the Services, technical and security
      notices).
    </p>
    <p>
      <strong>How to Control Calls or SMS Text Messages Preferences</strong>.
      You may opt-out of receiving promotional calls or SMS text messages at any
      time. Please note that unsubscribing from promotional calls and SMS text
      messages will not prevent you from receiving calls or SMS texts from
      Clutter directly relating to your use of the Services, such as account
      information, item pick-up details and delivery information that are
      necessary to provide the requested Services to you. To unsubscribe from
      promotional calls or SMS texts, email{' '}
      <a href="mailto:optout@clutter.com" target="_blank" rel="noopener">
        optout@clutter.com
      </a>
      . For more information, see our <Link to="/legal/tos">Terms of Use</Link>.
    </p>
    <p>
      <strong>Modifying or Deleting Your Information</strong>. If you have any
      questions about reviewing, modifying, or deleting your information, or if
      you want to remove your name or comments from our Services or publicly
      displayed content, you can contact us directly at{' '}
      <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
        privacy@clutter.com
      </a>
      . We may not be able to modify or delete your information in all
      circumstances.
    </p>
    <p id="information-protection">
      <strong>7. HOW WE STORE AND PROTECT YOUR INFORMATION</strong>
    </p>
    <p>
      <strong>Storage and Processing</strong>: Your information collected
      through the use of the Services may be stored and processed in the United
      States or any other country in which Clutter or its subsidiaries,
      affiliates or service providers maintain facilities. Clutter may transfer
      information that we collect about you, including personal information, to
      affiliated entities, or to other third parties across borders and from
      your country or jurisdiction to other countries or jurisdictions around
      the world. We may retain your information as long as appropriate for
      business purposes (e.g., as long as you use our Services for the purposes
      explained in this Privacy Policy), unless we are required by law or
      regulation or for litigation and regulatory investigations to keep it for
      longer periods of time. To determine the appropriate duration of the
      retention of personal information, we consider the amount, nature and
      sensitivity of the personal information, the potential risk of harm from
      unauthorized use or disclosure of personal information and if we can
      attain our objectives by other means, as well as our legal, regulatory,
      tax, accounting, and other applicable obligations.
    </p>
    <p>
      <strong>Keeping your information safe</strong>: Clutter cares about the
      security of your information and uses safeguards to preserve the integrity
      and security of information collected through the use of the Services.
      Despite the actions and precautions we take, no data transmissions over
      the Internet can be guaranteed to be 100% secure. Consequently, we cannot
      ensure or warrant the security of the information and you acknowledge and
      agree that you transmit it to us at your own risk. Clutter is not
      responsible for the functionality or security measures of any third party.
    </p>
    <p>
      <strong>Compromise of information</strong>: In the event that any
      information under our control is compromised as a result of a breach of
      security, Clutter will take steps to investigate the situation and where
      appropriate, notify those individuals whose information may have been
      compromised and take other steps, in accordance with any applicable laws
      and regulations.
    </p>
    <p id="children-privacy">
      <strong>8. CHILDREN&rsquo;S PRIVACY</strong>
    </p>
    <p>
      Clutter does not knowingly collect or solicit any information from anyone
      under the age of 13 or knowingly allow such persons to register as users.
      Our Services and its content are not directed at children under the age of
      13. In the event that we learn that we have inadvertently collected
      personal information from a child under age&nbsp;13, we will delete that
      information. If you believe that we might have any information from or
      about a child under 13, please contact us at{' '}
      <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
        privacy@clutter.com
      </a>
      .
    </p>
    <p id="third-party-links">
      <strong>9. LINKS TO OTHER WEB SITES AND SERVICES</strong>
    </p>
    <p>
      The Services may contain links to and from third-party websites of our
      business partners, advertisers, and social media sites and our users may
      post links to third-party websites. If you follow a link to any of these
      websites, please note that these websites have their own privacy policies
      and that we do not accept any responsibility or liability for their
      policies. We strongly recommend that you read their privacy policies and
      terms and conditions of use to understand how they collect, use, and share
      information. We are not responsible for the privacy practices or the
      content on the websites of third-party sites.
    </p>
    <p id="contact-us">
      <strong>10. HOW TO CONTACT US</strong>
    </p>
    <p>
      If you have any questions about this Privacy Policy or the Services,
      please contact us at{' '}
      <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
        privacy@clutter.com
      </a>
      .
    </p>
    <p id="privacy-policy-changes">
      <strong>11. CHANGES TO OUR PRIVACY POLICY</strong>
    </p>
    <p>
      Clutter may modify or update this Privacy Policy from time to time to
      reflect the changes in our business and practices, and so you should
      review this page periodically. We will notify you about material changes
      in the way we treat personal information by sending a notice to the
      primary email address specified in your account, by placing a prominent
      notice on our website(s), or through other appropriate communication
      channels. It is your responsibility to review this Privacy Policy
      periodically. When we do change the Privacy Policy, we will also revise
      the &ldquo;Last Updated&rdquo; date at the top of this Privacy Policy. If
      you disagree with the revised Privacy Policy, you may cancel your account.
      If you do not cancel your account before the date the revised Privacy
      Policy becomes effective, your continued access to or use of the Services
      will be subject to the revised Privacy Policy.
    </p>
    <p id="region-disclosures">
      <strong>12. REGION-SPECIFIC DISCLOSURES</strong>
    </p>
    <p id="region-disclosures-nevada">
      <strong>Nevada:</strong> If you are a resident of the State of Nevada,
      Chapter 603A of the Nevada Revised Statutes permits a Nevada resident to
      opt out of future sales of certain covered information that a website
      operator has collected or will collect about the resident. To submit such
      a request, please contact us at{' '}
      <a href="mailto:privacy@clutter.com" target="_blank" rel="noopener">
        privacy@clutter.com
      </a>
      .
    </p>
    <p id="region-disclosures-california">
      <strong>California:</strong> If you are a resident of the State of
      California, please click{' '}
      <Link to="/legal/california_privacy_rights">here</Link> for additional
      California-specific disclosures.
    </p>
  </Content>
);
