import * as React from 'react';

import styled from '@emotion/styled';

import { BigText } from '@root/components/www/shared/text';

import { ContactPhoneLink } from '@shared/contact_phone_link';

import { reportError } from '@utils/monitoring';

import * as FullStory from '@fullstory/browser';

export interface IErrorComponentProps {
  error?: Error;
  onClearError?(): void;
}

export const ErrorContainer = styled.div`
  text-align: center;
  max-width: 800px;
  margin: auto;
  margin-top: 20vh;
  padding: 16px;
`;

interface IErrorBoundaryProps {
  children: React.ReactNode;
  errorComponent?: React.ComponentType<IErrorComponentProps>;
  renderError?(e: Error, onClearError: () => void): React.ReactNode;
}

interface IErrorBoundaryState {
  error?: Error;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public state: IErrorBoundaryState = { error: undefined };

  public componentDidCatch(error: Error) {
    this.setState({ error });

    reportError(error, { tags: { caughtReactError: 'true' } });

    if (FullStory.isInitialized()) {
      FullStory.event('uh_oh', {});
    }
  }

  public render() {
    const { error } = this.state;
    const {
      renderError,
      errorComponent: ErrorComponent,
      children,
    } = this.props;

    if (error) {
      if (ErrorComponent) {
        return (
          <ErrorComponent error={error} onClearError={this.onClearError} />
        );
      } else if (renderError) {
        return renderError(error, this.onClearError);
      } else {
        return (
          <ErrorContainer>
            <BigText>Uhoh!</BigText>
            <br />
            We’re so sorry, but something went wrong! Please reach out to us at{' '}
            <ContactPhoneLink /> and we’ll help you out right away!
          </ErrorContainer>
        );
      }
    }

    return children;
  }

  private onClearError = () => {
    this.setState({ error: undefined });
  };
}
