import { PricingSetFragment } from '@graphql/platform';

import { Commitment } from '@root/resources/types/commitment';
import { PlanKey } from '@root/resources/types/plan_key';

import { usePricingSetForZIP } from '../pricing';
import { PricingSummary, usePricingSummary } from './use_pricing_summary';

type StoragePricingValues = {
  pricingSet?: PricingSetFragment;
  pricingSummary?: PricingSummary;
  defaultPricingSummary?: PricingSummary;
};

export const useStoragePricingValues = (
  zip?: string,
  commitment?: Commitment,
  planSize?: PlanKey,
): StoragePricingValues => {
  const pricingSet = usePricingSetForZIP(zip) ?? undefined;
  const pricingSummary =
    usePricingSummary({
      pricingSet,
      zip,
      commitment,
      planSize,
    }) ?? undefined;
  const defaultPricingSummary =
    usePricingSummary({
      pricingSet,
      zip,
      commitment,
      planSize: planSize ? undefined : '5x5',
    }) ?? undefined;

  return {
    pricingSet: pricingSet,
    pricingSummary: pricingSummary,
    defaultPricingSummary: defaultPricingSummary,
  };
};
