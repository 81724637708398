import React from 'react';

import styled from '@emotion/styled';

import { CardElement } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

import {
  COLORS,
  Text as CleanText,
  FontWeight,
  Input,
  Label,
} from '@clutter/clean';

import {
  PAYMENT_ERROR_TO_MESSAGE,
  PaymentMethodErrorKind,
} from '@shared/payment_method_error';

const CC_INPUT_BREAKPOINT = 340;

const getStripeElementStyle = (isMobile: boolean) => ({
  base: {
    fontSize: isMobile ? '13px' : '16px',
    fontFamily: 'Basier Square',
    lineHeight: '32px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: COLORS.hippo,
    },
  },
});

const Container = styled.div<{ error?: boolean }>`
  .StripeElement {
    line-height: 32px;
    border: 1.76px solid #ebeced;
    padding: 8px 16px;
    border-radius: 4px;
    border-color: ${({ error }) => (error ? COLORS.toucan : COLORS.grayBorder)};
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  font-size: 16px;
  @media (max-width: ${CC_INPUT_BREAKPOINT}px) {
    font-size: 13px;
  }
`;

const Error = styled(CleanText.Caption)`
  height: 28px;
  padding: 8px 0 0;
`;

type CreditCardInput = {
  billingName?: string;
  disabled?: boolean;
  billingNameError?: string;
  paymentError?: PaymentMethodErrorKind;
  onChangeBillingName(billingName: string): void;
  onChangeCard(changeEvent: StripeElementChangeEvent): void;
};

export const CreditCardInput: React.FC<CreditCardInput> = ({
  billingName,
  disabled = false,
  onChangeBillingName,
  onChangeCard,
  paymentError,
  billingNameError,
}) => {
  const isMobile = window.innerWidth < CC_INPUT_BREAKPOINT;

  return (
    <Container error={!!paymentError}>
      <Label htmlFor="billingName">Name on card</Label>
      <StyledInput
        id="billingName"
        onChange={(e) => onChangeBillingName(e.target.value)}
        type="text"
        value={billingName ?? ''}
        disabled={disabled}
        autoComplete="chrome-off"
        state={billingNameError ? 'error' : undefined}
      />
      <Error color={COLORS.toucan} weight={FontWeight.Medium}>
        {billingNameError}
      </Error>
      <Label>Card number</Label>
      <CardElement
        onChange={onChangeCard}
        options={{ style: getStripeElementStyle(isMobile) }}
      />
      <Error color={COLORS.toucan} weight={FontWeight.Medium}>
        {paymentError ? PAYMENT_ERROR_TO_MESSAGE[paymentError] : ''}
      </Error>
    </Container>
  );
};
