import React, { useEffect, useState } from 'react';

import { Box, COLORS, FontWeight, HEADLINE_STYLES, Text } from '@clutter/clean';

import { useClientDataContext } from '@shared/client_data_context';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import locationTruckTrackingSmall from '@images/illustrations/location_truck_tracking_small.svg';
import moverWavingSmall from '@images/illustrations/mover_waving_small.svg';
import moverWithSixteenFootTruckSmall from '@images/illustrations/mover_with_sixteen_foot_truck_small.svg';

import { useMovingCheckoutContext } from '../../context';
import { MapWithDirections } from './map_with_directions';
import { Estimations } from './moving_map/estimations';
import { ReviewFooter, ReviewOverlay } from './moving_map/map_reviews';
import { SkipAddressOverlay } from './moving_map/skip_address';

export const COMBINED_DESKTOP_MAP_HEIGHT = 480; // Map + Social / Estimation footer

const REVIEW_A =
  'If all moving experiences were like this, people would move more often!';
const REVIEW_A_AUTHOR = 'Louis M.';

const REVIEW_B =
  'On time, AMAZING customer service, cautious with our things, absolutely OUT OF THIS WORLD!!!!!';
const REVIEW_B_AUTHOR = 'Janet C.';

const ValueProps: React.FC<{ isLongDistance: boolean }> = ({
  isLongDistance,
}) => (
  <Box textAlign="center" margin="24px 0 0">
    <Text style={{ ...HEADLINE_STYLES.SM, fontWeight: FontWeight.Medium }}>
      Want a precise estimate?
    </Text>
    <Text.Body color={COLORS.storm}>
      Complete a 10-minute Virtual Walkthrough after your move is booked.
    </Text.Body>
    {isLongDistance ? (
      <Box.Flex margin="32px 0 0" gap="24px">
        <Box.Flex flexDirection="column" gap="8px" alignItems="center">
          <img src={moverWithSixteenFootTruckSmall} alt="" width="72" />
          <Text.Caption>
            <Text.Caption weight={FontWeight.Medium} display="inline">
              Guaranteed delivery date
            </Text.Caption>{' '}
            within a 1-hour arrival window. You’ll be able to track your team’s
            ETA in realtime.
          </Text.Caption>
        </Box.Flex>
        <Box.Flex flexDirection="column" gap="8px" alignItems="center">
          <img src={locationTruckTrackingSmall} alt="" width="34" />
          <Text.Caption>
            Enjoy peace of mind with a{' '}
            <Text.Caption weight={FontWeight.Medium} display="inline">
              dedicated truck
            </Text.Caption>{' '}
            just for your items so that they’re secure and never lost.
          </Text.Caption>
        </Box.Flex>
      </Box.Flex>
    ) : (
      <Box.Flex margin="32px 0 0" gap="24px">
        <Box.Flex flexDirection="column" gap="8px" alignItems="center">
          <img src={locationTruckTrackingSmall} alt="" width="34" />
          <Text.Caption>
            On the day of your move, you’ll be able to track your team and know
            exactly when they’ll arrive at your home.
          </Text.Caption>
        </Box.Flex>
        <Box.Flex flexDirection="column" gap="8px" alignItems="center">
          <img src={moverWavingSmall} alt="" width="34" />
          <Text.Caption>
            Your Move & Pack Specialists are full time employees who completed
            an extensive interview and background check process.
          </Text.Caption>
        </Box.Flex>
      </Box.Flex>
    )}
  </Box>
);

const MovingMap: React.FC<{
  mapContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ mapContainerRef }) => {
  const {
    flowState: {
      values: {
        skipAddressSelected,
        startAddress,
        endAddress,
        zip,
        movingQuote,
        moverCountSelected,
      },
    },
    creatingQuote,
  } = useMovingCheckoutContext();

  const {
    data: { lead },
  } = useClientDataContext();

  const { isDesktop } = useBreakpoints();

  const [hasCompletedAddress, setHasCompletedAddress] = useState(
    !!startAddress?.state,
  );

  useEffect(() => {
    if (startAddress?.state || skipAddressSelected) {
      setHasCompletedAddress(true);
    }
  }, [startAddress, skipAddressSelected]);

  const showEstimations =
    moverCountSelected &&
    (!isDesktop ||
      (!!(movingQuote || creatingQuote) && hasCompletedAddress && lead.token));

  const isLongDistance = !!movingQuote?.longDistanceTransportFee;

  return (
    <>
      <Box ref={mapContainerRef} position="relative">
        <Box borderRadius="4px 4px 0 0" overflow="hidden">
          <MapWithDirections
            origin={skipAddressSelected ? undefined : startAddress}
            destination={skipAddressSelected ? undefined : endAddress}
            zip={zip}
            height={['214px', '400px']}
          />
        </Box>
        {skipAddressSelected && <SkipAddressOverlay zip={zip} />}
        {!hasCompletedAddress && (
          <Box
            position="absolute"
            left="0"
            top="0"
            width="100%"
            display={['none', 'block']}
          >
            <ReviewOverlay
              reviewA={{ content: REVIEW_A, author: REVIEW_A_AUTHOR }}
              reviewB={{ content: REVIEW_B, author: REVIEW_B_AUTHOR }}
            />
          </Box>
        )}
        {showEstimations ? (
          <Estimations />
        ) : (
          <ReviewFooter description="Moves with Clutter" count={151990} />
        )}
      </Box>
      {showEstimations && <ValueProps isLongDistance={isLongDistance} />}
    </>
  );
};

export { MovingMap };
