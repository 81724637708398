import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { loadMapsApi } from '@root/initializers/google_maps';

import GoogleMap from 'google-map-react';

type MapState = {
  map?: google.maps.Map;
  maps?: typeof google.maps;
};

export type MapContextValue = MapState & {
  onMapLoaded(value: MapState): void;
  fitToBounds(markers: GoogleMap.Coords[] | google.maps.LatLngBounds): void;
};

export const DEFAULT_MAP_STYLE: GoogleMap.MapTypeStyle[] = [
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'all',
    stylers: [{ lightness: 40 }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#EBEBEB' }],
  },
];

type MapProps = GoogleMap.Props & {
  children: React.ReactNode;
  onBoundsChange?(bounds: google.maps.LatLngBounds, firstMount: boolean): void;
};

const MapContext = React.createContext<MapContextValue | undefined>(undefined);

export const useMapContext = () => {
  const googleMapVars = useContext(MapContext);
  if (!googleMapVars) {
    throw new Error('No Map found');
  }
  return googleMapVars;
};

const Map: React.FC<MapProps> = ({
  children,
  defaultZoom,
  onBoundsChange,
  ...props
}) => {
  const { map, onMapLoaded } = useMapContext();
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    const listener = map?.addListener('idle', () => {
      const bounds = map?.getBounds();
      if (bounds && onBoundsChange) onBoundsChange(bounds, hasInitialized);
      if (!hasInitialized) {
        setHasInitialized(true);
      }
    });
    return () => listener?.remove();
  }, [map, hasInitialized, onBoundsChange]);

  return (
    <GoogleMap
      googleMapLoader={() => loadMapsApi()}
      defaultZoom={defaultZoom ?? 13}
      {...props}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={onMapLoaded}
    >
      {map ? children : null}
    </GoogleMap>
  );
};

const MapProvider: React.FC<
  MapProps & {
    includeMapComponent?: boolean;
  }
> = ({ children, includeMapComponent = true, ...props }) => {
  const [mapState, setMapState] = useState<MapState | undefined>();

  const fitToBounds = useCallback(
    (markersOrBounds: GoogleMap.Coords[] | google.maps.LatLngBounds) => {
      if (!mapState?.map || !mapState.maps) return;
      let bounds: google.maps.LatLngBounds;
      if (markersOrBounds instanceof mapState.maps.LatLngBounds) {
        bounds = markersOrBounds;
      } else {
        bounds = new mapState.maps.LatLngBounds();
        markersOrBounds.forEach((m) => {
          bounds.extend({ lat: +m.lat, lng: +m.lng });
        });
      }
      mapState.map.fitBounds(bounds);
    },
    [mapState],
  );

  const contextValue = useMemo<MapContextValue>(
    () => ({
      ...mapState,
      onMapLoaded: setMapState,
      fitToBounds,
    }),
    [mapState, fitToBounds],
  );

  const content = includeMapComponent ? (
    <Map {...props}>{children}</Map>
  ) : (
    children
  );

  return (
    <MapContext.Provider value={contextValue}>{content}</MapContext.Provider>
  );
};

const Combined = Object.assign(MapProvider, { Map });

export { Combined as MapProvider };
