import * as React from 'react';

import styled from '@emotion/styled';

import {
  COLORS,
  Column,
  Grid,
  SANS_SERIF_FONT_FAMILY,
  SERIF_FONT_FAMILY,
  mq,
  toggleStyleValue,
  transparentize,
} from '@clutter/clean';

import { RoundCheckmark } from '../icons/round_checkmark';
import { XButton } from '../icons/x_button';

enum CheckType {
  X = 'x',
  Check = 'check',
}

type ComparisonRow = {
  serviceName: string;
  clutter: CheckType;
  competitor1: CheckType;
  competitor2?: CheckType;
};

type Legend = {
  name?: React.ReactNode;
  icon?: string;
};

type MatrixDensity = 'responsive' | 'compact';

const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FeaturedColumn = styled(StyledColumn)`
  background: ${COLORS.tealBackground};
`;

const ColumnHeader = styled(StyledColumn)`
  justify-content: flex-end;
`;

const Matrix = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
`;

const Row = styled(Grid)`
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.grayBorder};
  }
`;

const ColumnName = styled.div<{ density: MatrixDensity }>`
  ${({ density }) => {
    switch (density) {
      case 'compact':
        return { fontSize: '12px', fontWeight: 500, paddingBottom: '12px' };
      default:
        return mq({
          fontSize: ['12px', '20px'],
          fontFamily: [SANS_SERIF_FONT_FAMILY, SERIF_FONT_FAMILY],
          fontWeight: [500, 600],
          paddingBottom: '24px',
        });
    }
  }};
`;

const ServiceColumn = styled.div<{ density: MatrixDensity }>`
  width: 33%;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${COLORS.tealDark};
  text-align: left;
  display: flex;
  align-items: center;
  padding-right: 12px;

  ${({ density }) => {
    switch (density) {
      case 'compact':
        return { height: '48px' };
      default:
        return mq({
          height: ['48px', '80px'],
        });
    }
  }};
`;

const ColumnFiller = styled.div`
  width: 33%;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ServiceName = styled.span<{ density: MatrixDensity }>`
  color: ${COLORS.tealDark};
  text-align: left;

  ${({ density }) => {
    switch (density) {
      case 'compact':
        return { fontSize: '12px', fontWeight: 500 };
      default:
        return mq({
          fontSize: ['12px', '16px'],
          fontWeight: [500, 400],
        });
    }
  }};
`;

const Icon = styled.img<{ useMargin: boolean }>`
  margin-bottom: ${toggleStyleValue('useMargin', '16px', 0)};

  ${mq({ height: ['40px', '54px'] })}
`;

const CheckIcon = styled.div<{ checkType: CheckType; density: MatrixDensity }>`
  ${({ checkType, density }) => {
    if (density === 'compact') {
      return checkType === CheckType.Check
        ? { width: '22px', height: '22px' }
        : { width: '13px', height: '13px' };
    }

    return checkType === CheckType.Check
      ? mq({ width: ['22px', '30px'], height: ['22px', '30px'] })
      : mq({ width: ['13px', '18px'], height: ['13px', '18px'] });
  }}

  & > img, & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Check: React.FC<{
  type: CheckType;
  isFeatured: boolean;
  density: MatrixDensity;
}> = ({ type, density, isFeatured }) => {
  return (
    <CheckIcon checkType={type} density={density}>
      {type === CheckType.Check ? (
        <RoundCheckmark
          fillColor={isFeatured ? COLORS.tealPrimary : COLORS.dew}
        />
      ) : (
        <XButton
          fillColor={
            isFeatured ? COLORS.dew : transparentize(COLORS.tealBrand, 0.5)
          }
        />
      )}
    </CheckIcon>
  );
};

const ComparisonMatrix: React.FC<{
  legend: Legend[];
  serviceFeatures: ComparisonRow[];
  services?: number;
  density?: MatrixDensity;
}> = ({ legend, serviceFeatures, services = 3, density = 'responsive' }) => {
  const columnWidth = (() => {
    switch (services) {
      case 2:
        return 6;
      case 3:
        return 4;
      default:
        throw new Error('More than 3 columns not permitted');
    }
  })();
  const widths = [columnWidth, columnWidth, columnWidth] as any;
  return (
    <Matrix>
      <Grid>
        <ColumnFiller />
        {legend.map((company, i) => (
          <ColumnHeader key={i} widths={widths}>
            {density !== 'compact' && (
              <Icon useMargin={!!company.name} src={company.icon!} alt="" />
            )}
            <ColumnName density={density}>{company.name}</ColumnName>
          </ColumnHeader>
        ))}
      </Grid>
      {serviceFeatures.map((service, i) => (
        <Row key={i}>
          <ServiceColumn density={density}>
            <ServiceName density={density}>{service.serviceName}</ServiceName>
          </ServiceColumn>
          <FeaturedColumn widths={[columnWidth, columnWidth, columnWidth]}>
            <Check type={service.clutter} isFeatured={true} density={density} />
          </FeaturedColumn>
          <StyledColumn widths={[columnWidth, columnWidth, columnWidth]}>
            <Check
              type={service.competitor1}
              isFeatured={false}
              density={density}
            />
          </StyledColumn>
          {service.competitor2 && (
            <StyledColumn widths={[columnWidth, columnWidth, columnWidth]}>
              <Check
                type={service.competitor2}
                isFeatured={false}
                density={density}
              />
            </StyledColumn>
          )}
        </Row>
      ))}
    </Matrix>
  );
};

export { ComparisonMatrix, ComparisonRow as ServiceFeature, Legend, CheckType };
