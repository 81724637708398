import React from 'react';

import { ReviewWizard } from '@clutter/clean';

import { Rating, useReviewSaveMutation } from '@graphql/platform';

export const ReviewSave: React.FC<{ url?: string; question?: string }> = ({
  url,
  question = 'Was this information helpful?',
}) => {
  const [submit] = useReviewSaveMutation();
  return (
    <ReviewWizard
      question={question}
      prompts={{
        GOOD: { answer: 'Yes' },
        POOR: {
          answer: 'No',
          followup: {
            title: 'What could improve?',
            tags: ['Difficult to understand', 'Not what I was looking for'],
          },
        },
      }}
      onSubmit={({ rating, comments, reasons }) => {
        const input = {
          url: url ?? window.location.href,
          rating: rating as Rating,
          comments,
          reasons: JSON.stringify(reasons ?? {}),
        };
        submit({ variables: { input } });
      }}
    />
  );
};
