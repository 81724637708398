import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  Text,
  UnstyledButton,
  mq,
} from '@clutter/clean';

export const BannerContainer: React.FC<{
  background?: string;
  children: React.ReactNode;
}> = ({ background = COLORS.dust, children }) => (
  <Box.Flex
    width="100%"
    padding="16px"
    gap="16px"
    background={background}
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Box.Flex>
);

export const BannerImageContainer: React.FC<{
  height: string;
  width: string;
  children: React.ReactNode;
}> = ({ height, width, children }) => (
  <Box height={height} width={width}>
    {children}
  </Box>
);

const BannerContentContainerTextCallout = styled(Text.Callout)`
  ${mq({
    fontSize: ['14px', null, '16px'],
  })}
`;

export const BannerContentContainer: React.FC<{
  children: React.ReactNode;
  color?: string;
}> = ({ children, color = COLORS.tealDark }) => (
  <BannerContentContainerTextCallout weight={FontWeight.Medium} color={color}>
    {children}
  </BannerContentContainerTextCallout>
);

export const BannerButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...props }) => (
  <UnstyledButton {...props}>
    <Text.SmallCaps color={props.color}>{children}</Text.SmallCaps>
  </UnstyledButton>
);
