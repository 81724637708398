import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import { Box, Button, COLORS, Input, Modal, Text } from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';
import { email as emailValidator } from '@root/resources/validator';

const Label = styled(Text.Callout)`
  text-align: left;
  display: block;
  margin-bottom: 12px;
`;

const EmailInput = styled(Input)`
  width: 100%;
  margin-bottom: 8px;
`;

const Error = styled(Text.Caption)`
  color: ${COLORS.toucan};
  text-align: left;
  float: left;
`;

const SUBMIT_BUTTON_LABEL = 'Continue';

export const FunnelEntryPointSmokeTestModal: React.FC<{
  zip?: string;
  isOpen: boolean;
  handleModalClose(): void;
}> = ({ isOpen, zip, handleModalClose }) => {
  const [captured, setCaptured] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [invalid, setInvalid] = useState<boolean>(false);

  const track = useTrack({
    container: 'funnel_entry_disposal_smoke_test_modal',
  });

  const onSubmit = (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const emailValid = emailValidator(email);

    if (emailValid) {
      track({
        objectType: 'input:button',
        objectName: 'cta',
        label: SUBMIT_BUTTON_LABEL,
        action: 'click',
        metadata: {
          email: email,
          serviceSelection: 'disposal',
          zip: zip,
        },
      });
      setCaptured(true);
    } else {
      setInvalid(true);
    }
  };

  useEffect(() => {
    setInvalid(false);
  }, [isOpen]);

  return (
    <Modal
      handleModalClose={handleModalClose}
      isOpen={isOpen}
      includeCloseButton
    >
      <Box padding="48px 64px">
        <Text.Title size="large">Disposal Contact Details</Text.Title>
        {captured ? (
          <Box margin="12px 0 24px">
            <Text.Body>
              Thank you for your interest. Unfortunatelly disposals are not
              available at this time.
              <br />
              We encourage you to try out our moving and storage offerings.
            </Text.Body>
          </Box>
        ) : (
          <Box textAlign="center">
            <form onSubmit={onSubmit}>
              <Box margin="12px 0 24px">
                <Text.Body>
                  Please enter your email so we can confirm availability.
                </Text.Body>
              </Box>
              <Box margin="0 0 24px;">
                <Label>Email</Label>
                <EmailInput
                  value={email}
                  state={invalid ? 'error' : undefined}
                  onChange={({ currentTarget }) => {
                    setInvalid(false);
                    setEmail(currentTarget.value);
                  }}
                />
                {invalid && <Error>Please enter a valid email address</Error>}
              </Box>
              <Button size="small" type="submit" fullWidth>
                {SUBMIT_BUTTON_LABEL}
              </Button>
            </form>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
