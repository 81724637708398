import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, ZIndex, toggleStyleValue } from '@clutter/clean';

import { DraftFragment, Maybe } from '@graphql/platform';

import { Drawer } from '@shared/drawer';

import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import { Content } from './cart/content';
import { ToggleButton } from './cart/toggle_button';

const Container = styled.div<{
  isOpen: boolean;
}>`
  position: absolute;
  display: ${toggleStyleValue('isOpen', 'block', 'none')};
  top: 0;
  left: 0;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  z-index: ${ZIndex.DROPDOWN};

  &:focus-visible {
    outline: none;
  }

  & > div {
    display: ${toggleStyleValue('isOpen', 'block', 'none')};
    background: ${COLORS.cloud};
    border: 2px solid ${COLORS.cloud};
    border-radius: 4px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: ${ZIndex.MODAL};
    top: 104px;
    right: 64px;
    padding: 16px;
  }
`;

export const Cart: React.FC<{
  draft?: Maybe<DraftFragment>;
  loading?: boolean;
}> = ({ draft, loading }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isMobile } = useIsomorphicBreakpoints();

  const cartItemCount = draft?.latestService ? 1 : 0;

  const onBackgroundClick: React.MouseEventHandler<HTMLDivElement> = (
    event,
  ) => {
    if (event.currentTarget === event.target) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        count={cartItemCount}
        onToggle={() => setIsOpen(!isOpen)}
      />
      {isMobile ? (
        <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <Box padding="16px" width="100vw">
            <Content draft={draft} loading={loading} />
          </Box>
        </Drawer>
      ) : (
        <Container isOpen={isOpen} onClick={onBackgroundClick}>
          <div>
            <Content draft={draft} loading={loading} />
          </div>
        </Container>
      )}
    </>
  );
};
