import { CouponFragment, ReferralPromoFragment } from '@graphql/platform';

import { ServiceEnum } from '@root/resources/types/service';

const SERVICE_ENUM_TO_COUPON: PartialRecord<ServiceEnum, string> = {
  [ServiceEnum.DoorToDoor]: '$100 - Customer Referral',
  [ServiceEnum.Moving]: '$50 - Moving Customer Referral',
};

export const getReferralCouponByService = (
  serviceType: ServiceEnum,
  referral?: ReferralPromoFragment,
): CouponFragment | undefined => {
  if (!referral) return undefined;
  const referralCoupon = referral.coupons.find(
    ({ name }) => name === SERVICE_ENUM_TO_COUPON[serviceType],
  );
  if (!referralCoupon) return undefined;
  return { ...referralCoupon, promoCode: referral.code };
};
