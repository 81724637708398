import React from 'react';

import styled from '@emotion/styled';

import {
  BREAKPOINTS,
  Box,
  COLORS,
  Column,
  Grid,
  Text,
  mq,
} from '@clutter/clean';

import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  ZIP_INPUT_TRIGGER_ID,
} from '@shared/header/header';
import { PinnedFooterContainer } from '@shared/pinned_footer_container';
import { ServiceSelection } from '@shared/service_selector/constants';
import { HeroServiceSelector } from '@shared/service_selector/hero_service_selector';
import { PinnedServiceSelector } from '@shared/service_selector/pinned_service_selector';

import { useIntersectionObserver } from '@utils/hooks';

import { DesktopImages, MobileImages } from './hero/images';

const Container = styled.div`
  position: relative;
  color: ${COLORS.tealDark};

  ${mq({
    textAlign: ['center', null, 'left'],
    paddingTop: [MOBILE_HEADER_HEIGHT, null, DESKTOP_HEADER_HEIGHT, 0],
  })}

  @media (max-width: 1099px) and (min-width: ${BREAKPOINTS.MD}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT};
    text-align: center;
  }
`;

const FluidContainer = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1600px;
`;

const CtaContainer = styled.div`
  ${mq({
    marginTop: ['24px', null, null, '186px'],
  })}
`;

const Headline = styled(Text.Display)`
  ${mq({ fontSize: ['32px', null, '48px'] })}
  line-height: 1.1;

  // Shift largest breakpoint up slightly to handle weird line breaks
  @media (min-width: 1340px) {
    font-size: 72px;
  }
`;

const FooterContainer = styled(PinnedFooterContainer)`
  padding: 16px 24px;
  ${mq({
    display: ['block', 'none'],
  })}
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  form {
    width: 100%;
  }
`;

const Hero: React.FC = () => {
  const [setNode, entry] = useIntersectionObserver();
  const isIntersecting = entry ? entry.isIntersecting : true;

  return (
    <>
      <Container ref={setNode}>
        <FluidContainer>
          <Grid wrap>
            <DesktopImages />
            <Column widths={[12, 12, 6]}>
              <CtaContainer>
                <Box textAlign={['center', null, 'left']} margin="0 0 24px">
                  <Headline>
                    Storage & moving &ndash;
                    <br />
                    now smarter than ever
                  </Headline>
                  <Text.Title size="medium">(and cheaper too)</Text.Title>
                </Box>
                <MobileImages />
                <Box id={ZIP_INPUT_TRIGGER_ID}>
                  <HeroServiceSelector
                    initialService={ServiceSelection.Storage}
                    id="hero_service_selector"
                  />
                </Box>
              </CtaContainer>
              <FooterContainer show={!isIntersecting} hideMobile={true}>
                <Footer>
                  <PinnedServiceSelector
                    position="footer"
                    id="footer_service_selector"
                    container="footer_service_selector"
                  />
                </Footer>
              </FooterContainer>
            </Column>
          </Grid>
        </FluidContainer>
      </Container>
    </>
  );
};

export { Hero };
