import { DateTime } from 'luxon';

import { type ApolloClient } from '@apollo/client';

import {
  AddressFragment,
  DraftDocument,
  DraftFragment,
  DraftInput,
  DraftUpsertDocument,
  DraftUpsertMutation,
  DraftUpsertMutationVariables,
  Draft__Address,
  Draft__MovingAddress,
  Lead__ServiceNeeds,
  Moving__BuildingTypeEnum,
  PricingSetFragment,
  RateGroupKindEnum,
} from '@graphql/platform';

import { InitialValues } from '@root/components/checkout/utilities/initial_values';
import { RATE_GROUP_COMMITMENT } from '@root/constants/pricing';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';
import { PlanKey } from '@root/resources/types/plan_key';

import { geocode } from '@utils/geocode';
import { extractPlan, extractRateGroup } from '@utils/pricing/pricing_set';
import { ProtectionPlanEnum } from '@utils/protection_plan';

import { MovingCheckoutData, StorageCheckoutData } from '../data';
import stringify from 'fast-json-stable-stringify';

export let lastStorageDraft: string | undefined;
export let lastMovingDraft: string | undefined;

function resolveDatePreferred(dateString?: string) {
  if (!dateString) return undefined;
  const parsed = DateTime.fromISO(dateString);
  return parsed.toMillis() > Date.now() ? parsed : undefined;
}

function convertAddress(
  address: Draft__Address | Draft__MovingAddress,
): Partial<AddressFragment> {
  return {
    street: address.street ?? undefined,
    aptsuite: address.aptsuite ?? undefined,
    zip: address.zip ?? undefined,
    city: address.city ?? undefined,
    state: address.state ?? undefined,
  };
}

function extractStorageData(draft: DraftFragment) {
  const data: Partial<InitialValues['storageData']> = {};

  if (draft.address) {
    data.address = convertAddress(draft.address);
  }

  if (draft.rateGroup) {
    data.commitment = RATE_GROUP_COMMITMENT[draft.rateGroup.name];
  } else {
    data.commitment = RateGroupKindEnum.Flexer;
  }

  if (draft.plan?.name) {
    data.planSize = draft.plan.name as PlanKey;
  }

  if (draft.protectionPlanSlug) {
    data.protectionPlan = draft.protectionPlanSlug as ProtectionPlanEnum;
  }

  return data;
}

function extractMovingData(draft: DraftFragment) {
  const data: Partial<InitialValues['movingData']> = {};

  if (draft.movingOriginAttributes) {
    data.startAddress = convertAddress(draft.movingOriginAttributes);
    const { buildingType, squareFootage, unitSize } =
      draft.movingOriginAttributes;
    if (
      buildingType === Moving__BuildingTypeEnum.House ||
      buildingType === Moving__BuildingTypeEnum.Apartment
    ) {
      data.startAddressDetails = {
        buildingType,
      };
      data.rooms = draft.movingQuote?.unitTypes ?? undefined;
    } else if (
      buildingType === Moving__BuildingTypeEnum.StorageFacilityOrWarehouse
    ) {
      data.startAddressDetails = {
        buildingType,
        unitSize: unitSize ?? undefined,
      };
    } else if (buildingType === Moving__BuildingTypeEnum.Commercial) {
      data.startAddressDetails = {
        buildingType,
        squareFootage: squareFootage ?? undefined,
      };
    }
  }

  if (draft.movingDestinationAttributes) {
    data.endAddress = convertAddress(draft.movingDestinationAttributes);
  }

  if (draft.movingQuote) {
    data.packingHelp = draft.movingQuote.packing;
    data.packingMaterials =
      draft.movingQuote.materialPackageSetEntry ?? undefined;
  }

  if (draft.plan?.name) {
    data.planSize = draft.plan.name as PlanKey;

    if (draft.rateGroup) {
      data.commitment = RATE_GROUP_COMMITMENT[draft.rateGroup.name];
    }

    if (draft.protectionPlanSlug) {
      data.protectionPlan = draft.protectionPlanSlug as ProtectionPlanEnum;
    }
  }

  if (draft.lead && !draft.plan) {
    data.skipStoragePlanSizeSelected = true;
  }

  return data;
}

const buildStorageDraftValues = (
  data: StorageCheckoutData,
  pricingSet: PricingSetFragment,
) => {
  const { zip, address, planSize, commitment, dateScheduled, protectionPlan } =
    data;

  const draftValues: Partial<DeepMutable<DraftInput>> = {};

  if (zip) {
    draftValues.address = {
      zip: zip,
      street: address?.street,
      city: address?.city,
      state: address?.state,
    };
  }

  if (planSize && commitment && pricingSet) {
    draftValues.storagePlanID = extractPlan(
      commitment,
      planSize,
      pricingSet,
    )?.id;
  }

  if (commitment) {
    draftValues.rateGroupID = extractRateGroup(commitment, pricingSet)?.id;
  }

  if (dateScheduled) {
    draftValues.preferredTime = dateScheduled.fromTime;
  }

  if (protectionPlan) {
    draftValues.protectionPlanSlug = protectionPlan;
  }

  if (Object.keys(draftValues).length !== 0) {
    draftValues.latestService = Lead__ServiceNeeds.SmartStorage;
    return draftValues;
  }
};

const buildMovingDraftValues = (
  data: MovingCheckoutData,
  pricingSet?: PricingSetFragment,
) => {
  const {
    startAddress,
    endAddress,
    startAddressDetails,
    dateScheduled,
    movingQuote,
    planSize,
    commitment,
    protectionPlan,
  } = data;

  const draftValues: Partial<DeepMutable<DraftInput>> = {};

  const originZip = startAddress?.zip || data.zip;
  if (!!originZip) {
    draftValues.movingOriginAttributes = {
      ...startAddress,
      ...startAddressDetails,
      zip: originZip,
    };
  }

  if (endAddress && endAddress.zip) {
    draftValues.movingDestinationAttributes = {
      ...endAddress,
      zip: endAddress.zip,
    };
  }

  if (dateScheduled) {
    draftValues.preferredTime = dateScheduled.fromTime;
  }

  if (movingQuote) {
    draftValues.movingQuoteID = movingQuote.id;
  }

  if (planSize && commitment && pricingSet) {
    draftValues.storagePlanID = extractPlan(
      commitment,
      planSize,
      pricingSet,
    )?.id;

    const rateGroupName = commitment;
    draftValues.rateGroupID = extractRateGroup(rateGroupName, pricingSet)?.id;

    if (protectionPlan) {
      draftValues.protectionPlanSlug = protectionPlan;
    }
  }

  if (Object.keys(draftValues).length !== 0) {
    draftValues.latestService = Lead__ServiceNeeds.Moving;
    return draftValues;
  }
};

const upsertDraft = (client: ApolloClient<unknown>, input: DraftInput) => {
  client
    .mutate<DraftUpsertMutation, DraftUpsertMutationVariables>({
      mutation: DraftUpsertDocument,
      variables: {
        input,
      },
    })
    .then((result) => {
      if (result.data?.draftUpsert?.draft) {
        client.writeQuery({
          data: { draft: result.data?.draftUpsert?.draft },
          query: DraftDocument,
        });
      }
    });
};

export const updateStorageDraft = (
  client: ApolloClient<unknown>,
  data: StorageCheckoutData,
  leadToken?: string,
  pricingSet?: PricingSetFragment,
) => {
  if (!pricingSet) return;

  const values = buildStorageDraftValues(data, pricingSet);
  const stringified = values && stringify(values);

  // updateDraft may be called when non-draft values have changed, so we guard
  // against noop API calls
  if (values && stringified !== lastStorageDraft) {
    lastStorageDraft = stringified;
    upsertDraft(client, {
      leadToken,
      visitorToken: WT_VISITOR_TOKEN,
      quoteID: pricingSet.quoteId,
      ...values,
    });
  }
};

export const updateMovingDraft = (
  client: ApolloClient<unknown>,
  data: MovingCheckoutData,
  leadToken?: string,
  pricingSet?: PricingSetFragment,
) => {
  const values = buildMovingDraftValues(data, pricingSet);
  const stringified = values && stringify(values);

  // updateDraft may be called when non-draft values have changed, so we guard
  // against noop API calls
  if (values && stringified !== lastMovingDraft) {
    lastMovingDraft = stringified;
    upsertDraft(client, {
      leadToken,
      visitorToken: WT_VISITOR_TOKEN,
      ...values,
    });
  }
};

export const parseDraftToValues = async (
  client: ApolloClient<unknown>,
  draft: DraftFragment,
) => {
  const storageZip = draft?.address?.zip ?? undefined;
  const movingZip = draft?.movingOriginAttributes?.zip ?? undefined;

  const sharedProperties = {
    datePreferred: resolveDatePreferred(draft?.preferredTime ?? undefined),
    leadToken: draft?.lead?.token,
    email: draft?.lead?.email ?? undefined,
    name: draft.lead?.name ?? undefined,
    phone: draft.lead?.phone ?? undefined,
  };

  const geocodeResult = storageZip
    ? (await geocode(client, storageZip))?.geocode
    : undefined;

  const storageData: Omit<InitialValues['storageData'], 'checkoutType'> = {
    ...sharedProperties,
    ...(draft ? extractStorageData(draft) : {}),
    zip: storageZip,
    zipValidated: geocodeResult?.eligibleForDoorToDoor,
  };

  const movingData: Omit<InitialValues['movingData'], 'checkoutType'> = {
    ...sharedProperties,
    ...(draft ? extractMovingData(draft) : {}),
    zip: movingZip,
    zipValidated: geocodeResult?.eligibleForMoving,
  };

  return {
    movingData,
    storageData,
  };
};
