import { DateTime, Duration } from 'luxon';

import {
  AddressInput,
  AvailabilityFragment,
  Moving__BuildingTypeEnum as BuildingTypes,
  Draft,
  Moving__BuildingTypeEnum as MovingBuildingEnum,
  Moving__AddressInput,
  Moving__BuildingTypeEnum,
  Moving__MoveSizeEnum,
  Moving__WhiteGloveBundleEnum,
} from '@graphql/platform';
import { MovingQuoteFragment } from '@graphql/platform';

import { MOVE_SIZE_MAPPINGS } from '@root/components/checkout/product_pages/steps/moving/move_size';
import { MovingCheckoutStepProps } from '@root/components/checkout/types';
import {
  MovingAccessKind,
  MovingAddress,
  StrictMovingAddressDetails,
} from '@root/resources/types/address';

const SQ_FT_REQUIRED_BUILDINGS = [
  MovingBuildingEnum.Commercial,
  MovingBuildingEnum.Other,
];

const MAX_ROOMS = 4;
const STUDIO_ROOMS = 0;

export const flatRateOption = ({
  movingQuote,
  movingBundle,
}: {
  movingQuote?: MovingQuoteFragment;
  movingBundle?: Moving__WhiteGloveBundleEnum;
}) =>
  movingQuote?.flatRateOptions?.length && movingBundle
    ? movingQuote.flatRateOptions.find(
        (option) =>
          option.whiteGloveBundle ===
          (movingBundle as Moving__WhiteGloveBundleEnum),
      )
    : undefined;

export const clearStoragePlan = (
  onChange: MovingCheckoutStepProps['onChange'],
) => {
  onChange('skipStoragePlanSizeSelected', true);
  onChange('planSize', undefined);
  onChange('commitment', undefined);
  onChange('protectionPlan', undefined);
};

export const buildInputMovingAddress = (
  address: Partial<MovingAddress>,
): Moving__AddressInput => ({
  ...buildInputAddress(address),
  details: {
    buildingType: address!.buildingType!,
    floor: address.floor || 1,
    stories: address.stories || 1,
    stairs: address.access === MovingAccessKind.STAIRS,
    elevator: address.access === MovingAccessKind.ELEVATOR,
    serviceElevator: address.access === MovingAccessKind.SERVICE_ELEVATOR,
  },
});

const buildInputAddress = (address: Partial<MovingAddress>): AddressInput => ({
  aptsuite: address!.aptsuite,
  street: address!.street!,
  city: address!.city!,
  state: address!.state!,
  zip: address!.zip!,
});

export const unitDescriptor = (
  address: Partial<MovingAddress>,
  rooms?: Record<string, number | boolean>,
): string => {
  const unitTypes = sanitizeUnitTypesInput(address, rooms);
  const unitType: [string, number] | undefined = Object.entries(unitTypes)[0];
  const buildingType = address.buildingType;

  if (!unitType) {
    return 'move';
  }

  const [unitTypeKey, unitTypeValue] = unitType;

  if (unitTypeKey === 'square_footage' && unitTypeValue) {
    // Turns our absolute square footage into one of the range options
    // E.g 1000 becomes '1001 - 1500'
    const formattedSquareFootage = MOVE_SIZE_MAPPINGS[
      Moving__BuildingTypeEnum.Commercial
    ].options
      .find((option) => option.value === unitTypeValue)
      ?.label?.replace(' sqft', '');

    return `${formattedSquareFootage} square foot business`;
  } else if (unitTypeKey === '1_item' || unitTypeKey === '10_items') {
    return 'move up to 10 items';
  } else if (unitTypeKey === 'dorm_room') {
    return 'dorm room';
  } else if (
    buildingType === BuildingTypes.House ||
    buildingType === BuildingTypes.Apartment
  ) {
    const bedroomCount =
      unitTypeValue === MAX_ROOMS
        ? `${MAX_ROOMS}-bedroom+`
        : unitTypeValue === STUDIO_ROOMS
          ? 'studio'
          : `${unitTypeValue}-bedroom`;

    return `${bedroomCount} ${buildingType.toLowerCase()}`;
  } else if (
    buildingType === BuildingTypes.StorageFacilityOrWarehouse &&
    address.unitSize
  ) {
    // Turns 10x10 into 10’ x 10’
    const formattedUnitSize = MOVE_SIZE_MAPPINGS[
      Moving__BuildingTypeEnum.StorageFacilityOrWarehouse
    ].options
      .find((option) => option.value === address.unitSize)
      ?.label?.toLowerCase();
    return `${formattedUnitSize} storage unit`;
  } else {
    return 'move';
  }
};

export const sanitizeUnitTypesInput = (
  movingStartAddress: Partial<MovingAddress>,
  rooms?: Record<string, number | boolean>,
) => {
  const unitTypes: Record<string, number> = {};
  const address = movingStartAddress!;
  const building = address.buildingType!;

  const addressWithSqFt = SQ_FT_REQUIRED_BUILDINGS.includes(building);

  if (SQ_FT_REQUIRED_BUILDINGS.includes(building)) {
    unitTypes.square_footage = address.squareFootage ?? 0;
  } else if (building === MovingBuildingEnum.StorageFacilityOrWarehouse) {
    unitTypes[address.unitSize!] = 1;
  } else if (building === MovingBuildingEnum.Studio) {
    unitTypes.studio = 1;
  } else if (rooms && !addressWithSqFt) {
    // Convert boolean values to 1 or 0
    Object.entries(rooms).forEach(
      ([label, value]) => (unitTypes[label] = Number(value)),
    );
  }
  return unitTypes;
};

export const isValidAddress = (address?: Partial<MovingAddress>): boolean => {
  return !(!address || !address.street || !address.city || !address.zip);
};

export const sanitizeAddressInput = (
  address: Partial<MovingAddress>,
): Moving__AddressInput => {
  return {
    street: address!.street!,
    city: address!.city!,
    state: address!.state!,
    zip: address!.zip!,
    details: {
      buildingType: address?.buildingType || BuildingTypes.Apartment,
      floor: address?.floor || 1,
      stories: address?.stories || 1,
    },
  };
};

export const parseAddressFromDraftAttributes = (
  attributes?: Draft['movingOriginAttributes'],
) => {
  if (!attributes) return undefined;
  return {
    zip: attributes?.zip ?? undefined,
    aptsuite: attributes?.aptsuite ?? undefined,
    street: attributes?.street ?? undefined,
    city: attributes?.city ?? undefined,
    state: attributes?.state ?? undefined,
    buildingType: attributes?.buildingType ?? undefined,
    floor: attributes?.floor ?? undefined,
    access: attributes?.access ?? undefined,
    stories: attributes?.stories ?? undefined,
    unitSize: attributes?.unitSize ?? undefined,
    squareFootage: attributes?.squareFootage ?? undefined,
    googleAddressType: attributes?.googleAddressType ?? undefined,
    latitude: attributes?.latitude ?? undefined,
    longitude: attributes?.longitude ?? undefined,
  };
};

export const isDateScheduledBeforeDepositCutoff = (
  depositIntervalInDays?: number,
  dateScheduled?: AvailabilityFragment,
) =>
  dateScheduled &&
  depositIntervalInDays &&
  DateTime.fromISO(dateScheduled.fromTime).diff(DateTime.local(), 'days').days >
    depositIntervalInDays;

export const movingQuoteSummary = (
  movingQuote: MovingQuoteFragment | undefined,
  dateScheduled: AvailabilityFragment | undefined,
) => {
  const laborRateAmount = movingQuote?.laborRate?.amount;
  const minimumHours = movingQuote?.movingConfiguration
    ? movingQuote?.movingConfiguration?.requiredLaborDuration / 3600
    : 0;
  const minimumCost = laborRateAmount ? laborRateAmount * minimumHours : 0;
  const packingSuppliesCost =
    movingQuote?.materialPackageSetEntry?.fixedCost ?? 0;
  const reservationDepositAmount = movingQuote?.reservationDepositAmount ?? 0;
  const depositIntervalInDays =
    movingQuote && Duration.fromISO(movingQuote.depositInterval).as('days');
  const dueTodayCost = isDateScheduledBeforeDepositCutoff(
    depositIntervalInDays,
    dateScheduled,
  )
    ? 0
    : reservationDepositAmount;
  const pricingSummary = movingQuote?.pricingSummary;

  return {
    laborRateAmount,
    minimumHours,
    minimumCost,
    packingSuppliesCost,
    reservationDepositAmount,
    dueTodayCost,
    pricingSummary,
  };
};

export const determineMoveSize = (
  startAddressDetails?: StrictMovingAddressDetails,
) => {
  return startAddressDetails?.buildingType === MovingBuildingEnum.House ||
    startAddressDetails?.buildingType === MovingBuildingEnum.Apartment
    ? undefined
    : Moving__MoveSizeEnum.Everything;
};
