import 'core-js/stable';

import 'regenerator-runtime/runtime';

import ReactRailsUJS from 'react_ujs';

import { hydrate } from '@utils/hydrate';

import '../initializers/full_story';
import '../initializers/gtm';
import '../initializers/impact';
import '../initializers/new_relic';
import '../initializers/sentry';
import { trackPageview } from '../initializers/wt';
import { ClientWWW } from '../root/www/client';
import '../styles/www';

trackPageview();

ReactRailsUJS.getConstructor = (name: string) => {
  switch (name) {
    case 'WWW':
      return ClientWWW;
  }
};

hydrate();
