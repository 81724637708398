import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  SERIF_FONT_FAMILY,
  SkeletonLoader,
  Text,
} from '@clutter/clean';

import { Currency } from '@shared/currency';
import { Fallback } from '@shared/fallback';

const Title = styled.span`
  font-family: ${SERIF_FONT_FAMILY};
  font-weight: ${FontWeight.Semibold};
  font-size: 16px;
`;

const Badge = styled.img`
  width: 52px;
`;

export const CardContents: React.FC<{
  label: string;
  description: React.ReactNode;
  price?: number | null;
  imgSrc?: string;
  prefix?: string;
  suffix?: string;
  showLoader?: boolean;
}> = ({
  label,
  description,
  price,
  imgSrc,
  prefix,
  suffix,
  showLoader = true,
}) => {
  return (
    <Box.Flex>
      {imgSrc && <Badge src={imgSrc} />}
      <Box width="100%" margin="0 0 0 16px">
        <Box.Flex
          justifyContent="space-between"
          margin="2px 0 4px"
          flexDirection={['row', null, 'column', 'row']}
        >
          <Title>{label}</Title>
          <Text.Callout weight={FontWeight.Medium} color={COLORS.tealPrimary}>
            <Fallback
              delayMs={0}
              placeholder={showLoader ? <Box height="19px" /> : undefined}
              loader={
                <SkeletonLoader height="19px" width="90px" margin="0 auto" />
              }
              value={price !== undefined || price === 0 || !showLoader}
            >
              {price !== 0
                ? price && (
                    <>
                      {prefix}
                      <Currency amount={price} />
                      {suffix}
                    </>
                  )
                : 'Free'}
            </Fallback>
          </Text.Callout>
        </Box.Flex>
        <Text.Caption color={COLORS.hippo}>{description}</Text.Caption>
      </Box>
    </Box.Flex>
  );
};
