import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  COLORS,
  Searchbar,
  Text,
  UnstyledButton,
  mq,
} from '@clutter/clean';
import { useDebouncedValue } from '@clutter/hooks';

import {
  Estimation__ItemCategory,
  useItemCategorySearchQuery,
} from '@graphql/platform';

import { SelectedItems } from '@root/components/checkout/product_pages/subcomponents/plan_estimator_modal/types';
import { useTrack } from '@root/initializers/wt';

import { Pluralize } from '@shared/formatters/pluralize';
import { ResponsiveBreak } from '@shared/responsive_breaks';

import { SearchResult } from './search_result';

const POPULAR_SEARCH_TERMS = [
  'Bed',
  'Mattress',
  'Appliance',
  'Sofa',
  'Desk',
  'Box',
  'Chair',
  'Table',
];

const AddButton = styled(Button)`
  ${mq({
    width: ['100%', null, 'initial'],
  })}
`;

const StyledInput = styled(Searchbar.Input)`
  input {
    background: white !important;
  }
`;

const SuggestedCategory = ({
  name,
  onClick,
}: {
  name: string;
  onClick(): void;
}) => (
  <Box
    borderRadius="15px"
    height="30px"
    padding="4px 8px"
    display="inline-block"
    margin="4px 8px 4px 0"
    border="1px solid"
    borderColor={COLORS.tealPrimary}
  >
    <UnstyledButton
      type="button"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Text color={COLORS.tealPrimary}>{name}</Text>
    </UnstyledButton>
  </Box>
);

const DropdownButtonContainer = styled.div`
  border-top: 1px solid ${COLORS.grayBorder};
  padding: 16px;
`;

const SearchResultsContainer = styled.div`
  overflow: scroll;
`;
type Props = {
  selectedItems: SelectedItems;
  onSelectedItemsChange(items: SelectedItems): void;
};

function Inner({ selectedItems: selectedItems, onSelectedItemsChange }: Props) {
  const track = useTrack();
  const { setActive } = Searchbar.useSearchbarContext();
  const [query, setQuery] = useState('');
  const [pendingSelectedItems, setMultiSelectValues] = useState<SelectedItems>(
    () => new Map(),
  );
  const debouncedQuery = useDebouncedValue(query!);

  const { data, loading } = useItemCategorySearchQuery({
    variables: {
      query: debouncedQuery,
    },
    skip: !debouncedQuery,
  });
  const searchResults = data?.results;

  const onSelectQueryResult = (category: Estimation__ItemCategory) => {
    const update = new Map(pendingSelectedItems);
    if (pendingSelectedItems.has(category.id)) {
      // Remove the selection if clicked twice
      update.delete(category.id);
    } else {
      update.set(category.id, { category, quantity: 1 });
    }
    setMultiSelectValues(update);
  };

  const onAddSelectedItems = () => {
    const update: SelectedItems = new Map();

    selectedItems.forEach((item, id) => {
      if (!pendingSelectedItems.has(id)) {
        update.set(id, item);
      }
    });
    pendingSelectedItems.forEach(({ category }, id) => {
      update.set(id, {
        category,
        quantity: selectedItems.has(id)
          ? selectedItems.get(id)!.quantity + 1
          : 1,
      });
    });

    onSelectedItemsChange(update);
    setQuery('');
    setMultiSelectValues(new Map());
    setActive(false);
  };

  const onChangeQuery = (value?: string) => {
    track({
      container: 'item_inventory',
      value: value,
      objectName: 'item_inventory_search_bar',
      objectType: 'search_bar',
      action: 'keystroke',
    });
    setQuery(value ?? '');
    setMultiSelectValues(new Map());
  };

  const pendingItemCount = pendingSelectedItems.size;

  return (
    <>
      <StyledInput
        placeholder="Search and add items from library"
        query={query}
        onChange={(value) => onChangeQuery(value)}
      />
      {(searchResults || loading) && query.length > 0 ? (
        <Searchbar.Dropdown>
          <Box.Flex flexDirection="column" maxHeight="400px">
            <SearchResultsContainer>
              {searchResults?.length ? (
                searchResults.map((result, i) => (
                  <SearchResult
                    key={i}
                    value={result}
                    onClick={() => onSelectQueryResult(result)}
                    selected={!!pendingSelectedItems.has(result.id)}
                  />
                ))
              ) : loading ? (
                <>
                  <SearchResult.Loader />
                  <SearchResult.Loader />
                </>
              ) : (
                <Box padding="16px">
                  <Text color={COLORS.storm}>
                    We couldn’t find that item, please try another search term.
                  </Text>
                </Box>
              )}
            </SearchResultsContainer>
            {pendingItemCount > 0 && (
              <DropdownButtonContainer>
                <AddButton onClick={() => onAddSelectedItems()}>
                  Add{' '}
                  <Pluralize
                    count={pendingItemCount}
                    singular="item"
                    plural="items"
                  />
                </AddButton>
              </DropdownButtonContainer>
            )}
          </Box.Flex>
        </Searchbar.Dropdown>
      ) : (
        <Searchbar.Dropdown>
          <Box.Flex flexDirection="column" maxHeight="400px">
            <SearchResultsContainer>
              <Box padding="12px 20px">
                <Text color={COLORS.hippo}>
                  Try out one of these popular categories to get started: &nbsp;
                  <ResponsiveBreak mode="only" sm />
                  {POPULAR_SEARCH_TERMS.map((term) => (
                    <SuggestedCategory
                      key={term}
                      name={term}
                      onClick={() => setQuery(term)}
                    />
                  ))}
                </Text>
              </Box>
            </SearchResultsContainer>
          </Box.Flex>
        </Searchbar.Dropdown>
      )}
    </>
  );
}
export function SearchBar(props: Props) {
  const track = useTrack();
  return (
    <Searchbar.Form
      onSubmit={() => {
        track({
          container: 'item_inventory',
          objectName: 'item_inventory_search_bar',
          objectType: 'search_bar',
          action: 'submit',
        });
      }}
    >
      <Inner {...props} />
    </Searchbar.Form>
  );
}
