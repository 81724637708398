import React, { useState } from 'react';

import { CallbackWindow } from '@graphql/platform';

import { WTProvider, useTrack } from '@root/initializers/wt';

import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { Drawer } from './drawer';
import { Button } from './qualified_contact_widget/button';
import { Container } from './qualified_contact_widget/container';
import { Content } from './qualified_contact_widget/content';

export const QualifiedContactWidget: React.FC<{ bottomOffset?: number }> = ({
  bottomOffset,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scheduledWindow, setScheduledWindow] = useState<CallbackWindow>();

  const { isMobile } = useBreakpoints();
  const track = useTrack();

  const onWidgetOpenChange = (value: boolean) => {
    track({
      action: 'click',
      objectName: 'button',
      objectType: 'qualified_contact_widget',
      metadata: { open: value },
    });
    setIsOpen(value);
  };

  const ContentContainer = isMobile ? Drawer : Container;

  return (
    <>
      <Button
        bottomOffset={bottomOffset}
        isOpen={isOpen}
        onClick={() => onWidgetOpenChange(!isOpen)}
      />
      <WTProvider
        params={{
          container: 'qualified_contact_widget',
        }}
      >
        <ContentContainer
          isOpen={isOpen}
          onClose={() => onWidgetOpenChange(false)}
        >
          <Content
            scheduledWindow={scheduledWindow}
            onConfirm={setScheduledWindow}
          />
        </ContentContainer>
      </WTProvider>
    </>
  );
};
