import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { EventSchema } from '@root/resources/wt/types';

import { useTrackFunnelEvents } from '@utils/hooks/funnel_events/use_track_funnel_event';

export const useReservationCompletedFunnelEvent = () => {
  const { flowVersion } = useSharedCheckoutContext();
  const track = useTrackFunnelEvents();
  return ({ orderId }: { orderId: string }) =>
    track({
      schema: EventSchema.WWW__ReservationCompleted,
      action: 'submit',
      metadata: {
        order_id: orderId,
        service: flowVersion === 'two_page_storage' ? 'storage' : 'moving',
      },
    });
};
