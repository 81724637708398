import { EnhancedStep, StepProps } from '@clutter/staircase';

import { AddedFlowHelpers } from '@shared/flow/flow_container';

import {
  MovingCheckoutData,
  SharedCheckoutData,
  StorageCheckoutData,
} from './data';

type AddedCheckoutStepProps = {
  enabled: boolean;
  scrollToStep: (
    identifier?: 'next' | 'current',
    mode?: 'top' | 'centered',
  ) => void;
};

// prettier-ignore
type CheckoutStepPropsInner<Data> =
  Omit<StepProps<Data>, 'onChange'>
  & {
      onChange<K extends keyof Data>(
        key: K,
        value: Data[K],
        cb?: (values: Data) => void,
      ): void;
    }
  & Omit<AddedFlowHelpers<StorageCheckoutData>, 'createEvent'>
  & AddedCheckoutStepProps;

type StepDescriptorInner<Data, Props, StepEnum, Extra = {}> = Omit<
  EnhancedStep<Data, Props>,
  'name'
> & {
  name: StepEnum;
  resolveCompleted: (
    arg: {
      values: Data;
      leadToken: string | undefined;
    } & Extra,
  ) => boolean;
};

export enum CheckoutType {
  Standard = 'standard',
  Shareable = 'shareable',
  Reonboarding = 'reonboarding',
  Agent = 'agent',
}

export enum CheckoutVersion {
  Storage = 'two_page_storage',
  Moving = 'two_page_moving',
}

export enum StorageCheckoutStep {
  Zip = 'zip',
  PlanSize = 'plan_size',
  Commitment = 'commitment',
  ProtectionPlan = 'protection_plan',
  AddressAndLeadInfo = 'address_and_lead_info',
  LeadInfo = 'lead_info',
  Bundle = 'bundle',
  Appointment = 'appointment',
  PackingHelp = 'packing_help',
  Disposal = 'disposal',
  Cart = 'cart',
}

export enum MovingCheckoutStep {
  Zip = 'zip',
  Building = 'building',
  MoveSize = 'move_size',
  Address = 'address',
  PackingHelp = 'packing_help',
  StoragePlanSize = 'storage_plan_size',
  StorageCommitment = 'storage_commitment',
  StorageProtectionPlan = 'storage_protection_plan',
  LeadInfo = 'lead_info',
  MoverCount = 'mover_count',
  Appointment = 'appointment',
  PackingMaterials = 'packing_materials',
  Disposal = 'disposal',
  Cart = 'cart',
}

export type SharedCheckoutStepProps =
  CheckoutStepPropsInner<SharedCheckoutData>;

export type MovingCheckoutStepProps =
  CheckoutStepPropsInner<MovingCheckoutData>;

export type MovingStepDescriptor = StepDescriptorInner<
  MovingCheckoutData,
  MovingCheckoutStepProps,
  MovingCheckoutStep,
  { validMoveRoute: boolean; disposalEligible: boolean }
>;

export type StorageCheckoutStepProps =
  CheckoutStepPropsInner<StorageCheckoutData>;

export type StorageStepDescriptor = StepDescriptorInner<
  StorageCheckoutData,
  StorageCheckoutStepProps,
  StorageCheckoutStep,
  {
    disposalEligible: boolean;
  }
>;
