import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  Button,
  COLORS,
  FontWeight,
  LARGE_TITLE_STYLES,
  Modal,
  Text,
  UnstyledButton,
} from '@clutter/clean';

import { WTProvider, useTrack } from '@root/initializers/wt';
import { ClientAddress, MovingAddress } from '@root/resources/types/address';

import { useMovingCheckoutContext } from '../../context';
import { MovingCheckoutStep } from '../../types';
import { scrollToStep } from '../../utilities/scroll_animation';
import { AddressField, MovingAddressFields } from './moving_address_fields';

const CancelButton = styled(UnstyledButton)`
  font-weight: ${FontWeight.Semibold};
`;

const ReserveButton = styled(Button)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled && {
      pointerEvents: 'none',
      boxShadow: 'none',
      color: COLORS.hippo,
      border: 'none',
      background: COLORS.grayBorder,
    }}
`;

const BASE_PARAMS = {
  container: 'moving_address_modal',
};

const BUTTON_LABEL = 'Continue to Reservation';

export const MovingAddressModal: React.FC<{
  isOpen: boolean;
  onComplete(longDistance?: boolean): void;
  onClose(): void;
}> = ({ isOpen, onComplete: onReserve, onClose }) => {
  const track = useTrack(BASE_PARAMS);
  const {
    flowState: {
      values: { startAddress, endAddress, startAddressDetails, rooms },
      onChange,
    },
    createQuote,
    creatingQuote,
  } = useMovingCheckoutContext();
  const [addresses, setAddresses] = useState<
    Record<AddressField, Partial<MovingAddress | undefined>>
  >({ startAddress, endAddress });

  const [hasValidRoute, setHasValidRoute] = useState<boolean>(false);
  const [validatingRoute, setValidatingRoute] = useState<boolean>(false);

  const handleAddressChange = (field: AddressField, value: ClientAddress) => {
    setAddresses({ ...addresses, [field]: value });
  };

  return (
    <WTProvider params={BASE_PARAMS}>
      <Modal isOpen={isOpen} includeCloseButton handleModalClose={onClose}>
        <Box
          padding="24px"
          width={['calc(100vw - 32px)', null, '736px']}
          maxWidth="736px"
        >
          <Box padding="0 24px 16px" textAlign="center">
            <Text color={COLORS.tealDark} style={LARGE_TITLE_STYLES.SM}>
              Add address to continue
            </Text>
            <Text.Body color={COLORS.storm}>
              Add all locations to see final pricing and reserve your move
            </Text.Body>
          </Box>
          <Box.Flex flexDirection="column" gap="20px">
            <MovingAddressFields
              startAddress={addresses.startAddress}
              endAddress={addresses.endAddress}
              startAddressDetails={startAddressDetails}
              rooms={rooms}
              flowOnChange={onChange}
              onChange={handleAddressChange}
              onValidateRoute={(valid) => setHasValidRoute(valid)}
              onValidatingRoute={(validating) => setValidatingRoute(validating)}
            />
          </Box.Flex>
          <Box padding="36px 0 16px" textAlign="center">
            <ReserveButton
              fullWidth
              size="large"
              disabled={!hasValidRoute || validatingRoute || creatingQuote}
              loading={creatingQuote}
              onClick={async () => {
                onChange('startAddress', addresses.startAddress);
                onChange('endAddress', addresses.endAddress);
                onChange('skipAddressSelected', false, async () => {
                  const quote = await createQuote();
                  track({
                    label: BUTTON_LABEL,
                    action: 'click',
                    objectName: 'submit_addresses',
                  });
                  if (!!quote) {
                    onChange('moverCountSelected', undefined);
                    onChange('movingBundle', undefined);
                    onReserve(true);

                    if (
                      quote.data?.movingQuoteCreate?.quote
                        ?.longDistanceTransportFee
                    ) {
                      // We don't want to show a "date unavailable" message
                      // if the customer has to re-select their date due to
                      // querying long distance availability
                      onChange('datePreferred', undefined);
                      onChange('dateScheduled', undefined, async () => {
                        onReserve(true);
                        // We want to make sure that the latest changes have been flushed.
                        // Otherwise, we sometimes get a bug where the scrollToStepCall doesn't
                        // work because it's broken by other flow state updates.
                        await new Promise((resolve) => setTimeout(resolve));
                      });
                    }

                    scrollToStep(MovingCheckoutStep.MoverCount);
                  } else {
                    onReserve();
                  }
                });
              }}
            >
              {BUTTON_LABEL}
            </ReserveButton>
          </Box>
          <Box textAlign="center">
            <CancelButton onClick={onClose}>
              <Text.Button color={COLORS.tealPrimary}>Cancel</Text.Button>
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </WTProvider>
  );
};
