import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, Box, COLORS } from '@clutter/clean';

import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { flatRateOption } from '@root/components/checkout/helpers/moving';

import { useClientDataContext } from '@shared/client_data_context';
import { Currency } from '@shared/currency';
import { Pluralize } from '@shared/formatters/pluralize';

import { useLastDefined } from '@utils/hooks/use_last_defined';

import {
  LaborContent,
  MoversContent,
  TodayContent,
  TrucksContent,
} from './expandable_content';
import { Tab } from './tab';

const TabContainer = styled.div`
  min-height: 80px;
  background: ${COLORS.cloud};
  border: 1px solid ${COLORS.grayBorder};
  border-radius: 0 0 4px 4px;
  border-style: none solid none solid;
  display: grid;
  overflow: hidden;

  grid-template:
    'tab1 tab2'
    'expand-upper expand-upper'
    'tab3 tab4'
    'expand-lower expand-lower';
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: ${BREAKPOINTS.LG}) {
    grid-template:
      'tab1 tab2 tab3 tab4'
      'expand-lower expand-lower expand-lower expand-lower';
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Estimations: React.FC<{}> = ({}) => {
  const {
    flowState: {
      values: { movingQuote, movingBundle },
    },
    creatingQuote,
  } = useMovingCheckoutContext();
  const leadToken = useClientDataContext().data.lead.token;
  const [activeTab, setActiveTab] = useState('');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const toggleTab = (tab: string) => {
    setActiveTab(tab === activeTab ? '' : tab);
  };

  const lastTab = useLastDefined(activeTab || undefined);

  const flatRateAmount = flatRateOption({ movingQuote, movingBundle })
    ?.flatRateDetails?.totalAmount;

  const expandedContent = (() => {
    switch (lastTab) {
      case 'labor':
        return <LaborContent flatRate={!!flatRateAmount} />;
      case 'movers':
        return <MoversContent />;
      case 'trucks':
        return <TrucksContent />;
      case 'today':
        return <TodayContent />;
    }
  })();

  const { laborRate, moverSelection, truckCount } = movingQuote ?? {};

  const { flatRate, hourlyRate, estMovers, trucks, alsoIncluded } = leadToken
    ? {
        flatRate: flatRateAmount && <Currency amount={flatRateAmount} />,
        hourlyRate: laborRate && (
          <Currency amount={laborRate.amount} suffix="/hr" />
        ),
        estMovers: moverSelection && (
          <Pluralize count={moverSelection} singular="mover" plural="movers" />
        ),
        trucks: truckCount && (
          <Pluralize count={truckCount} singular="truck" plural="trucks" />
        ),
        alsoIncluded: 'Today app',
      }
    : {
        flatRate: undefined,
        hourlyRate: '-',
        estMovers: '-',
        trucks: '-',
        alsoIncluded: '-',
      };

  return (
    <>
      <TabContainer>
        <Tab
          gridArea="tab1"
          active={activeTab === 'labor'}
          onClick={() => toggleTab('labor')}
        >
          {flatRate ? (
            <Tab.Content
              label="Flat Rate"
              value={flatRate}
              loading={creatingQuote}
            />
          ) : (
            <Tab.Content
              label="Hourly Rate"
              value={hourlyRate}
              loading={creatingQuote}
            />
          )}
        </Tab>
        <Tab
          active={activeTab === 'movers'}
          gridArea="tab2"
          onClick={() => toggleTab('movers')}
        >
          <Tab.Content
            label={'Movers'}
            value={estMovers}
            loading={creatingQuote}
          />
        </Tab>
        <Tab
          active={activeTab === 'trucks'}
          gridArea="tab3"
          onClick={() => toggleTab('trucks')}
        >
          <Tab.Content label="Trucks" value={trucks} loading={creatingQuote} />
        </Tab>
        <Tab
          active={activeTab === 'today'}
          gridArea="tab4"
          onClick={() => toggleTab('today')}
        >
          <Tab.Content
            label="Also Included"
            value={alsoIncluded}
            loading={creatingQuote}
          />
        </Tab>
        <Box.GridItem
          ref={containerRef}
          overflow="hidden"
          transition="max-height 300ms ease-in-out"
          maxHeight={activeTab ? '500px' : '0px'}
          background={COLORS.tealBackground}
          gridArea={[
            lastTab === 'labor' || lastTab === 'movers'
              ? 'expand-upper'
              : 'expand-lower',
            null,
            null,
            'expand-lower',
          ]}
        >
          <div>{expandedContent}</div>
        </Box.GridItem>
      </TabContainer>
    </>
  );
};
