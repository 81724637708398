import { AddressFragment } from '@graphql/platform';

import { Errors } from './errors';
import { IValidation } from './types/validation';
import { Validator } from './types/validator';

const ZIP = /^\d{5}$/; // i.e. 10001
const PHONE = /^\+?(1 ?)?\(?\d{3}\)?\s?\d{3}\-?\d{4}$/; // i.e. 555 555 5555, +1 (555) 555-5555, or 15555555555
const EMAIL = /^(\S+)@(\S+)\.(\S{2,})$/; // i.e. info@clutter.com
export const format = (value: string, regexp: RegExp) => regexp.test(value);

// Base validations
export const blank: Validator = (value: string) => !value;
export const presence: Validator = (value: any) =>
  value !== undefined && value !== null;
export const required: Validator = (value: any) =>
  value !== undefined && value !== null && value !== '';
export const length = (len: number) => (value: string | undefined) =>
  !!value && value.length === len;
export const wordCount = (count: number) => (value: string | undefined) => {
  const words = value!.split(' ').filter((e) => e.length > 0);
  return !!value && words.length >= count;
};

// Specific validations
export const phone: Validator = (value: string) => format(value, PHONE);
export const email: Validator = (value: string) => format(value, EMAIL);
export const zip: Validator = (value: string) =>
  (blank(value) as boolean) || format(value, ZIP);
// TODO: validate address based on whether we service them or not
export const address: Validator = ({
  street,
  city,
  state,
  zip,
}: Partial<AddressFragment> = {}) => !!(street && city && state && zip);

// Validation factories
export const requiredValidator = (
  formattedName: string,
  allowEmpty = false,
) => {
  return {
    validator: required,
    message: `${formattedName} is required.`,
    conditions: allowEmpty ? blank : undefined,
  };
};

export const validate = (
  params: { [key: string]: any },
  validations: { [key: string]: IValidation[] },
  keys?: string[],
  errors = new Errors(),
): Errors => {
  const validatedKeys = keys || Object.keys(validations);

  for (const key of validatedKeys) {
    const value = params[key];
    if (!validations[key]) {
      continue;
    }
    let hasErrors = false;
    for (const validation of validations[key]) {
      if (validation.conditions && !validation.conditions(value, params)) {
        break;
      }
      if (!validation.validator(value)) {
        errors.add(key, validation.message);
        hasErrors = true;
      }
    }
    if (!hasErrors) {
      errors.reset(key);
    }
  }

  return errors;
};
