/* @jsx jsx */
import * as React from 'react';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import { ResponsiveContainer, Text, mq } from '@clutter/clean';

import { ResponsiveProp } from '../utils/responsive_props';

const Container = styled.div<{ display: ResponsiveProp<'display'> }>`
  padding-top: 84px;

  ${({ display }) => mq({ display })}
`;

export const FooterTitle: React.FC<{
  display?: ResponsiveProp<'display'>;
  children: React.ReactNode;
}> = ({ children, display = 'block' }) => (
  <ResponsiveContainer>
    <Container display={display}>
      <Text.Title size="medium">{children}</Text.Title>
    </Container>
  </ResponsiveContainer>
);
