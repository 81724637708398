import React from 'react';

import styled from '@emotion/styled';

import { FontWeight, LARGE_TITLE_STYLES, Text } from '@clutter/clean';

import { useStorageCheckoutContext } from '@root/components/checkout/context';

import { Spacer } from '@shared/spacer';

import { PolicyModal } from './subcomponents/policy_modal';

const Body = styled(Text.Body)`
  padding-top: 16px;
  padding-bottom: 40px;
`;

export const SmartStorageBillingModal: React.FC<{
  isOpen: boolean;
  handleModalClose(): void;
}> = ({ isOpen, handleModalClose }) => {
  const { pricingSummary } = useStorageCheckoutContext();
  if (!pricingSummary) return null;
  return (
    <PolicyModal isOpen={isOpen} handleModalClose={() => handleModalClose()}>
      <Text style={LARGE_TITLE_STYLES.SM}>When will I be charged?</Text>
      <Body>
        Don’t worry, making a reservation is completely free! Your first month
        of storage and pickup fee will be charged after your appointment is
        completed.
        <Spacer height="12px" />
        We will perform a pre-authorization check with the card on file 7 days
        before your appointment to verify sufficient funds are available to
        cover the appointment. If you are booking within 7 days of your
        appointment then we will perform the check immediately after booking.
        The check will be immediately released once the pre-authorization check
        is completed.
      </Body>
      <Text.Headline weight={FontWeight.Medium}>
        What if I need to reschedule?
      </Text.Headline>
      <Body>
        We ask that you reschedule or cancel at least 48hrs before your
        appointment. Otherwise, you will be charged a $100 charge. This charge
        is increased to $200 on the same day of the appointment. You can
        reschedule or cancel online in just a few clicks.
      </Body>
    </PolicyModal>
  );
};
