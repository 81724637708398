import React from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  Text,
  toggleStyleValue,
} from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

const InvisibleInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 0px;
  height: 0px;
`;

const SkipLabel = styled.label<{ selected: boolean }>`
  position: relative;
  padding: 0 0 0 24px;
  display: inline-block;
  cursor: pointer;

  &::before {
    content: ' ';
    position: absolute;
    left: 0px;
    top: 6px;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    box-shadow:
      0 0 0 2px ${COLORS.cloud},
      0 0 0 5px ${COLORS.tealPrimary};
    background: ${toggleStyleValue(
      'selected',
      COLORS.tealPrimary,
      COLORS.cloud,
    )};
  }
`;

export const SkipButton: React.FC<{
  buttonText: string;
  selected: boolean;
  trackingObjectName: string;
  name: string;
  value: string;
  onSkip: () => void;
}> = ({ buttonText, selected, trackingObjectName, name, value, onSkip }) => {
  const track = useTrack();

  return (
    <Box margin="24px 0 0" textAlign="center">
      <SkipLabel
        selected={selected}
        onClick={(e) => {
          e.preventDefault();
          track({
            action: 'click',
            objectType: 'button',
            objectName: trackingObjectName,
            label: buttonText,
          });
          onSkip();
        }}
      >
        <Text.Body weight={FontWeight.Medium}>{buttonText}</Text.Body>
        <InvisibleInput type="radio" value={value} name={name} />
      </SkipLabel>
    </Box>
  );
};
