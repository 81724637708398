import { DateTime } from 'luxon';

import { Token } from '@stripe/stripe-js';

import {
  ErrorCodeEnum,
  Moving__MoveInput,
  Moving__MoveSizeEnum,
  Moving__StoragePlanInput,
  useMoveCreateMutation,
} from '@graphql/platform';

import {
  buildInputMovingAddress,
  flatRateOption,
  sanitizeUnitTypesInput,
} from '@root/components/checkout/helpers/moving';
import { IMPACT_CLICK_ID } from '@root/initializers/impact';
import { WT_VISITOR_TOKEN } from '@root/initializers/wt';

import { GQLError } from '@utils/gql_errors';
import { useReservationCompletedFunnelEvent } from '@utils/hooks/funnel_events/use_reservation_completed_funnel_event';
import {
  EventName,
  ProductType,
  createThirdPartyConversionEvent,
} from '@utils/third_party_conversion_events';

import { MovingCheckoutData } from '../data';

export function useReserveMove() {
  const [moveCreate] = useMoveCreateMutation();
  const trackMoveReserved = useReservationCompletedFunnelEvent();
  return async ({
    stripeToken,
    leadToken,
    values,
    storagePlanInput,
  }: {
    stripeToken?: Token;
    customerToken?: string;
    leadToken: string;
    values: MovingCheckoutData;
    storagePlanInput?: Moving__StoragePlanInput;
  }) => {
    const {
      name,
      phone,
      email,
      zip,
      dateScheduled,
      disposalSelected,
      startAddress,
      startAddressDetails,
      endAddress,
      movingQuote,
      rooms,
      packingHelp,
      coupon,
      freeStorageSelected,
      adjustedMoverCount,
      unpackingScheduled,
      movingBundle,
    } = values;

    if (!movingQuote) {
      throw new Error(
        `Moving quote did not exist on checkout for lead (${leadToken}) & visitor (${WT_VISITOR_TOKEN})`,
      );
    }

    const marketingData = IMPACT_CLICK_ID
      ? { impactClickID: IMPACT_CLICK_ID }
      : undefined;

    const unpackingScheduledTime =
      dateScheduled && unpackingScheduled
        ? DateTime.fromISO(dateScheduled.fromTime)
            .set({
              year: unpackingScheduled.year,
              month: unpackingScheduled.month,
              day: unpackingScheduled.day,
            })
            .toISO()
        : undefined;

    const moveInput: Moving__MoveInput = {
      name: name!,
      phone: phone!,
      email: email!,
      zip: zip!,
      token: stripeToken && { id: stripeToken!.id },
      scheduled: dateScheduled!.fromTime!,
      originAddress: buildInputMovingAddress({
        ...startAddress,
        ...startAddressDetails,
      }),
      destinationAddress: buildInputMovingAddress({
        ...endAddress,
        buildingType: startAddressDetails?.buildingType,
        floor: 1,
        stories: 1,
      }),
      unitTypes: sanitizeUnitTypesInput(startAddress!, rooms),
      movingConfigurationID: movingQuote.movingConfiguration!.id,
      leadToken,
      laborRateID: movingQuote.laborRate!.id,
      packageSetEntryIDs: movingQuote.materialPackageSetEntry
        ? [movingQuote.materialPackageSetEntry.id]
        : [],
      packing: packingHelp!,
      moveSize: Moving__MoveSizeEnum.Few || null,
      movingQuoteSignedID: movingQuote.signedID,
      promoCode: coupon?.promoCode,
      financingIntent: null,
      marketingData,
      freeStorage: !!freeStorageSelected,
      disposalRequested: !!disposalSelected,
      moverOverride: adjustedMoverCount,
      slaWindow: dateScheduled!.duration,
      unpackingScheduled: unpackingScheduledTime,
      whiteGloveBundle: movingBundle,
      flatRate: !!flatRateOption({ movingQuote, movingBundle }),
    };

    const { data } = await moveCreate({
      variables: { input: moveInput, storagePlanInput },
    });

    if (!data || data.moveCreate?.error) {
      throw new GQLError(
        data?.moveCreate?.error ?? {
          errorCode: ErrorCodeEnum.Unknown,
          errorMessage: 'Unknown server error',
        },
      );
    }

    createThirdPartyConversionEvent(EventName.CHECKOUT, {
      value: movingQuote.laborRate?.amount,
      zip_code: zip,
      product_type: ProductType.MOVING,
      currency: 'USD',
      orderID: data.moveCreate?.orderID,
    });

    const accountPortalUrl = data.moveCreate?.accountPortalUrl;
    const orderToken = data.moveCreate?.orderToken;

    if (!accountPortalUrl || !orderToken) {
      throw new Error('Create move failed');
    }
    trackMoveReserved({ orderId: data.moveCreate.orderID! });

    return {
      accountPortalUrl,
      orderToken,
    };
  };
}
