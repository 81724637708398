import React, { useEffect } from 'react';

import { DoorToDoor__BundleEnum, RateGroupKindEnum } from '@graphql/platform';

import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { BundleCards } from '@root/components/checkout/product_pages/steps/smart_storage/bundles/bundle_cards';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';

import { useFreePickupReturnEligible } from '@utils/hooks/use_free_pickup_return_eligible';

export const Bundles: React.FC<StorageCheckoutStepProps> = (props) => {
  const { subjobPackageEligible } = useStorageCheckoutContext();
  const {
    onChange,
    scrollToStep,
    values: { bundleKind, commitment, leadToken },
  } = props;
  const track = useTrack();

  useEffect(() => {
    if (commitment === RateGroupKindEnum.Mover)
      onChange('bundleKind', undefined);
  }, [commitment]);

  const freePickupReturnEligible = useFreePickupReturnEligible();

  const handleChange = (value: DoorToDoor__BundleEnum) => {
    onChange('bundleKind', value);
    track({ value, label: 'Pickup package', objectName: 'package_radio_card' });
    scrollToStep('next');
  };

  return (
    <BundleCards
      selectedBundleKind={bundleKind}
      showPrice={!!leadToken}
      onBundleKindSelect={handleChange}
      showFreeDurationDisclaimer={
        !subjobPackageEligible || !freePickupReturnEligible
      }
    />
  );
};
