import React, { useEffect, useState } from 'react';

import { Box, ButtonLink, Modal, Text } from '@clutter/clean';

import {
  clearBookedFlag,
  fetchBookedFlag,
} from '@root/components/checkout/utilities/persistence';
import { useTrack } from '@root/initializers/wt';

export const PreviousBookingWarningModal = () => {
  const [isOpen] = useState(fetchBookedFlag);
  const track = useTrack();

  useEffect(() => {
    track({
      action: 'display',
      objectName: 'previous_booking_warning_modal',
    });
    clearBookedFlag();
  });

  return (
    <Modal
      isOpen={isOpen}
      includeCloseButton={false}
      handleModalClose={() => {
        /* don't allow for closing the modal by clicking the overlay */
      }}
    >
      <Box padding="24px" width="calc(100vw - 48px)" maxWidth="582px">
        <Box textAlign="center" margin="0 0 16px">
          <Text.Title size="medium">Back so soon?</Text.Title>
        </Box>
        <Text.Body>
          It looks like you just booked an appointment with Clutter. If you want
          to make changes or cancel your appointment, head to your Account
          Portal.
        </Text.Body>
        <Box.Flex margin="24px 0 0">
          <ButtonLink fullWidth href="https://account.clutter.com">
            Go to your Account Portal
          </ButtonLink>
        </Box.Flex>
      </Box>
    </Modal>
  );
};
