import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from '@emotion/styled';

import { Box, Button, COLORS } from '@clutter/clean';

import { Lead__ServiceNeeds, useGeocodeQuery } from '@graphql/platform';

import { AbandonmentModal } from '@root/components/checkout/abandonment_modal';
import { ShareableHero } from '@root/components/checkout/product_pages/subcomponents/shareable_hero';
import { resolveEnabledSteps } from '@root/components/checkout/utilities/enabled_steps';
import { WTProvider, useTrack } from '@root/initializers/wt';
import { ServiceEnum } from '@root/resources/types/service';
import { WWW_ROUTES } from '@root/root/routes';

import { useClientDataContext } from '@shared/client_data_context';
import { DESKTOP_HEADER_HEIGHT_INT } from '@shared/header/header';
import { QualifiedContactWidget } from '@shared/qualified_contact_widget';
import { ServiceSelection } from '@shared/service_selector/constants';

import { useFlowInitiatedFunnelEvents } from '@utils/hooks/funnel_events/use_flow_initiated_funnel_event';
import { useOnMount } from '@utils/hooks/mount';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import {
  EventName,
  ProductType,
  createThirdPartyConversionEvent,
} from '@utils/third_party_conversion_events';

import { FAQ } from '../../shared/faq/faq';
import { buildSmartStorageFAQContentList } from '../../shared/faq/utils';
import { ReviewCarousel } from '../../shared/page_builder/components/review_carousel';
import { SMART_STORAGE_TESTIMONIALS } from '../../shared/page_builder/components/review_carousel/data';
import { useStorageCheckoutContext } from '../context';
import { CheckoutType, StorageCheckoutStep } from '../types';
import {
  createBoundScrollToStep,
  scrollToStep,
} from '../utilities/scroll_animation';
import { Layout } from './layout';
import { DESKTOP_PLAN_SELECTOR_SIZE } from './steps/smart_storage/plan_size';
import { Modal as CouponModal } from './subcomponents/coupon/modal';
import { FeaturesOverlay } from './subcomponents/features_overlay';
import { SMART_STORAGE_FEATURES } from './subcomponents/features_overlay/data';
import { PlanIllustrations } from './subcomponents/plan_illustrations';
import { ReserveButton } from './subcomponents/reserve_button';
import { SmartStorageHero } from './subcomponents/smart_storage_hero';
import { StorageMap } from './subcomponents/storage_map';
import { StoragePlanValueProps } from './subcomponents/storage_plan_value_props';

export const STICKY_CONTENT_CONTAINER_HEIGHT = 564;
const HERO_HEIGHT = 424;
const FALLBACK_ADDRESS_OFFSET = DESKTOP_HEADER_HEIGHT_INT - HERO_HEIGHT - 60; // Where we think the address step will be relative to the top of the page before measuring

const MapContainer = styled.div`
  border-radius: 4px;
  overflow: hidden;
  width: 100%;
`;

const FeaturesButton = styled(Button)`
  background: ${COLORS.cloud};
`;

const getMapOffset = (container: HTMLDivElement | null) => {
  const element = document.querySelector<HTMLDivElement>(
    '#' + StorageCheckoutStep.AddressAndLeadInfo,
  );
  const offset = element?.offsetTop ?? 0;
  const containerOffset = container
    ? container.offsetTop
    : FALLBACK_ADDRESS_OFFSET;
  return offset - containerOffset;
};

let cachedShowMobileReserveButton = false;

const Steps: React.FC<{ onShowFeaturesOverlay(): void }> = ({
  onShowFeaturesOverlay,
}) => {
  const track = useTrack({
    action: 'click',
  });
  const leftContainerRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [planContainerHeight, setPlanContainerHeight] = useState(0);
  const [showMobileReserveButton, setShowMobileReserveButton] = useState(
    cachedShowMobileReserveButton,
  );
  const { isDesktop } = useBreakpoints();
  const {
    data: {
      lead: { token },
    },
  } = useClientDataContext();

  const {
    resolvedSteps,
    disposalEligible,
    flowState: { steps, getStepProps, onChange, goToStep, values },
    navigationState: { hasViewedCart, targetStep, dispatch },
  } = useStorageCheckoutContext();

  useOnMount(() => {
    if (hasViewedCart && targetStep) {
      // If a target step is present, that means we are coming from the edit
      // page via a "back" navigation, but we also might want to focus a
      // particular step.
      setTimeout(() => {
        dispatch({ type: 'setTargetStep', payload: { step: null } });
        scrollToStep(targetStep);
      });
    }
  });

  const { data, loading: loadingGeocode } = useGeocodeQuery({
    variables: { zip: values.zip! },
    skip: !values.zip,
  });
  const storageEligible = !!data?.geocode?.eligibleForDoorToDoor;

  const initiateFlowTriggeredRef = useRef<boolean>(false);

  useEffect(() => {
    if (storageEligible && !initiateFlowTriggeredRef.current) {
      initiateFlowTriggeredRef.current = true;
      createThirdPartyConversionEvent(EventName.INITIATE_FLOW, {
        zip_code: values.zip,
        checkout_version: 'two_page_storage',
        product_type: ProductType.SMART_STORAGE,
      });
    }
  }, [storageEligible]);

  useOnMount(() => {
    setPlanContainerHeight(getMapOffset(leftContainerRef.current));

    const listener = () => {
      setPlanContainerHeight(getMapOffset(leftContainerRef.current));
    };
    addEventListener('resize', listener);

    return () => removeEventListener('resize', listener);
  });

  const [completionAggregate, enabledSteps] = resolveEnabledSteps(
    resolvedSteps,
    {
      values,
      leadToken: token,
      disposalEligible,
    },
  );

  useEffect(() => {
    if (completionAggregate) {
      cachedShowMobileReserveButton = true;
      setShowMobileReserveButton(true);
    }
  }, [completionAggregate]);

  const handleClick = () => {
    track({
      objectName: 'features',
      container: 'plan_illustration',
      objectType: 'button',
      label: 'Learn more',
    });
    onShowFeaturesOverlay();
  };

  const handleReserveButtonClick = async () => {
    history.push(WWW_ROUTES.STORAGE_CART);
    goToStep(StorageCheckoutStep.Cart);
  };

  const reserveButtonDisabled =
    !completionAggregate || loadingGeocode || !storageEligible;

  return (
    <Layout.StepsOuter>
      <Layout.StepsLeft ref={leftContainerRef}>
        <Box position="relative" height={planContainerHeight + 'px'}>
          <Layout.StickyContainer
            containerHeight={STICKY_CONTENT_CONTAINER_HEIGHT}
          >
            <Box height={DESKTOP_PLAN_SELECTOR_SIZE}>
              <PlanIllustrations />
            </Box>
            <Box margin="24px 0 0" padding="0 0 128px">
              <StoragePlanValueProps />
              <Box margin="40px 0 0" textAlign="center">
                <FeaturesButton kind="secondary" onClick={handleClick}>
                  Learn more
                </FeaturesButton>
              </Box>
            </Box>
          </Layout.StickyContainer>
        </Box>
        <Layout.StickyContainer
          containerHeight={STICKY_CONTENT_CONTAINER_HEIGHT}
        >
          <MapContainer>
            <StorageMap zip={values.zip!} address={values.address} />
          </MapContainer>
        </Layout.StickyContainer>
      </Layout.StepsLeft>
      <Layout.StepsRight>
        {steps.map((Step, i) => {
          const { name: stepName } = resolvedSteps[i];
          const enabled = enabledSteps[i];

          return (
            <WTProvider
              key={stepName}
              params={{
                container: stepName,
                position: i,
              }}
            >
              <Layout.StepContainer id={stepName} enabled={enabled}>
                <Step
                  {...getStepProps()}
                  enabled={enabled}
                  scrollToStep={createBoundScrollToStep(i, resolvedSteps)}
                  onChange={
                    ((key, value, cb) => {
                      onChange(key, value, (...args) => {
                        goToStep(i);
                        cb?.(...args);
                      });
                    }) as typeof onChange
                  }
                />
              </Layout.StepContainer>
            </WTProvider>
          );
        })}
        <QualifiedContactWidget
          bottomOffset={
            !isDesktop && (showMobileReserveButton || hasViewedCart) ? 88 : 0
          }
        />
        <ReserveButton
          sticky={showMobileReserveButton}
          disabled={reserveButtonDisabled}
          hasViewedCart={hasViewedCart}
          service={Lead__ServiceNeeds.SmartStorage}
          onClick={handleReserveButtonClick}
        />
      </Layout.StepsRight>
    </Layout.StepsOuter>
  );
};

export const StorageProductPage = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  const {
    flowState: {
      values: { checkoutType, name, customerToken },
    },
    subjobPackageEligible,
  } = useStorageCheckoutContext();

  const onShowFeaturesOverlay = () => setShowOverlay(true);

  const {
    data: { urlPromoCode },
  } = useClientDataContext();

  const faqContentList = buildSmartStorageFAQContentList(subjobPackageEligible);

  useFlowInitiatedFunnelEvents();

  return (
    <Layout isLoggedIn={!!customerToken}>
      {checkoutType === CheckoutType.Shareable ? (
        <ShareableHero name={name} service={ServiceEnum.DoorToDoor} />
      ) : (
        <SmartStorageHero onShowFeaturesOverlay={onShowFeaturesOverlay} />
      )}
      <Steps onShowFeaturesOverlay={onShowFeaturesOverlay} />
      <ReviewCarousel reviews={SMART_STORAGE_TESTIMONIALS} />
      <FAQ title="Smart Storage FAQ" contentList={faqContentList} />
      <FeaturesOverlay
        isOpen={showOverlay}
        onClose={() => setShowOverlay(false)}
        content={SMART_STORAGE_FEATURES}
      />
      <AbandonmentModal allowOpen={!urlPromoCode} />
      <CouponModal serviceSelection={ServiceSelection.Storage} />
    </Layout>
  );
};
