import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';

import { Currency } from '@shared/currency';

import { SmartStorageBillingModal } from './smart_storage_billing_modal';
import { SmartStorageFinalReturnBillingModal } from './smart_storage_final_return_billing_modal';

const ModalButton = styled(UnstyledButton)`
  color: ${COLORS.tealPrimary};
  &:hover {
    color: ${COLORS.__primaryButtonHover};
  }
`;

export const DueToday: React.FC<{
  monthlyTotalAmount?: number;
  onboardingFeeAmount?: number;
  planSizeLabel?: string;
}> = ({ monthlyTotalAmount, onboardingFeeAmount, planSizeLabel }) => {
  const [showBillingModal, setShowBillingModal] = useState<boolean>(false);
  const [showFinalBillingModal, setShowFinalBillingModal] =
    useState<boolean>(false);

  return (
    <Box background={COLORS.tealBackground} borderRadius="4px" padding="8px">
      {(monthlyTotalAmount !== undefined ||
        onboardingFeeAmount !== undefined) && (
        <>
          <Box.Flex margin="0 0 8px 0" justifyContent="space-between">
            <Text.Callout>{planSizeLabel} Unit</Text.Callout>
            <Text.Callout>
              <Currency amount={monthlyTotalAmount || 0} />
            </Text.Callout>
          </Box.Flex>
          <Box.Flex margin="0 0 12px 0" justifyContent="space-between">
            <Text.Callout>One-time pickup fee</Text.Callout>
            <Text.Callout>
              <Currency amount={onboardingFeeAmount || 0} />
            </Text.Callout>
          </Box.Flex>
        </>
      )}
      <Box.Flex justifyContent="space-between" margin="0 0 12px">
        <Text.Body weight={FontWeight.Medium}>Due today</Text.Body>
        <Text.Body weight={FontWeight.Medium}>$0</Text.Body>
      </Box.Flex>
      <Box.Flex flexDirection="column" gap="16px" alignItems="flex-start">
        <ModalButton>
          <Text.Callout
            weight={FontWeight.Medium}
            onClick={() => setShowBillingModal(true)}
          >
            When will I be charged?
          </Text.Callout>
        </ModalButton>
        <ModalButton>
          <Text.Callout
            weight={FontWeight.Medium}
            onClick={() => setShowFinalBillingModal(true)}
          >
            What does the final delivery cost?
          </Text.Callout>
        </ModalButton>
      </Box.Flex>
      <SmartStorageBillingModal
        isOpen={showBillingModal}
        handleModalClose={() => setShowBillingModal(false)}
      />
      <SmartStorageFinalReturnBillingModal
        isOpen={showFinalBillingModal}
        handleModalClose={() => setShowFinalBillingModal(false)}
      />
    </Box>
  );
};
