import React from 'react';

import { Box, SpinLoader, Text } from '@clutter/clean';

import { MovingCartValueProps } from '@root/components/checkout/cart/moving_cart_value_props';
import { StorageCartValueProps } from '@root/components/checkout/cart/storage_cart_value_props';
import { Layout } from '@root/components/checkout/product_pages/layout';
import { CheckoutType } from '@root/components/checkout/types';

export const LoadingState: React.FC<{
  checkoutType: CheckoutType;
  service: 'storage' | 'moving';
}> = ({ checkoutType, service }) => {
  return (
    <Layout isLoggedIn={false}>
      <Box.Flex
        minHeight="100vh"
        justifyContent="center"
        alignItems="flex-start"
      >
        {checkoutType !== CheckoutType.Standard && (
          <Box margin="60px 0 0" textAlign="center">
            <Text.Title size="large">Hang tight</Text.Title>
            <Box margin="16px 0 0">
              <Text.Body>
                We’re loading your information so you can pick up right where
                you left off.
              </Text.Body>
            </Box>
            <Box textAlign="center" margin="60px 0">
              <SpinLoader />
            </Box>
            <Box>
              {service === 'storage' ? (
                <StorageCartValueProps />
              ) : (
                <MovingCartValueProps />
              )}
            </Box>
          </Box>
        )}
      </Box.Flex>
    </Layout>
  );
};
