/* @jsx jsx */
import * as React from 'react';
import { Link } from 'react-router-dom';

import { DateTime } from 'luxon';

import { jsx } from '@emotion/react';
import styled from '@emotion/styled';

import {
  COLORS,
  FontWeight,
  ResponsiveContainer,
  SkeletonLoader,
  Text,
  TextButton,
  mq,
  transparentize,
} from '@clutter/clean';

import { Help__Article, Help__Section } from '@graphql/landing';

import { articleURL } from '../routes';
import { Repeat } from '../utils/repeat';

const Container = styled.section`
  background: ${COLORS.grayBackground};

  ${mq({ padding: ['56px 0px 40px', null, '108px 0px 104px'] })}
`;

const SectionTitle = styled(Text.Title)`
  ${mq({ marginBottom: ['24px', null, '32px'] })}
`;

const List = styled.div<{ expanded: boolean }>`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 24px;

  ${mq({
    gridTemplateColumns: ['100%', null, 'repeat(4, 1fr)'],
  })}

  > :nth-of-type(n + 4) {
    ${({ expanded }) =>
      mq({ display: [expanded ? 'inherit' : 'none', null, 'inherit'] })}
  }
`;

const ArticleContainer = styled.div`
  display: flex;
  padding: 24px 20px 16px;
  background: ${COLORS.cloud};
  box-shadow: 0px 4px 12px ${transparentize(COLORS.panther, 0.1)};
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
`;

const ArticleLink = ArticleContainer.withComponent(Link);

const ArticleSection = styled.div`
  color: ${COLORS.tealDark};
  flex-grow: 0;
  flex-shrink: 0;
`;

const ArticleTitle = styled.div`
  margin: 8px 0px 24px;
  flex-grow: 1;
  flex-shrink: 0;
  color: ${COLORS.tealPrimary};
`;

const ArticleDate = styled.div`
  margin: 0 0 auto;
  flex-grow: 0;
  flex-shrink: 0;
  color: ${COLORS.hippo};
`;

const ShowMoreContainer = styled.div`
  margin-top: 40px;
  text-align: center;

  ${mq({ display: ['inherit', null, 'none'] })}
`;

const Article: React.FC<{
  article: Pick<Help__Article, 'id' | 'title' | 'editedAt'> & {
    section: Pick<Help__Section, 'id' | 'name'>;
  };
}> = ({ article }) => (
  <ArticleLink to={articleURL(article.id)}>
    <ArticleSection>
      <Text.Caption weight={FontWeight.Medium}>
        {article.section.name}
      </Text.Caption>
    </ArticleSection>
    <ArticleTitle>
      <Text.Body weight={FontWeight.Medium}>{article.title}</Text.Body>
    </ArticleTitle>
    <ArticleDate>
      <Text.Caption>
        {DateTime.fromISO(article.editedAt).toLocaleString(DateTime.DATE_MED)}
      </Text.Caption>
    </ArticleDate>
  </ArticleLink>
);

const FakeArticle: React.FC = () => (
  <ArticleContainer>
    <ArticleSection>
      <SkeletonLoader width="100%" height="17px" />
    </ArticleSection>
    <ArticleTitle>
      <SkeletonLoader width="100%" height="24px" />
    </ArticleTitle>
    <ArticleDate>
      <SkeletonLoader width="100%" height="17px" />
    </ArticleDate>
  </ArticleContainer>
);

export const FeaturedArticles: React.FC<{
  articles?: ReadonlyArray<
    Pick<Help__Article, 'id' | 'title' | 'editedAt'> & {
      section: Pick<Help__Section, 'id' | 'name'>;
    }
  >;
}> = ({ articles }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <Container>
      <ResponsiveContainer>
        <SectionTitle size="medium">Featured Articles</SectionTitle>
        <List expanded={expanded}>
          {articles ? (
            articles.map((article) => (
              <Article key={article.id} article={article} />
            ))
          ) : (
            <Repeat times={4} component={FakeArticle} />
          )}
        </List>
        <ShowMoreContainer>
          <TextButton onClick={() => setExpanded(!expanded)}>
            See {expanded ? 'Fewer' : 'More'} Featured Articles
          </TextButton>
        </ShowMoreContainer>
      </ResponsiveContainer>
    </Container>
  );
};
