import React from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, SpinLoader, Text } from '@clutter/clean';

import { DraftFragment, Lead__ServiceNeeds, Maybe } from '@graphql/platform';

import { DraftContent } from './draft_content';
import { ServiceSelectionContent } from './service_selection_content';

const TitleContainer = styled.div`
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${COLORS.grayBorder};
`;

const CART_SERVICE_NEEDS = [
  Lead__ServiceNeeds.SmartStorage,
  Lead__ServiceNeeds.Moving,
];

export const Content: React.FC<{
  draft?: Maybe<DraftFragment>;
  loading?: boolean;
}> = ({ draft, loading }) => {
  const hasDraftContent =
    draft?.latestService && CART_SERVICE_NEEDS.includes(draft.latestService);

  if (loading) {
    return <SpinLoader />;
  }
  return (
    <>
      <TitleContainer>
        <Text.Title size="small" color={COLORS.tealDark}>
          {hasDraftContent ? 'In your cart' : 'Your cart is empty'}
        </Text.Title>
      </TitleContainer>
      {hasDraftContent ? (
        <Box maxWidth={['initial', null, '484px']}>
          <DraftContent draft={draft} />
        </Box>
      ) : (
        <Box maxWidth={['initial', null, '280px']}>
          <ServiceSelectionContent />
        </Box>
      )}
    </>
  );
};
