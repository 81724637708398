import React from 'react';

import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';

import { useTrack } from '@root/initializers/wt';

import { useOnMount } from '@utils/hooks/mount';
import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import userCart from '@images/icons/user-cart.svg';

const BUTTON_LABEL = 'Your Cart';

export const ToggleButton: React.FC<{
  isOpen: boolean;
  count: number;
  onToggle: () => void;
}> = ({ isOpen, count, onToggle }) => {
  const { isDesktop } = useIsomorphicBreakpoints();
  const track = useTrack({
    label: BUTTON_LABEL,
    container: 'header',
    objectType: 'button',
    objectName: 'cart_preview_toggle',
  });

  useOnMount(() => {
    track({ action: 'display' });
  });

  const onClick = () => {
    track({
      action: 'click',
      metadata: { preview_open: isOpen },
    });
    onToggle();
  };
  return (
    <Box width="128px">
      <UnstyledButton onClick={onClick}>
        <Box.Flex
          alignItems="center"
          borderRadius="42px"
          background={
            isDesktop && isOpen ? COLORS.tealBackground : COLORS.cloud
          }
          boxShadow={
            isDesktop
              ? isOpen
                ? `0 0 0 2px  ${COLORS.tealPrimary}`
                : `0 0 0 1px ${COLORS.grayBorder}`
              : 'none'
          }
          padding={['0', null, '8px 12px']}
          position="relative"
        >
          {!!count && (
            <Box
              position="absolute"
              background={COLORS.tealPrimary}
              color={COLORS.cloud}
              borderRadius="100px"
              height="24px"
              width="24px"
              right={['-18px', null, '-8px']}
              top={['-12px', null, '-6px']}
            >
              <Text.Body>{count}</Text.Body>
            </Box>
          )}
          <Box.FlexItem margin="0 4px 0 0">
            <img src={userCart} alt="cart" width={24} height={24} />
          </Box.FlexItem>
          <Text.Body weight={FontWeight.Medium}>{BUTTON_LABEL}</Text.Body>
        </Box.Flex>
      </UnstyledButton>
    </Box>
  );
};
