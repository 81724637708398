import React, { useState } from 'react';

import styled from '@emotion/styled';

import { COLORS, Column, FontWeight, Grid, Text, mq } from '@clutter/clean';

import { CollapsibleCard } from '@shared/collapsible_card';
import { DesktopOnly, MobileOnly } from '@shared/screen_size_filters';

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  ${mq({
    padding: ['54px 0 0 0', null, '120px 0'],
  })};
`;

const FluidContainer = styled.div`
  ${mq({
    margin: [0, 0, '0 24px'],
  })}
  max-width: 1200px;
`;

const HeadingColumn = styled(Column)`
  display: flex;
  justify-content: center;
  ${mq({
    textAlign: ['center', null, 'left'],
    marginBottom: ['34px', null, 0],
  })}
`;

const Heading = styled(Text.Title)`
  ${mq({
    lineHeight: ['40px', null, null, '54px'],
  })}
`;

const StorageTitles = styled.div`
  display: flex;
`;

const StorageTitle = styled.div<{ selected: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  color: ${({ selected }) => (selected ? COLORS.tealPrimary : COLORS.hippo)};
  border-bottom-color: ${({ selected }) =>
    selected ? COLORS.tealPrimary : COLORS.grayBorder};
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  height: 48px;

  &:hover {
    color: ${COLORS.tealPrimary};
    border-bottom-color: ${COLORS.tealPrimary};
    cursor: pointer;
  }
`;

const DesktopStorageTitleText = styled(Text.Title)`
  line-height: 48px;
`;

const MobileStorageTitleText = styled(Text.Body)`
  line-height: 32px;
`;

const StorageTitleText: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <>
    <DesktopOnly>
      <DesktopStorageTitleText size="small">{children}</DesktopStorageTitleText>
    </DesktopOnly>
    <MobileOnly>
      <MobileStorageTitleText weight={FontWeight.Medium}>
        {children}
      </MobileStorageTitleText>
    </MobileOnly>
  </>
);

const Questions = styled.div<{ showBorderTop: boolean }>`
  border-top: ${({ showBorderTop }) =>
    showBorderTop && `1.5px solid ${COLORS.grayBorder}`};
  .card {
    border-bottom: 1.5px solid ${COLORS.grayBorder};
    &:last-of-type {
      margin-bottom: 0px;
    }
    &:hover {
      h3 {
        color: ${COLORS.tealPrimary};
      }
    }
  }
`;

type FAQProps = {
  headline: React.ReactNode;
  categories: Array<{
    title?: string;
    questions: Array<{ question: string; answer: React.ReactNode }>;
  }>;
};

export const FAQCategories: React.FC<FAQProps> = ({ headline, categories }) => {
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  return (
    <Container>
      <FluidContainer>
        <Grid wrap>
          <HeadingColumn widths={[12, 12, 5]}>
            <Heading size="large">{headline}</Heading>
          </HeadingColumn>
          <Column widths={[12, 12, 7]}>
            {categories.length > 1 && (
              <StorageTitles>
                {categories.map(({ title }, idx) => (
                  <StorageTitle
                    key={title}
                    onClick={() => setSelectedCategoryIndex(idx)}
                    selected={idx === selectedCategoryIndex}
                  >
                    <StorageTitleText>{title}</StorageTitleText>
                  </StorageTitle>
                ))}
              </StorageTitles>
            )}
            <Questions showBorderTop={categories.length > 1}>
              {categories[selectedCategoryIndex].questions.map(
                ({ question, answer }, i) => (
                  <CollapsibleCard
                    key={i}
                    title={question}
                    position={i}
                    className="card"
                  >
                    {answer}
                  </CollapsibleCard>
                ),
              )}
            </Questions>
          </Column>
        </Grid>
      </FluidContainer>
    </Container>
  );
};
