import React from 'react';

import styled from '@emotion/styled';

import { COLORS, Column, FontWeight, Text, mq } from '@clutter/clean';

import { DeferredImage } from '@shared/deferred_image';
import { Spacer } from '@shared/spacer';

import { useDeferredImage } from '@utils/hooks';

import phoneOutline from '@images/home/phone.svg';

import { ButtonCTA } from '../../shared/button_cta';
import phoneVideo from '@videos/home/booking_animation.mp4';

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${mq({
    flexWrap: ['wrap', null, 'nowrap'],
    padding: ['60px 24px', null, '100px 24px'],
  })}
`;

const PhoneSection = styled(Column)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const PhoneContainer = styled.div`
  max-width: 374px;
  max-height: 762px;
  position: relative;
`;

const PhoneVideo = styled.video`
  position: absolute;
  border: 1.5px solid ${COLORS.grayBorder};
  border-radius: 4.75px;
  top: 11.45%;
  left: 5.95%;
  width: 88.64%;
`;

const DescriptionSection = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;

  ${mq({
    marginTop: ['40px', null, 0],
    textAlign: ['center', null, 'left'],
    alignItems: ['center', null, 'flex-start'],
  })}

  button {
    ${mq({
      width: ['100%', 'initial'],
    })}
  }
`;

const DescriptionHeader = styled(Text.Headline)`
  margin-bottom: 4px;
`;

const Header: React.FC<{
  className?: string;
}> = ({ className }) => (
  <div className={className}>
    <Text.Title size="large">
      Affordable move-in,
      <br /> on demand access
    </Text.Title>
  </div>
);

const HeaderDesktop = styled(Header)`
  margin-bottom: 40px;
  ${mq({
    display: ['none', null, 'block'],
  })}
`;

const HeaderMobile = styled(Header)`
  margin-bottom: 30px;
  text-align: center;
  ${mq({
    display: ['block', null, 'none'],
  })}
`;

export const WarehouseStorage: React.FC = () => {
  const { resolvedImage, targetRef } = useDeferredImage(phoneVideo);

  return (
    <Container ref={targetRef}>
      <HeaderMobile />
      <PhoneSection widths={[12, 12, 6]}>
        <PhoneContainer>
          <DeferredImage src={phoneOutline} width={374} height={762} />
          <PhoneVideo {...resolvedImage} muted autoPlay loop playsInline />
        </PhoneContainer>
      </PhoneSection>
      <DescriptionSection widths={[12, 12, 6]}>
        <HeaderDesktop />
        <DescriptionHeader weight={FontWeight.Medium}>
          Our lowest monthly rate
        </DescriptionHeader>
        <Text.Body>
          Because we store your items in our secure
          <br />
          warehouses outside of the city, we can
          <br />
          pass along savings to you.
        </Text.Body>
        <br />
        <DescriptionHeader weight={FontWeight.Medium}>
          Online photo catalog
        </DescriptionHeader>
        <Text.Body>
          Browse your items anytime from the
          <br />
          convenience of your home.
        </Text.Body>
        <br />
        <DescriptionHeader weight={FontWeight.Medium}>
          Flexible, affordable access
        </DescriptionHeader>
        <Text.Body>
          Tap the items you want in your online
          <br />
          photo catalog and we’ll deliver them to a
          <br />
          location of your choice.
        </Text.Body>
        <Spacer height="40px" />
        <ButtonCTA
          container="product_offering"
          offeringType="on_demand"
          label="Get a Quote"
          entryPoint="door_to_door_cta"
        />
      </DescriptionSection>
    </Container>
  );
};
