import React from 'react';

import { MovingPreview } from '@root/components/www/shared/moving_preview';
import { ProductComparison } from '@root/components/www/shared/product_comparison';
import { ProductHero } from '@root/components/www/shared/product_hero';
import { ProductOverview } from '@root/components/www/shared/product_overview';
import { ProductTeam } from '@root/components/www/shared/product_team';
import { MOVING_FAQ_CONTENT } from '@root/constants/faq';

import { CheckType } from '@shared/comparison_matrix';
import { Footer } from '@shared/content/footer';
import { IconValueProps } from '@shared/page_builder/components/icon_value_props';
import { ResponsiveBreak } from '@shared/responsive_breaks';
import { ServiceSelection } from '@shared/service_selector/constants';
import { ZebraContainer, ZebraPattern } from '@shared/zebra_container';

import calendar from '@images/illustrations/calendar.svg';
import clutterTruck from '@images/illustrations/clutter_truck.svg';
import truckTracking from '@images/illustrations/location_truck_tracking.svg';
import moverTruck from '@images/illustrations/mover_with_truck.svg';
import tvMovers from '@images/illustrations/movers_with_tv_small.svg';
import photoCatalog from '@images/illustrations/online_photo_catalog_small.svg';
import onlineQuote from '@images/illustrations/online_quote_check.svg';
import movingSecondaryImage from '@images_responsive/www/services/mover_unloading_box.jpg';
import movingHero from '@images_responsive/www/services/moving_hero.jpg';
import movingMainImage from '@images_responsive/www/services/moving_open_window.jpg';

import { Header } from '../../../shared/header/header';
import { FAQCategories } from '../faq_categories';

const valueProps = [
  {
    title: 'Transparent pricing',
    description:
      'Answer a few questions and we’ll get you a customized quote in under 2 minutes.',
    illustration: onlineQuote,
  },
  {
    title: 'Don’t lift a finger',
    description:
      'We’ll bring all the packing supplies needed to efficiently pack and move your items to your new home.',
    illustration: tvMovers,
  },
  {
    title: 'Professional team',
    description:
      'Our team is trained to keep your items safe every step of the way.',
    illustration: moverTruck,
  },
  {
    title: 'Flexible scheduling',
    description:
      'We’re here whenever you need us. Schedule your move on the most convenient day and time for you.',
    illustration: calendar,
  },
];

const serviceFeatures = [
  {
    serviceName: 'Truck',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.Check,
  },
  {
    serviceName: 'Packing supplies',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.Check,
  },
  {
    serviceName: 'Packing help',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Furniture protection',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Disassembly & reassembly',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Reliable movers',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Professional driver',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Track your truck online',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Digital walkthrough',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Online move management',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Accurate quote',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
];

const movingValueProps = [
  {
    title: 'Digital walkthroughs',
    description:
      'Every move is different! Complete a digital walkthrough of your home so our team can come prepared for your move.',
    illustration: photoCatalog,
  },
  {
    title: 'Track your truck online',
    description:
      'The day of your move you’ll be able to track our team and know exactly when they’ll arrive at your home.',
    illustration: truckTracking,
  },
];

const teamValueProps = [
  {
    headline: 'Hand Selected',
    description:
      'We’ve invested in building a full-time team of professional movers who are thoroughly vetted to be the best of the best.',
  },
  {
    headline: 'Valued',
    description:
      'All team members are W-2 workers that receive full benefits like health insurance and paid time off.',
  },
  {
    headline: 'Trustworthy',
    description:
      'We train our team to sweat the details and to handle your items with the utmost care. The things you love, are safe with us.',
  },
];

export const MovingServicePage = () => {
  return (
    <>
      <Header service={ServiceSelection.Moving} />
      <ZebraContainer zebra={ZebraPattern.LIGHT_THEN_DARK}>
        <ProductHero
          title="Moving"
          subtitle="Stress-free moving & packing with transparent prices."
          image={movingHero}
          service={ServiceSelection.Moving}
        />
        <ProductOverview
          title="Movers you can trust"
          subtitle="Get a transparent quote online in minutes."
          mainImage={movingMainImage}
          secondaryImage={movingSecondaryImage}
          service={ServiceSelection.Moving}
          valueProps={[
            'Simple pricing',
            'Packing help & supplies',
            'Truck & professional driver',
            'Efficient & friendly movers',
          ]}
        />
        <IconValueProps
          title="Effortless, affordable moving"
          valueProps={valueProps}
          cta={{ service: ServiceSelection.Moving }}
        />
        <ProductComparison
          title="Unbeatable service"
          legend={[
            { icon: clutterTruck },
            {
              name: (
                <>
                  Local <ResponsiveBreak mode="only" sm />
                  Movers
                </>
              ),
            },
            {
              name: (
                <>
                  U-Haul <ResponsiveBreak mode="only" sm />
                  Moving
                </>
              ),
            },
          ]}
          serviceFeatures={serviceFeatures}
          service={ServiceSelection.Moving}
        />
        <MovingPreview
          title="A technology-first approach to moving"
          valueProps={movingValueProps}
        />
        <ProductTeam title="Your moving team" content={teamValueProps} />
        <FAQCategories
          headline={
            <>
              A fresh take on the <br />
              moving industry
            </>
          }
          categories={[{ questions: MOVING_FAQ_CONTENT }]}
        />
      </ZebraContainer>
      <Footer />
    </>
  );
};
