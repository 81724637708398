import React from 'react';

import styled from '@emotion/styled';

import { BREAKPOINTS, COLORS, getPropValue } from '@clutter/clean';

import { useBreakpoint } from '@utils/hooks';

const Grid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${getPropValue('columns')}, 1fr);
  border: 1px solid ${COLORS.grayBorder};
  border-radius: 4px;

  & > div {
    border: 1px solid ${COLORS.grayBorder};
    border-left: none;
    border-top: none;

    &:nth-of-type(${getPropValue('columns')}n) {
      border-right: none;
    }

    &:nth-last-of-type(-n + ${getPropValue('columns')}) {
      border-bottom: none;
    }
  }
`;

export const BookingDetailsGrid: React.FC<{
  columns: number;
  children: React.ReactElement[];
}> = ({ children, columns }) => {
  const multiColumn = useBreakpoint(BREAKPOINTS.LG_INT);
  return <Grid columns={multiColumn ? columns : 1}>{children}</Grid>;
};
