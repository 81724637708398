import React from 'react';

import { ProductAppPreview } from '@root/components/www/shared/product_app_preview';
import { ProductComparison } from '@root/components/www/shared/product_comparison';
import { ProductHero } from '@root/components/www/shared/product_hero';
import { ProductOverview } from '@root/components/www/shared/product_overview';
import { ProductReview } from '@root/components/www/shared/product_review';
import { ProductTeam } from '@root/components/www/shared/product_team';
import { DOOR_TO_DOOR_FAQ_CONTENT } from '@root/constants/faq';

import { CheckType } from '@shared/comparison_matrix';
import { Footer } from '@shared/content/footer';
import { IconValueProps } from '@shared/page_builder/components/icon_value_props';
import { ResponsiveBreak } from '@shared/responsive_breaks';
import { ServiceSelection } from '@shared/service_selector/constants';
import { ZebraContainer, ZebraPattern } from '@shared/zebra_container';

import moverTruck from '@images/illustrations/mover_with_truck.svg';
import photoCatalog from '@images/illustrations/online_photo_catalog_small.svg';
import onlineQuote from '@images/illustrations/online_quote_check.svg';
import facility from '@images/illustrations/storage_facility.svg';
import warehouse from '@images_responsive/home/warehouse.jpg';
import amberReview from '@images_responsive/www/services/d2d_review_amber.jpg';
import shannonReview from '@images_responsive/www/services/d2d_review_shannon.jpg';
import doorToDoorHero from '@images_responsive/www/services/door_to_door_hero.jpg';
import dropOffDelivery from '@images_responsive/www/services/drop_off_delivery.jpg';

import { Header } from '../../../shared/header/header';
import { FAQCategories } from '../faq_categories';

const valueProps = [
  {
    title: 'Low monthly rate',
    description:
      'Answer a few questions and we’ll get you a customized quote in under 2 minutes.',
    illustration: onlineQuote,
  },
  {
    title: 'Customize your move-in',
    description:
      'Choose a do-it-yourself option or let our team help you pack and move into storage.',
    illustration: moverTruck,
  },
  {
    title: 'Flexible deliveries & pickups',
    description: `Tap the items you want in your online photo catalog and we’ll deliver them to your home.`,
    illustration: photoCatalog,
  },
] as const;

const serviceFeatures = [
  {
    serviceName: 'Truck & driver available',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Home pickup & delivery available',
    clutter: CheckType.Check,
    competitor1: CheckType.Check,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Packing help available',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Packing supplies available',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Online photo catalog',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
  {
    serviceName: 'Individual item delivery',
    clutter: CheckType.Check,
    competitor1: CheckType.X,
    competitor2: CheckType.X,
  },
];

export const WarehouseStorageServicePage = () => {
  return (
    <>
      <Header service={ServiceSelection.Storage} />
      <ZebraContainer zebra={ZebraPattern.LIGHT_THEN_DARK}>
        <ProductHero
          title="Storage"
          subtitle={`Save by storing in our secure Warehouse. Have your items delivered to your home at your convenience.`}
          image={doorToDoorHero}
          service={ServiceSelection.Storage}
        />
        <ProductOverview
          title={
            <>
              Flexible access, <ResponsiveBreak mode="only" sm />
              lowest monthly rate
            </>
          }
          subtitle="Get an affordable quote online in minutes."
          mainImage={warehouse}
          secondaryImage={dropOffDelivery}
          service={ServiceSelection.Storage}
          valueProps={[
            'Low monthly rate',
            'Online photo catalog of your items',
            'Moving & packing help available',
            'Home pickup & delivery for purchase',
          ]}
        />
        <IconValueProps
          title={
            <>
              The most flexible & <ResponsiveBreak mode="only" sm />
              affordable storage
            </>
          }
          valueProps={valueProps}
          cta={{
            service: ServiceSelection.Storage,
          }}
        />
        <ProductComparison
          title={
            <>
              Modernize <ResponsiveBreak mode="only" sm />
              your storage
            </>
          }
          legend={[
            { name: 'Clutter', icon: facility },
            { name: 'PODS' },
            { name: 'Public Storage' },
          ]}
          serviceFeatures={serviceFeatures}
          service={ServiceSelection.Storage}
        />
        <ProductReview
          title="We’ve stored for 52,000+ customers"
          service={ServiceSelection.Storage}
          reviews={[
            {
              name: 'Amber',
              img: amberReview,
              quote:
                'With everything inventoried and the option to have Clutter bring back any item at any time, we could store our extra belongings without stress or arguments.',
              service: 'Door to Door customer in SF',
            },
            {
              name: 'Shannon',
              img: shannonReview,
              quote:
                'I truly swear by this service. Clutter is extremely convenient storage without the hassle of having to pack, move, and store things yourself.',
              service: 'Door to Door customer in Chicago',
            },
          ]}
        />
        <ProductAppPreview
          title="A technology-first approach to storage"
          description={
            <>
              Our state of the art storage facilities keep your items organized
              and safe.
              <br />
              <br />
              With your online photo catalog browse your items and have them
              delivered directly to your home.
            </>
          }
          service={ServiceSelection.Storage}
        />
        <ProductTeam
          title="Our team"
          content={[
            {
              headline: 'Hand Selected',
              description:
                'We’ve invested in building a full-time team of storage professionals who are thoroughly vetted to be the best of the best.',
            },
            {
              headline: 'Valued',
              description:
                'All team members are W-2 workers that receive full benefits like health insurance and paid time off.',
            },
            {
              headline: 'Trustworthy',
              description:
                'We train our team to sweat the details and to handle your items with the utmost care. The things you love, are safe with us.',
            },
          ]}
        />
        <FAQCategories
          headline={
            <>
              A fresh take on the <br />
              storage industry
            </>
          }
          categories={[{ questions: DOOR_TO_DOOR_FAQ_CONTENT }]}
        />
      </ZebraContainer>
      <Footer />
    </>
  );
};
