import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DateTime } from 'luxon';

import { Box, COLORS, Text, TextButton } from '@clutter/clean';

import {
  AvailabilityFragment,
  Moving__PackingEnum,
  MultidayAvailabilityFragment,
  OrderSubtypeEnum,
  OrderTypeEnum,
  RateGroupKindEnum,
} from '@graphql/platform';

import { useMovingCheckoutContext } from '@root/components/checkout/context';
import { MovingCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';

import { Calendar } from '@shared/calendar';
import {
  FROM_ISO_OPTIONS,
  hashDateTime,
} from '@shared/calendar/calendar_utils';

import { useFilteredAvailabilities } from '@utils/availability';
import { useStabilizedFunction } from '@utils/hooks';
import { useMovingAvailabilities } from '@utils/hooks/availabilities';
import { useAppointmentFunnelEvents } from '@utils/hooks/funnel_events/use_appointment_time_funnel_events';
import { pluralize } from '@utils/pluralize';
import { extractPlan } from '@utils/pricing/pricing_set';
import {
  EventName,
  createThirdPartyConversionEvent,
} from '@utils/third_party_conversion_events';
import { isOutsideNextDayCutoff } from '@utils/time';

import { FeaturesOverlay } from '../../subcomponents/features_overlay';
import { MOVING_FLEXIBLE_SCHEDULING } from '../../subcomponents/features_overlay/data';
import { ArrivalWindowSelector } from '../shared/appointment/arrival_window_selector';
import { DeliveryDateSelector } from '../shared/appointment/delivery_date_selector';

export const Appointment: React.FC<MovingCheckoutStepProps> = ({
  enabled,
  onChange,
  values: {
    datePreferred,
    dateScheduled,
    movingQuote,
    zip,
    packingHelp,
    commitment,
    planSize,
    unpackingScheduled,
    endAddress,
  },
  scrollToStep,
}) => {
  const track = useTrack({ action: 'click' });

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const arrivalWindowSelectorRef = useRef<HTMLDivElement>(null);

  const [availableDays, setAvailableDays] = useState<Set<string> | undefined>(
    undefined,
  );
  const moverCount = movingQuote?.moverSelection ?? undefined;
  const { createQuote, pricingSet } = useMovingCheckoutContext();

  const currentDay = DateTime.local().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const rateGroupName = commitment ?? RateGroupKindEnum.Saver;
  const expectedPlanID =
    planSize && extractPlan(rateGroupName, planSize, pricingSet)?.id;

  const { from, till, availabilities, fetchMore, loading, fetched } =
    useMovingAvailabilities({
      zip: zip!,
      orderType: OrderTypeEnum.Move,
      orderSubtype: OrderSubtypeEnum.None,
      movers: moverCount,
      skip: !zip || !moverCount,
      movingQuote: movingQuote,
      expectedPlanID,
    });

  const filteredAvailabilities = useFilteredAvailabilities(
    availabilities,
    datePreferred,
  );

  const isUnavailable = useStabilizedFunction(
    (value: DateTime, availableDays: Set<string> | undefined) =>
      !availableDays?.has(hashDateTime(value)) ||
      !isOutsideNextDayCutoff(value),
  );

  const {
    trackAppointmentDateCompleted,
    trackArrivalWindowCompleted,
    trackUnpackingDateCompleted,
    trackUnpackingDateViewed,
  } = useAppointmentFunnelEvents({
    enabled,
    from,
    till,
    originZip: zip ?? '',
    destinationZip: endAddress?.zip,
    appointmentDatesShown: fetched,
    arrivalWindowCount: filteredAvailabilities?.length,
    availableDays,
    currentDay,
    isUnavailable,
  });

  useEffect(() => {
    if (loading) {
      return;
    }
    const selectables = new Set<string>();
    for (const availability of availabilities) {
      const selectable = DateTime.fromISO(
        availability.fromTime,
        FROM_ISO_OPTIONS,
      );
      selectables.add(hashDateTime(selectable));
    }
    setAvailableDays(selectables);
  }, [loading, availabilities]);

  const selectDate = (date: DateTime) => {
    if (availableDays?.has(hashDateTime(date))) {
      trackAppointmentDateCompleted(date.toISO());
      onChange('datePreferred', date);
      arrivalWindowSelectorRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
      createThirdPartyConversionEvent(EventName.DATE_PREFERRED, {
        date_preferred: date?.toISO(),
      });
      onChange('dateScheduled', undefined);
      onChange('unpackingScheduled', undefined);
    }
  };

  const onTimeChange = (availability: AvailabilityFragment) => {
    trackArrivalWindowCompleted(availability.formatted);
    onChange('dateScheduled', availability);
    createThirdPartyConversionEvent(EventName.AVAILABILITY, {
      date_scheduled: availability?.fromTime,
    });
    createQuote();
    packingHelp !== Moving__PackingEnum.EverythingIsPacked && scrollToStep();
  };

  const onSelectDeliveryDate = (date: DateTime) => {
    trackUnpackingDateCompleted(date.toISO());
    onChange('unpackingScheduled', date);
  };

  const packingDays = movingQuote?.packingDays;

  const stabilizedTrackUnpackingViewed = useCallback(
    (availableDays: number) => {
      if (datePreferred && typeof packingDays === 'number') {
        trackUnpackingDateViewed({
          packingDate: datePreferred.toISO(),
          availableDays,
          packingDays,
        });
      }
    },
    [datePreferred, packingDays, trackUnpackingDateViewed],
  );

  const handleLearnMoreClick = () => {
    track({
      objectName: 'features',
      objectType: 'button',
      label: 'Flexible scheduling',
    });
    setShowOverlay(true);
  };

  const allOneHourWindows = filteredAvailabilities?.every(
    (availability) => availability.duration === 'PT1H',
  );

  const longDistance = !!movingQuote?.longDistanceTransportationRate;

  const header = longDistance
    ? 'Select start date'
    : 'When do you want to move?';

  const subheader =
    longDistance && packingDays && packingDays > 0
      ? `
        We estimate your move will require ${pluralize(
          packingDays,
          'day',
          'consecutive days',
        )} of packing and loading. Select your start date.
      `
      : 'You can reschedule or cancel for free up to 48 hours before your appointment.';
  return (
    <>
      <Box margin={['48px 0 0', '72px 0 0']}>
        <Box.Grid
          gridTemplateAreas={[
            "'title' 'description' 'link'",
            "'title link' 'description description'",
          ]}
          margin="0 0 32px"
        >
          <Box.GridItem gridArea="title">
            <Text.Title size="small" color={COLORS.tealDark}>
              {header}
            </Text.Title>
          </Box.GridItem>
          <Box.GridItem margin="4px 0 0" gridArea="description">
            <Text.Body color={COLORS.storm}>{subheader}</Text.Body>
          </Box.GridItem>
          <Box.GridItem
            margin={['16px 0 0', 0]}
            textAlign={['left', 'right']}
            gridArea="link"
            style={{ alignSelf: 'center' }}
          >
            <TextButton onClick={handleLearnMoreClick}>
              Flexible scheduling
            </TextButton>
          </Box.GridItem>
        </Box.Grid>

        <Calendar
          from={from}
          till={till}
          fetchMore={loading ? undefined : fetchMore}
          selected={(value) =>
            packingDays && packingDays > 1
              ? !!datePreferred &&
                value.toISODate() >= datePreferred.toISODate() &&
                value.toISODate() <=
                  datePreferred.plus({ days: packingDays - 1 }).toISODate()
              : !!datePreferred &&
                datePreferred.toISODate() === value.toISODate()
          }
          pastDay={(value) => value <= currentDay}
          unavailable={(value) => isUnavailable(value, availableDays)}
          onSelect={(value) => selectDate(value)}
        />
        {datePreferred && !filteredAvailabilities?.length && !loading && (
          <Box textAlign="center">
            <Text.Body>
              We’re fully booked for that date! Please try another date.
            </Text.Body>
          </Box>
        )}

        {longDistance &&
          datePreferred &&
          filteredAvailabilities &&
          typeof packingDays === 'number' &&
          !!filteredAvailabilities?.length && (
            <Box margin="0 0 40px">
              <DeliveryDateSelector
                selectedAvailability={hashDateTime(datePreferred)}
                availabilities={
                  filteredAvailabilities as ReadonlyArray<MultidayAvailabilityFragment>
                }
                unpackingScheduled={unpackingScheduled}
                onSelect={onSelectDeliveryDate}
                trackUnpackingDateViewed={stabilizedTrackUnpackingViewed}
              />
            </Box>
          )}
        <Box
          ref={arrivalWindowSelectorRef}
          style={
            datePreferred && !!filteredAvailabilities?.length
              ? undefined
              : { visibility: 'hidden' }
          }
        >
          <ArrivalWindowSelector
            selectedAvailability={dateScheduled}
            availabilities={filteredAvailabilities ?? []}
            onChange={(newAvailability, _) =>
              onTimeChange(newAvailability as AvailabilityFragment)
            }
            fromTimeOnly={allOneHourWindows}
            isLongDistance={longDistance}
          />
        </Box>
      </Box>
      <FeaturesOverlay
        isOpen={showOverlay}
        onClose={() => setShowOverlay(false)}
        content={MOVING_FLEXIBLE_SCHEDULING}
      />
    </>
  );
};
