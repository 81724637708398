import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import { buildErrorLink } from './build_error_link';
import { buildHttpLink } from './build_http_link';
import { dedupeBy } from './dedupe_by';

export const buildLandingApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            search: {
              keyArgs: ['query'],
              merge(existing, incoming) {
                if (!existing) return incoming;
                return {
                  ...existing,
                  pagination: {
                    ...existing.pagination,
                    ...incoming.pagination,
                  },
                  results: dedupeBy(
                    [...existing.results, ...incoming.results],
                    '__ref',
                  ),
                };
              },
            },
          },
        },
      },
    }),
    link: from([buildErrorLink(), buildHttpLink(`/graphql`)]),
  });
