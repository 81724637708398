import React from 'react';

import { COLORS, FontWeight, Text } from '@clutter/clean';

import { CouponDurationTypeEnum } from '@graphql/platform';

import {
  BannerContainer,
  BannerContentContainer,
  BannerImageContainer,
} from '@shared/banner';
import { useClientDataContext } from '@shared/client_data_context';
import { ServiceSelection } from '@shared/service_selector/constants';

import { useClientDataContextCoupon } from '@utils/hooks/use_client_data_context_coupon';

import tadaImage from '@images/home/tada.png';

export const Banner: React.FC = () => {
  const {
    data: { serviceSelection },
  } = useClientDataContext();

  const { coupon, couponError, expirationDate } = useClientDataContextCoupon();

  if (
    !coupon ||
    !!couponError ||
    (serviceSelection === ServiceSelection.Moving && !coupon.movingEligible)
  ) {
    return null;
  }

  const couponDiscount =
    (coupon.percent ? `${coupon.percent * 100}%` : `$${coupon.amount}`) +
    ' off';

  return (
    <BannerContainer background={COLORS.dust}>
      <BannerImageContainer height="24px" width="24px">
        <img src={tadaImage} alt="" />
      </BannerImageContainer>
      <BannerContentContainer>
        <Text.Callout weight={FontWeight.Medium} color={COLORS.tealDark}>
          {serviceSelection === ServiceSelection.Moving ? (
            <>Save with {couponDiscount} your moving appointment</>
          ) : (
            <>
              Skip the storage unit and save with {couponDiscount} your{' '}
              {coupon.duration !== CouponDurationTypeEnum.Once
                ? 'monthly storage'
                : coupon.percent
                  ? 'first month of storage'
                  : 'appointment'}
            </>
          )}
          {expirationDate && ` when you book before ${expirationDate}`}.
        </Text.Callout>
      </BannerContentContainer>
    </BannerContainer>
  );
};
