/** @jsx jsx */
import * as React from 'react';

import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  Column,
  FontWeight,
  Grid,
  Text,
  transparentize,
} from '@clutter/clean';

import { ButtonLink } from '@shared/button';
import { CustomLink } from '@shared/custom_link';
import { ServerDataContext } from '@shared/server_data_context';

import facebook from '@images/icons/social-facebook.svg';
import gmb from '@images/icons/social-google.svg';
import instagram from '@images/icons/social-instagram.svg';
import twitter from '@images/icons/social-twitter.svg';
import yelp from '@images/icons/social-yelp.svg';
import bbb from '@images/www/bbb.svg';
import ssl from '@images/www/ssl.svg';

const FooterContainer = styled.div`
  background: ${COLORS.tealPrimary};
  padding: 72px 0 56px;
  color: ${transparentize('#FFFFFF', 0.8)};

  & > ${Grid} {
    /* Actual width of 1190px - padding */
    max-width: 1222px;
    /* added padding so bottom zip bar doesn’t overlap footer */
    padding: 0 16px 72px;
    margin: auto;
  }
`;

const PaddedColumn = styled(Column)`
  margin-bottom: 16px;
`;

const NavLink = styled(CustomLink)`
  &,
  &:visited,
  &:active {
    color: white;
    display: block;
    margin-bottom: 16px;
    text-align: left;
    text-decoration: none;
    transition: opacity 0.2s;
    font-weight: bold;
  }

  &:hover {
    color: ${COLORS.tealBackground};
  }
`;

const NavHeader = styled.span`
  font-weight: bold;
  display: block;
  margin-bottom: 16px;

  && {
    text-transform: uppercase;
    text-decoration: none;
    transition: opacity 0.2s;
    opacity: 1;
    color: white;
  }

  &[href]:hover {
    opacity: 0.8;
  }
`;

const loginButtonStyles = css`
  margin-bottom: 16px;
  width: 100%;
`;

const contactButtonStyles = css`
  width: 100%;
`;

const EndorsementLogo = styled.img`
  opacity: 0.75;
  display: inline-block;
  max-width: 80px;
  margin: 0 0 30px;

  &:first-of-type {
    margin-right: 32px;
  }
`;

const SocialContainer = styled.div`
  display: flex;
  margin: 12px auto 0;

  > a {
    margin: 0 10px;
    width: 30px;
  }
`;

const logoColumnStyles = css`
  padding-left: 16px;
  font-size: 14px;
`;

const buttonsColumnStyles = css`
  padding-right: 16px;
  margin-bottom: 40px;
`;

/**
 * Only use react-router Links for WWW (not self-storage facilities pages)
 * There are two issues with using Links for self-storage facilities pages:
 *
 * - Our carousel is glitchy upon navigating with Links
 * - The WWW routes do not exist in its pack, so we'd need to do a hard redirect.
 *   We need to figure out how to do this with react-router (our first attempt failed because we couldn't figure out
 *   how to prevent the actual URL from being pushed to the history on the original page when we want
 *   target="_blank" behavior)
 */

const Footer: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { gmbUrl, yelpUrl } = React.useContext(ServerDataContext);

  return (
    // TODO: fix this tailerv2, used for a spec
    <FooterContainer
      className={'tailerv2 ' + (className || '')}
      role="contentinfo"
      aria-label="footer"
    >
      <Grid wrap>
        <PaddedColumn widths={[12, 12, 3]}>
          <NavHeader>Company</NavHeader>
          <NavLink to="/company">About Us</NavLink>
          <NavLink to="/locations">Locations</NavLink>
          <NavLink to="/company">Company</NavLink>
          <NavLink to="https://careers.clutter.com">Careers</NavLink>
          <NavLink target="_blank" to="https://www.clutter.com/blog">
            Blog
          </NavLink>
          <NavLink
            rel="nofollow"
            to="http://app.impact.com/campaign-mediapartner-signup/Clutter.brand?type=dm&io=IbndLFQZq5rcbk4U6ypo3RdK0SeJddfu2kam9ccWYagq83cTsL46OE94SpUPlNTR"
          >
            Become an Affiliate
          </NavLink>
        </PaddedColumn>
        <PaddedColumn widths={[12, 12, 3]}>
          <NavHeader>Support</NavHeader>
          <NavLink to="/help">Help Center</NavLink>
          <NavLink to="/legal/tos">Legal</NavLink>
          <NavLink to="/legal/privacy_policy">Privacy Policy</NavLink>
          <NavLink to="/legal/california_privacy_rights">
            California Privacy Rights
          </NavLink>
          <NavLink to="/legal/california_privacy_rights#information-sharing">
            Do Not Sell Or Share My Personal Information
          </NavLink>
        </PaddedColumn>
        <PaddedColumn widths={[12, 12, 6]}>
          <Grid wrap>
            <Column widths={[12, 12, 6]} css={buttonsColumnStyles}>
              <ButtonLink
                themeName="tertiary"
                to="https://account.clutter.com"
                css={loginButtonStyles}
              >
                Login to My Account
              </ButtonLink>
              <ButtonLink
                themeName="tertiary"
                to="https://account.clutter.com/support"
                css={contactButtonStyles}
              >
                Contact Customer Support
              </ButtonLink>
              <Box.Flex
                margin="40px 0 0"
                flexDirection="column"
                textAlign="center"
              >
                <Text.Callout weight={FontWeight.Medium} color={COLORS.cloud}>
                  FIND US ON SOCIAL
                </Text.Callout>
                <SocialContainer>
                  <a
                    href="https://www.facebook.com/clutterstorage"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Facebook – link opens in new tab"
                  >
                    <img
                      loading="lazy"
                      src={facebook}
                      alt="Facebook"
                      width={30}
                      height={30}
                    />
                  </a>
                  <a
                    href="https://twitter.com/clutter"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Twitter – link opens in new tab"
                  >
                    <img
                      loading="lazy"
                      src={twitter}
                      alt="Twitter"
                      width={30}
                      height={30}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/clutter"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Instagram – link opens in new tab"
                  >
                    <img
                      loading="lazy"
                      src={instagram}
                      alt="Instagram"
                      width={30}
                      height={30}
                    />
                  </a>
                  <a
                    href={gmbUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Google – link opens in new tab"
                  >
                    <img
                      loading="lazy"
                      src={gmb}
                      alt="Google My Business"
                      width={30}
                      height={30}
                    />
                  </a>
                  <a
                    href={yelpUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Yelp – link opens in new tab"
                  >
                    <img
                      loading="lazy"
                      src={yelp}
                      alt="Yelp"
                      width={30}
                      height={30}
                    />
                  </a>
                </SocialContainer>
              </Box.Flex>
            </Column>
            <Column widths={[12, 12, 6]} css={logoColumnStyles}>
              <EndorsementLogo
                loading="lazy"
                src={ssl}
                width={80}
                height={40}
                alt="SSL"
              />
              <EndorsementLogo
                loading="lazy"
                src={bbb}
                width={80}
                height={40}
                alt="Better Business Bureau"
              />
              <p>Copyright {new Date().getFullYear()} Clutter, Inc.</p>
              <p>All rights reserved.</p>
              <p>DOT Number #2719785</p>
            </Column>
          </Grid>
        </PaddedColumn>
      </Grid>
    </FooterContainer>
  );
};

export { Footer };
