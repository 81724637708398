import * as React from 'react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, Text, mq } from '@clutter/clean';

import saveIcon from '@images/www/guide/save_icon.svg';
import stressIcon from '@images/www/guide/stress_icon.svg';

import { GuideIncentive } from '../types/guide_incentive';
import { IGuideStepProps } from '../types/guide_step';

interface ILandingContent {
  callToAction: string;
  adjective: string;
  iconPath: string;
  imageWidths: string[];
}

const prepareText = (callToAction: string) =>
  `${callToAction} by preparing for your Clutter appointment`;

const tipText = (adjective: string) =>
  `Here are ${adjective} steps to take in advance.`;

const LANDING_CONTENT: Record<GuideIncentive, ILandingContent> = {
  stress: {
    callToAction: 'Reduce stress',
    adjective: 'stress-reducing',
    iconPath: stressIcon,
    imageWidths: ['75px', '75px', '85px', '120px'],
  },
  save: {
    callToAction: 'Save money',
    adjective: 'money-saving',
    iconPath: saveIcon,
    imageWidths: ['55px', '55px', '65px', '80px'],
  },
};

const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${COLORS.tealJungle};
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 24px;
  color: ${COLORS.cloud};
`;

const BlockFlair = styled(Text.SmallCaps)`
  display: block;
  padding-top: 36px;
  padding-bottom: 18px;
`;

const IncentiveIcon = styled.img<{ widths: string[] }>`
  ${({ widths }) =>
    mq({
      width: widths,
    })};
  padding-right: 15px;
`;

const CallToAction = styled(Text.Display)`
  display: inline;
  font-weight: ${FontWeight.Semibold};
`;

const PaddingBody = styled(Text.Body)`
  padding: 24px 0px;
`;

const ReadOnButton = styled.button`
  color: ${COLORS.tealPrimary};
  border: ${COLORS.cloud};
  background-color: ${COLORS.cloud};
  display: inline-block;
  cursor: pointer;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: -0.2px;
  outline: none;
  padding: 12px 32px;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const GuideLanding: React.FC<IGuideStepProps> = ({
  guideIncentive,
  next,
}) => {
  const { iconPath, callToAction, adjective, imageWidths } =
    LANDING_CONTENT[guideIncentive];
  return (
    <Background>
      <ContentWrapper>
        <BlockFlair>THE OFFICIAL GUIDE</BlockFlair>
        <IncentiveIcon widths={imageWidths} src={iconPath} alt={callToAction} />
        <CallToAction>{prepareText(callToAction)}</CallToAction>
        <PaddingBody>{tipText(adjective)}</PaddingBody>
        <ReadOnButton onClick={() => next()}>Read On</ReadOnButton>
      </ContentWrapper>
    </Background>
  );
};
