import { DateTime } from 'luxon';

export const formatDateTime = (dt: string) =>
  DateTime.fromISO(dt).toLocaleString({
    day: 'numeric',
    weekday: 'short',
    month: 'long',
    hour: 'numeric',
    minute: '2-digit',
  });
