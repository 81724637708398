export enum ServiceSelection {
  Storage = 'storage',
  Moving = 'moving',
  Disposal = 'disposal',
}

export const SERVICE_SELECTION_OPTIONS = [
  {
    value: ServiceSelection.Storage,
    label: 'Storage',
  },
  {
    value: ServiceSelection.Moving,
    label: 'Moving',
  },
];

export const SERVICE_SELECTION_OPTIONS_CHALLENGER = [
  {
    value: ServiceSelection.Storage,
    label: 'Storage',
  },
  {
    value: ServiceSelection.Moving,
    label: 'Moving',
  },
  {
    value: ServiceSelection.Disposal,
    label: 'Disposal',
  },
];
