import * as React from 'react';

import styled from '@emotion/styled';

import {
  COLORS,
  FontWeight,
  Input,
  Text,
  UnstyledButton,
} from '@clutter/clean';

import { XButton } from '../icons/x_button';
import { Actions } from 'downshift';
import DownShift from 'downshift';

const LeftIcon = styled.div`
  width: 46px;
  height: 100%;
  background: ${COLORS.grayBorder};
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px 0 0 3px;
  cursor: default;
`;

const StyledInput = styled(Input)<{ hasClearButton: boolean }>`
  ${({ hasClearButton }) => hasClearButton && 'padding-right: 32px;'}
  width: 100%;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  background: white;
  display: inline-block;
  border-radius: 4px;

  ${StyledInput} {
    z-index: 1;
    background: transparent;
    position: relative;
  }
  ${LeftIcon} + ${StyledInput} {
    padding-left: 62px;
  }
`;

const PromptContainer = styled.div<{
  state?: 'error' | 'valid';
}>`
  width: 100%;
  height: 36px;
  color: ${({ state }) =>
    state === 'error'
      ? COLORS.toucan
      : state === 'valid'
        ? COLORS.tealPrimary
        : COLORS.storm};

  padding: 10px 0 0;
`;

const ClearButton = styled(UnstyledButton)`
  pointer-events: all;
  position: absolute;
  right: 12px;
  top: 16px;
  z-index: 1;
`;

interface IFieldAutocompleteOption<T = any> {
  key: string;
  value: T;
  Component?: React.ComponentType<{
    option: IFieldAutocompleteOption<T>;
    highlighted?: boolean;
  }>;
  [key: string]: any;
}

interface IFieldAutocompleteProps<T = any> {
  value?: T;
  placeholder?: string;
  field: string;
  options: Array<IFieldAutocompleteOption<T>>;
  disabled?: boolean;
  predictionsClassName?: string;
  className?: string;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  inputValue?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  hideClearButton?: boolean;
  Menu?: React.ComponentType;
  error?: string | string[];
  prompt?: React.ReactNode;
  icon?: React.ReactNode;
  valid?: boolean;
  itemToString?(item: IFieldAutocompleteOption<T> | null): string;
  onChange(field: string, selectedOption: IFieldAutocompleteOption<T>): void;
  onInputChange?(inputValue: string): void;
  onClear?(): void;
  selectFirstOnBlur?: boolean;
  testId?: string;
}

interface IFieldAutocompleteState {
  input: string;
  isOpen: boolean;
}

class FieldAutocomplete extends React.Component<
  IFieldAutocompleteProps,
  IFieldAutocompleteState
> {
  public state = {
    input: '',
    isOpen: false,
  };

  private changedSinceSelection = false;

  private inputRef = this.props.innerRef || React.createRef<HTMLInputElement>();
  private itemToString =
    this.props.itemToString ||
    ((item: IFieldAutocompleteOption | null) => item && item.value);

  public render() {
    const {
      placeholder,
      inputProps,
      className,
      Menu,
      disabled,
      hideClearButton,
      prompt,
      error,
      icon,
      valid,
      testId,
    } = this.props;

    const promptMessage = error || prompt;

    const MenuComponent = Menu || 'ul';
    return (
      <DownShift
        onChange={this.onChange}
        itemToString={this.itemToString}
        onInputValueChange={this.onInputValueChange}
        selectedItem={this.props.value ?? null}
        inputValue={this.getInputValue()}
        stateReducer={this.stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          inputValue,
          clearSelection,
          selectItemAtIndex,
          openMenu,
        }) => (
          // Inline `style` used due to downshift not playing well with emotion
          <div
            className={className}
            data-test-id={testId}
            style={{ position: 'relative' }}
          >
            <div>
              <div
                className="control has-icons-right"
                style={{ cursor: 'pointer' }}
              >
                <InputContainer>
                  {icon && <LeftIcon>{icon}</LeftIcon>}
                  <StyledInput
                    {...(getInputProps({
                      ref: this.inputRef,
                      placeholder,
                      disabled,
                      autoComplete: 'chrome-off',
                      onBlur: () =>
                        this.onInputBlur(selectItemAtIndex, openMenu),
                      ...inputProps,
                    }) as React.ComponentProps<typeof StyledInput>)}
                    hasClearButton={!this.props.hideClearButton}
                    placeholder={this.props.placeholder}
                    state={
                      error && !isOpen ? 'error' : valid ? 'valid' : undefined
                    }
                  />
                </InputContainer>
                {!hideClearButton && (
                  <ClearButton onClick={this.clearInput(clearSelection)}>
                    <XButton />
                  </ClearButton>
                )}
              </div>
            </div>
            {promptMessage && (
              <PromptContainer
                state={error ? 'error' : valid ? 'valid' : undefined}
              >
                <Text.Caption weight={FontWeight.Medium}>
                  {promptMessage}
                </Text.Caption>
              </PromptContainer>
            )}

            {isOpen && inputValue !== '' ? (
              <div {...getMenuProps()}>
                <MenuComponent>
                  {this.props.options.map((item, index) => {
                    const { Component: Item } = item;
                    return (
                      <li
                        key={item.key}
                        {...getItemProps({
                          key: item.key,
                          index,
                          item,
                        })}
                      >
                        {Item ? (
                          <Item
                            option={item}
                            highlighted={highlightedIndex === index}
                          />
                        ) : (
                          this.itemToString(item)
                        )}
                      </li>
                    );
                  })}
                </MenuComponent>
              </div>
            ) : null}
          </div>
        )}
      </DownShift>
    );
  }

  private clearInput = (clearSelection: () => void) => () => {
    clearSelection();
    this.inputRef.current!.value = '';
    this.inputRef.current!.focus();
    this.props.onClear?.();
  };

  // We use this instead of the inputValue provided by DownShift as that relies on controlled component
  // state which doesn't work properly using SSR. The user will type into the input, and then once
  // the JS is loaded, the text that had typed will disappear.
  private getInputValue = () => {
    if (this.props.inputValue) {
      return this.props.inputValue;
    } else if (this.state.input) {
      return this.state.input;
    } else if (this.inputRef.current && this.inputRef.current.value) {
      return this.inputRef.current.value;
    } else {
      return '';
    }
  };

  private onChange = (option: IFieldAutocompleteOption | null) => {
    if (!option) {
      return;
    }
    this.inputRef.current!.blur();
    if (this.props.onChange) {
      this.props.onChange(this.props.field, option);
    }
  };

  private onInputValueChange = async (input: string) => {
    this.setState({ input });
    if (this.props.onInputChange) {
      this.props.onInputChange(input);
    }
  };

  private stateReducer = (state: any, changes: any) => {
    switch (changes.type) {
      case DownShift.stateChangeTypes.keyDownEnter:
      case DownShift.stateChangeTypes.clickItem:
        this.changedSinceSelection = false;
        return changes;
      case DownShift.stateChangeTypes.changeInput:
        this.changedSinceSelection = true;
        return changes;
      default:
        return changes;
    }
  };
  private onInputBlur = (
    selectItemAtIndex: Actions<
      IFieldAutocompleteOption<any>
    >['selectItemAtIndex'],
    openMenu: Actions<IFieldAutocompleteOption<any>>['openMenu'],
  ) => {
    // selectFirstOnBlur is a prop that toggles the behavior
    //
    // changedSinceSelection is a flag that lets us know if the user has typed since
    //   actively selecting an autocomplete suggestion. This allows the user to
    //   click a suggestion and choose it without this select-on-first overwriting them.
    //
    // in some cases, Downshift closes the menu before the blur event fires, so we
    //   open it first to make sure we have something to select
    if (this.props.selectFirstOnBlur) {
      if (this.changedSinceSelection) {
        openMenu(() => selectItemAtIndex(0));
      }
    }
  };
}
export { FieldAutocomplete, IFieldAutocompleteProps };
