import { Duration } from 'luxon';

import {
  FinalReturnCostSummaryFragment,
  LaborBillingFormatEnum,
  OnboardingCostSummaryFragment,
  PricingSetFragment,
  RateGroupKindEnum,
  StorageBundleFragment,
  useEstimatedMoversQuery,
  useFinalReturnCostSummariesQuery,
  useOnboardingCostSummariesQuery,
  useStorageBundlesQuery,
} from '@graphql/platform';

import { Commitment } from '@root/resources/types/commitment';
import { PlanKey } from '@root/resources/types/plan_key';

import { pluralize } from '@utils/pluralize';
import { amountForFinalPackageSetEntry } from '@utils/pricing';
import { extractLaborRateAmount } from '@utils/pricing/formatted_onboarding_fees';
import {
  extractLaborPolicy,
  extractPlan,
  extractStoragePrice,
} from '@utils/pricing/pricing_set';

import {
  AppointmentQuoteCreateResult,
  useAppointmentQuoteCreate,
} from './use_create_appointment_quote';

export type PricingSummary = {
  estimatedMovers: number;
  moverMonthlyAmount: number;
  flexerMonthlyAmount: number;
  saverMonthlyAmount: number;
  /** The maximum possible onboarding fee for the current plan */
  maxOnboardingFeeAmount: number;
  /** The maximum possible labor rate for the current plan regardless of service type */
  maxLaborRateAmount: number;
  /** The potential onboarding fee for the current plan and commitment if a fee is charged for the desired service type */
  onboardingFeeAmount: number;
  /** The potential final return fee for the current plan and commitment if a fee is charged for the desired service type */
  finalReturnFeeAmount: number;
  /** Number of free hours for a final return */
  finalReturnFreeDuration?: number;
  /** Formatted free hours if a final return receives a partially free return, e.g. "3 hours" */
  formattedFinalReturnFreeDuration?: string;
  /** The potential labor rate for the current plan and commitment if labor is charged for full service*/
  fullServiceLaborRateAmount: number;
  /** The monthly amount for the current plan, commitment, and service type */
  monthlyAmount: number;
  /** Monthly savings based on the most expensive monthly option for the current plan, dollar value */
  monthlySavingsAmount: number;
  /** Monthly savings based on the most expensive monthly option for the current plan, 0-100 */
  monthlySavingsPercent: number;
  /** Fee savings based on the most expensive fee, dollar value */
  onboardingFeeSavingsAmount: number;
  /** Fee savings based on the most expensive fee, 0-100 */
  onboardingFeeSavingsPercent: number;
  /** Labor rate percent off based on the most expensive labor rate, dollar value */
  laborSavingsAmount: number;
  /** Labor rate percent off based on the most expensive labor rate, 0-100 */
  laborSavingsPercent: number;
  /** Commitment length in months for the current commitment */
  commitmentLength: number;
  /** Whether a move is billed by the number of movers or a flat hourly rate  */
  laborBillingFormat?: LaborBillingFormatEnum;
  /** Number of free hours for an onboarding */
  freeDuration?: number;
  /** Formatted free hours if an onboarding receives a partially free pickup, e.g. "3 hours" */
  formattedFreeDuration?: string;
  /** The commitments that come with a free pickup */
  freePickupCommitments: number[];
  /** True if any commitment comes with free labor */
  anyCommitmentHasFreePickup: boolean;
  /** True if the appointment is free based on the selected commitment length */
  selectedCommitmentHasFreePickup: boolean;
  /** The unmultiplied labor rate amount for per-mover-hour billing */
  baseLaborRateAmount: number;
  /** An array of available storage "bundles" */
  bundles: ReadonlyArray<StorageBundleFragment>;
};

const extractOnboardingFeeAmount = (quote: AppointmentQuoteCreateResult) =>
  quote.onboardingPackageSetEntry?.amount ?? 0;

/** Get the percentage off (0-100) rounded to the nearest multiple of 5 */
export const roundedDiscountPercentage = (
  fullAmount: number,
  partialAmount: number,
) => Math.round((1 - partialAmount / fullAmount) * 20) * 5;

export const usePricingSummary = ({
  pricingSet,
  zip,
  commitment,
  planSize,
  customerToken,
  moverCountOverride,
}: {
  pricingSet?: PricingSetFragment;
  zip?: string;
  commitment?: Commitment;
  planSize?: PlanKey;
  customerToken?: string;
  moverCountOverride?: number;
}): PricingSummary | null => {
  function useBoundQuote(rateGroupName?: string) {
    return useAppointmentQuoteCreate({
      zip,
      pricingSet,
      rateGroupName,
      planSize,
      customerToken,
    });
  }

  const plan =
    planSize &&
    pricingSet &&
    extractPlan(RateGroupKindEnum.Saver, planSize, pricingSet);

  const planId = plan?.id;
  const planCuft = plan?.cuft || 100; // Defaulting to minimum cuft

  const estimatedMovers = useEstimatedMoversQuery({
    variables: {
      planId: planId!,
      postalCode: zip,
    },
    skip: !planId,
  }).data?.estimatedMovers;
  const moverCount = moverCountOverride ?? estimatedMovers;

  const fullServiceName = commitment;

  const rateGroupKinds = [
    RateGroupKindEnum.Saver,
    RateGroupKindEnum.Flexer,
    RateGroupKindEnum.Mover,
  ];

  const pricingSetId = pricingSet?.id;

  const costSummaries = useOnboardingCostSummariesQuery({
    variables: {
      pricingSetId: pricingSetId!,
      planIds: [planId!],
      rateGroupKinds,
    },
    skip: !pricingSetId || !planId,
  }).data?.result;

  const finalReturnCostSummaries = useFinalReturnCostSummariesQuery({
    variables: {
      pricingSetId: pricingSetId!,
      planIds: [planId!],
      rateGroupKinds,
    },
    skip: !pricingSetId || !planId,
  }).data?.result;

  const bundles = useStorageBundlesQuery({
    variables: { cuft: planCuft, challengerVariant: true },
  }).data?.bundles;

  // Monthly Amount
  const saverMonthlyAmount = extractStoragePrice(
    RateGroupKindEnum.Saver,
    planSize,
    pricingSet,
  );
  const flexerMonthlyAmount = extractStoragePrice(
    RateGroupKindEnum.Flexer,
    planSize,
    pricingSet,
  );
  const moverMonthlyAmount = extractStoragePrice(
    RateGroupKindEnum.Mover,
    planSize,
    pricingSet,
  );

  // Onboarding Fees
  const {
    quote: fullServiceMoverAppointmentQuote,
    loading: moverQuoteLoading,
  } = useBoundQuote(RateGroupKindEnum.Mover);
  const {
    quote: fullServiceFlexerAppointmentQuote,
    loading: flexerQuoteLoading,
  } = useBoundQuote(RateGroupKindEnum.Flexer);
  const {
    quote: fullServiceSaverAppointmentQuote,
    loading: saverQuoteLoading,
  } = useBoundQuote(RateGroupKindEnum.Saver);

  const [fullServiceAppointmentQuote, monthlyAmount, commitmentLength] =
    (() => {
      if (!commitment) return [];

      switch (commitment) {
        case RateGroupKindEnum.Mover:
          return [
            fullServiceMoverAppointmentQuote,
            moverMonthlyAmount,
            1,
          ] as const;
        case RateGroupKindEnum.Flexer:
          return [
            fullServiceFlexerAppointmentQuote,
            flexerMonthlyAmount,
            4,
          ] as const;
        case RateGroupKindEnum.Saver:
          return [
            fullServiceSaverAppointmentQuote,
            saverMonthlyAmount,
            8,
          ] as const;
      }
    })();

  const quoteLoading =
    moverQuoteLoading || flexerQuoteLoading || saverQuoteLoading;

  if (
    quoteLoading ||
    !moverCount ||
    !saverMonthlyAmount ||
    !flexerMonthlyAmount ||
    !moverMonthlyAmount ||
    !monthlyAmount ||
    !fullServiceAppointmentQuote ||
    commitmentLength === undefined
  )
    return null;

  const fullServiceLaborPolicy =
    fullServiceName && extractLaborPolicy(fullServiceName, pricingSet);

  // Summary fields by rate group
  const moverOnboardingFeeAmount = extractOnboardingFeeAmount(
    fullServiceMoverAppointmentQuote,
  );
  const moverLaborRateAmount = extractLaborRateAmount({
    laborRate: fullServiceMoverAppointmentQuote.laborRate,
    estimatedMovers: moverCount,
    laborBillingFormat: fullServiceLaborPolicy?.laborBillingFormat,
  });
  const finalReturnFeeAmount = amountForFinalPackageSetEntry(
    fullServiceAppointmentQuote,
  );

  // Labor rate by service type
  const fullServiceLaborRateAmount = extractLaborRateAmount({
    laborRate: fullServiceAppointmentQuote.laborRate,
    estimatedMovers: moverCount,
    laborBillingFormat: fullServiceLaborPolicy?.laborBillingFormat,
  });

  // Fields for current selection
  const onboardingFeeAmount =
    fullServiceAppointmentQuote.onboardingPackageSetEntry?.amount ?? 0;

  const monthlySavingsAmount = moverMonthlyAmount - monthlyAmount;
  const onboardingFeeSavingsAmount =
    moverOnboardingFeeAmount - onboardingFeeAmount;
  const laborSavingsAmount = moverLaborRateAmount - fullServiceLaborRateAmount;

  // Savings rounded down to the nearest multiple of 5
  const monthlySavingsPercent = roundedDiscountPercentage(
    moverMonthlyAmount,
    monthlyAmount,
  );
  const onboardingFeeSavingsPercent = roundedDiscountPercentage(
    moverOnboardingFeeAmount,
    onboardingFeeAmount,
  );
  const laborSavingsPercent = roundedDiscountPercentage(
    moverLaborRateAmount,
    fullServiceLaborRateAmount,
  );

  const hasFreePickup = (quote: AppointmentQuoteCreateResult) =>
    (quote.laborRate?.amount ?? 0) === 0 &&
    (quote.onboardingPackageSetEntry?.amount ?? 0) === 0;
  const maxOnboardingFeeAmount = moverOnboardingFeeAmount;
  const freePickupCommitments = [];
  const hasFreePickupMover = hasFreePickup(fullServiceMoverAppointmentQuote);
  const hasFreePickupFlexer = hasFreePickup(fullServiceFlexerAppointmentQuote);
  const hasFreePickupSaver = hasFreePickup(fullServiceSaverAppointmentQuote);
  if (hasFreePickupMover) freePickupCommitments.push(0);
  if (hasFreePickupFlexer) freePickupCommitments.push(4);
  if (hasFreePickupSaver) freePickupCommitments.push(8);

  const anyCommitmentHasFreePickup = freePickupCommitments.length > 0;
  const selectedCommitmentHasFreePickup =
    freePickupCommitments.includes(commitmentLength);

  const { freeDuration, formattedFreeDuration } = parseFreeDuration(
    costSummaries,
    commitment,
  );

  const {
    freeDuration: finalReturnFreeDuration,
    formattedFreeDuration: formattedFinalReturnFreeDuration,
  } = parseFreeDuration(finalReturnCostSummaries, commitment);

  return {
    estimatedMovers: moverCount,
    moverMonthlyAmount,
    flexerMonthlyAmount,
    saverMonthlyAmount,
    maxOnboardingFeeAmount,
    maxLaborRateAmount: moverLaborRateAmount,
    onboardingFeeAmount,
    finalReturnFeeAmount,
    finalReturnFreeDuration,
    formattedFinalReturnFreeDuration,
    fullServiceLaborRateAmount,
    monthlyAmount,
    monthlySavingsAmount,
    monthlySavingsPercent,
    onboardingFeeSavingsAmount,
    onboardingFeeSavingsPercent,
    laborSavingsAmount,
    laborSavingsPercent,
    laborBillingFormat: fullServiceLaborPolicy?.laborBillingFormat,
    freeDuration,
    formattedFreeDuration,
    commitmentLength,
    freePickupCommitments,
    selectedCommitmentHasFreePickup,
    anyCommitmentHasFreePickup,
    baseLaborRateAmount: fullServiceAppointmentQuote.laborRate?.amount ?? 0,
    bundles: bundles ?? [],
  };
};

function parseFreeDuration(
  costSummaries:
    | ReadonlyArray<
        OnboardingCostSummaryFragment | FinalReturnCostSummaryFragment
      >
    | undefined,
  commitment: RateGroupKindEnum | undefined,
) {
  const freeDurationISO =
    costSummaries?.find((s) => s.rateGroupKind === commitment)?.freeDuration ??
    undefined;

  const freeDuration =
    freeDurationISO !== undefined
      ? Duration.fromISO(freeDurationISO).shiftTo('minutes').minutes / 60
      : undefined;

  const formattedFreeDuration = freeDuration
    ? pluralize(freeDuration, 'hour', 'hours')
    : undefined;
  return { freeDuration, formattedFreeDuration };
}
