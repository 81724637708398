import React from 'react';
import { Route, Switch } from 'react-router';

import { NotFound } from '@root/components/errors/not_found';

import { Checkout } from '../checkout/checkout';

// Should be used instead of React Router's plain `Switch` so
// we can have a consistent collection of shared routes
export const SwitchWithSharedRoutes: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <Switch>
    {children}
    <Route path="/book/:service" component={Checkout} />
    <Route component={NotFound} />
  </Switch>
);
