import * as React from 'react';

import badPull from '@images/www/guide/good_bad/bad/pull_items.png';
import goodPull from '@images/www/guide/good_bad/good/pull_items.png';

import { IGuideImage } from '../guide_image';
import { IGuideStepProps } from '../types/guide_step';
import { ImageStep } from './image_step';

const PULL_STEP_IMAGES: IGuideImage[] = [
  {
    captionText: 'Pull items from furniture',
    captionKind: 'Good',
    imagePath: goodPull,
  },
  {
    captionText: 'Don’t wait to sort items',
    captionKind: 'Bad',
    imagePath: badPull,
  },
];

const PULL_STEP_TITLE = 'Pull items out of closets, drawers, and shelves';
const PULL_STEP_DETAILS =
  'Clearing out furniture and closets will help you organize items to store together.';

export const PullStep: React.FC<IGuideStepProps> = (props) => (
  <ImageStep
    guideImages={PULL_STEP_IMAGES}
    title={PULL_STEP_TITLE}
    details={PULL_STEP_DETAILS}
    {...props}
  />
);
